import { IKTSVG } from "components/ui/IKTSVG";
import { Tooltip } from "react-tooltip";
import EDITSVG from "../../../../../assets/media/icons/other_icons/edit-square.svg";

const MovementRowAction = ({
  list,
  sIndex,
  matchedView,
  handleAction,
  handleEditClick,
}: any) => {
  return (
    <>
      <div className={` bg-opacity-[7%]`}>
        <div
          className={`flex items-center justify-center relative w-full h-full border-l`}
          id="left-shad-list"
        >
          <div className="w-[6.5625rem] max-w-[6.5625rem] min-w-[6.5625rem] border-r subscription_drop p-[.625rem] h-full ">
            {(list?.seller_level?.weightage < list?.proposed_level?.weightage ||
              list?.seller_level?.weightage >
                list?.proposed_level?.weightage) && (
              <>
                <button
                  className={`min-w-[5rem] group inline-flex group items-center justify-center text-white hover:text-white bg-violet-500 hover:bg-indigo-500 transition font-medium rounded text-sm13 gap-2 py-1 px-1.5  h-7  `}
                  type="button"
                  id={`delete-record-view-tooltip${sIndex}`}
                  onClick={() => {
                    handleAction({ list, action: "downgrade" });
                  }}
                >
                  {list?.seller_level?.weightage <
                  list?.proposed_level?.weightage
                    ? "Upgrade"
                    : "Downgrade"}
                </button>
                <Tooltip
                  anchorId={`delete-record-view-tooltip${sIndex}`}
                  content={
                    list?.seller_level?.weightage <
                    list?.proposed_level?.weightage
                      ? "Confirm upgrade"
                      : "Confirm downgrade"
                  }
                  place={`${sIndex === 0 ? "left" : "top"}`}
                  variant="light"
                  className={`!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10  ${
                    sIndex === 0 ? "mt-0" : "mt-1.5"
                  }`}
                />
              </>
            )}
          </div>
          {/* EDIT BUTTON */}
          <div className="flex items-center justify-center p-1 md:p-1 w-8  md:flex-auto flex-1 max-md:hidden">
            <button
              className={` group inline-flex group items-center justify-center text-white hover:text-white bg-violet-500 hover:bg-indigo-500 transition font-medium rounded text-sm13 gap-2 p-1 w-7 h-7  `}
              type="button"
              id={`edit-record-tooltip${sIndex}`}
              onClick={() => {
                // handOnDeleteLevel(list);
                // layout.setSellerLevelEditData(list);
                handleEditClick(list);
              }}
            >
              <Tooltip
                anchorId={`edit-record-tooltip${sIndex}`}
                content={`Edit Seller Level Configuration`}
                place={`${sIndex === 0 ? "left" : "top"}`}
                variant="light"
                className={`!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10  ${
                  sIndex === 0 ? "mt-0" : "mt-1.5"
                }`}
              />

              <IKTSVG
                className={`flex items-center justify-center fill-white group-hover:fill-white transition`}
                svgClassName="w-4 h-[.6875rem]"
                path={EDITSVG}
              />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default MovementRowAction;
