import { IKTSVG } from "components/ui/IKTSVG";
import { TX_TAB } from "pages/tx_pay/core/const";
import { TXContext } from "pages/tx_pay/core/TXProvider";
import { useContext } from "react";
import downAngleSVG from "../../../../assets/media/icons/other_icons/down-angle.svg";

const TableHeader = ({
  title,
  className,
  cellWidth,
  isArrow = false,
  postKey,
  padding,
}: any) => {
  const layout = useContext(TXContext);
  let order =
    layout?.activeTab === TX_TAB.wallet
      ? layout.TXPayFilterHandle
      : layout.virtualFilterHandle;

  const setFilters =
    layout?.activeTab === TX_TAB.wallet
      ? layout.setTXPayFilterHandle
      : layout.setVirtualFilterHandle;

  const handleSort = (sortedKey: any) => {
    setFilters((preValue: any) => {
      return {
        ...preValue,
        order_by: sortedKey,
        sort_order:
          preValue &&
          Object.keys(preValue).length > 0 &&
          preValue.order_by !== sortedKey
            ? "desc"
            : preValue.sort_order === "desc"
            ? "asc"
            : preValue.sort_order === "asc"
            ? undefined
            : "desc",
        page: 1,
      };
    });
  };

  return (
    <th
      className={`px-2.5 py-3 font-medium whitespace-nowrap ${cellWidth} ${padding}`}
      key={title}
    >
      {isArrow ? (
        <div className="w-full pr-3 relative">
          {title}
          <button type="button" onClick={() => handleSort(postKey)}>
            <IKTSVG
              path={downAngleSVG}
              className={`fill-gray-400 group-hover:fill-white transition absolute top-1/2 right-1 -translate-y-2/4 ${
                // @ts-ignore
                order?.order_by === postKey && order?.sort_order === "desc"
                  ? "rotate-180 !fill-violet-500"
                  : order?.order_by === postKey && order?.sort_order === "asc"
                  ? " !fill-violet-500"
                  : "fill-gray-400"
              }
                  `}
            />
          </button>
        </div>
      ) : (
        title
      )}
    </th>
  );
};

export default TableHeader;
