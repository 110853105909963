/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { CSSProperties, useEffect, useRef, useState } from "react";
// import { default as ReactSelect, components,  } from "react-select";
import SVG from "react-inlinesvg";
import Select, {
  ClearIndicatorProps,
  NoticeProps,
  SingleValueProps,
  components,
} from "react-select";
// import CrossSVG from "../../assets/media/icons/standard_icons/cross.svg";
import _ from "lodash";
import eventMenu from "../../assets/media/icons/other_icons/event_cat.svg";
import infoIcon from "../../assets/media/icons/other_icons/info.svg";
import CrossSVGdef from "../../assets/media/icons/standard_icons/def_cross.svg";
import CrossSVGhov from "../../assets/media/icons/standard_icons/hover_cross.svg";
import Warning1 from "../../assets/media/icons/standard_icons/warning1.svg";
import { colorVirtualCardForDropDown } from "../../helpers/AssetHelpers";
import { tooltipContentForTicketType } from "../../pages/add_inventory/core/_functions";
import TooltipPortal from "../../pages/tx_trade/table/component/TooltipPortal";
import { IKTSVG } from "../ui/IKTSVG";
import { KTSVG } from "../ui/KTSVG";

const customStyle = {
  option: (base: any, state: any) => {
    // console.log(state);
    return {
      ...base,
      //textTransform: state?.selectProps?.name === 'duration' ? 'initial' : 'inherit',
      textTransform: `initial`,
      backgroundColor: state.isSelected
        ? ""
        : state.isFocused
        ? "#F5F5FE"
        : "white",
      "&:hover": {
        backgroundColor: "#F5F5FE",
        color: "#6F6CFF",
      },
      color: state.isSelected
        ? "#6F6CFF"
        : state.isFocused
        ? "#6F6CFF"
        : "#0D0019",
    };
  },
  noOptionsMessage: (base: any) => {
    return {
      ...base,
      fontSize: "0.75rem",
    };
  },
  loadingMessage: (provided: any) => {
    return {
      ...provided,
      fontSize: "0.75rem",
      textAlign: "center",
    };
  },
  indicatorSeparator: (provided: any) => ({ ...provided, display: "none" }), // Hide the indicator separator
  loadingIndicator: (provided: any) => ({ ...provided, display: "none" }), // Hide the loading indicator

  control: (base: any, state: any) => {
    return {
      ...base,
      // border: '1px solid',
      cussor: "pointer",
      border: state.isFocused ? "2px solid #6f6cff" : "2px solid lightgrey",
      minHeight: "1px",
      minWidth: "9.375rem",
      // boxShadow: state.isFocused ? "0px 0px 0.37rem #6f6cff" : "none",
      // borderColor: "lightgrey",
      borderWidth: "2px",
      borderRadius: "0.25rem",
      boxShadow: "none",
      "&:hover": {
        border: "1px solid",
        borderColor: "#6f6cff ",
        borderWidth: "2px",
        // boxShadow: "#ff8b67"
      },
    };
  },
  container: (provided: any, state: any) => ({
    ...provided,
    // marginTop: 50,
  }),
  valueContainer: (provided: any, state: any) => {
    return {
      ...provided,
      overflow: "visible",
      //textTransform: state?.selectProps?.name === 'duration' ? '' : 'capitalize',
      textTransform: "",
    };
  },
  placeholder: (base: any, state: any) => {
    return {
      ...base,
      position: "absolute",
      fontWeight: "500",
      color: state.selectProps.menuIsOpen ? "black" : "grey",
      top:
        state.hasValue ||
        state.selectProps.inputValue ||
        state.selectProps.isFocused
          ? "-0.75rem"
          : "8%",
      left:
        state.hasValue ||
        state.selectProps.inputValue ||
        state.selectProps.isFocused
          ? "0.37rem"
          : "-10px",
      paddingLeft:
        state.hasValue ||
        state.selectProps.inputValue ||
        state.selectProps.isFocused
          ? "0.37rem"
          : "0.87rem",
      paddingRight:
        (state.hasValue ||
          state.selectProps.inputValue ||
          state.selectProps.isFocused) &&
        "0.37rem",

      transition: "top 0.2s, font-size 0.2s",
      backgroundColor:
        (state.hasValue ||
          state.selectProps.inputValue ||
          state.selectProps.isFocused) &&
        "white",
      fontSize:
        state.hasValue ||
        state.selectProps.inputValue ||
        state.selectProps.isFocused
          ? "0.75rem"
          : "0.94rem",
    };
  },
  dropdownIndicator: (provided: any, state: any) => ({
    ...provided,
    transform: state.selectProps.menuIsOpen && "rotate(180deg)",
  }),
};

const DropDownField = ({
  isCapital = false,
  value,
  handleOnChange,
  isSearchable,
  isMenuPortalTarget,
  options,
  name,
  isLoading,
  loadingMessage,
  noOptionsMessage,
  placeholder,
  selectInputRef,
  multiple = false,
  classNamePrefix,
  id,
  isClearable = true,
  isDisabled = false,
  isOpen = true,
  divClassName,
  className,
  defaultValue,
  menuPlacement = undefined,
  formatOptionLabel,
  getOptionLabel = "name",
  getOptionValue = "id",
  children,
  height,
  handleOnBlur = () => {},
  isValueCapital = false,
  ticketTypeIconVisible = false,
  SingleIcon = false,
  MenuIsOpenHandler = () => {},
  handleInputChange = () => {},
  menuPosition = "absolute",
  menuIsOpen = undefined,
  optionClassEnable = false,
  tabIndex = undefined,
  errorMessage = false,
  errorEventIndex = false,
  connexpayDropdown = false,
  minMenuHeight = undefined,
}: any) => {
  const [focused, setFocused] = useState(false);
  const { ValueContainer, Placeholder } = components;
  const selectRef: any = useRef<any>(null);

  const CustomValueContainer = ({ children, ...props }: any) => {
    return (
      <>
        {SingleIcon && (
          <div className="ddIcon h-full border-r">
            <IKTSVG
              path={eventMenu}
              className="w-7  flex items-center justify-center h-full fill-violet-800"
              svgClassName="w-3.5 h-3.5"
            />
          </div>
        )}
        <ValueContainer {...props}>
          {props.selectProps.placeholder && (
            <Placeholder {...props} isFocused={props.isFocused}>
              <span className={`form`} data-name={name}>
                {props.selectProps.placeholder}
              </span>
            </Placeholder>
          )}
          {React.Children.map(children, (child) =>
            child && child.key !== "placeholder" ? (
              <React.Fragment key={name}>{child}</React.Fragment>
            ) : null
          )}
        </ValueContainer>
      </>
    );
  };
  const MenuList = (props: any, notice: NoticeProps) => {
    return (
      <components.MenuList
        {...props}
        className="scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full"
      >
        {props.children}
      </components.MenuList>
    );
  };

  const ClearIndicator = (props: ClearIndicatorProps<any>) => {
    const {
      getStyles,
      innerProps: { ref, ...restInnerProps },
    } = props;
    return (
      <div
        {...restInnerProps}
        ref={ref}
        style={getStyles("clearIndicator", props) as CSSProperties}
      >
        <div className="w-3 ">
          {" "}
          <KTSVG
            className="flex items-center justify-center fill-violet-800 hover:fill-indigo-500 transition"
            path="other_icons/Clear.svg"
          />
        </div>
      </div>
    );
  };

  const getOptions = () => {
    // const temOptions = [];
    // for (let i = 1; i <= 9000; i++) {
    //   temOptions.push({ id: `option${i}`, name: `Option ${i}` });
    // }

    return options;
  };

  const [currentHoverOption, setCurrentHoverOption] = useState<any>();

  const ClearableOption = ({ children, data, ...props }: any) => {
    const handleClear = (e: any) => {
      e.stopPropagation();
      props.clearValue();
      props.selectProps.onMenuClose();
    };

    return (
      <components.Option
        {...props}
        className={`
            ${
              props.isSelected &&
              props?.selectProps?.isClearable &&
              "actcross items-center"
            }`}
      >
        <div
          style={{
            marginLeft: data?.depth > 0 ? data?.depth * 7 + "px" : "",
            ...(data?.depth > 0 && { display: "flex" }),
          }}
          className={`${
            data?.icon ? "flex items-center w-full gap-2" : ""
          } ${colorForActiveDeclinedUser(children)} ${
            optionClassEnable ? data?.className : ""
          }`}
        >
          {data?.icon && name === "color" && (
            <SVG
              src={data?.icon}
              // className="ml-1  block"
              className={`w-3.5 h-3.5 flex rounded-full fill-${colorVirtualCardForDropDown(
                data?.name
              )} `}
            />
          )}
          {typeof children === "number" || isCapital ? (
            data?.depth > 0 ? (
              <>
                <div className="mr-1">– </div> <div>{children}</div>
              </>
            ) : (
              children
            )
          ) : (
            _.capitalize(children?.toLowerCase())
          )}

          {data?.icon && name !== "color" && <img src={data?.icon} alt="" />}
          {props.isSelected && props?.selectProps?.isClearable && (
            <button
              type="button"
              tabIndex={-1}
              onClick={handleClear}
              className="group absolute right-[.75rem] top-1/2 -translate-y-1/2 w-[.875rem] h-[.875rem] rounded-full bg-white flex items-center justify-center hover:bg-indigo-500"
            >
              <SVG
                src={CrossSVGdef}
                className={"w-[14px] h-[14px] group-hover:hidden"}
              />
              <SVG
                src={CrossSVGhov}
                className={"w-[14px] h-[14px] group-hover:block hidden"}
              />
            </button>
          )}
        </div>

        {ticketTypeIconVisible && (
          <div
            className="flex items-center justify-center"
            data-tooltip-id={`infoTooltip-${props?.innerProps?.id}`}
            // onMouseEnter={() => setCurrentHoverOption(props?.innerProps?.id)}
            // onMouseLeave={() => setCurrentHoverOption(null)} // Add onMouseLeave to clear currentHoverOption
          >
            <TooltipPortal
              id={`infoTooltip-${props?.innerProps?.id}`}
              content={tooltipContentForTicketType(
                props?.data || { id: props?.value }
              )}
              className="text-center !bg-white !opacity-100 shadow !px-[.3125rem] !py-0.5 font-medium  !text-xxs z-[9999999999999] max-w-[10.625rem] cursor-default !text-violet-800"
            />
            <IKTSVG
              path={infoIcon}
              className="ml-1 fill-grey-400 block"
              svgClassName="fill-gray-400 hover:fill-indigo-500 w-3.5 h-3.5 min-w-3.5 min-h-3.5"
            />
          </div>
        )}
      </components.Option>
    );
  };

  const Input = ({ children, ...props }: any) => {
    return <components.Input {...props} autoComplete="nope" />;
  };

  function colorForActiveDeclinedUser(data: any) {
    if (
      typeof data === "string" &&
      data?.trim() === "Active" &&
      !connexpayDropdown
    ) {
      return "active-user";
    } else if (typeof data === "string" && data?.trim() === "Declined") {
      return "decline-user";
    }
    return "";
  }
  const SingleValue = ({ children, ...props }: SingleValueProps<any>) => {
    return (
      <components.SingleValue {...props}>
        {typeof children === "number" || isValueCapital
          ? children
          : typeof children === "string" && (
              <div
                className={`${
                  name === "color" &&
                  "flex items-center gap-2 text-sm13 font-medium"
                }`}
              >
                {name === "color" && (
                  <SVG
                    src={props?.data?.icon}
                    className={`w-3.5 h-3.5  fill-${colorVirtualCardForDropDown(
                      props?.data?.name
                    )} `}
                  />
                )}
                <div
                  className={`${colorForActiveDeclinedUser(children)} ${
                    optionClassEnable ? props?.data?.className : ""
                  }`}
                >
                  {typeof children === "number" || isValueCapital
                    ? children
                    : typeof children === "string" && (
                        <>{_.capitalize(children?.toLowerCase())}</>
                      )}
                </div>
              </div>
            )}
      </components.SingleValue>
    );
  };

  // CLOSE ON SELECT
  const outsideClose = (e: any, id: any) => {
    let filterDiv: any = document.getElementById(id);
    if (
      filterDiv &&
      !filterDiv?.contains(e.target) &&
      selectRef?.current !== undefined
    ) {
      selectRef.current?.props?.onMenuClose();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", (e: any) => outsideClose(e, name));

    return () => {
      document.removeEventListener("mousedown", (e: any) =>
        outsideClose(e, name)
      );
    };
  }, [name]);

  const scrollToTopView = () => {
    setTimeout(() => {
      let tempRef = selectInputRef ? selectInputRef : selectRef;
      const focusedOptionRef: any = tempRef?.current?.focusedOptionRef;
      focusedOptionRef && focusedOptionRef.scrollIntoView();
    }, 1);
  };

  return (
    <div className={`${divClassName} relative`}>
      <Select
        openMenuOnFocus
        isMulti={multiple}
        escapeClearsValue={true}
        hideSelectedOptions={false}
        ref={selectInputRef ? selectInputRef : selectRef}
        defaultValue={defaultValue}
        classNamePrefix={classNamePrefix ? classNamePrefix : "all_dropdown"}
        styles={customStyle}
        className={`${className} ${value ? "login-dd-value" : ""}`}
        onChange={(selectedOption: any) => {
          let event = { target: { name: name, value: selectedOption } };
          handleOnChange(event);
        }}
        menuIsOpen={menuIsOpen}
        // menuIsOpen={true}
        onMenuClose={() => MenuIsOpenHandler(false)}
        onMenuOpen={() => {
          MenuIsOpenHandler(true);
          //scrollToTopView();
        }}
        onInputChange={handleInputChange}
        formatOptionLabel={formatOptionLabel}
        name={name}
        value={value ? value : null}
        options={getOptions()}
        // filterOption={createFilter({ ignoreAccents: false })}
        isClearable={isClearable ? isClearable : false}
        components={{
          Input,
          ValueContainer: CustomValueContainer,
          MenuList,
          SingleValue,
          // ClearIndicator,
          Option: ClearableOption,
        }}
        isSearchable={isSearchable}
        onFocus={(e: any) => {
          setFocused(true);
          handleOnBlur(e);
        }}
        onBlur={(e: any) => {
          setFocused(false);
          handleOnBlur(e);
        }}
        tabIndex={tabIndex}
        // openMenuOnFocus={focused}
        getOptionLabel={(option) => option[getOptionLabel]}
        getOptionValue={(option) => option[getOptionValue]}
        isLoading={isLoading}
        loadingMessage={() => loadingMessage}
        noOptionsMessage={() => noOptionsMessage}
        id={id}
        menuPlacement={menuPlacement ? menuPlacement : "auto"}
        isDisabled={isDisabled}
        placeholder={placeholder}
        menuPosition={menuPosition}
        minMenuHeight={minMenuHeight}
        menuPortalTarget={
          isMenuPortalTarget === true
            ? document.body
            : isMenuPortalTarget
            ? isMenuPortalTarget
            : null
        }
        tabSelectsValue={false}
        theme={(theme: any) => ({
          ...theme,
          spacing: {
            ...theme.spacing,
            baseUnit: 2,
            controlHeight: 10,
            menuGutter: 8,
          },
        })}
      />
      {value?.[getOptionLabel]
        ? ""
        : errorMessage &&
          errorEventIndex && (
            <>
              <TooltipPortal
                id={`error-${errorEventIndex}`}
                content={errorMessage}
                className="text-center z-[99999] !bg-white !opacity-100 shadow px-1.5 py-0.5 font-medium text-xs z-10 max-w-[9rem] !text-rose-500"
              />
              <div
                id={`error-${errorEventIndex}`}
                data-tooltip-id={`error-${errorEventIndex}`}
                className="absolute top-1/2 -translate-y-1/2 right-3 "
              >
                <IKTSVG
                  className="flex items-center justify-center h-3.5 w-3.5 fill-rose-500 hover:fill-rose-500 transition"
                  path={Warning1}
                />
              </div>
            </>
          )}
      {children}
    </div>
  );
};

export default DropDownField;
