import { LayoutContext } from "components/core/LayoutProvider";
import { size } from "lodash";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useQuery } from "react-query";
import NoDataComponent from "../../../components/tableComponent/NoDataComponent";
import ProgressBardLoader from "../../../components/ui/ProgressBardLoader";
import { QUERIES } from "../../../helpers/const";
import {
  sellerLevelConfiguration,
  sellerLevelOptionsWithConfig,
} from "../seller_levels/core/request";
import CountBoxWrapper from "./component/CountBoxWrapper";
import FilterWrapper from "./component/FilterWrapper";
import HeaderFilterWrapper from "./component/HeaderFilterWrapper";
import TableShimmer from "./component/TableShimmer";
import { PersonalDetailsContext } from "./core/PersonalDetailsProvider";
import { getFilterOptions, getListings } from "./core/requests";
import DataTable from "./table/DataTable";

const PersonalDetails = () => {
  const layout = useContext(PersonalDetailsContext);
  const globalLayout = useContext(LayoutContext);
  const [IsToggle, setIsToggle] = useState<any>(true);
  const [overViewHeight, setOverViewHeight] = useState<any>();
  const toggleRef: any = useRef();
  const [lazyLoadLoading, setLazyLoadLoading] = useState(false);
  const [loaderForActionUpdate, setLoaderForActionUpdate] = useState(false);
  const [viewForm, setViewForm] = useState<any>({
    status: false,
    data: undefined,
    apiTrigger: false,
    id: "",
  });
  const [filtersSet, setFiltersSet] = useState(false);
  const [loaded, setLoaded] = useState(false);

  // GET FILTER OPTIONS
  useQuery(QUERIES.PERSONAL_DETAILS_OPTIONS, () => getFilterOptions(), {
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: false,
    onSettled(data, error) {
      if (data) {
        layout.setFilterOptions(data?.data);
      }
    },
  });

  // GET LISTINGS
  const { isLoading: loading, refetch: listRefetch } = useQuery(
    [QUERIES.PERSONAL_DETAILS_LISTING, layout.filterData],
    () => getListings(layout.filterData),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      cacheTime: 0,
      onSettled(data, error) {
        if (data) {
          const updatedData = data?.data?.map((obj: any) => {
            return {
              ...obj,
              status: {
                id: obj?.status,
                name: obj?.status,
                label: obj?.status,
              },
            };
          });

          layout.setListings((pre: any) => {
            if (data?.meta?.current_page > 1) {
              return [...pre, ...updatedData];
            }
            return updatedData;
          });

          layout.setFilterOptions((current: any) => {
            return {
              ...current,
              counts: data?.counts,
            };
          });
          layout.setMeta(data?.meta);
        }
        setLazyLoadLoading(false);
        setLoaderForActionUpdate(false);
      },
    }
  );

  // GET LISTINGS
  const { refetch: refetchLevelAPi } = useQuery(
    [QUERIES.LEVEL_LIST],
    () => sellerLevelConfiguration(),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      cacheTime: 0,
      // enabled: true,
      onSettled(data, error) {
        const newUpdate = data?.data?.lists?.map((obj: any) => {
          return {
            id: obj?.id,
            name: obj?.title,
            label: obj?.title,
          };
        });
        layout.setLevelLists((pre: any) => {
          return {
            ...pre,
            list: data?.data?.lists,
            levelOptions: newUpdate,
          };
        });
      },
    }
  );

  // GET options
  const { data: levelConfigData } = useQuery(
    [QUERIES.SELLER_LEVEL_OPTION],
    () => sellerLevelOptionsWithConfig(),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      cacheTime: 0,
      onSettled(data, error) {
        layout.setLevelLists((pre: any) => {
          return {
            ...pre,
            options: data?.data,
          };
        });
      },
    }
  );

  // LOADING
  const isLoading = useMemo(() => {
    return layout.filterData?.page === 1 && loading;
  }, [layout.filterData?.page, loading]);

  useEffect(() => {
    let height3: any;
    const element: any = document.querySelector("#inventoryaccordion");
    setTimeout(() => {
      height3 = IsToggle === true ? element?.offsetHeight : 0;
      setOverViewHeight(height3);
    }, 400);
  }, [IsToggle]);

  useEffect(() => {
    const stickyElement = document.getElementById("topFilters") as HTMLElement;
    if (stickyElement) {
      const initialPosition = stickyElement.getBoundingClientRect().top;

      let MarginTopTotle = 0;
      let topPosition = 0;

      const recalculateMarginTopTotle = () => {
        const accordion = document.getElementById(
          "inventoryaccordion"
        ) as HTMLElement;
        const accordionStyle = window.getComputedStyle(accordion);
        const accordionheight: any = parseFloat(
          accordionStyle.getPropertyValue("height")
        );
        // Recalculate MarginTopTotle based on updated heights or values
        const heightheightRef1: any =
          document.getElementById("heightRef1")?.clientHeight;

        const HeaderHeight: any =
          document.querySelector("header")?.clientHeight;
        const filter = document.getElementById("filters_block") as HTMLElement;
        const heightheightRef2 = filter.offsetHeight;
        const salesFilter: any =
          document.getElementById("salesFilter")?.clientHeight;

        var computedStyle = window.getComputedStyle(stickyElement);
        var paddingBottom = computedStyle.paddingBottom;
        var paddingBottomValue = parseInt(paddingBottom, 10);

        // topPosition =
        //   heightheightRef2 +
        //   salesFilter +
        //   HeaderHeight +
        //   paddingBottomValue -
        //   1;
        if (window.matchMedia("(max-width: 767px)").matches) {
          topPosition = HeaderHeight;
        } else {
          topPosition =
            heightheightRef2 +
            salesFilter +
            HeaderHeight +
            paddingBottomValue -
            1;
        }
        MarginTopTotle =
          IsToggle === true
            ? heightheightRef1 + accordionheight - HeaderHeight
            : HeaderHeight - heightheightRef1;
      };

      const makeSticky = function () {
        const HeaderHeight: any =
          document.querySelector("header")?.clientHeight;
        const sticky_head: any = document.getElementById("sticky-head");
        const stickyShadow: any = document.getElementById("sticky-shadow");
        const sticky_headHeight: any = sticky_head?.clientHeight;

        if (window.scrollY + HeaderHeight >= initialPosition) {
          stickyElement.classList.add("sticky");
          stickyElement.style.top =
            IsToggle === true ? `-${MarginTopTotle}px` : `${MarginTopTotle}px`;
          sticky_head.style.top = topPosition + "px";
          stickyShadow.style.top = topPosition + sticky_headHeight + "px";
        } else {
          stickyElement.classList.remove("sticky");
          stickyElement.style.top = `0px`;
          sticky_head.style.top = "0px";
          stickyShadow.style.top = "0px";
        }
        recalculateMarginTopTotle();
      };
      const handleWindowResize = () => {
        recalculateMarginTopTotle();
        makeSticky();
      };
      window.addEventListener("scroll", makeSticky);
      window.addEventListener("change", makeSticky);
      window.addEventListener("blur", makeSticky);

      setTimeout(() => {
        document.addEventListener("resize", handleWindowResize);
      }, 200);
      return () => {
        window.removeEventListener("scroll", makeSticky);
        window.removeEventListener("change", makeSticky);
        window.removeEventListener("blur", makeSticky);
        document.removeEventListener("resize", handleWindowResize);
      };
    }
    // });
  }, [toggleRef, IsToggle, overViewHeight]);

  useEffect(() => {
    if (globalLayout.levelGlobal?.length > 0) {
      layout.setFilterData((pre: any) => {
        return {
          ...pre,
          seller_levels: globalLayout.levelGlobal,
        };
      });
    }
  }, [globalLayout.levelGlobal?.length > 0]);

  useEffect(() => {
    if (size(globalLayout?.sellerLevelConfigEdit?.teams) > 0 && !loaded) {
      // First condition to handle filter data setting
      layout?.setFilterData((prev: any) => ({
        ...prev,
        teams: globalLayout?.sellerLevelConfigEdit?.teams,
      }));
      setFiltersSet(true);
    }

    // Second condition to handle view form and tabs setting
    if (
      size(globalLayout?.sellerLevelConfigEdit?.teams) > 0 &&
      !isLoading &&
      filtersSet &&
      layout?.listings?.length > 0 &&
      !loaded
    ) {
      setViewForm({
        status: false,
        apiTrigger: true,
        id: layout?.listings?.[0]?.id,
        mainData: layout?.listings?.[0],
      });

      layout.setPersonalDetailsTabs((pre: any) => ({
        ...pre,
        activeTab: "Seller Level Configuration",
      }));

      setFiltersSet(false);
      setLoaded(true);
    }
  }, [isLoading, loaded]);

  return (
    <div className="main-content-wrap">
      <div
        className={`topFilters sticky-element top-[60px] z-[11] pb-5 bg-gray-200 max-md:!static`}
        id="topFilters"
      >
        {/* HEADER FILTER WRAPPER */}
        <HeaderFilterWrapper
          handleToggle={(data: any) => setIsToggle(!data ? true : false)}
        />

        {/* COUNT BOX WRAPPER */}
        <CountBoxWrapper loading={isLoading} />

        {/* FILTER & CHIPS WRAPPER*/}
        <FilterWrapper loading={isLoading} />
      </div>

      {/* DATA TABLE */}
      {isLoading ? (
        <>
          <TableShimmer loading={isLoading} />
          <ProgressBardLoader
            LoadingText="Loading your Personal details data"
            secondCounter={2}
          />
        </>
      ) : size(layout.listings) > 0 ? (
        <DataTable
          setLazyLoadLoading={setLazyLoadLoading}
          lazyLoadLoading={lazyLoadLoading}
          loading={isLoading}
          listRefetch={listRefetch}
          loaderForActionUpdate={loaderForActionUpdate}
          setLoaderForActionUpdate={setLoaderForActionUpdate}
          viewForm={viewForm}
          setViewForm={setViewForm}
        />
      ) : (
        <NoDataComponent />
      )}
    </div>
  );
};

export default PersonalDetails;
