export const SALES_NEST_ROUTES = {
  PENDING: "pending",
  AWAITING_DELIVERY: "awaiting-delivery",
  DELIVERED: "delivered",
  COMPLETED: "completed",
  CANCELLED: "cancelled",
};

export const ACTIVE_TAB_KEY = {
  [`/sales/${SALES_NEST_ROUTES.PENDING}`]: "new",
  [`/sales/${SALES_NEST_ROUTES.AWAITING_DELIVERY}`]: "to_be_fulfilled",
  [`/sales/${SALES_NEST_ROUTES.DELIVERED}`]: "fulfilled",
  [`/sales/${SALES_NEST_ROUTES.COMPLETED}`]: "completed",
  [`/sales/${SALES_NEST_ROUTES.CANCELLED}`]: "cancelled",
};

export const TAB_WISE_ROUTE = Object.fromEntries(
  Object.entries(ACTIVE_TAB_KEY).map(([key, value]) => [value, key])
);

export const SALES_REDIRECT_ROUTES: any = {
  Pending: `/sales/${SALES_NEST_ROUTES.PENDING}`,
  "Awaiting Delivery": `/sales/${SALES_NEST_ROUTES.AWAITING_DELIVERY}`,
  Delivered: `/sales/${SALES_NEST_ROUTES.DELIVERED}`,
  Completed: `/sales/${SALES_NEST_ROUTES.COMPLETED}`,
  Cancelled: `/sales/${SALES_NEST_ROUTES.CANCELLED}`,
};
