/* eslint-disable @typescript-eslint/no-unused-vars */
import _, { isArray, size } from "lodash";
import moment from "moment";
import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import TablePopup from "../../../components/tableComponent/TablePopup";
import { MarketInsight } from "../../add_inventory/table/components/MarketInsight";

import clsx from "clsx";
import { LayoutContext } from "components/core/LayoutProvider";
import Collapse from "../../../assets/media/icons/other_icons/Collapse";
import HaxPublishGray from "../../../assets/media/icons/other_icons/HaxPublishGray";
import MarketInsightGreen from "../../../assets/media/icons/other_icons/MarketInsightGreen";
import Unpublish from "../../../assets/media/icons/other_icons/Unpublish";
import Calender from "../../../assets/media/icons/standard_icons/Calender";
import ClockOutline from "../../../assets/media/icons/standard_icons/ClockOutline";
import GeoIcon from "../../../assets/media/icons/standard_icons/GeoIcon";
import ListingLines from "../../../assets/media/icons/standard_icons/ListingLines";
import Substraction from "../../../assets/media/icons/standard_icons/Substraction";
import Warning from "../../../assets/media/icons/standard_icons/Warning";
import NoJSXComponent from "../../../components/noJSX/NoJSXComponent";
import ComponentSVG from "../../../components/ui/typography/ComponentSVG";
import {
  addCommaInNumber,
  convertPPEPartnersToLocalFormat,
  handleScroll,
} from "../../../helpers/Functions";
import {
  fetchMarketplacePrice,
  fetchMarketplacePriceWithSection,
} from "../../add_inventory/core/_requsets";
import TooltipPortal from "../../tx_trade/table/component/TooltipPortal";
import {
  calculateAvgPriceForProceedValue,
  visibilityMeterCalculationForInventory,
} from "../core/_functions";
import { getInventoryRecordListing } from "../core/_request";
import EventCount from "./component/EventCount";
import EventFields3 from "./component/EventFields3";
import EventHeader from "./component/EventHeader";

const EventTable = memo(
  ({
    layout,
    list,
    event,
    eIndex,
    users,
    setList,
    publishStatus,
    loadMoreListing,
    loadAllEventListing,
    updateVisibility,
    tableRefSide,
    editData,
    setEditData,
    setIsOpenTicketPopUp,
    matchIndexView,
    teamsSelected,
    isLoading,
    query,
    currentRecordId,
    setCurrentRecordId,
    SortingCurrentID,
    setSortingCurrentID,
    setIsOpenListingQualityPopup,
    setEditView,
  }: any) => {
    const {
      isGodAdmin,
      conversation_rate,
      price_suggestion_factor,
      listingIds,
    } = NoJSXComponent();
    const [tableAlert, setTableAlert] = useState(false);
    const [msgAlert, setMsgAlert] = useState("");
    const [customLoader, setCustomerLoader] = useState<boolean>(false);
    const eventId = layout.eventId;
    const setEventId = layout.setEventId;
    const newInventoryFilter = layout.inventoryNewFilterValue;
    const priceFactor = Number(price_suggestion_factor);
    const coreLayout = useContext(LayoutContext);
    const target_currency = coreLayout.currencyLabel;

    const handleEventScroll = (eventId: any) => {
      if (!event?.isOpen) {
        setTimeout(() => {
          const eventContainer: any = document.getElementById(
            "EventAccordion" + eventId
          ) as HTMLElement;
          if (eventContainer) {
            const filters_block: any =
              document.getElementById("filters_block")?.clientHeight;
            const inventoryFilter: any =
              document.getElementById("InventoryFilter")?.clientHeight;
            const headingOne: any =
              document.getElementById("headingOne")?.clientHeight;
            const HeaderHeight: any =
              document.querySelector("header")?.clientHeight;

            let topOffset: any = 0;
            if (window.matchMedia("(max-width: 767px)").matches) {
              topOffset = HeaderHeight + headingOne;
            } else {
              topOffset = filters_block + inventoryFilter + HeaderHeight + 15;
            }
            const elementPosition: any =
              eventContainer.getBoundingClientRect().top +
              window.scrollY -
              topOffset;
            // Scroll to the calculated position smoothly
            window.scrollTo({
              top: elementPosition,
              behavior: "smooth",
              left: 0,
            });
          }
        }, 100);
      }
    };

    // HANDLE FOR GET EVENT RECORD WITH API ->  inventory/event/${id}/lists
    const handleEventId = useCallback(
      async (id: any, newEvent: any) => {
        const updatedValues = [...eventId, id];
        if (eventId.includes(id)) {
          let newArray = eventId.filter((item: any) => item !== id);
          setEventId(newArray);
        } else {
          setEventId(updatedValues);
        }

        //  GET IF EVENTRECORD EXIST
        let eventRecordExist = list
          ?.filter((event: any) => event?.id === id)
          ?.find((item: any) => item?.eventRecords);

        // IF RECORD NOT EXIST THEN ALLOW TO CALL API
        if (
          eventRecordExist === undefined ||
          !eventRecordExist ||
          _.size(eventRecordExist?.eventRecords) === 0
        ) {
          setCustomerLoader(true);
          let userId =
            users &&
            Array.isArray(users) &&
            users.length > 0 &&
            _.map(users, "id");
          let publishStatusId =
            publishStatus &&
            Array.isArray(publishStatus) &&
            publishStatus.length > 0 &&
            _.map(publishStatus, "id");

          try {
            getInventoryRecordListing({
              id,
              userId,
              publishStatusId,
              teamsSelected,
              orderState: layout.orderState,
              per_page: 20,
              query,
              newInventoryFilter,
              listingIds,
            }).then((response) => {
              setList((prev: any) => {
                return prev.map((event: any) => {
                  if (event?.id === id) {
                    let final = {
                      ...event,
                      isOpen: !event?.isOpen,
                      eventRecords: response?.data?.map((item: any) => {
                        let visibility = visibilityMeterCalculationForInventory(
                          event,
                          item,
                          layout.ticketType
                        );

                        let ppePartners = [];
                        // item?.ticket_exchange_prices &&
                        // size(item?.ticket_exchange_prices) > 0 &&
                        // item?.ticket_exchange_prices?.map((exchange: any) => {
                        //   return {
                        //     id: exchange?.reseller_id,
                        //     name: exchange?.reseller_name,
                        //     value: {
                        //       symbol: exchange?.sell_price_currency,
                        //       value: exchange?.sell_price,
                        //       name: exchange?.reseller_id,
                        //     },
                        //     exchange_id: exchange?.exchange_id,
                        //   };
                        // });

                        if (
                          item?.ticket_exchange_prices &&
                          size(item?.ticket_exchange_prices) > 0 &&
                          Array.isArray(item?.ticket_exchange_prices)
                        ) {
                          // ppePartners = item?.ticket_exchange_prices?.map(
                          //   (exchange: any) => {
                          //     return {
                          //       id: exchange?.reseller_id,
                          //       name: exchange?.reseller_name,
                          //       value: {
                          //         symbol: exchange?.sell_price_currency,
                          //         value:
                          //           exchange?.sell_price == 0
                          //             ? item.sell_price
                          //             : addCommaInNumber(exchange?.sell_price),
                          //         name: exchange?.reseller_id,
                          //       },
                          //       exchange_id: exchange?.exchange_id,
                          //       is_hidden: exchange?.is_hidden,
                          //     };
                          //   }
                          // );

                          ppePartners = convertPPEPartnersToLocalFormat(
                            item?.ticket_exchange_prices,
                            item.sell_price,
                            target_currency
                          );
                        }

                        return {
                          ...item,
                          priceFactor: priceFactor,
                          sub_ticket_type:
                            item?.ticket_type === "eticket" &&
                            item?.sub_ticket_type === ""
                              ? "pdf"
                              : item?.ticket_type === "eticket" &&
                                item?.sub_ticket_type
                              ? item?.sub_ticket_type
                              : "",
                          benefits: item?.restrictions,
                          ticketsInHands: item?.in_hand === "no" ? false : true,
                          visibility: visibility,
                          oldVisibility: 0,
                          exchanges: isArray(event?.selected_event_exchanges)
                            ? event?.selected_event_exchanges
                            : [],
                          isLock: true,
                          loadFlagStatus: true,
                          ppePartners: ppePartners,
                        };
                      }),
                      meta: response?.meta,
                    };
                    if (final?.total_listings > 20) {
                      final = {
                        ...final,
                      };
                    }

                    return final;
                  } else {
                    return event;
                  }
                });
              });

              setTimeout(() => {
                handleEventScroll(event?.id);

                setTimeout(() => {
                  setList((prev: any) => {
                    return prev.map((event: any) => {
                      if (event?.id === id) {
                        let final = {
                          ...event,
                        };
                        if (final?.total_listings > 20) {
                          final = {
                            ...final,
                            loadFlagStatus: true,
                          };
                        }
                        return final;
                      } else {
                        return event;
                      }
                    });
                  });
                }, 50);
              }, 0.5);

              setCustomerLoader(false);
            });

            // CALL API TO GET EVENT RECORDS
          } catch (error) {}
          try {
            let responseData: any;
            const result: any = fetchMarketplacePriceWithSection(event)
              .then((res) => {
                responseData = res;
              })
              .catch((err) => {})
              .finally(() => {
                const markinsight = responseData?.data?.results?.sections;
                // setTimeout(() => {
                setList((prev: any) => {
                  return prev.map((event: any) => {
                    if (event?.id === id) {
                      let final = {
                        ...event,
                        eventRecords: event?.eventRecords?.map((item: any) => {
                          const avg_price = calculateAvgPriceForProceedValue(
                            item?.section,
                            markinsight,
                            item,
                            event,
                            conversation_rate,
                            isGodAdmin
                          );
                          return {
                            ...item,
                            priceFactor: priceFactor,

                            markinsight: markinsight,
                            avg_price:
                              markinsight && !avg_price
                                ? calculateAvgPriceForProceedValue(
                                    item?.section,
                                    markinsight,
                                    item,
                                    event,
                                    conversation_rate,
                                    isGodAdmin
                                  )
                                : avg_price,
                          };
                        }),
                      };

                      return final;
                    } else {
                      return event;
                    }
                  });
                });
                // }, 100);
              });
          } catch (error) {}
        } else {
          handleEventScroll(id);
          setTimeout(() => {
            setList((prev: any) => {
              return prev.map((event: any) => {
                if (event?.id === id) {
                  let element = document.getElementById(event?.id);
                  if (
                    element &&
                    element?.getAttribute("aria-expanded") === "false"
                  ) {
                    // layout.setSelectAllConfirmation(false);
                    return {
                      ...event,
                      requireSelectAll: {},
                      eventRecords: event?.eventRecords?.map((record: any) => {
                        return {
                          ...record,
                          selected: false,
                        };
                      }),
                      isOpen: !event?.isOpen,
                      loadFlagStatus: true,
                    };
                  } else {
                    let final = {
                      ...event,
                      isOpen: !event?.isOpen,
                      loadFlagStatus: true,
                    };
                    return final;
                  }
                } else {
                  return event;
                }
              });
            });
          }, 300);
        }
      },
      [
        eventId,
        list,
        setEventId,
        users,
        publishStatus,
        teamsSelected,
        layout?.inventoryNewFilterValue,
      ]
    );

    const handleMarketplaceInsights = useCallback(async (data: any) => {
      if (!data?.markeInsights) {
        setList((current: any) => {
          return current.map((innerEvent: any) => {
            return data?.id === innerEvent.id
              ? { ...innerEvent, marketInsightLoader: true }
              : innerEvent;
          });
        });
        let response: any;
        fetchMarketplacePrice(data)
          .then((res) => {
            response = res;
          })
          .catch((err) => {})
          .finally(() => {
            setList((current: any) => {
              return current.map((innerEvent: any) => {
                return data?.id === innerEvent.id
                  ? {
                      ...innerEvent,
                      markeInsights: response,
                      marketInsightLoader: false,
                      isOpenMarket: true,
                    }
                  : innerEvent;
              });
            });
          });
      } else {
        setList((current: any) => {
          return current.map((innerEvent: any) => {
            return data?.id === innerEvent.id
              ? { ...innerEvent, isOpenMarket: !innerEvent?.isOpenMarket }
              : innerEvent;
          });
        });
      }
    }, []);

    // SORTING EVENT TABLE
    const handleSortAction = useCallback(
      (orderDetail: any) => {
        if (orderDetail.order) {
          let userId =
            users &&
            Array.isArray(users) &&
            users.length > 0 &&
            _.map(users, "id");
          // if (userSpecific) {
          //   userId = [authUser?.id];
          // }
          let id = orderDetail?.id;
          let orderState = orderDetail;
          let publishStatusId =
            publishStatus &&
            Array.isArray(publishStatus) &&
            publishStatus.length > 0 &&
            _.map(publishStatus, "id");
          if (id) {
            setCustomerLoader(true);
            getInventoryRecordListing({
              id,
              userId,
              publishStatusId,
              orderState,
              query,
              teamsSelected,
              newInventoryFilter: layout.inventoryNewFilterValue,
              listingIds,
            }).then((response) => {
              setCustomerLoader(false);
              setList((prev: any) => {
                return prev.map((InEvent: any) => {
                  if (InEvent?.id === id) {
                    return {
                      ...InEvent,
                      total_listings: response?.meta?.total,
                      requireSelectAll: {},
                      eventRecords: response.data?.map((item: any) => {
                        let visibility: any =
                          visibilityMeterCalculationForInventory(
                            InEvent,
                            item,
                            layout.ticketType
                          );

                        const markeInsights =
                          InEvent?.markeInsights?.data?.results?.sections;
                        const avg_price = calculateAvgPriceForProceedValue(
                          item?.section,
                          markeInsights,
                          item,
                          InEvent,
                          conversation_rate,
                          isGodAdmin
                        );

                        // let ppePartners =
                        // item?.ticket_exchange_prices &&
                        // size(item?.ticket_exchange_prices) > 0 &&
                        // Array.isArray(item?.ticket_exchange_prices) &&
                        // item?.ticket_exchange_prices?.map((exchange: any) => {
                        //   return {
                        //     id: exchange?.reseller_id,
                        //     name: exchange?.reseller_name,
                        //     value: {
                        //       symbol: exchange?.sell_price_currency,
                        //       value: exchange?.sell_price,
                        //       name: exchange?.reseller_id,
                        //     },
                        //     exchange_id: exchange?.exchange_id,
                        //   };
                        // });

                        let ppePartners = convertPPEPartnersToLocalFormat(
                          item?.ticket_exchange_prices,
                          item.sell_price,
                          target_currency
                        );

                        return {
                          ...item,
                          benefits: item?.restrictions,
                          ticketsInHands: item?.in_hand === "no" ? false : true,
                          visibility: visibility,
                          oldVisibility: 0,
                          exchanges: isArray(event?.selected_event_exchanges)
                            ? event?.selected_event_exchanges
                            : [],
                          isLock: true,
                          markinsight: markeInsights,
                          avg_price: avg_price,
                          ppePartners: ppePartners,
                        };
                      }),
                      meta: response?.meta,
                    };
                  } else {
                    return InEvent;
                  }
                });
              });
            });
          }
        }
      },
      [layout.orderState]
    );

    const eventFieldsCompo = useMemo(() => {
      return (
        <EventFields3
          layout={layout}
          list={list}
          setList={setList}
          eventLoader={customLoader}
          event={event}
          eIndex={eIndex}
          loadMoreListing={loadMoreListing}
          loadAllEventListing={loadAllEventListing}
          updateVisibility={updateVisibility}
          tableRefSide={tableRefSide}
          editData={editData}
          setEditData={setEditData}
          setIsOpenTicketPopUp={setIsOpenTicketPopUp}
          matchIndexView={matchIndexView}
          handleSortAction={handleSortAction}
          currentRecordId={currentRecordId}
          setCurrentRecordId={setCurrentRecordId}
          SortingCurrentID={SortingCurrentID}
          setSortingCurrentID={setSortingCurrentID}
          setIsOpenListingQualityPopup={setIsOpenListingQualityPopup}
          setEditView={setEditView}
        />
      );
    }, [event, list, customLoader, currentRecordId, SortingCurrentID]);

    const [show, setShow] = useState(false);

    // auto disabled left right table button
    useEffect(() => {
      let timer1 = setTimeout(() => setShow(true), 5 * 100);
      const element: any = document.getElementById(event?.id + "lazyLoad");
      const stickyHead: any = document.getElementById(
        "EventCollapseBody" + event?.id
      );

      if (element && show) {
        const scrollHandler = () => handleScroll(element, stickyHead);
        element.removeEventListener("scroll", scrollHandler);
        element.addEventListener("scroll", scrollHandler);

        setTimeout(() => {
          scrollHandler();
          setShow(false);
        }, 1000);

        return () => {
          clearTimeout(timer1);
          element.removeEventListener("scroll", scrollHandler);
        };
      }
    }, [event]); // Dependency array with 'event' will trigger effect on event changes

    return (
      <React.Fragment key={`EventRecordTAble${event?.id}`}>
        {tableAlert && (
          <TablePopup
            isConfirm={false}
            message={msgAlert && msgAlert}
            cancelBtn={(data: any) => setTableAlert(data)}
          />
        )}

        <div
          id="table-shrink"
          className={clsx(
            "inventory-list",
            event?.isOpen &&
              event?.eventRecords?.length > 0 &&
              event?.loadFlagStatus &&
              event?.total_listings !== event?.eventRecords?.length &&
              "below-hidden-events",
            event?.isOpen &&
              Object.keys(layout?.tableAlert).length &&
              layout?.tableAlert?.confirm &&
              "bulk-action",
            event?.isOpenMarket && "marketInsight-open"
          )}
        >
          <div
            className={clsx(
              "accordion font-medium mb-2.5",
              (event?.isOpenMarket || event?.isOpen) && "md:mb-5"
            )}
            id={`EventAccordion${event?.id}`}
            data-id={event.id}
          >
            <div
              className={`accordion-item bg-white !rounded ${
                isLoading && "shimmer-effect"
              } `}
            >
              <div
                className={clsx(
                  (layout.tableAlert?.confirm || layout.isBulkActionLoader) &&
                    "pointer-events-none",
                  "flex accordion-header mb-0 event__header bg-violet-700 text-white custom-head",
                  event?.isOpen ? "event__header" : "rounded-b",
                  "rounded-t"
                )}
                id={`EventHeading${event?.id}`}
              >
                <div
                  className={`flex lg2:flex-nowrap flex-wrap w-full cursor-pointer title-accordion ${
                    layout.selectAllLoaderCurrentEvent === event.id &&
                    "!pointer-events-none"
                  }`}
                  data-te-collapse-init
                  data-te-collapse-collapsed
                  data-te-target={`${`#EventCollapseBody${event?.id}`}`}
                  aria-expanded="false"
                  aria-controls={`${`EventCollapseBody${event?.id}`}`}
                  id={event.id}
                  onClick={(e: any) => {
                    setTimeout(() => {
                      handleEventScroll(event.id);
                    }, 100);
                    handleEventId(event.id, event);
                  }}
                >
                  <div className="accordion-search flex max-lg:w-full lg2:flex-nowrap flex-wrap lg:flex-1 lg2:mr-4 max-lg2:border-b max-lg2:border-white/20">
                    <div
                      className={`${
                        event.id === ""
                          ? "xl:max-w-[30rem] lg:max-w-[34.31rem] !p-1.5"
                          : "lg2:max-w-[22.187rem] lg:max-w-[15.625rem] md:border-r"
                      } w-full max-w-full items-center px-3 lg:px-4 lg:pb-2.5 pb-1 lg:pt-2.5 pt-2.5 text-sm  border-white/20 text-ellipsis overflow-hidden whitespace-nowrap`}
                      title={event.name}
                    >
                      {event.name}
                    </div>

                    {event.id !== "" && (
                      <div className="flex flex-wrap flex-auto max-lg:px-3">
                        <EventHeader
                          title={moment(event.date).format("ddd, DD MMM YYYY")}
                          Imgpath={Calender}
                          className="lg:max-w-[9.875rem] max-w-full md:w-full flex items-center lg:px-3.5 py-1 lg:py-3 text-xs lg:border-r border-white/20 max-md:mr-5"
                          svgClassName="w-[.675rem] h-[.75rem] "
                        />
                        <EventHeader
                          title={moment(event.time, ["h:mm A"]).format("HH:mm")}
                          Imgpath={ClockOutline}
                          className="lg2:max-w-[5.3125rem] lg:max-w-[5.3125rem] max-w-full md:w-full flex items-center lg:justify-center lg:px-3 lg:py-3 py-1 text-xs lg:border-r border-white/20 "
                          svgClassName="w-[.75rem] h-[.75rem]"
                        />
                        <EventHeader
                          title={event.address}
                          Imgpath={GeoIcon}
                          className="lg:w-auto w-full flex items-center lg:px-4 lg:pt-3 pt-1 lg:pb-3 pb-3 text-xs lg:border-none lg:border-r border-white/20 ellips-text lg:flex-1 leading-none"
                          svgClassName="w-[.7813rem] h-[.7813rem]"
                        />
                      </div>
                    )}
                  </div>
                  <div className="lg:w-full lg2:w-auto max-lg2:justify-end flex ml-auto mt-auto">
                    <div className="flex items-center">
                      <div className="flex items-center lg:flex-nowrap flex-wrap max-md:px-3 max-md:gap-y-1 max-md:gap-x-2">
                        {/* TOTAL LISTING */}
                        <EventCount
                          data={event.total_listings}
                          dataTooltipID={`${eIndex}-total_listings`}
                          tooltipContent={`${addCommaInNumber(
                            event.total_listings
                          )} listing${event.total_listings > 1 ? "s" : ""}`}
                          SVGParentClassName="flex items-center justify-center min-w-[0.75] fill-white mr-1.5"
                          SVGClassName="w-3 h-[.5625rem]"
                          SVGImageComponent={ListingLines}
                        />

                        {/* TOTAL PUBLISHED COUNT */}
                        {event.total_published_listings > 0 && (
                          <EventCount
                            data={event.total_published_listings}
                            dataTooltipID={`${eIndex}-total_published_listings`}
                            tooltipContent={`${addCommaInNumber(
                              event.total_published_listings
                            )} published`}
                            SVGParentClassName="flex items-center justify-center w-[.8125rem] h-[.9375rem] mr-1.5 fill-green-500"
                            SVGClassName="w-[.8125rem] h-[.9375rem]"
                            SVGImageComponent={HaxPublishGray}
                          />
                        )}

                        {/* TOTAL UNPUBLISHED COUNT  */}
                        {event.total_unpublished_listings > 0 && (
                          <EventCount
                            data={event?.total_unpublished_listings}
                            dataTooltipID={`${eIndex}-total_unpublished_listings`}
                            tooltipContent={`${addCommaInNumber(
                              event.total_unpublished_listings
                            )} unpublished`}
                            SVGParentClassName="flex items-center justify-center w-[.8125rem] h-[.9375rem] mr-1.5 fill-gray-400"
                            SVGClassName="w-[.8125rem] h-[.9375rem]"
                            SVGImageComponent={Unpublish}
                          />
                        )}

                        {/* ERROR COUNT */}
                        {event.total_error_listings > 0 && (
                          <EventCount
                            data={event?.total_error_listings}
                            dataTooltipID={`${eIndex}-total_error_listings`}
                            tooltipContent={`${addCommaInNumber(
                              event?.total_error_listings
                            )} error${
                              event?.total_error_listings > 1 ? "s" : ""
                            }`}
                            SVGParentClassName="flex items-center justify-center w-[.8125rem] h-[.9375rem] mr-1.5
                            fill-rose-500"
                            SVGClassName="w-4 h-4"
                            SVGImageComponent={Warning}
                          />
                        )}

                        {/* TOTAL TICKETS */}
                        <EventCount
                          data={event.total_qty_available}
                          dataTooltipID={`${eIndex}-total_qty_available`}
                          tooltipContent={
                            event.total_qty_available > 1
                              ? `${addCommaInNumber(
                                  event.total_qty_available
                                )} tickets`
                              : `${event.total_qty_available} ticket`
                          }
                          SVGParentClassName="flex items-center justify-center min-w-[0.75] fill-white mr-1.5"
                          SVGClassName="w-3 h-[.5938rem]"
                          SVGImageComponent={Substraction}
                        />
                      </div>
                    </div>

                    {/* MARKET INSIGHT  */}
                    <button
                      id={`EventInsight${event?.id}`}
                      className={clsx(
                        "flex items-center pb-2 mt-2 text-base text-gray-800 text-left md:min-w-[7.625rem] !transition focus:outline-none group collapsed bg-transparent",
                        "[&[data-te-collapse-collapsed]]:bg-transparent",
                        "relative mrk-btn",
                        event?.marketInsightLoader && "pointer-events-none",
                        event?.isOpenMarket && "border_bottom",
                        !layout.isPublish && [
                          "bottom-arrow",
                          "[&:not([data-te-collapse-collapsed])]:fill-white",
                          "[&:not([data-te-collapse-collapsed])]:bg-gray-750",
                          "[&:not([data-te-collapse-collapsed])]:rounded-t",
                          "[&:not([data-te-collapse-collapsed])>.arrow]:block",
                        ]
                      )}
                      type="button"
                      data-te-collapse-init
                      data-te-collapse-collapsed
                      data-te-target={`#EventInsightCollapseBody${event?.id}`}
                      aria-expanded="false"
                      aria-controls={`EventInsightCollapseBody${event?.id}`}
                      onClick={(e: any) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleMarketplaceInsights(event);
                      }}
                    >
                      <TooltipPortal
                        id={`market-insights-tooltip${event?.id}`}
                        content={"Market Insights"}
                        place="top"
                        variant="light"
                        className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-[12] mt-2.5"
                      />
                      <div id={`market-insights-tooltip${event?.id}`}>
                        <div className="flex items-center border border-white border-opacity-[13%] bg-white bg-opacity-[7%] p-1.5 md:px-2.5 rounded mkt-btn">
                          {!layout.isPublish && (
                            <>
                              <div className="arrow hidden absolute w-2.5 h-2.5 right-full bottom-0 bg-gray-750">
                                <span className="block w-full h-full bg-violet-700 rounded-br-2xl"></span>
                              </div>
                              <div className="arrow hidden absolute w-2.5 h-2.5 left-full bottom-0 bg-gray-750">
                                <span className="block w-full h-full bg-violet-700 rounded-bl-2xl"></span>
                              </div>
                            </>
                          )}

                          <ComponentSVG className="flex items-center justify-center  fill-green-600 transition hover:!fill-indigo-500 group-[:not([data-te-collapse-collapsed])]:fill-violet-500 ">
                            <MarketInsightGreen className="w-[.8125rem] h-[.8125rem]" />
                          </ComponentSVG>

                          <span className="max-md:hidden text-sm12 text-white ml-1.5 leading-[1] font-medium group-[:not([data-te-collapse-collapsed])]:text-violet-800">
                            Market Insights
                          </span>
                        </div>
                      </div>
                    </button>

                    <TooltipPortal
                      id={`collapseIcon-${event?.id}`}
                      content={`${event?.isOpen ? `Collapse` : "Expand"}`}
                      place="top"
                      variant="light"
                      className="!bg-white !opacity-100 !py-0.5 !px-1 !text-xxs font-medium  z-[12]  mt-5 "
                    />
                    <button
                      className="relative flex items-center py-4 lg:px-[.9375rem] px-[.9375rem] text-base text-gray-800 text-left border-white/20 !transition focus:outline-none group collapse-icon "
                      type="button"
                      data-tooltip-id={`collapseIcon-${event?.id}`}
                      data-te-collapse-init
                      data-te-collapse-collapsed
                      data-te-target={`${`#EventCollapseBody${event?.id}`}`}
                      aria-expanded="true"
                      aria-controls={`${`EventCollapseBody${event?.id}`}`}
                    >
                      <ComponentSVG className="flex items-center justify-center group-[.collapsed]:rotate-180 fill-white transition group-[[data-te-collapse-collapsed]]:rotate-180 rotate-180}">
                        <Collapse className="w-[.5625rem] h-[.5625rem]" />
                      </ComponentSVG>
                    </button>
                  </div>
                </div>
              </div>

              {/* MARKET INSIGHT - COMPONENT */}
              <MarketInsight event={event} setList={setList} />

              {/* MAIN */}
              {eventFieldsCompo}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
);

export default EventTable;
