import infoIcon from "assets/media/icons/other_icons/info.svg";
import { IKTSVG } from "components/ui/IKTSVG";
import TooltipPortal from "pages/tx_trade/table/component/TooltipPortal";
const DataCell = ({
  value,
  subValue,
  classname = "",
  width = "",
  padding,
  noCapitalize,
  noEllips = false,
  evtName = false,
  children,
  infoIconValue = "",
  id,
}: any) => {
  return (
    <>
      <td
        className={`p-1.5 font-medium min-h-[2.8125rem] h-[2.8125rem]  ${padding} ${evtName}`}
      >
        <span className={`${noEllips ? " " : "ellips-text"}`}>
          <p
            className={`${width} ${classname} ${
              noCapitalize === "yes" ? "" : "capitalize"
            } ${infoIconValue && "flex items-center"}`}
            title={value ? value : ""}
          >
            {children ? children : value || "-"}
            {infoIconValue && (
              <span
                className="cursor-pointer"
                data-tooltip-id={`infoTooltip-${id}`}
              >
                <TooltipPortal
                  id={`infoTooltip-${id}`}
                  content={infoIconValue}
                  place="top"
                  variant="light"
                  className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10"
                  data-tooltip-id={`infoTooltip`}
                />
                <IKTSVG
                  path={infoIcon}
                  className="fill-violet-500 group-hover:fill-white transition"
                  svgClassName="w-3 h-3 ml-1.5"
                />
              </span>
            )}
          </p>
          {subValue && (
            <small className="text-xxs text-gray-500">{subValue}</small>
          )}
        </span>
      </td>
    </>
  );
};

export default DataCell;
