import { useContext, useState } from "react";
import uparrowSVG from "../../../assets/media/icons/standard_icons/arrowUp.svg";
import { LayoutContext } from "../../../components/core/LayoutProvider";
import { IKTSVG } from "../../../components/ui/IKTSVG";
import {
  addCommaInNumber,
  getCurrencySymbol,
} from "../../../helpers/Functions";
import { ReportsContext } from "../core/ReportsProvider";

const ReportsCountBoxes = ({
  name,
  countValue,
  ticketCount,
  isCheckbox,
  extraProp = false,
  reportsLoading,
}: any) => {
  const [active, setActive] = useState<boolean>(false);
  const coreLayout = useContext(LayoutContext);
  const isGodAdmin = coreLayout?.allowedAccess?.account_info?.is_god_admin;
  const layout = useContext(ReportsContext);

  function isGodadminBasedCurrency(Array: any) {
    const teamArr = Array;

    if (teamArr === undefined || teamArr?.length === 0) {
      return coreLayout.currencySymbol;
    } else if (teamArr?.length > 1) {
      return coreLayout.currencySymbol;
    } else {
      return getCurrencySymbol(teamArr?.[0]?.base_currency);
    }
  }

  return (
    <>
      <div className={`w-full sm:w-1/2 lg:w-1/3 xl:w-auto mb-5 px-2.5`}>
        <label
          htmlFor={name}
          className={`border form-check items-center rounded bg-gray-100/25 group block min-w-[9.875rem] ${
            active && "active-style"
          } [&.active-style]:bg-indigo-500/5 [&.active-style]:border-indigo-500 min-w-[12.5rem] ${
            reportsLoading && "shimmer-effect"
          } ${
            isCheckbox &&
            "hover:bg-indigo-500/5 hover:border-indigo-500 hover:text-indigo-500"
          } `}
        >
          <div className={` px-2.5 pt-[.4375rem] pb-[.5625rem] h-full`}>
            <div className="flex justify-between gap-2.5">
              <div>
                <h3
                  className={`md:text-lg md:leading-6 ${
                    active && "active-style"
                  } [&.active-style]:text-indigo-500`}
                  // dangerouslySetInnerHTML={{
                  //   __html: `${
                  //     // extraProp
                  //     //   ? coreLayout.currencySymbol
                  //     //     ? coreLayout.currencySymbol
                  //     //     : ""
                  //     //   : ""

                  //     extraProp && isGodAdmin
                  //       ? isGodadminBasedCurrency(
                  //           layout?.reportsFilterHandle?.team_id
                  //         )
                  //       : extraProp && !isGodAdmin
                  //       ? coreLayout.currencySymbol
                  //       : ""
                  //   }
                  //   ${countValue ? countValue : "0"}

                  //   `,
                  // }}
                >
                  {extraProp && isGodAdmin
                    ? isGodadminBasedCurrency(
                        layout?.reportsFilterHandle?.team_id
                      )
                    : extraProp && !isGodAdmin
                    ? coreLayout.currencySymbol
                    : ""}
                  {countValue ? addCommaInNumber(countValue) : "0"}
                </h3>
              </div>
              {ticketCount > 0 && (
                <h5>
                  <span
                    className={`flex items-center bg-green-600 text-xxs text-white font-semibold px-2 py-0.5 rounded my-1 ${
                      active && "active-style"
                    } [&.active-style]:text-indigo-500 [&.active-style]:bg-indigo-500/5`}
                  >
                    <IKTSVG
                      className="flex items-center justify-center group-[.collapsed]:rotate-180 fill-white mr-1"
                      path={uparrowSVG}
                    />
                    {/* {extraProp && coreLayout.currencySymbol} */}
                    {extraProp && isGodAdmin
                      ? isGodadminBasedCurrency(
                          layout?.reportsFilterHandle?.team_id
                        )
                      : extraProp && !isGodAdmin
                      ? coreLayout.currencySymbol
                      : ""}
                    {ticketCount ? ticketCount : "0"}
                  </span>
                </h5>
              )}
            </div>
            <div className="flex justify-between items-center pt-1.5">
              <p
                className={`text-xs text-gray-500 whitespace-nowrap ${
                  isCheckbox && "group-hover:text-indigo-500"
                } ${active && "active-style"} [&.active-style]:text-indigo-500`}
              >
                {name ? name : "0"}
              </p>

              {isCheckbox && (
                <div className="flex">
                  <input
                    className="form-check-input h-3 w-3 rounded-sm bg-gray-100 border-gray-300 checked:bg-indigo-500 transition ml-2 cursor-pointer border checked:border-indigo-500 hover:border-indigo-500 group-hover:border-indigo-500 focus:ring-0 focus:ring-offset-0`}"
                    type="checkbox"
                    name={name}
                    id={name}
                    value=""
                    onChange={(e: any) => setActive(e.target.checked)}
                  />
                </div>
              )}
            </div>
          </div>
        </label>
      </div>
    </>
  );
};

export default ReportsCountBoxes;
