/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { LayoutContext } from "../../../components/core/LayoutProvider";
import {
  addCommaInNumber,
  getCurrencySymbol,
} from "../../../helpers/Functions";
import { SalesContext } from "../core/SalesProvider";
import { TAB_WISE_ROUTE } from "../core/const";

const TabHeader = ({
  tabId,
  name,
  count,
  ticketPrice,
  countColor,
  handleTabClick,
  postKey,
  shimmerLoader,
}: any) => {
  const layout = useContext(SalesContext);
  const coreLayout = useContext(LayoutContext);
  const isGodAdmin = coreLayout?.allowedAccess?.account_info?.is_god_admin;

  const navigate = useNavigate();

  function isGodadminBasedCurrency(Array: any) {
    const teamArr = Array;
    if (teamArr === undefined || teamArr?.length === 0) {
      return coreLayout.currencySymbol;
    } else if (teamArr?.length > 1) {
      return coreLayout.currencySymbol;
    } else {
      return getCurrencySymbol(teamArr?.[0]?.base_currency);
    }
  }

  return (
    <>
      <a
        href={void 0}
        className={` lg2:max-w-[14.9375rem] mx-[0.5px] ${
          postKey === layout.tabValue && "text-indigo-500 z-10"
        }  w-full`}
        data-te-toggle="pill"
        data-te-target={`#${tabId}`}
        role="tab"
        aria-controls={tabId}
        aria-selected="true"
        onClick={() => {
          navigate(TAB_WISE_ROUTE[postKey]);
          handleTabClick(postKey);
        }}
      >
        <div
          className={`group transition-all single-tab h-full px-4 py-2.5 bg-white rounded-t cursor-pointer mr-[1px] w-full z-10 lg2:max-w-[14.9375rem] min-w-[12.5rem] ${
            postKey === layout.tabValue
              ? "bg-opacity-100"
              : "bg-opacity-60 hover:bg-opacity-100"
          }`}
        >
          <div className="text flex justify-between items-center whitespace-nowrap gap-x-2.5">
            <h4 className="text-sm15 leading-5 font-semibold group-hover:text-indigo-500 transition-all">
              {name}
            </h4>
            <span
              className={`flex items-center ${countColor} text-xxs text-white font-semibold px-2 py-0.5 rounded group-hover:bg-indigo-500 transition-all`}
            >
              {count ? addCommaInNumber(count) : "0"}
            </span>
          </div>
          {shimmerLoader ? (
            <div className="shimmer-effect rounded-lg w-20 text-sm12 font-medium text-gray-500 flex-1 mt-1.5 block">
              <span className="inline-block text-xs">0000</span>
            </div>
          ) : (
            <span
              className={`text-sm12 font-medium group-hover:text-indigo-500 flex-1 mt-1.5 block ${
                postKey === layout.tabValue
                  ? "text-indigo-500"
                  : "text-gray-500"
              }`}
            >
              {/* {ticketPrice
              ? `${coreLayout.currencySymbol}${ticketPrice.toLocaleString(
                  "en-US",
                  { minimumFractionDigits: 2 }
                )}`
              : coreLayout.currencySymbol
              ? `${coreLayout.currencySymbol}0.00`
              : "0"} */}

              {isGodAdmin
                ? isGodadminBasedCurrency(layout?.salesFilterHandle?.team_id)
                : coreLayout.currencySymbol}

              {ticketPrice
                ? ticketPrice.toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                  })
                : "0.00"}
            </span>
          )}
        </div>
      </a>
    </>
  );
};

export default TabHeader;
