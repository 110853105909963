import { createContext, PropsWithChildren, useState } from "react";

export interface LayoutContextModel {
  salesOptions: any;
  setSalesOptions: (data: any) => void;

  fulfilledOptions: any;
  setFulfilledOptions: (data: any) => void;

  newsOptions: any;
  setNewsOptions: (data: any) => void;

  loader: any;
  setLoader: (data: any) => void;

  notificationIndex: any;
  setNotificationIndex: (data: any) => void;

  newsData: any;
  setNewsData: (data: any) => void;

  newsOffCanvas: any;
  setNewsOffCanvas: (data: any) => void;

  title: any;
  setTitle: (data: any) => void;

  priceCardData: any;
  setPriceCardData: (data: any) => void;

  eventsData: any;
  setEventsData: (data: any) => void;

  listingActivityData: any;
  setListingActivityData: (data: any) => void;

  industryNewsData: any;
  setIndustryNewsData: (data: any) => void;

  notificationData: any;
  setNotificationData: (data: any) => void;

  toBeFulfilledData: any;
  setToBeFulfilledData: (data: any) => void;

  tabValue: any;
  setTabValue: (data: any) => void;

  selectedOrderOutOffer: any;
  setSelectedOrderOutOffer: (data: any) => void;
}

const DashboardContext = createContext<LayoutContextModel>({
  salesOptions: [],
  setSalesOptions: (data: any) => {},

  newsOptions: [],
  setNewsOptions: (data: any) => {},

  fulfilledOptions: [],
  setFulfilledOptions: (data: any) => {},

  loader: [],
  setLoader: (data: any) => {},

  notificationIndex: [],
  setNotificationIndex: (data: any) => {},

  newsData: [],
  setNewsData: (data: any) => {},

  newsOffCanvas: [],
  setNewsOffCanvas: (data: any) => {},

  title: [],
  setTitle: (data: any) => {},

  priceCardData: {},
  setPriceCardData: (data: any) => {},

  eventsData: {},
  setEventsData: (data: any) => {},

  listingActivityData: {},
  setListingActivityData: (data: any) => {},

  industryNewsData: {},
  setIndustryNewsData: (data: any) => {},

  notificationData: {},
  setNotificationData: (data: any) => {},

  toBeFulfilledData: {},
  setToBeFulfilledData: (data: any) => {},

  tabValue: [],
  setTabValue: (data: any) => {},

  selectedOrderOutOffer: {},
  setSelectedOrderOutOffer: (data: any) => {},
});

const DashboardProvider = ({ children }: PropsWithChildren) => {
  const [salesOptions, setSalesOptions] = useState("today");
  const [fulfilledOptions, setFulfilledOptions] = useState({
    id: "next_7day",
    page: 1,
  });
  const [newsOptions, setNewsOptions] = useState({
    // id: "industry",
    page: 1,
  });
  const [loader, setLoader] = useState<boolean>(false);
  const [notificationIndex, setNotificationIndex] = useState<any>();
  const [newsData, setNewsData] = useState<any>([]);
  const [newsOffCanvas, setNewsOffCanvas] = useState<any>("");
  const [title, setTitle] = useState<any>("");
  const [priceCardData, setPriceCardData] = useState<any>();
  const [eventsData, setEventsData] = useState<any>();
  const [listingActivityData, setListingActivityData] = useState<any>();
  const [industryNewsData, setIndustryNewsData] = useState<any>();
  const [notificationData, setNotificationData] = useState<any>([]);
  const [toBeFulfilledData, setToBeFulfilledData] = useState<any>();
  const [tabValue, setTabValue] = useState<any>("");
  const [selectedOrderOutOffer, setSelectedOrderOutOffer] = useState<any>();

  const value: LayoutContextModel = {
    salesOptions,
    setSalesOptions,

    fulfilledOptions,
    setFulfilledOptions,

    newsOptions,
    setNewsOptions,

    loader,
    setLoader,

    notificationIndex,
    setNotificationIndex,

    newsData,
    setNewsData,

    newsOffCanvas,
    setNewsOffCanvas,

    title,
    setTitle,

    priceCardData,
    setPriceCardData,

    eventsData,
    setEventsData,

    listingActivityData,
    setListingActivityData,

    industryNewsData,
    setIndustryNewsData,

    notificationData,
    setNotificationData,

    toBeFulfilledData,
    setToBeFulfilledData,

    tabValue,
    setTabValue,

    selectedOrderOutOffer,
    setSelectedOrderOutOffer,
  };

  return (
    <DashboardContext.Provider value={value}>
      {children}
    </DashboardContext.Provider>
  );
};

export { DashboardContext, DashboardProvider };
