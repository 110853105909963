/* eslint-disable array-callback-return */
import moment from "moment";
import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { Tooltip } from "react-tooltip";
import { TableVirtuoso } from "react-virtuoso";
import eyeSVG from "../../../assets/media/icons/other_icons/Eye.svg";
import logIcon from "../../../assets/media/icons/other_icons/logSvg.svg";
import angleLeftSVG from "../../../assets/media/icons/standard_icons/angle_left.svg";
import angleRightSVG from "../../../assets/media/icons/standard_icons/angle_right.svg";
import { LOG_TAB_KEYS } from "../../../components/ExpandableList/constants/constants";
import { LayoutContext } from "../../../components/core/LayoutProvider";
import { IKTSVG } from "../../../components/ui/IKTSVG";
import {
  decimalNumber,
  handleScroll,
  isChildInViewport,
  isDateBeforeValidLogDate,
  isSameOrderId,
  scrollToVisible,
  ticketTypeRenameForSalesReport,
} from "../../../helpers/Functions";
import { isMobile, REPORT_COLUMN_ADMIN, SYMBOLS } from "../../../helpers/const";
import { getSalesLogListing } from "../../Sales/core/_requests";
import { LogDetailsPopUp } from "../../inventory/widgets/LogDetails/LogDetailsPopUp";
import { ReportsContext } from "../core/ReportsProvider";
import { display } from "../core/_function";
import OrderDetail from "../widgets/OrderDetail";
import DataCell from "./component/DataCell";
import HeaderCell from "./component/HeaderCell";
import TableRow from "./component/TableRow";

const Table = ({ style, ...props }: any) => (
  <table
    {...props}
    className="w-full text-xs text-left  min-w-[87.5rem]"
    style={{
      ...style,
      //width: 700
    }}
  />
);
const TableHead = ({ style, ...props }: any) => (
  <thead
    {...props}
    className="text-xs text-gray-400 "
    style={{
      ...style,
      position: "static",
    }}
  />
);

const DataTable = ({
  data,
  tabValue,
  paginateData,
  reportsLoading = true,
  reportsOptions,
}: any) => {
  const layout = useContext(ReportsContext);
  const [showOrder, setShowOrder] = useState<any>({
    show: false,
    data: "",
  });
  const tableRef: any = useRef();
  const globalLayout = useContext(LayoutContext);

  const isGodAdmin = globalLayout?.allowedAccess?.account_info?.is_god_admin;

  const stickyTableRef: any = useRef();
  const virtuosoRef: any = useRef();
  const [logsData, setLogsData] = useState<any>(null);
  const [activeTab, setActiveTab] = useState(LOG_TAB_KEYS.ORDER);

  const isRowHighlighted = useCallback(
    (list: any) =>
      isSameOrderId(showOrder?.data, list) || isSameOrderId(logsData, list),
    [showOrder, logsData]
  );

  //   TABLE SCROLL HANDLE
  // const scroll = (scrollOffset: any) => {
  //   // const load = document.getElementById(`tableId1`) as HTMLElement | null;
  //   // const loadHead = document.getElementById(
  //   //   `sticky-head`
  //   // ) as HTMLElement | null;
  //   tableRef.current.scrollLeft += scrollOffset;
  //   stickyTableRef.current.scrollLeft += scrollOffset;
  //   // if (load && loadHead) {
  //   //   load.scrollLeft += scrollOffset;
  //   //   loadHead.scrollLeft += scrollOffset;
  //   // } else {
  //   // }
  // };
  const scroll = (scrollOffset: any) => {
    tableRef.current.scrollLeft += scrollOffset;
    stickyTableRef.current.scrollLeft += scrollOffset;
  };

  // HANDLE ORDER VIEW PAGE
  const handleOrderView = (list: any, index: number) => {
    if (
      list?.order_id &&
      showOrder?.data?.order_id &&
      list?.order_id === showOrder?.data?.order_id
    ) {
      display(false);
      setShowOrder({
        show: false,
        data: "",
        index: "",
      });
    } else {
      setShowOrder({
        show: true,
        data: list,
        index: index,
      });
    }
  };

  const getFilterIndexForCheckbox = (id: any) => {
    let index = layout.columnItems.findIndex((item: any) => item.id === id);
    return index;
  };

  //Function for changing order of Table Columns basis draggable Columns
  const getColumnValue = isGodAdmin
    ? layout.columnItems
    : layout.columnItems?.filter(
        (obj: any) => !REPORT_COLUMN_ADMIN?.includes(obj?.name)
      );

  const getColumnItems = () => {
    return getColumnValue?.filter((columnItem) => columnItem.checked === true);
  };
  // const getColumnItems = () => {
  //   return isGodAdmin
  //     ? layout.columnItems.filter((columnItem) => columnItem.checked === true)
  //     : layout.columnItems.filter(
  //         (columnItem) =>
  //           columnItem.checked === true &&
  //           columnItem?.name !== "Team name" &&
  //           columnItem?.name !== "Team member" &&
  //           columnItem?.name !== "Marketplace"
  //       );
  // };

  const [hoveredId, setHoveredId] = useState(null);

  // Function to handle mouse enter
  const handleMouseEnter = (id: React.SetStateAction<null>) => {
    setHoveredId(id);
  };

  // Function to handle mouse leave
  const handleMouseLeave = () => {
    setHoveredId(null);
  };

  useEffect(() => {
    const isInViewport = isChildInViewport(`report-tr-${showOrder?.index}`);

    if (!isInViewport) {
      scrollToVisible(`report-tr-${showOrder?.index}`);
    }
  }, [showOrder?.index]);

  // Save Draggable Column Data to Local Storage
  const reportTableColumn = () => {
    localStorage.setItem(
      "reportTableColumn",
      JSON.stringify(layout.columnItems)
    );
  };

  useEffect(() => {
    // reportTableColumn();
  }, [layout.columnItems]);

  // CALCULATE THE HEIGHT OF TABLE VIRTOUSO
  const [height, setHeight] = useState<any | undefined>(undefined);
  const [virtHeight, setVirtHeight] = useState<any>();

  // useEffect(() => {
  //   // const virtousHeight = virtuosoHeight();
  //   setTimeout(() => {
  //     const divElement: any = document.querySelector("#Report-lazyload");
  //     if (divElement) {
  //       // const observer = new MutationObserver(() => {
  //       //   const height = divElement?.offsetHeight + 41;

  //       //   setHeight(`${height}`);
  //       //   // Use the height value here
  //       // });

  //       // observer.observe(divElement, { childList: true, subtree: true });

  //       // // Clean up the observer when the component unmounts
  //       // return () => {
  //       //   observer.disconnect();
  //       // };

  //       const elemHeight = divElement.offsetHeight + 41;
  //       const height = virtHeight > elemHeight ? virtHeight : elemHeight;
  //       setHeight(`${height}`);
  //     }
  //   }, 300);
  // }, [data, layout?.reportsFilterHandle, virtHeight]);
  let timeout: any;
  const nomoreListing = document.getElementById("NoMoreListing");

  const renderCall = () => {
    timeout = setTimeout(() => {
      const divElement: any = document.querySelector("#Report-lazyload");
      const thead = document.querySelector("#tableId1 table thead");
      const theadHeight = thead?.scrollHeight;
      const nomoreListing = document.getElementById("NoMoreListing");
      let nomoreListingHeight = nomoreListing?.clientHeight;
      if (divElement.offsetHeight > 1) {
        let elemHeight = divElement.offsetHeight + theadHeight;
        const height = virtHeight > elemHeight ? virtHeight : elemHeight;

        if (nomoreListingHeight) {
          elemHeight =
            divElement.offsetHeight + theadHeight + nomoreListingHeight + 1;
        }
        setHeight(`${elemHeight}`);
      }
    }, 800);
  };
  useLayoutEffect(() => {
    renderCall();
    window.addEventListener("scroll", renderCall);
    // Clear the timeout in case the component unmounts or the effect re-runs
    return () => {
      clearTimeout(timeout);
    };
  });

  // RE-RENDER HEIGHT ON RESIZING RESOLUTION
  useEffect(() => {
    renderCall();
  }, [
    globalLayout?.htmlFontSize,
    data?.length === paginateData?.total && paginateData?.current_page > 1,
    nomoreListing,
  ]);

  useEffect(() => {
    setTimeout(() => {
      if (globalLayout?.htmlFontSize) {
        renderCall();
      }
    }, 1000);
  }, [globalLayout?.htmlFontSize]);

  useEffect(() => {
    const divElement: any = document.querySelector("#Report-lazyload");
    const elemHeight = divElement?.offsetHeight;
    setVirtHeight(elemHeight);
  }, [data, layout?.reportsFilterHandle]);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     // Check if the user has scrolled to the bottom of the page

  //     if (
  //       window.innerHeight + window.scrollY + 1 >=
  //       document.body.offsetHeight
  //     ) {
  //       // Scroll has reached the bottom

  //       if (paginateData?.current_page < paginateData?.last_page) {
  //         layout.setReportsFilterHandle((current: any) => {
  //           let nextPage: number = paginateData?.current_page + 1;

  //           return {
  //             ...current,
  //             page: nextPage,
  //             per_page: 20,
  //           };
  //         });
  //       }
  //     }
  //   };

  //   // Attach the scroll event listener when the component mounts
  //   window.addEventListener("scroll", handleScroll);

  //   // Clean up the event listener when the component unmounts
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, [paginateData]);

  // useEffect(() => {
  //   const handleScroll = debounce(() => {
  //     if (
  //       window.innerHeight + window.scrollY + 1 >=
  //       document.body.offsetHeight - 500
  //     ) {
  //       if (paginateData?.current_page < paginateData?.last_page) {
  //         layout.setReportsFilterHandle((current: any) => {
  //           let nextPage: number = paginateData?.current_page + 1;

  //           return {
  //             ...current,
  //             page: nextPage,
  //             per_page: 20,
  //           };
  //         });
  //       }
  //     }
  //   }, 300); // Adjust the debounce delay as needed

  //   window.addEventListener("scroll", handleScroll);

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, [paginateData]);

  const ScrollSeekPlaceholder = ({
    height,
    index,
  }: // context: { randomHeights },
  any) => (
    <div
      key={index}
      style={{
        height,
        // width: "710px",
        padding: "8px",
        boxSizing: "border-box",
        overflow: "hidden",
      }}
      className=" abc"
    >
      <div
        className=" shimmer-effect "
        style={{
          // width: "700px!important",
          // height: randomHeights[index % 10],
          height: "40px",
        }}
      ></div>
    </div>
  );
  const [customLoader, setCustomLoader] = useState<any>(false);
  useEffect(() => {
    const parentDiv: any = document.getElementById("custom-loader");
    if (
      paginateData?.current_page === paginateData?.last_page &&
      paginateData?.current_page !== undefined &&
      paginateData?.last_page !== undefined
    ) {
      if (parentDiv) {
        parentDiv.classList.add("hidden");
      }
    }
    if (
      paginateData?.current_page !== paginateData?.last_page &&
      paginateData?.current_page < paginateData?.last_page
    ) {
      if (parentDiv) {
        parentDiv.classList.add("visible");
        parentDiv.classList.remove("hidden");
      }
    }
  }, [paginateData]);

  useEffect(() => {
    if (data?.length > 0)
      setTimeout(() => {
        setCustomLoader(true);
      }, 1000);
  }, [data]);

  useEffect(() => {
    const handleScrollTable = () => {
      const EventAccordion = document.getElementById(
        "EventAccordion"
      ) as HTMLElement;

      const topfilterTop: any = document.getElementById("topFilters");

      const topValue: any = topfilterTop.style.top; // Retrieve the current 'top' value
      // if (topValue.includes("-")) {
      // Check if the value contains a negative sign
      const currentValue = parseInt(topValue); // Extract the numeric value
      const newValue = Math.abs(currentValue); // Make it positive
      topfilterTop.style.top = `${newValue}px`; // Apply the new positive value back as a string
      // }
      const windScroll: number = window.scrollY;

      const headingOne: any =
        document.getElementById("headingOne")?.clientHeight;
      const inventoryaccordion: any =
        document.getElementById("inventoryaccordion")?.clientHeight;
      // const totleScroll = headingOne + inventoryaccordion + 16;

      const topFiltersHeight = topfilterTop.clientHeight;
      let totleScroll = 0;
      if (window.matchMedia("(max-width: 767px)").matches) {
        totleScroll = topFiltersHeight;
      } else {
        totleScroll = headingOne + inventoryaccordion + 16;
      }
      // const currentTop = parseInt(topfilterTop.style.top || "0");
      const stickyShadow: any = document.getElementById("sticky-shadow");

      const stickyHead: any = document.getElementById("sticky-head");

      //code to adjust sticky header based on filter chip Area scrollbar
      const filterChipArea = document.querySelector(
        ".filterChipArea"
      ) as HTMLElement;
      let filterChipAreaWidth = 0;
      let width = 0;

      function getBodyScrollbarWidth() {
        const filterChipArea = document.querySelector(
          ".filterChipArea"
        ) as HTMLElement;
        // Creating a div element
        const scrollDiv = document.createElement("div");
        scrollDiv.style.width = "100px";
        scrollDiv.style.height = "100px";
        scrollDiv.style.overflow = "scroll";
        scrollDiv.style.position = "absolute";
        scrollDiv.style.top = "-9999px"; // Hide the div off-screen

        // Append the div to the document body
        filterChipArea.appendChild(scrollDiv);
        // Calculate the body scrollbar width
        const scrollbarWidth =
          filterChipArea.offsetHeight - filterChipArea.clientHeight;
        // Remove the div from the document
        filterChipArea.removeChild(scrollDiv);
        return scrollbarWidth;
      }
      const bodyScrollbarWidth = getBodyScrollbarWidth();
      if (
        filterChipArea &&
        filterChipArea.children.length > 0 &&
        bodyScrollbarWidth !== 0
      ) {
        width = filterChipArea.scrollWidth;
        filterChipAreaWidth = filterChipArea.offsetWidth;
      }

      if (width > filterChipAreaWidth && width != 0) {
        // stickyHead?.classList.remove("top-[14.625rem]");
        stickyHead?.classList.add("sticky-header-scroll");
        // stickyHead?.classList.remove("xl:top-[12.375rem]");
        // stickyHead?.classList.remove("xl:top-[12.625rem]");
      }

      if (windScroll > totleScroll) {
        EventAccordion?.classList?.add("-mt-10");
        stickyHead?.classList.remove("opacity-0");
        stickyHead?.classList.remove("h-0");
        stickyHead?.classList.add("h-[2.5625rem]");
        stickyShadow?.classList.add("!block");
      } else if (windScroll < totleScroll) {
        EventAccordion?.classList?.remove("-mt-10");
        stickyHead?.classList.add("opacity-0");
        stickyHead?.classList.add("h-0");
        stickyHead?.classList.remove("h-[2.5625rem]");
        stickyShadow?.classList.remove("!block");
      }
    };
    document.addEventListener("scroll", handleScrollTable);
    document.addEventListener("resize", handleScrollTable);

    setTimeout(() => {
      const head: any = document.querySelector("#tableId1 thead");
      const CloneHead: any = head?.cloneNode(true);
      const TopFilter: any = document.querySelector("#sticky-head table");
      // TopFilter?.cloneNode(head);

      // TopFilter?.appendChild(CloneHead);
    }, 200);
  }, []);

  /*On scroll virtuoso list scroll stickyhead*/
  const handleScrollVirtuoso = () => {
    const scrollLeft = tableRef.current.scrollLeft;
    stickyTableRef.current.scrollLeft = scrollLeft;
  };
  /*On scroll sticky head list scroll virtuoso*/
  const handleScrollHead = () => {
    const scrollLeft = stickyTableRef.current.scrollLeft;
    tableRef.current.scrollLeft = scrollLeft;
  };

  const dataLenght = data.length + paginateData?.current_page * 30;

  // auto disabled left right table button
  useEffect(() => {
    const element = document.getElementById("tableId1");
    const stickyHead = document.getElementById("sticky-head");
    const scrollHandler = () => handleScroll(element, stickyHead);
    if (element) {
      element.removeEventListener("scroll", scrollHandler);
      element.addEventListener("scroll", scrollHandler);
      scrollHandler(); // Initial check
      return () => {
        element.removeEventListener("scroll", scrollHandler);
      };
    }
  }, []);

  useEffect(() => {
    const element = document.getElementById("tableId1");
    const stickyHead = document.getElementById("sticky-head");
    const scrollHandler = () => handleScroll(element, stickyHead);
    setTimeout(() => {
      scrollHandler();
    }, 1000);
  }, [showOrder]);

  useEffect(() => {
    display(true);
  }, [globalLayout.htmlFontSize]);

  return (
    <>
      <div
        className="accordion mb-4 font-medium transition-all relative md:px-5 max-lg:!mr-0"
        id={`EventAccordion`}
      >
        {/* <div className="absolute bg-white left-0 top-0 w-full h-full gap-y-2.5 flex flex-wrap mr-1 z-[0] overflow-hidden scrollbar-none flex-col">
          {Array.from({ length: data?.length }).map((key, index) => (
            <div
              className={`bg-gray-100 rounded mr-1 h-[1.875rem] w-full shimmer-effect`}
              key={index}
            ></div>
          ))}
        </div> */}
        <div
          id="sticky-head"
          className={`sticky sales-report-sticky-head z-10 opacity-0 h-0  overflow-auto scrollbar-none ${
            isGodAdmin
              ? "top-[14.625rem]"
              : "xl:top-[12.375rem] top-[14.625rem]"
          }`}
          ref={stickyTableRef}
          onScroll={handleScrollHead}
        >
          <table className="w-full text-xs text-left  min-w-[87.5rem] absolute left-0 top-0 w-full">
            <thead className="text-xs text-gray-400 ">
              <tr className="border-b bg-white">
                {getColumnItems().map((columnItem: any, index: number) => {
                  switch (columnItem.id) {
                    case 0:
                      return (
                        layout.columnItems[`${getFilterIndexForCheckbox(0)}`]
                          ?.checked && (
                          <React.Fragment key={index}>
                            <HeaderCell
                              title="Team name"
                              width="min-w-[9.375rem]"
                              arrow={true}
                              postKey="name"
                              padding="pl-5"
                            />
                          </React.Fragment>
                        )
                      );
                    case 1:
                      return (
                        layout.columnItems[`${getFilterIndexForCheckbox(1)}`]
                          ?.checked && (
                          <React.Fragment key={index}>
                            <HeaderCell
                              title="Team member"
                              width="min-w-[9.375rem]"
                              arrow={true}
                              postKey="username"
                              padding="pl-5"
                            />
                          </React.Fragment>
                        )
                      );
                    case 2:
                      return (
                        layout.columnItems[`${getFilterIndexForCheckbox(2)}`]
                          .checked && (
                          <React.Fragment key={index}>
                            <HeaderCell
                              title="Order ID"
                              width="min-w-[6.875rem]"
                              arrow={false}
                              postKey="order_id"
                              padding="pl-5"
                            />
                          </React.Fragment>
                        )
                      );
                    case 3:
                      return (
                        layout.columnItems[`${getFilterIndexForCheckbox(3)}`]
                          .checked && (
                          <React.Fragment key={index}>
                            <HeaderCell
                              title="Order date"
                              width="min-w-[10.875rem]"
                              arrow={true}
                              postKey="order_date_time"
                            />
                          </React.Fragment>
                        )
                      );
                    case 4:
                      return (
                        layout.columnItems[`${getFilterIndexForCheckbox(4)}`]
                          ?.checked && (
                          <React.Fragment key={index}>
                            <HeaderCell
                              // title="Order value"
                              title={
                                isGodAdmin ? "Proceed price" : "Order value"
                              }
                              width="min-w-[6.875rem]"
                              arrow={true}
                              postKey="transaction_value"
                            />
                          </React.Fragment>
                        )
                      );
                    case 5:
                      return (
                        layout.columnItems[`${getFilterIndexForCheckbox(5)}`]
                          ?.checked && (
                          <React.Fragment key={index}>
                            <HeaderCell
                              // title="Order value"
                              title="Marketplace value"
                              width="min-w-[6.875rem]"
                              arrow={false}
                              postKey="transaction_value"
                            />
                          </React.Fragment>
                        )
                      );
                    case 6:
                      return (
                        layout.columnItems[`${getFilterIndexForCheckbox(6)}`]
                          ?.checked && (
                          <React.Fragment key={index}>
                            <HeaderCell
                              // title="Order value"
                              title="Seller payouts	"
                              width="min-w-[6.875rem]"
                              arrow={false}
                              postKey="transaction_value"
                            />
                          </React.Fragment>
                        )
                      );
                    case 7:
                      return (
                        layout.columnItems[`${getFilterIndexForCheckbox(7)}`]
                          ?.checked && (
                          <React.Fragment key={index}>
                            <HeaderCell
                              title="Tixstock revenue"
                              width="min-w-[6.875rem]"
                              arrow={false}
                              postKey="tixstock_revenue"
                            />
                          </React.Fragment>
                        )
                      );
                    case 8:
                      return (
                        layout.columnItems[`${getFilterIndexForCheckbox(8)}`]
                          ?.checked && (
                          <React.Fragment key={index}>
                            <HeaderCell
                              title="Event"
                              width="min-w-[15.125rem]"
                              arrow={true}
                              postKey="event_description"
                            />
                          </React.Fragment>
                        )
                      );
                    case 9:
                      return (
                        layout.columnItems[`${getFilterIndexForCheckbox(9)}`]
                          ?.checked && (
                          <React.Fragment key={index}>
                            <HeaderCell
                              title="Venue"
                              width="min-w-[9.375rem]"
                              arrow={true}
                              postKey="event_venue"
                            />
                          </React.Fragment>
                        )
                      );
                    case 10:
                      return (
                        layout.columnItems[`${getFilterIndexForCheckbox(10)}`]
                          ?.checked && (
                          <React.Fragment key={index}>
                            <HeaderCell
                              title="Event date"
                              width="min-w-[6.875rem]"
                              arrow={true}
                              postKey="event_date"
                            />
                          </React.Fragment>
                        )
                      );
                    case 11:
                      return (
                        layout.columnItems[`${getFilterIndexForCheckbox(11)}`]
                          ?.checked && (
                          <React.Fragment key={index}>
                            <HeaderCell
                              title="Ticket details"
                              width="min-w-[15.125rem]"
                              arrow={true}
                              postKey="ticket_section"
                            />
                          </React.Fragment>
                        )
                      );
                    case 12:
                      return (
                        layout.columnItems[`${getFilterIndexForCheckbox(12)}`]
                          ?.checked && (
                          <React.Fragment key={index}>
                            <HeaderCell
                              title="Qty"
                              width="min-w-[6.875rem]"
                              arrow={true}
                              postKey="ticket_quantity"
                            />
                          </React.Fragment>
                        )
                      );
                    case 13:
                      return (
                        layout.columnItems[`${getFilterIndexForCheckbox(13)}`]
                          ?.checked && (
                          <React.Fragment key={index}>
                            <HeaderCell
                              title="Ticket type"
                              width="min-w-[6.875rem]"
                              arrow={true}
                              postKey="ticket_type"
                            />
                          </React.Fragment>
                        )
                      );
                    case 14:
                      return (
                        layout.columnItems[`${getFilterIndexForCheckbox(14)}`]
                          ?.checked && (
                          <React.Fragment key={index}>
                            <HeaderCell
                              title="Order status"
                              width="min-w-[7rem]"
                              arrow={true}
                              postKey="order_status"
                            />
                          </React.Fragment>
                        )
                      );
                    case 15:
                      return (
                        layout.columnItems[`${getFilterIndexForCheckbox(15)}`]
                          ?.checked &&
                        isGodAdmin && (
                          <React.Fragment key={index}>
                            <HeaderCell
                              title="Internal order status"
                              width="min-w-[11.5rem]"
                              arrow={false}
                              postKey="internal_order_status"
                            />
                          </React.Fragment>
                        )
                      );
                    case 16:
                      return (
                        layout.columnItems[`${getFilterIndexForCheckbox(16)}`]
                          ?.checked && (
                          <React.Fragment key={index}>
                            <HeaderCell
                              title="Payment status"
                              width="min-w-[6.875rem]"
                              arrow={true}
                              postKey="paid"
                            />
                          </React.Fragment>
                        )
                      );
                    case 17:
                      return (
                        layout.columnItems[`${getFilterIndexForCheckbox(17)}`]
                          .checked && (
                          <React.Fragment key={index}>
                            <HeaderCell
                              title="Marketplace"
                              width="min-w-[15.125rem]"
                              arrow={true}
                              postKey="exchange_title"
                            />
                          </React.Fragment>
                        )
                      );
                    case 18:
                      return (
                        layout.columnItems[`${getFilterIndexForCheckbox(18)}`]
                          .checked && (
                          <React.Fragment key={index}>
                            <HeaderCell
                              title="Marketplace ID"
                              width="min-w-[15.125rem]"
                              arrow={false}
                            />
                          </React.Fragment>
                        )
                      );

                    case 19:
                      return (
                        isGodAdmin &&
                        layout.columnItems[`${getFilterIndexForCheckbox(19)}`]
                          .checked && (
                          <HeaderCell
                            key={index}
                            title="Buyer fees"
                            width="min-w-[8.875rem]"
                            arrow={false}
                          />
                        )
                      );
                    case 20:
                      return (
                        isGodAdmin &&
                        layout.columnItems[`${getFilterIndexForCheckbox(20)}`]
                          .checked && (
                          <HeaderCell
                            key={index}
                            title="Buyer"
                            width="min-w-[8.875rem]"
                            arrow={false}
                          />
                        )
                      );
                  }
                })}
                <th className="sticky right-0 ml-auto  p-0 max-md:min-w-[2.5rem] w-[5.0625rem]">
                  <div
                    className="py-[.4375rem] px-4 flex justify-end h-[2.5rem] left-shad border-l bg-white max-md:hidden"
                    id="left-shad-list"
                  >
                    <div className="arrow_buttons flex shadow-op1 rounded ">
                      <button
                        className="group rounded-l  hover: transition px-[.5625rem] py-2"
                        id="leftArrow"
                        type="button"
                        onClick={(e: any) => scroll(-120)}
                      >
                        <IKTSVG
                          className="flex items-center justify-center fill-violet-500 transition group-hover:fill-indigo-500"
                          path={angleLeftSVG}
                          svgClassName="w-1.5 h-[.5625rem]"
                        />
                      </button>
                      <button
                        className="group rounded-r  transition px-[.5625rem] py-2"
                        id="rightArrow"
                        type="button"
                        onClick={(e: any) => scroll(120)}
                      >
                        <IKTSVG
                          className="flex items-center justify-center fill-violet-500 transition group-hover:fill-indigo-500"
                          path={angleRightSVG}
                          svgClassName="w-1.5 h-[.5625rem]"
                        />
                      </button>
                    </div>
                  </div>
                </th>
                {isMobile && (
                  <th className="md:hidden sticky right-0 ml-auto p-0 max-md:min-w-[2.5rem] w-10 "></th>
                )}
              </tr>
            </thead>
          </table>
        </div>
        <span id="sticky-shadow" className="z-10 hidden h-[0.5rem]"></span>
        <div className="accordion-item bg-white  !rounded overflow-hidden">
          {/* <EventFields /> */}
          <div
            id="EventCollapseBody"
            className="accordion-collapse collapse show"
            aria-labelledby="EventCollapseBody"
            data-te-collapse-show
          >
            <div className="accordion-body relative">
              <div
                className="inventory-table sales-table overflow-x-auto scrollbar-none scrollbar-thumb-transparent scrollbar-thumb-rounded-full hover-style2"
                ref={tableRef}
                id={`tableId1`}
                onScroll={handleScrollVirtuoso}
                // style={{
                //   height: `${
                //     virtuosoHeight() > height ? virtuosoHeight() : height
                //   }px`,
                // }}
                style={{ height: height > 41 ? `${height}px` : "auto" }}
              >
                <TableVirtuoso
                  data={data}
                  endReached={() => {
                    if (paginateData?.current_page < paginateData?.last_page) {
                      layout.setReportsFilterHandle((current: any) => {
                        let nextPage: number = paginateData?.current_page + 1;

                        return {
                          ...current,
                          page: nextPage,
                          per_page: 20,
                        };
                      });
                    }
                  }}
                  // ref={containerRef}
                  useWindowScroll
                  // isScrolling={(e: any) => {
                  //   setVirtousScroll(e);
                  // }}

                  overscan={200}
                  totalCount={data?.length}
                  atBottomThreshold={100}
                  id={"Report-lazyload"}
                  components={{
                    Table: Table,
                    TableHead: TableHead,
                    TableRow: (props) => {
                      return (
                        <TableRow
                          {...props}
                          hoveredId={hoveredId}
                          setHoveredId={setHoveredId}
                          showOrder={showOrder}
                          isRowHighlighted={isRowHighlighted}
                        />
                      );
                    },
                    TableFoot: (props) => {
                      if (
                        data?.length === paginateData?.total &&
                        paginateData?.current_page > 1
                      ) {
                        return (
                          <tfoot
                            className={`text-center text-sm13 py-3 px-2.5 bg-white border-t`}
                            id={`NoMoreListing`}
                          >
                            <tr className="border-b bg-white">
                              <td
                                colSpan={11}
                                align="center"
                                className="h-[2.8125rem]"
                              >
                                No more items to show
                              </td>
                            </tr>
                          </tfoot>
                        );
                      } else {
                        return null;
                      }
                    },
                    // ScrollSeekPlaceholder,
                  }}
                  // scrollSeekConfiguration={{
                  //   enter: (velocity) => Math.abs(velocity) > 300,
                  //   exit: (velocity) => {
                  //     const shouldExit = Math.abs(velocity) < 10;
                  //     if (shouldExit) {
                  //       // setVisibleRange(["-", "-"]);
                  //     }
                  //     return shouldExit;
                  //   },
                  //   // change: (_velocity, { startIndex, endIndex }) => setVisibleRange([startIndex, endIndex])
                  // }}
                  fixedFooterContent={() => {
                    return (
                      <tr className="border-b bg-white">
                        <td colSpan={0}>Hi</td>
                      </tr>
                    );
                  }}
                  fixedHeaderContent={() => (
                    <tr className="border-b bg-white">
                      {getColumnItems().map(
                        (columnItem: any, index: number) => {
                          switch (columnItem.id) {
                            case 0:
                              return (
                                layout.columnItems[
                                  `${getFilterIndexForCheckbox(0)}`
                                ]?.checked && (
                                  <React.Fragment key={index}>
                                    <HeaderCell
                                      title="Team name"
                                      width="min-w-[9.375rem]"
                                      arrow={true}
                                      postKey="name"
                                      padding="pl-5"
                                    />
                                  </React.Fragment>
                                )
                              );
                            case 1:
                              return (
                                layout.columnItems[
                                  `${getFilterIndexForCheckbox(1)}`
                                ]?.checked && (
                                  <React.Fragment key={index}>
                                    <HeaderCell
                                      title="Team member"
                                      width="min-w-[9.375rem]"
                                      arrow={true}
                                      postKey="username"
                                      padding="pl-5"
                                    />
                                  </React.Fragment>
                                )
                              );
                            case 2:
                              return (
                                layout.columnItems[
                                  `${getFilterIndexForCheckbox(2)}`
                                ].checked && (
                                  <React.Fragment key={index}>
                                    <HeaderCell
                                      title="Order ID"
                                      width="min-w-[6.875rem]"
                                      arrow={false}
                                      postKey="order_id"
                                      padding="pl-5"
                                    />
                                  </React.Fragment>
                                )
                              );
                            case 3:
                              return (
                                layout.columnItems[
                                  `${getFilterIndexForCheckbox(3)}`
                                ].checked && (
                                  <React.Fragment key={index}>
                                    <HeaderCell
                                      title="Order date"
                                      width="min-w-[6.875rem]"
                                      arrow={true}
                                      postKey="order_date_time"
                                    />
                                  </React.Fragment>
                                )
                              );
                            case 4:
                              return (
                                layout.columnItems[
                                  `${getFilterIndexForCheckbox(4)}`
                                ]?.checked && (
                                  <React.Fragment key={index}>
                                    <HeaderCell
                                      // title="Order value"
                                      title={
                                        isGodAdmin
                                          ? "Proceed price"
                                          : "Order value"
                                      }
                                      width="min-w-[6.875rem]"
                                      arrow={true}
                                      postKey="transaction_value"
                                    />
                                  </React.Fragment>
                                )
                              );
                            case 5:
                              return (
                                layout.columnItems[
                                  `${getFilterIndexForCheckbox(5)}`
                                ]?.checked && (
                                  <React.Fragment key={index}>
                                    <HeaderCell
                                      // title="Order value"
                                      title="Marketplace value"
                                      width="min-w-[6.875rem]"
                                      arrow={false}
                                      postKey="transaction_value"
                                    />
                                  </React.Fragment>
                                )
                              );
                            case 6:
                              return (
                                layout.columnItems[
                                  `${getFilterIndexForCheckbox(6)}`
                                ]?.checked && (
                                  <React.Fragment key={index}>
                                    <HeaderCell
                                      // title="Order value"
                                      title="Seller payouts"
                                      width="min-w-[6.875rem]"
                                      arrow={false}
                                      postKey="transaction_value"
                                    />
                                  </React.Fragment>
                                )
                              );
                            case 7:
                              return (
                                layout.columnItems[
                                  `${getFilterIndexForCheckbox(7)}`
                                ]?.checked && (
                                  <React.Fragment key={index}>
                                    <HeaderCell
                                      title="Tixstock revenue"
                                      width="min-w-[6.875rem]"
                                      arrow={false}
                                      postKey="tixstock_revenue"
                                    />
                                  </React.Fragment>
                                )
                              );
                            case 8:
                              return (
                                layout.columnItems[
                                  `${getFilterIndexForCheckbox(8)}`
                                ]?.checked && (
                                  <React.Fragment key={index}>
                                    <HeaderCell
                                      title="Event"
                                      width="min-w-[6.875rem]"
                                      arrow={true}
                                      postKey="event_description"
                                    />
                                  </React.Fragment>
                                )
                              );
                            case 9:
                              return (
                                layout.columnItems[
                                  `${getFilterIndexForCheckbox(9)}`
                                ]?.checked && (
                                  <React.Fragment key={index}>
                                    <HeaderCell
                                      title="Venue"
                                      width="min-w-[6.875rem]"
                                      arrow={true}
                                      postKey="event_venue"
                                    />
                                  </React.Fragment>
                                )
                              );
                            case 10:
                              return (
                                layout.columnItems[
                                  `${getFilterIndexForCheckbox(10)}`
                                ]?.checked && (
                                  <React.Fragment key={index}>
                                    <HeaderCell
                                      title="Event date"
                                      width="min-w-[6.875rem]"
                                      arrow={true}
                                      postKey="event_date"
                                    />
                                  </React.Fragment>
                                )
                              );
                            case 11:
                              return (
                                layout.columnItems[
                                  `${getFilterIndexForCheckbox(11)}`
                                ]?.checked && (
                                  <React.Fragment key={index}>
                                    <HeaderCell
                                      title="Ticket details"
                                      width="min-w-[6.875rem]"
                                      arrow={true}
                                      postKey="ticket_section"
                                    />
                                  </React.Fragment>
                                )
                              );
                            case 12:
                              return (
                                layout.columnItems[
                                  `${getFilterIndexForCheckbox(12)}`
                                ]?.checked && (
                                  <React.Fragment key={index}>
                                    <HeaderCell
                                      title="Qty"
                                      width="min-w-[6.875rem]"
                                      arrow={true}
                                      postKey="ticket_quantity"
                                    />
                                  </React.Fragment>
                                )
                              );
                            case 13:
                              return (
                                layout.columnItems[
                                  `${getFilterIndexForCheckbox(13)}`
                                ]?.checked && (
                                  <React.Fragment key={index}>
                                    <HeaderCell
                                      title="Ticket type"
                                      width="min-w-[6.875rem]"
                                      arrow={true}
                                      postKey="ticket_type"
                                    />
                                  </React.Fragment>
                                )
                              );
                            case 14:
                              return (
                                layout.columnItems[
                                  `${getFilterIndexForCheckbox(14)}`
                                ]?.checked && (
                                  <React.Fragment key={index}>
                                    <HeaderCell
                                      title="Order status"
                                      width="min-w-[6.875rem]"
                                      arrow={true}
                                      postKey="order_status"
                                    />
                                  </React.Fragment>
                                )
                              );
                            case 15:
                              return (
                                layout.columnItems[
                                  `${getFilterIndexForCheckbox(15)}`
                                ]?.checked &&
                                isGodAdmin && (
                                  <React.Fragment key={index}>
                                    <HeaderCell
                                      title="Internal order status"
                                      width="min-w-[11.5rem]"
                                      arrow={false}
                                      postKey="internal_order_status"
                                    />
                                  </React.Fragment>
                                )
                              );
                            case 16:
                              return (
                                layout.columnItems[
                                  `${getFilterIndexForCheckbox(16)}`
                                ]?.checked && (
                                  <React.Fragment key={index}>
                                    <HeaderCell
                                      title="Payment status"
                                      width="min-w-[6.875rem]"
                                      arrow={true}
                                      postKey="paid"
                                    />
                                  </React.Fragment>
                                )
                              );
                            case 17:
                              return (
                                layout.columnItems[
                                  `${getFilterIndexForCheckbox(17)}`
                                ].checked && (
                                  <React.Fragment key={index}>
                                    <HeaderCell
                                      title="Marketplace"
                                      width="min-w-[9.375rem]"
                                      arrow={true}
                                      postKey="exchange_title"
                                    />
                                  </React.Fragment>
                                )
                              );
                            case 18:
                              return (
                                layout.columnItems[
                                  `${getFilterIndexForCheckbox(18)}`
                                ].checked && (
                                  <React.Fragment key={index}>
                                    <HeaderCell
                                      title="Marketplace ID"
                                      width="min-w-[15.125rem]"
                                      arrow={false}
                                      // postKey="exchange_id"
                                    />
                                  </React.Fragment>
                                )
                              );

                            case 19:
                              return (
                                isGodAdmin &&
                                layout.columnItems[
                                  `${getFilterIndexForCheckbox(19)}`
                                ].checked && (
                                  <HeaderCell
                                    key={index}
                                    title="Buyer fees"
                                    width="min-w-[8.875rem]"
                                    arrow={false}
                                  />
                                )
                              );
                            case 20:
                              return (
                                isGodAdmin &&
                                layout.columnItems[
                                  `${getFilterIndexForCheckbox(20)}`
                                ].checked && (
                                  <HeaderCell
                                    key={index}
                                    title="Buyer"
                                    width="min-w-[8.875rem]"
                                    arrow={false}
                                  />
                                )
                              );
                          }
                        }
                      )}
                      <th className="sticky right-0 ml-auto  p-0 max-md:min-w-[2.5rem] w-[5.0625rem]">
                        <div
                          className="py-[.4375rem] px-4 flex justify-end h-[2.5rem] left-shad border-l bg-white max-md:hidden"
                          id="left-shad-list"
                        >
                          <div className="arrow_buttons flex shadow-op1 rounded ">
                            <button
                              className="group rounded-l  hover: transition px-[.5625rem] py-2"
                              id="leftArrow"
                              type="button"
                              onClick={(e: any) => scroll(-120)}
                            >
                              <IKTSVG
                                className="flex items-center justify-center fill-violet-500 transition group-hover:fill-indigo-500"
                                path={angleLeftSVG}
                                svgClassName="w-1.5 h-[.5625rem]"
                              />
                            </button>
                            <button
                              className="group rounded-r  transition px-[.5625rem] py-2"
                              id="rightArrow"
                              type="button"
                              onClick={(e: any) => scroll(120)}
                            >
                              <IKTSVG
                                className="flex items-center justify-center fill-violet-500 transition group-hover:fill-indigo-500"
                                path={angleRightSVG}
                                svgClassName="w-1.5 h-[.5625rem]"
                              />
                            </button>
                          </div>
                        </div>
                      </th>
                      {isMobile && (
                        <th className="md:hidden sticky right-0 ml-auto p-0 max-md:min-w-[2.5rem] w-10 "></th>
                      )}
                    </tr>
                  )}
                  itemContent={(sIndex: any, list: any) => {
                    let matchedView = isRowHighlighted(list);
                    let viewHighlighted = isSameOrderId(showOrder?.data, list);
                    let logHighlighted = isSameOrderId(logsData, list);
                    return (
                      <React.Fragment key={sIndex}>
                        {getColumnItems().map((columnItem: any) => {
                          switch (columnItem.id) {
                            case 0:
                              return (
                                <>
                                  {/* ORDER ID */}
                                  {layout.columnItems[
                                    `${getFilterIndexForCheckbox(0)}`
                                  ]?.checked && (
                                    <DataCell
                                      value={list?.team_name}
                                      classname="block"
                                      padding="pl-5"
                                      width="w-[7.75rem]"
                                    />
                                  )}
                                </>
                              );
                            case 1:
                              return (
                                <>
                                  {/* ORDER ID */}
                                  {layout.columnItems[
                                    `${getFilterIndexForCheckbox(1)}`
                                  ]?.checked && (
                                    <DataCell
                                      value={list?.user_name}
                                      classname="block"
                                      padding="pl-5"
                                      width="w-[7.75rem]"
                                    />
                                  )}
                                </>
                              );
                            case 2:
                              return (
                                <>
                                  {/* ORDER ID */}
                                  {layout.columnItems[
                                    `${getFilterIndexForCheckbox(2)}`
                                  ].checked && (
                                    <DataCell
                                      value={list?.order_id}
                                      classname="block"
                                      padding="pl-5"
                                      width="w-[5.25rem]"
                                    />
                                  )}
                                </>
                              );
                            case 3:
                              return (
                                <>
                                  {/* ORDER DATE */}
                                  {layout.columnItems[
                                    `${getFilterIndexForCheckbox(3)}`
                                  ].checked && (
                                    <DataCell
                                      value={moment(
                                        list?.order_date_time
                                      ).format("DD/MM/YYYY")}
                                      classname="block"
                                      width="w-[10.125rem]"
                                    />
                                  )}
                                </>
                              );
                            case 4:
                              return (
                                <>
                                  {/* ORDER VALUE */}
                                  {layout.columnItems[
                                    `${getFilterIndexForCheckbox(4)}`
                                  ]?.checked && (
                                    <DataCell
                                      value={`${
                                        SYMBOLS?.[list?.ticket_currency]
                                      }${decimalNumber(
                                        list?.transaction_value
                                      )}`}
                                      classname="block"
                                    />
                                  )}
                                </>
                              );
                            case 5:
                              return (
                                <>
                                  {/* Marketplace value */}
                                  {layout.columnItems[
                                    `${getFilterIndexForCheckbox(5)}`
                                  ]?.checked && (
                                    <DataCell
                                      value={`${
                                        SYMBOLS?.[
                                          list?.marketplace_ticket_currency
                                        ]
                                          ? SYMBOLS?.[
                                              list?.marketplace_ticket_currency
                                            ]
                                          : `${list?.marketplace_ticket_currency} `
                                      }${decimalNumber(
                                        list?.marketplace_selling_price
                                      )}`}
                                      classname="block"
                                    />
                                  )}
                                </>
                              );
                            case 6:
                              return (
                                <>
                                  {/* Settlement/net payout value */}
                                  {layout.columnItems[
                                    `${getFilterIndexForCheckbox(6)}`
                                  ]?.checked && (
                                    <DataCell
                                      value={`${
                                        SYMBOLS?.[list?.settlement_currency]
                                      }${decimalNumber(
                                        list?.settlement_value
                                      )}`}
                                      classname="block"
                                    />
                                  )}
                                </>
                              );
                            case 7:
                              return (
                                <>
                                  {/* Tixstock revenue */}
                                  {layout.columnItems[
                                    `${getFilterIndexForCheckbox(7)}`
                                  ]?.checked && (
                                    <DataCell
                                      value={`${
                                        SYMBOLS?.[list?.settlement_currency]
                                      }${decimalNumber(
                                        list?.tixstock_revenue
                                      )}`}
                                      classname="block"
                                    />
                                  )}
                                </>
                              );
                            case 8:
                              return (
                                <>
                                  {/* ORDER DESCRIPTION */}
                                  {layout.columnItems[
                                    `${getFilterIndexForCheckbox(8)}`
                                  ]?.checked && (
                                    <DataCell
                                      value={list?.event_description}
                                      width="w-[14.375rem]"
                                      noCapitalize={"yes"}
                                    />
                                  )}
                                </>
                              );
                            case 9:
                              return (
                                <>
                                  {/*   VENUE */}
                                  {layout.columnItems[
                                    `${getFilterIndexForCheckbox(9)}`
                                  ]?.checked && (
                                    <DataCell
                                      value={list?.venue}
                                      subValue={"&nbsp"}
                                      width="w-[8.625rem]"
                                    />
                                  )}
                                </>
                              );
                            case 10:
                              return (
                                <>
                                  {/*   EVENT DATE */}
                                  {layout.columnItems[
                                    `${getFilterIndexForCheckbox(10)}`
                                  ]?.checked && (
                                    <DataCell
                                      value={moment(list?.event_date).format(
                                        "DD/MM/YYYY"
                                      )}
                                    />
                                  )}
                                </>
                              );
                            case 11:
                              return (
                                <>
                                  {/* TICKET DETAILS */}
                                  {layout.columnItems[
                                    `${getFilterIndexForCheckbox(11)}`
                                  ]?.checked && (
                                    <DataCell
                                      value={list?.seating_location}
                                      width="w-[14.375rem]"
                                    />
                                  )}
                                </>
                              );
                            case 12:
                              return (
                                <>
                                  {/* QUANTITY */}
                                  {layout.columnItems[
                                    `${getFilterIndexForCheckbox(12)}`
                                  ]?.checked && (
                                    <DataCell
                                      value={
                                        list?.ticket_quantity < 10
                                          ? list?.ticket_quantity
                                              .toString()
                                              .padStart(2, 0)
                                          : list?.ticket_quantity
                                      }
                                    />
                                  )}
                                </>
                              );
                            case 13:
                              return (
                                <>
                                  {/* TICKET TYPE */}
                                  {layout.columnItems[
                                    `${getFilterIndexForCheckbox(13)}`
                                  ]?.checked && (
                                    <DataCell
                                      // value={list?.ticket_type}
                                      value={ticketTypeRenameForSalesReport(
                                        list?.ticket_type,
                                        list?.sub_ticket_type
                                      )}
                                      width="w-[6.25rem]"
                                      noCapitalize={"yes"}
                                    />
                                  )}
                                </>
                              );
                            case 14:
                              return (
                                <>
                                  {/* ORDER STATUS */}
                                  {layout.columnItems[
                                    `${getFilterIndexForCheckbox(14)}`
                                  ]?.checked && (
                                    <DataCell value={list?.order_status} />
                                  )}
                                </>
                              );
                            case 15:
                              return (
                                <>
                                  {/* ORDER STATUS */}
                                  {layout.columnItems[
                                    `${getFilterIndexForCheckbox(15)}`
                                  ]?.checked &&
                                    isGodAdmin && (
                                      <DataCell
                                        value={
                                          list?.internal_order_status
                                            ? list?.internal_order_status
                                            : "-"
                                        }
                                      />
                                    )}
                                </>
                              );
                            case 16:
                              return (
                                <>
                                  {/* ORDER STATUS */}
                                  {layout.columnItems[
                                    `${getFilterIndexForCheckbox(16)}`
                                  ]?.checked && (
                                    <DataCell
                                      value={
                                        list?.paid === "false"
                                          ? "Unpaid"
                                          : "Paid"
                                      }
                                    />
                                  )}
                                </>
                              );
                            case 17:
                              return (
                                <>
                                  {/* MARKETPLACE */}
                                  {layout.columnItems[
                                    `${getFilterIndexForCheckbox(17)}`
                                  ].checked && (
                                    <DataCell
                                      value={list?.marketplace}
                                      width="w-[14.375rem]"
                                    />
                                  )}
                                </>
                              );
                            case 18:
                              return (
                                <>
                                  {/* MARKET ID */}
                                  {layout.columnItems[
                                    `${getFilterIndexForCheckbox(18)}`
                                  ].checked && (
                                    <DataCell
                                      value={list?.external_order_id}
                                      width="w-[14.375rem]"
                                    />
                                  )}
                                </>
                              );

                            case 19:
                              return (
                                isGodAdmin &&
                                layout.columnItems[
                                  `${getFilterIndexForCheckbox(19)}`
                                ].checked && (
                                  <DataCell
                                    value={`${
                                      SYMBOLS?.[list?.settlement_currency] ?? ""
                                    }${decimalNumber(list?.buyer_fees)}`}
                                    width="min-w-[8.125rem]"
                                  />
                                )
                              );
                            case 20:
                              return (
                                isGodAdmin &&
                                layout.columnItems[
                                  `${getFilterIndexForCheckbox(20)}`
                                ].checked && (
                                  <DataCell
                                    value={list?.buyer_name ?? "-"}
                                    width="min-w-[8.125rem]"
                                  />
                                )
                              );
                          }
                        })}
                        <td className="md:sticky relative right-0 ml-auto p-0 icon_td max-md:w-10">
                          <React.Fragment key={sIndex}>
                            <div
                              className={`${
                                matchedView
                                  ? "bg-indigo-500 bg-opacity-[7%]"
                                  : ""
                              } `}
                            >
                              <div
                                className="flex left-shad relative w-full h-full border-l"
                                id="left-shad-list"
                              >
                                {list?.is_legacy_order == "true" && (
                                  <button className="absolute top-1/2 -translate-y-1/2 right-full text-white text-xxs bg-violet-500 px-[.4375rem] py-1 leading-3 rounded-l whitespace-nowrap cursor-default">
                                    Legacy order
                                  </button>
                                )}

                                <button
                                  className={`group my-button flex items-center justify-center p-1 md:p-1 w-8 md:w-10 md:border-r md:flex-auto flex-1 ${
                                    isDateBeforeValidLogDate(
                                      list?.order_date_time
                                    ) && !isGodAdmin
                                      ? "pointer-events-none"
                                      : ""
                                  }`}
                                  disabled={
                                    isDateBeforeValidLogDate(
                                      list?.order_date_time
                                    ) && !isGodAdmin
                                  }
                                  type="button"
                                  id={`record-logs-tooltip${sIndex}`}
                                  onClick={() => {
                                    setActiveTab(LOG_TAB_KEYS.ORDER);
                                    setLogsData(list);
                                    setShowOrder({
                                      show: false,
                                      data: "",
                                      index: "",
                                    });
                                  }}
                                >
                                  <Tooltip
                                    anchorId={`record-logs-tooltip${sIndex}`}
                                    content={`Log`}
                                    place={`top`}
                                    variant="light"
                                    className={`!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10  ${
                                      sIndex === 0 ? "mt-0" : "mt-1.5"
                                    }`}
                                  />
                                  <IKTSVG
                                    className={`flex items-center justify-center ${
                                      isDateBeforeValidLogDate(
                                        list?.order_date_time
                                      ) && !isGodAdmin
                                        ? "fill-gray-500"
                                        : logHighlighted
                                        ? "fill-indigo-500"
                                        : "fill-violet-500"
                                    } ${
                                      logHighlighted
                                        ? "group-hover:fill-indigo-500"
                                        : "group-hover:fill-indigo-500"
                                    } transition`}
                                    path={logIcon}
                                    svgClassName="w-4 h-4"
                                  />
                                </button>
                                <button
                                  className={`group max-md:hidden my-button flex items-center justify-center p-1 md:p-1 w-8 md:w-10 border-r md:flex-auto flex-1 
                              `}
                                  type="button"
                                  id={`record-view-tooltip${sIndex}`}
                                  onClick={() => {
                                    setLogsData(null);
                                    handleOrderView(list, sIndex);
                                  }}
                                >
                                  <Tooltip
                                    anchorId={`record-view-tooltip${sIndex}`}
                                    content={`${
                                      showOrder?.data?.order_id === hoveredId
                                        ? "Close"
                                        : "View"
                                    }`}
                                    place="top"
                                    variant="light"
                                    className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10"
                                  />
                                  <IKTSVG
                                    className={`flex items-center justify-center transition group-hover:fill-indigo-500 ${
                                      viewHighlighted
                                        ? "fill-indigo-500"
                                        : "fill-violet-500"
                                    }`}
                                    path={eyeSVG}
                                    svgClassName="w-4 h-[.6875rem]"
                                  />
                                </button>
                              </div>
                            </div>
                          </React.Fragment>
                        </td>
                        {isMobile && (
                          <td
                            className={`sticky right-0 ml-auto p-0 icon_td max-md:min-w-[2.5rem] md:hidden `}
                          >
                            <React.Fragment key={sIndex}>
                              <div
                                className={`${
                                  matchedView
                                    ? "bg-indigo-500 bg-opacity-[7%]"
                                    : ""
                                } `}
                              >
                                <div
                                  className="flex  relative w-full h-full border-l"
                                  id="left-shad-list"
                                >
                                  <button
                                    className={`group my-button flex items-center justify-center p-1 md:p-1 w-8 md:w-10 border-r md:flex-auto flex-1 
                              `}
                                    type="button"
                                    id={`record-view-tooltip${sIndex}`}
                                    onClick={() => {
                                      setLogsData(null);
                                      handleOrderView(list, sIndex);
                                    }}
                                  >
                                    <Tooltip
                                      anchorId={`record-view-tooltip${sIndex}`}
                                      content={`${
                                        showOrder?.data?.order_id === hoveredId
                                          ? "Close"
                                          : "View"
                                      }`}
                                      place="top"
                                      variant="light"
                                      className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10"
                                    />
                                    <IKTSVG
                                      className={`flex items-center justify-center transition group-hover:fill-indigo-500 ${
                                        viewHighlighted
                                          ? "fill-indigo-500"
                                          : "fill-violet-500"
                                      }`}
                                      path={eyeSVG}
                                      svgClassName="w-4 h-[.6875rem]"
                                    />
                                  </button>
                                </div>
                              </div>
                            </React.Fragment>
                          </td>
                        )}
                        {/* </tr> */}
                      </React.Fragment>
                    );
                  }}
                  ref={virtuosoRef}
                  // height={data?.length * 40.5}
                />
                {reportsLoading && (
                  <></>
                  // <ProgressBardLoader
                  //   LoadingText="Loading your report data"
                  //   secondCounter={1}
                  // />
                  // <div
                  //   className={
                  //     "w-full bg-white flex justify-center items-center"
                  //   }
                  //   style={{ height: "40px" }}
                  // >
                  //   <IKTSVG
                  //     className="flex items-center justify-center fill-violet-500 transition animate-spin"
                  //     path={ProcessSVG}
                  //   />
                  // </div>
                )}

                {/* ORDER VIEW PAGE */}
                {showOrder?.show && (
                  <OrderDetail
                    show={showOrder}
                    handleShow={(data: any) => setShowOrder(data)}
                    isGodAdmin={isGodAdmin}
                    setShowOrder={setShowOrder}
                    reportsOptions={reportsOptions}
                    layoutContextOrder={layout.orders}
                    setLayoutContextOrder={layout.setOrders}
                    lockButtonShow={true}
                  />
                )}

                {logsData && (
                  <LogDetailsPopUp
                    logsData={logsData}
                    setLogsData={setLogsData}
                    request={getSalesLogListing(logsData)}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                  />
                )}
              </div>
            </div>

            <div
              id="custom-loader"
              className={`accordion bg-white font-medium pt-2.5 border-t `}
            >
              <div
                className={`accordion-item bg-white mb-2.5 !rounded overflow-hidden shimmer-effect`}
                style={{ height: "30px" }}
              ></div>
              <div
                className={`accordion-item bg-white mb-2.5 !rounded overflow-hidden shimmer-effect`}
                style={{ height: "30px" }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DataTable;
