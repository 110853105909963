import { quartersMonthList, quarterStartEndMonth } from "../const";
import { getMonthName } from "../utils";

export const useQuarterData = (currentDate: any) => {
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();
  const currentQuarter = Math.floor(currentMonth / 3) + 1; // Current quarter basis on current month
  const millisecondsPerDay = 1000 * 3600 * 24;

  const monthsInQuarter = quartersMonthList[currentQuarter];

  // Get start and end month index in Quarter
  const { start = 0, end = 0 } = quarterStartEndMonth[currentQuarter] || {};

  // Generate the days for each month in the quarter
  const totalDays = monthsInQuarter.reduce((init: any, next: any) => {
    init = init + new Date(currentYear, next + 1, 0).getDate();
    return init;
  }, 0);

  const startDayOfQuarter: any = new Date(
    currentYear,
    quarterStartEndMonth[currentQuarter]?.start,
    0
  ); // Get the start day of the month in current quater (Ex:- Mon Jan 01 20** 00:00:00 GMT+0530)

  const lastDayOfQuarter: any = new Date(
    currentYear,
    quarterStartEndMonth[currentQuarter]?.end + 1,
    0
  ); // Get the last day of the month in current quater (Ex:- Mon Mar 31 20** 00:00:00 GMT+0530)

  // Calculate the number of days passed from the start of the quarter
  const daysPassedInQuater = Math.floor(
    // Convert milliseconds to days
    (currentDate.getTime() - startDayOfQuarter.getTime()) / millisecondsPerDay
  );

  // Calculate the remaining days in the quarter
  const daysLeftInQuater = Math.ceil(
    // Convert milliseconds to days
    (lastDayOfQuarter.getTime() - currentDate.getTime()) / millisecondsPerDay
  );

  return {
    totalDays,
    daysLeftInQuater,
    daysPassedInQuater,
    startMonth: getMonthName(currentYear, start),
    endMonth: getMonthName(currentYear, end),
  };
};
