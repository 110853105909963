import { useContext } from "react";
import { SellerLevelsProviderContex } from "../core/SellerLevelsProvider";
import Tab from "./Tab";

const Tabs = () => {
  const layout = useContext(SellerLevelsProviderContex);
  return (
    <div className="tab-panel bg-no-repeat bg-tradepattern bg-cover bg-indigo-500 bg-opacity-[15%] p-5 pb-0 flex after:content after:absolute after:bottom-0 after:left-0 after:w-full after:h-2 after:z-0 after:from-black/0 after:bg-gradient-to-b after:to-black/5 relative overflow-x-auto flex-nowrap overflow-hidden">
      <Tab
        title="Configuration"
        path="/seller-levels/configuration"
        handleTab={() => {
          layout.setSellerLevelData({});
        }}
      />
      <Tab
        title="Movement"
        path="/seller-levels/movement"
        handleTab={() => {
          layout.setSellerLevelMovementData({});
          layout.setSellerLevelMovementFilters({
            page: 1,
            per_page: 20,
          });
        }}
      />
    </div>
  );
};

export default Tabs;
