import { SYMBOLS } from "helpers/const";
import { decimalNumber } from "helpers/Functions";
import { isArray, size } from "lodash";
import {
  FULL_WIDTH_KEYS,
  NEXT_LEVEL_KEY,
  PROGRESS_COLOR,
  SELLER_LEVEL_KEY,
  ZERO_WIDTH_KEYS,
} from "./const";

const getLevelGtv = (config: any, isEditable = false) => {
  if (config && isArray(config)) {
    const gtv =
      config?.find(
        (item: any) =>
          item?.key === "gtv_contribution_for_seller_level" &&
          (!isEditable || (isEditable && item?.is_edited))
      )?.value ?? 0;

    return Number(gtv);
  }

  return 0;
};

export const getUserSellerLevel = (sellerLevel: any, userLevel: any) => {
  if (sellerLevel && isArray(sellerLevel)) {
    return sellerLevel.reduce((acc: any, level: any) => {
      acc[level?.title] = getLevelGtv(level?.config);

      let editedGTV =
        level?.title === userLevel?.title
          ? getLevelGtv(userLevel?.config, true)
          : null;

      if (editedGTV) acc[level?.title] = editedGTV;
      return acc;
    }, {});
  }

  return {};
};

const getKeysForLevel = (level: any) => ({
  fullWidthKeys: FULL_WIDTH_KEYS[level] ?? [],
  zeroWidthKeys: ZERO_WIDTH_KEYS[level] ?? [],
});

export const checkIfSuperSeller = (seller: any) =>
  seller === SELLER_LEVEL_KEY.SUPER_SELLER;

export const calculateSellerLevelPercentage = (
  level: string,
  activeLevel: any,
  percentage: any
) => {
  const { fullWidthKeys, zeroWidthKeys } = getKeysForLevel(activeLevel);

  const isSuperSeller = checkIfSuperSeller(activeLevel);
  const isFullWidthLevel = fullWidthKeys.includes(level) || isSuperSeller;
  const isZeroWidthLevel = zeroWidthKeys.includes(level);

  if (isFullWidthLevel) return 100;
  if (isZeroWidthLevel) return 0;

  return Math.min(Math.max(percentage, 0), 100);
};

export const getLevelStatusMessage = (
  level: any,
  percentage: any,
  hasCongratsMsg = false,
  isOffTrack = false,
  userSellerLevel: any
) => {
  // Early return if the user is on track or the level is part of their seller level keys
  const { fullWidthKeys } = getKeysForLevel(userSellerLevel);
  if (isOffTrack || fullWidthKeys.includes(level))
    return `You are ${
      isOffTrack ? "not" : ""
    } on track to maintain ${userSellerLevel} status for next quarter`;

  // Early return for specific congratulations case
  if ((percentage <= 10 && hasCongratsMsg) || checkIfSuperSeller(level))
    return `Congratulations! You have unlocked ${level} status for next quarter`;

  const levelStatusMsg =
    percentage > 75 ? `unlock  ${NEXT_LEVEL_KEY[level]}` : `maintain ${level}`;

  // Return the general on-track message
  return `You are on track to ${levelStatusMsg} status for next quarter`;
};

export const getSellerTitle = (title: any) => {
  if (!title) return;

  return checkIfSuperSeller(title) ? title : `Seller ${title}`;
};

export const getMonthName = (currentYear: number, index: number) => {
  return new Date(currentYear, index).toLocaleString("default", {
    month: "short",
  });
};

export const formatCurrencyAmount = (currencyLabel: any, amount: any) => {
  const validAmount = !amount || isNaN(Number(amount)) ? 0 : amount;

  return `${SYMBOLS[currencyLabel] ?? ""}${decimalNumber(validAmount)}`;
};

export const getDarkBgColor = ({
  level,
  userSellerLevel,
  activeSellerLevel,
}: any) => {
  const { fullWidthKeys } = getKeysForLevel(userSellerLevel);

  // Highlight level- if level is Active Level, Level 3 and Not on track Levels
  const shouldHighlightLevel = [
    activeSellerLevel,
    userSellerLevel,
    SELLER_LEVEL_KEY.LEVEL3,
    ...fullWidthKeys,
  ].includes(level);

  if (shouldHighlightLevel) return PROGRESS_COLOR[level]?.dark;

  return "bg-gray-200";
};

export function getSellerLevel(
  targetAmount: any,
  sellerLevels: any,
  level: any
) {
  if (size(sellerLevels) === 0) return "";
  // Convert the seller levels object to an array of levels and their thresholds
  const levels = Object.entries(sellerLevels);

  // Iterate through the levels to determine the level for the targetAmount
  for (let i = 1; i < levels.length; i++) {
    const currentLevel: any = levels[i];
    const previousLevel: any = levels[i - 1];

    // Check if the targetAmount is within the range for this level
    if (targetAmount > previousLevel[1] && targetAmount <= currentLevel[1]) {
      return previousLevel[0]; // Return the level name
    }
  }

  // If no match is found, return the highest level (Super Seller)
  return level;
}
