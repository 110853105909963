import EDITSVG from "assets/media/icons/other_icons/edit-square.svg";
import CrossSVG from "assets/media/icons/standard_icons/cross.svg";
import { LayoutContext } from "components/core/LayoutProvider";
import TextInput from "components/formComponent/TextInput";
import TableDropdown from "components/tableComponent/TableDropdown";
import { IKTSVG } from "components/ui/IKTSVG";
import { useFormik } from "formik";
import { addCommaInNumber, errorReflect } from "helpers/Functions";
import { showAlert } from "helpers/ShowAlert";
import { display } from "pages/Sales/core/_functions";
import { useContext, useEffect, useState } from "react";
import { Tooltip } from "react-tooltip";
import * as Yup from "yup";
import { SellerLevelsProviderContex } from "../core/SellerLevelsProvider";
import { sellerLevelMovementAction } from "../core/request";

const MovementConfirmationPopup = ({
  canvasRef,
  setConfirmationPopup,
  confirmationPopup,
  actionApi,
  levelConfigData,
  setActionLoader,
}: any) => {
  const layout = useContext(SellerLevelsProviderContex);
  const globalLayout = useContext(LayoutContext);
  const [confirmationData, setConfirmationData] = useState<any>();
  const [OptionData, setOptionData] = useState<any>();
  const [isEdit, setEdit] = useState<any>({ status: false, index: [] });
  const [customEditedData, setCustomEditedData] = useState<any>();
  console.log(customEditedData, "customEditedData");
  const [
    persist_custom_seller_level_config,
    setPersist_custom_seller_level_config,
  ] = useState(1);
  const [validationSchema, setValidationSchema] = useState<any>();

  const handleAction = (persist_custom_seller_level_config: any) => {
    actionApi(confirmationData, persist_custom_seller_level_config);
  };

  useEffect(() => {
    display(confirmationPopup?.state, "momentconfirmationpopup");

    return () => {
      display(false);
    };
  }, [confirmationPopup?.state]);

  const handleOnCustomUpdate = (data: any, value: any, remove = false) => {
    let newValue: any = value === "false" ? false : value;
    setCustomEditedData((prevData: any) =>
      prevData?.map((obj: any) =>
        obj?.key?.toLowerCase() === data?.toLowerCase() && value
          ? { ...obj, is_edited: true, value: newValue }
          : remove
          ? { ...obj, is_edited: false }
          : obj
      )
    );
  };

  const defaultSellerLevel = globalLayout.allowedAccess?.seller_levels?.find(
    (sellerLevel: any) => sellerLevel.id === confirmationData?.seller_level?.id
  );

  const newSellerLevel = globalLayout.allowedAccess?.seller_levels?.find(
    (sellerLevel: any) =>
      sellerLevel.id === confirmationData?.proposed_level?.id
  );

  const initialValues = {
    ...confirmationData?.team_config?.reduce((acc: any, field: any) => {
      acc[field?.key] = field?.value || "";
      return acc;
    }, {}),
  };

  // Initialize useFormik
  const formik: any = useFormik({
    initialValues,
    validationSchema: validationSchema,
    validateOnMount: true,
    // enableReinitialize: true,
    onSubmit: async (values) => {
      // console.log(values, "valus");

      const data = customEditedData?.filter((item: any) => {
        return item?.is_edited === true;
      });

      setActionLoader(true);
      sellerLevelMovementAction({
        team_id: confirmationData?.id,
        seller_level_id: confirmationData?.proposed_level?.id,
        persist_custom_seller_level_config: persist_custom_seller_level_config,
        team_config:
          persist_custom_seller_level_config === 1 ? data : undefined,
      })
        .then((res) => {
          if (res?.data?.status) {
            layout?.setSellerLevelMovementData((pre: any) => {
              return {
                ...pre,
                tableData: pre?.tableData?.map((item: any) => {
                  if (item?.id === confirmationData?.id) {
                    return {
                      ...item,
                      seller_level: item?.proposed_level,
                    };
                  }
                  return item;
                }),
              };
            });
            showAlert(res?.message, false);
            setConfirmationPopup({ state: false, data: {} });
          } else {
            showAlert(errorReflect(res), true);
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          setActionLoader(false);
        });
    },
  });

  useEffect(() => {
    const filterData = confirmationPopup.data.team_config.map((obj: any) => {
      const isDropdown = obj?.component === "dropdown";
      const optionsForKey = levelConfigData?.data?.options?.[obj?.key];

      const findOptions = optionsForKey?.find(
        (option: any) =>
          option?.id === obj?.value || option?.name === obj?.value
      );

      return {
        ...obj,
        value: isDropdown ? findOptions : obj.value,
      };
    });

    setConfirmationData((prev: any) => ({
      ...prev,
      ...confirmationPopup?.data,
      team_config: filterData,
    }));
    setCustomEditedData(filterData);
    if (levelConfigData?.data?.options) {
      setOptionData(levelConfigData?.data?.options);
    }
  }, [confirmationPopup?.data, levelConfigData]);

  useEffect(() => {
    formik.setValues((prevValues: any) => ({
      ...confirmationData?.team_config?.reduce((acc: any, field: any) => {
        acc[field.key] = field.value !== undefined ? field.value : "";
        return acc;
      }, {}),
    }));
    const validationSchema = Yup.object().shape({
      ...confirmationData?.team_config?.reduce((acc: any, field: any) => {
        if (field.component !== "dropdown") {
          // acc[field?.key] = Yup.string()
          //   .trim()
          //   .required(`${field?.label} is Required`);
          if (field.component === "string") {
            acc[field?.key] = Yup.string().trim();
            // .required(`${field?.label} must be a valid string`);
          } else if (field.component !== "radio") {
            acc[field?.key] = Yup.number()
              .required(`${field?.label} must be a valid number`)
              .typeError(`${field?.label} must be a number`)
              .min(0, `${field?.label} cannot be negative`);
          }
        } else if (field.component !== "radio") {
          acc[field?.key] = Yup.object().nullable();
        }
        return acc;
      }, {}),
    });

    setValidationSchema(validationSchema);
  }, [confirmationData?.team_config]);

  const alertVisible =
    confirmationData?.team_config?.some((obj: any) => obj?.is_edited) ||
    isEdit?.index?.length > 0;

  return (
    <div
      id="momentconfirmationpopup"
      className={`   fixed bottom-0 z-100 top-[3.75rem] right-0 shadow-3xl bg-clip-padding outline-none transition duration-300 ease-in-out font-medium md:max-w-[43.125rem] max-w-full w-full `}
      ref={canvasRef}
    >
      <form className="relative flex flex-col h-full bg-white ml-auto">
        <div className="offcanvas-header flex justify-between border-t border-b">
          <h5
            className="offcanvas-title mb-0 leading-normal text-sm15 font-semibold py-2 px-5"
            id="offcanvasRightLabel"
          >
            {confirmationData?.name} Seller level configuration
          </h5>
          <button
            type="button"
            className="flex items-center justify-center w-9 border-l box-content group"
            onClick={() => {
              setConfirmationPopup({
                state: false,
              });
            }}
          >
            <IKTSVG
              className="fill-violet-800 group-hover:fill-indigo-500 h-2.5 w-2.5 transition"
              path={CrossSVG}
              svgClassName="w-2.5 h-2.5"
            />
          </button>
        </div>
        <>
          <div className="offcanvas-body max-h-full w-full flex flex-col overflow-x-auto scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full p-5 h-full">
            <div className="!visible">
              <h4 className="text-sm mb-2">Config</h4>
              <div className="group overflow-x-auto scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full">
                <div className="border-b last-of-type:border-b-0 min-w-[36.4375rem]">
                  <div className="text-xs text-left">
                    <div className={` md:w-full font-medium min-w-[8.125rem] `}>
                      <div className={`w-full flex border`}>
                        <div
                          className={`border-r w-[10.625rem] min-w-[10rem] whitespace-nowrap overflow-hidden text-ellipsis  py-1 px-2`}
                        ></div>
                        <div
                          className={`w-[9.1875rem] min-w-[7.5625rem] border-r py-1 px-2 whitespace-nowrap overflow-hidden text-ellipsis`}
                        >
                          Current {confirmationData?.seller_level?.title}
                          {/* Original value  */}
                        </div>
                        <div
                          className={` border-r  w-[11.4375rem] min-w-[11.4375rem] py-1 px-2 whitespace-nowrap overflow-hidden text-ellipsis  `}
                        >
                          Custom value
                        </div>
                        <div
                          className={`   w-[9.1875rem] min-w-[7.5625rem] py-1 px-2 whitespace-nowrap overflow-hidden text-ellipsis  `}
                        >
                          New {confirmationData?.proposed_level?.title}
                        </div>
                      </div>
                    </div>
                  </div>

                  {confirmationData?.team_config?.map(
                    (item: any, index: any) => {
                      let obj: any = item;

                      return (
                        <div className="text-xs text-left ">
                          <div
                            className={` md:w-full font-medium min-w-[8.125rem] `}
                            key={index}
                          >
                            <div
                              className={`w-full flex border -mt-px min-h-[2.125rem]`}
                            >
                              <div
                                className={`border-r w-[10.625rem] min-w-[10rem] flex items-center text-gray-400 py-1 px-2`}
                              >
                                {item?.label} :
                              </div>
                              <div
                                className={`py-1 px-2  border-r w-[9.1875rem] min-w-[7.5625rem] overflow-hidden text-ellipsis flex items-center`}
                              >
                                {/* {addCommaInNumber(
                                  defaultSellerLevel?.config?.find(
                                    (c: any) => c.key === item?.key
                                  )?.value
                                )} */}

                                {(() => {
                                  const value =
                                    defaultSellerLevel?.config?.find(
                                      (c: any) => c.key === item?.key
                                    )?.value;
                                  if (value === true) return "Yes";
                                  if (value === false) return "No";
                                  return addCommaInNumber(value);
                                })()}
                              </div>
                              <div
                                className={`  py-1 px-2 border-r w-[11.4375rem] min-w-[11.4375rem] overflow-hidden text-ellipsis justify-between flex items-center`}
                              >
                                {isEdit?.index?.includes(index) ? (
                                  <>
                                    <div
                                      data-tooltip-id={`toolitp-${obj?.key}`}
                                      className="flex justify-between w-full items-center cursor-pointer gap-x-1.5"
                                    >
                                      {item?.component === "dropdown" ? (
                                        <TableDropdown
                                          value={formik.values?.[obj?.key]}
                                          options={OptionData?.[obj?.key]}
                                          isCapital={true}
                                          isValueCapital={true}
                                          errorClassName={
                                            formik.touched?.[obj?.key] &&
                                            formik.errors?.[obj?.key] &&
                                            "error-field"
                                          }
                                          getOptionLabel="name"
                                          getOptionValue="id"
                                          isClearable={false}
                                          errorEventIndex={
                                            formik.touched?.[obj?.key] &&
                                            formik.errors?.[obj?.key]
                                          }
                                          errorMessage={
                                            formik.touched?.[obj?.key] &&
                                            formik.errors?.[obj?.key]
                                              ? formik.errors?.[obj?.key]
                                              : ""
                                          }
                                          placeholder={"Ticket type"}
                                          name="ticketType"
                                          handleOnChange={(e: any) => {
                                            formik?.setFieldValue(
                                              obj?.key,
                                              e?.target?.value
                                            );
                                            handleOnCustomUpdate(
                                              obj?.key,
                                              e?.target?.value
                                            );
                                          }}
                                          isOrderStatusDropdown={true}
                                        />
                                      ) : item?.component === "radio" ? (
                                        <>
                                          {" "}
                                          <div className="radiobtns flex flex-wrap gap-x-5">
                                            <label className="!text-sm12 flex items-center relative cursor-pointer !p-0">
                                              <input
                                                type="radio"
                                                name={obj?.key}
                                                checked={
                                                  formik.values?.[obj?.key] ===
                                                  true
                                                }
                                                onChange={() => {
                                                  formik?.setFieldValue(
                                                    obj?.key,
                                                    true
                                                  );
                                                  handleOnCustomUpdate(
                                                    obj?.key,
                                                    true
                                                  );
                                                }}
                                                className="mr-1 peer h-4 w-4 rounded-full checked:bg-transparent p-1 transition cursor-pointer border checked:border-indigo-500 border-gray-300 hover:border-indigo-500 focus:ring-0 focus:ring-offset-0"
                                              />
                                              <span className="peer-checked:opacity-100 check w-2.5 h-2.5 rounded-full bg-indigo-500 absolute left-[.1875rem]  pointer-events-none"></span>
                                              Yes
                                            </label>
                                            <label className="!text-sm12 flex items-center relative cursor-pointer !p-0">
                                              <input
                                                type="radio"
                                                name={obj?.key}
                                                checked={
                                                  formik.values?.[obj?.key] ===
                                                  false
                                                }
                                                // checked={persist_custom_seller_level_config === 0}
                                                onChange={(e) => {
                                                  formik?.setFieldValue(
                                                    obj?.key,
                                                    false
                                                  );
                                                  handleOnCustomUpdate(
                                                    obj?.key,
                                                    "false"
                                                  );
                                                }}
                                                className="mr-1 peer h-4 w-4 rounded-full checked:bg-transparent p-1 transition cursor-pointer border checked:border-indigo-500 border-gray-300 hover:border-indigo-500 focus:ring-0 focus:ring-offset-0"
                                              />
                                              <span className="peer-checked:opacity-100 check w-2.5 h-2.5 rounded-full bg-indigo-500 absolute left-[.1875rem]  pointer-events-none"></span>
                                              No
                                            </label>
                                          </div>
                                        </>
                                      ) : (
                                        <TextInput
                                          name={item?.key}
                                          type={
                                            item?.type === "numeric"
                                              ? "number"
                                              : "text"
                                          }
                                          placeholder={item?.label}
                                          value={formik.values?.[obj?.key]}
                                          id={obj?.key}
                                          required={false}
                                          className={`!bg-white h-[1.5rem]   `}
                                          handleOnChange={(e: any) => {
                                            formik.handleChange(e);
                                            handleOnCustomUpdate(
                                              obj?.key,
                                              e?.target?.value
                                            );
                                          }}
                                          errorClass={
                                            formik.touched?.[obj?.key] &&
                                            formik.errors?.[obj?.key] &&
                                            "border-rose-500"
                                          }
                                          handleClearValue={() =>
                                            formik.setFieldValue([obj?.key], "")
                                          }
                                        />
                                      )}

                                      <button
                                        onClick={() => {
                                          setEdit((pre: any) => {
                                            formik.setFieldValue(
                                              [obj?.key],
                                              obj?.value
                                            );
                                            return {
                                              ...pre,
                                              index: pre?.index?.filter(
                                                (obj: any) => obj !== index
                                              ),
                                            };
                                          });
                                          handleOnCustomUpdate(
                                            obj?.key,
                                            "",
                                            true
                                          );
                                        }}
                                        className=" group inline-flex group items-center justify-center transition font-medium rounded text-sm13   "
                                      >
                                        <IKTSVG
                                          className="fill-violet-800 hover:fill-indigo-500  transition"
                                          path={CrossSVG}
                                          svgClassName="w-4  h-2"
                                        />
                                      </button>
                                    </div>
                                    {formik?.errors[obj?.key] &&
                                      formik?.touched[obj?.key] && (
                                        <Tooltip
                                          content={formik?.errors[obj?.key]}
                                          id={`toolitp-${obj?.key}`}
                                          className={`!bg-white !text-rose-500 !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10 `}
                                        />
                                      )}
                                  </>
                                ) : (
                                  <>
                                    {" "}
                                    <span className="">
                                      {/* {item?.is_edited
                                        ? item?.value?.name ||
                                          addCommaInNumber(item?.value)
                                        : ""} */}
                                      {(() => {
                                        let value = item?.is_edited
                                          ? item?.value?.name || item?.value
                                          : "";

                                        if (value === true) return "Yes";
                                        if (value === false) return "No";

                                        return addCommaInNumber(value);
                                      })()}
                                    </span>
                                    <button
                                      onClick={() => {
                                        setEdit((pre: any) => {
                                          return {
                                            ...pre,

                                            index: [...pre?.index, index],
                                          };
                                        });
                                      }}
                                      className=" group inline-flex group items-center justify-center transition font-medium rounded text-sm13  "
                                    >
                                      <IKTSVG
                                        path={EDITSVG}
                                        className="flex items-center justify-center fill-violet-500 hover:fill-indigo-500 transition"
                                        svgClassName="w-4 h-[.6875rem]"
                                      />
                                    </button>
                                  </>
                                )}
                              </div>
                              <div
                                className={`  py-1 px-2  w-[9.1875rem] min-w-[7.5625rem] flex items-center`}
                              >
                                {(() => {
                                  const value = newSellerLevel?.config?.find(
                                    (c: any) => c.key === item?.key
                                  )?.value;
                                  if (value === true) return "Yes";
                                  if (value === false) return "No";
                                  return addCommaInNumber(value);
                                })()}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
              {alertVisible ? (
                <div className="mt-5">
                  <p className="text-sm14 leading-[1.2] mb-1.5">
                    Seller has custom values configured. Do you want to override
                    the default {confirmationData?.proposed_level?.title} values
                    with custom values?
                  </p>
                  <div className="radiobtns flex flex-wrap gap-x-5">
                    <label className="text-sm14 flex items-center relative cursor-pointer">
                      <input
                        type="radio"
                        name="options"
                        value={1}
                        checked={persist_custom_seller_level_config === 1}
                        onChange={() => {
                          setPersist_custom_seller_level_config(1);
                        }}
                        className="mr-1 peer h-4 w-4 rounded-full checked:bg-transparent p-1 transition cursor-pointer border checked:border-indigo-500 border-gray-300 hover:border-indigo-500 focus:ring-0 focus:ring-offset-0"
                      />
                      <span className="peer-checked:opacity-100 check w-2.5 h-2.5 rounded-full bg-indigo-500 absolute left-[.1875rem]  pointer-events-none"></span>
                      Yes, override using custom values
                    </label>
                    <label className="text-sm14 flex items-center relative cursor-pointer">
                      <input
                        type="radio"
                        name="options"
                        value={0}
                        checked={persist_custom_seller_level_config === 0}
                        onChange={() => {
                          setPersist_custom_seller_level_config(0);
                          formik?.setErrors({});
                        }}
                        className="mr-1 peer h-4 w-4 rounded-full checked:bg-transparent p-1 transition cursor-pointer border checked:border-indigo-500 border-gray-300 hover:border-indigo-500 focus:ring-0 focus:ring-offset-0"
                      />
                      <span className="peer-checked:opacity-100 check w-2.5 h-2.5 rounded-full bg-indigo-500 absolute left-[.1875rem]  pointer-events-none"></span>
                      No, use only {confirmationData?.proposed_level?.title}{" "}
                      values
                    </label>
                  </div>
                </div>
              ) : null}
            </div>
          </div>

          <div className="  offcanvas-footer bottom-0 right-0 w-full flex justify-start px-5 py-3 bg-white z-10 border-t">
            <div className="l_btn gap-1.5 flex flex-wrap items-center justify-end">
              <span className="text-sm13 font-medium block ">
                Confirming seller level change will send an automatic
                notification to the seller. Are you sure you want to proceed?
              </span>
              <div className="flex items-center gap-2.5 ">
                <button
                  className="border border-gray-300 hover:border-indigo-500 text-violet-800 hover:text-white bg-white hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5"
                  type="button"
                  onClick={() => {
                    setConfirmationPopup({
                      state: false,
                    });
                  }}
                >
                  Cancel
                </button>

                <button
                  className="false relative z-10 border border-green-600 hover:text-green-600 active:text-white text-white hover:bg-white bg-green-600 active:bg-green-600 transition font-semibold rounded text-sm13 px-2.5 py-0.5 "
                  type="button"
                  onClick={() => {
                    formik.handleSubmit();
                  }}
                >
                  Confirm{" "}
                  {confirmationData?.seller_level?.weightage <
                  confirmationData?.proposed_level?.weightage
                    ? "upgrade"
                    : "downgrade"}
                </button>
              </div>
            </div>
          </div>
        </>
      </form>
    </div>
  );
};

export default MovementConfirmationPopup;
