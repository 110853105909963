import { uploadFileWithPreAssignedURL } from "helpers/AssetHelpers";
import _, { size } from "lodash";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import {
  default as EyeSVG,
  default as eyeSVG,
} from "../../../../../assets/media/icons/other_icons/Eye.svg";
import UploadSVG from "../../../../../assets/media/icons/other_icons/Upload.svg";
import { default as angleLeftSVG } from "../../../../../assets/media/icons/standard_icons/angle_left.svg";
import { default as CrossSVG } from "../../../../../assets/media/icons/standard_icons/cross.svg";
import NoData from "../../../../../components/fullPopup/NoData";
import TableBody from "../../../../../components/fullPopup/TableBody";
import TableHeader from "../../../../../components/fullPopup/TableHeader";
import { IKTSVG } from "../../../../../components/ui/IKTSVG";
import ProgressBardLoader from "../../../../../components/ui/ProgressBardLoader";
import { sentanceCase } from "../../../../../helpers/Functions";
import { showAlert } from "../../../../../helpers/ShowAlert";
import NavigationButtons from "../../../../Sales/component/NavigationButtons";
import DataCell from "../../../../Sales/component/fullOrderDetail/DataCell";
import TooltipPortal from "../../../../tx_trade/table/component/TooltipPortal";
import CopytoChipboard from "../../component/bankViewComponent/CopytoChipboard";
import HeaderView from "../../component/bankViewComponent/HeaderView";
import PDFViewer from "../../component/bankViewComponent/PDFViewer";
import Tab from "../../component/bankViewComponent/Tab";
import { WalletAndBankDetailsContext } from "../../core/WalletAndBankDetailsProvider";
import {
  addBankAccount,
  deleteKycFile,
  uploadKycFiles,
} from "../../core/_request";
import { display } from "../../core/functions";
import ChangeRequest from "./ChangeRequest";

const BankAccountsViewForm = ({
  data,
  handleClose,
  setBankView,
  bankAccountRefetch,
  fetchViewAPI,
}: any) => {
  const layout = useContext(WalletAndBankDetailsContext);
  let status = data?.show;

  const {
    deposit_bank_account,
    withdrawal_bank_account,
    kyc_docs,
    txpay_change_requests,
    card_data,
    bank_account_currency,
  } = data?.data;

  let PDFViewInitial = {
    fileName: "",
    file: "",
    page: 1,
    pageNumber: 1,
  };

  const [loader, setLoader] = useState(false);
  const [PDFView, setPDFView] = useState<any>(PDFViewInitial);
  const [changeRequestToggle, setChangeRequestToggle] = useState<any>({
    show: false,
    data: undefined,
  });

  let initialState: any = {
    fund: {
      status: true,
      lockStatus: true,
      data: {
        ...deposit_bank_account,
        team_id: data?.data?.team_id,
        type: "Deposit",
      },
      newData: {
        team_id: data?.data?.team_id,
        type: "Deposit",
      },
    },
    withdraw: {
      status: false,
      lockStatus: true,
      data: {
        ...withdrawal_bank_account,
        team_id: data?.data?.team_id,
        bank_account_currency: bank_account_currency,
        type: "Withdrawal",
      },
      newData: {
        team_id: data?.data?.team_id,
        type: "Withdrawal",
      },
    },
    changeRequest: {
      status: false,
      data: txpay_change_requests
        ?.map((item: any) => {
          const accountTypeItem = item?.request_data?.find(
            (data: any) => data.key === "type"
          );
          const bankNameItem = item?.request_data?.find(
            (data: any) =>
              data?.key === "bank_name" ||
              data?.key === "funding_bank_name" ||
              data?.key === "withdrawal_bank_name"
          );

          if (accountTypeItem && bankNameItem) {
            return {
              ...item,
              displayData: [
                {
                  accountType: accountTypeItem?.value,
                  bankName: bankNameItem?.value,
                  requestDate: item?.request_date
                    ? moment(item?.request_date).format("DD/MM/YYYY")
                    : "-",
                },
              ],
            };
          } else {
            return null;
          }
        })
        .filter((item: any) => item !== null),
    },
  };

  const [state, setState] = useState<any>(initialState);

  // FILE DROPZONE
  const { getInputProps, open } = useDropzone({
    // Disable click and keydown behavior
    maxSize: 5244899, // 5MB in bytes
    onDrop: (acceptedFiles, rejectedFiles) => {
      setLoader(true);

      const uniqueCodes = Array.from(
        new Set(
          rejectedFiles.flatMap((item: any) =>
            item.errors.map((error: any) =>
              error.code === "file-too-large"
                ? "Please upload less then 5MB"
                : "Invalid File Type"
            )
          )
        )
      );
      if (uniqueCodes && uniqueCodes.length > 0) {
        setLoader(false);
        showAlert(uniqueCodes?.join(", <br/>"), true);
      } else if (acceptedFiles) {
        const uniqueAcceptedFiles = _.uniqBy(acceptedFiles, "name");
        // HANDLE FILE UPLOAD IN STATE
        // AWS UPLOAD
        let awsUPloadedFiles: any = [];
        Promise.all(
          uniqueAcceptedFiles?.map(async (item: any, itemIndex: number) => {
            const res: any = await uploadFileWithPreAssignedURL(
              item,
              `${
                data?.data?.seller_type === "Business"
                  ? "business-proof"
                  : "identity-proof"
              }/${data?.data?.user_id}`
            );
            awsUPloadedFiles.push({
              key: res?.key?.split("/")?.pop(),
              file_name: item?.name,
            }); // Push an object with both key and name
          })
        ).then(() => {
          //CALL KYC FILE SUBMIT API
          let payload: any = {
            team_id: data?.data?.team_id,
            fileData: awsUPloadedFiles,
          };
          uploadKycFiles(payload).then((response: any) => {
            setLoader(false);

            if (response?.data?.status) {
              bankAccountRefetch();
              fetchViewAPI(data?.data, data?.index);
              showAlert(response?.message, false);
            } else if (response?.data && size(response?.errors) > 0) {
              if (size(response?.data) === 0) {
                showAlert(response?.errors?.[0], true);
              }

              if (!response?.data?.status) {
                showAlert(response?.errors?.join(", "), true);
              }
            } else {
              showAlert("Something went wrong", true);
            }
          });
        });
      }
    },
    noClick: true,
    noKeyboard: true,
    accept: {
      "image/jpeg": [],
      "image/jpg": [],
      "image/png": [],
      "application/pdf": [],
    },
  });

  useEffect(() => {
    if (layout.porterState && size(layout.bankAccountList) === 1) {
      setState((current: any) => {
        return {
          ...initialState,
          fund: {
            ...initialState?.fund,
            status: false,
          },
          withdraw: {
            ...initialState?.withdraw,
            status: false,
          },
          changeRequest: {
            ...initialState?.changeRequest,
            status: true,
          },
        };
      });
      layout.setPorterState("");
    }

    if (data?.fetched) {
      setState((current: any) => {
        if (current?.changeRequest?.status) {
          return {
            ...initialState,
            fund: {
              ...initialState?.fund,
              status: false,
            },
            withdraw: {
              ...initialState?.withdraw,
              status: false,
            },
            changeRequest: {
              ...initialState?.changeRequest,
              status: true,
            },
          };
        } else {
          return initialState;
        }
      });
    }
  }, [layout.bankAccountList, layout.porterState, data]);

  // UNLOCK CONDITION
  let unLock: boolean = false;
  if (state?.fund?.status && !state?.fund?.lockStatus) {
    unLock = true;
  } else if (state?.withdraw?.status && !state?.withdraw?.lockStatus) {
    unLock = true;
  } else {
    unLock = false;
  }

  // HANDLE NAVIGATION OF ORDER VIEW PAGE
  const handleNavigation = (nav: string) => {
    let newIndex = data?.index;
    if (nav === "back") {
      newIndex =
        newIndex === 0 ? layout?.bankAccountList?.length - 1 : newIndex - 1;
    } else {
      newIndex =
        newIndex === layout?.bankAccountList?.length - 1 ? 0 : newIndex + 1;
    }

    const preRecord = layout?.bankAccountList?.[newIndex];
    setChangeRequestToggle({
      show: false,
      data: undefined,
    });
    setPDFView(PDFViewInitial);
    fetchViewAPI(preRecord, newIndex);
  };

  // HANDLE UPDATE
  const handleUpdate = (active: string) => {
    let payload = {
      ...state?.[active]?.data,
    };

    // BANK ADD - API INTEGRATION
    addBankAccount(payload).then((response: any) => {
      if (response?.data?.status) {
        display(false);
        setBankView({
          show: false,
          data: undefined,
          index: undefined,
        });
        bankAccountRefetch();

        showAlert(response?.message, false);
      } else if (size(response?.errors) > 0) {
        if (size(response?.data) === 0) {
          showAlert(response?.errors?.[0], true);
        }

        if (!response?.data?.status) {
          showAlert(response?.errors?.join(", "), true);
        }
      } else {
        showAlert("Something went wrong", true);
      }
    });
  };

  // HANDLE TAB ACTIVE
  const handleActive = (tab: "fund" | "withdraw" | "changeRequest") => {
    setState((current: any) => {
      return {
        ...current,
        fund: {
          ...current?.fund,
          status: tab === "fund",
        },
        withdraw: {
          ...current?.withdraw,
          status: tab === "withdraw",
        },
        changeRequest: {
          ...current?.changeRequest,
          status: tab === "changeRequest",
        },
      };
    });
  };

  // HANDLE KYC FILE DELETE
  const handleDeleteKycFile = (item: any) => {
    let payload: any = {
      ...item,
      team_id: data?.data?.team_id,
    };

    deleteKycFile(payload).then((response: any) => {
      if (response?.data?.status) {
        bankAccountRefetch();
        fetchViewAPI(data?.data, data?.index);
        showAlert(response?.message, false);
      } else if (response?.data && size(response?.errors) > 0) {
        if (size(response?.data) === 0) {
          showAlert(response?.errors?.[0], true);
        }

        if (!response?.data?.status) {
          showAlert(response?.errors?.join(", "), true);
        }
      } else {
        showAlert("Something went wrong", true);
      }
    });
  };

  const emptyFieldUpdate = (data: any) => {
    return data ? data : "";
  };

  return (
    <form action="">
      <div
        data-te-modal-init
        className={`${
          status ? "show" : "hidden"
        } popup input-style2 upload-ticket-popup  offcanvas s_ticket offcanvas-end fixed bottom-0 top-[3.75rem] right-0 shadow-3xl bg-clip-padding outline-none transition duration-1000 ease-in-out font-medium z-40 w-full
        ${
          PDFView?.file || changeRequestToggle?.show
            ? "max-w-[92.5rem] md:w-[86.25rem] md:max-w-[92%]"
            : "max-w-[46.25rem] md:w-[43.125rem] "
        }
        `}
        tabIndex={-1}
        id="singleTicket"
        aria-labelledby="singleTicketLabel"
      >
        <div className="  bg-white border-t w-full h-full ml-auto flex flex-col">
          <div className="flex justify-between pl-5 items-center border-b">
            <div>
              <span className="md:text-sm15 text-sm12 block font-semibold">
                {" "}
                {/* ID: {data?.data?.team_id} */}
              </span>
            </div>
            <div className="flex flex-wrap">
              <button
                type="button"
                className="group relative flex flex-auto items-center justify-center p-1 md:p-1 w-7 md:w-10 h-[2.562rem] border-l"
                data-tooltip-id="close-bank-view"
                onClick={() => {
                  handleClose();
                  display(false);
                }}
              >
                <TooltipPortal
                  id={`close-bank-view`}
                  content={"Close"}
                  place="left"
                  className="text-center  !bg-white !opacity-100 shadow !py-0.5 !px-1.5 font-medium text-xs z-100 max-w-[9rem] cursor-default "
                />
                <IKTSVG
                  className="flex items-center justify-center fill-violet-500 group-hover:fill-indigo-500 transition"
                  path={CrossSVG}
                  svgClassName="w-2.5 h-2.5"
                />
              </button>
            </div>
          </div>

          <div className="offcanvas-body h-full flex flex-col lg2:flex-row  overflow-auto scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full">
            {/* PDF VIEW */}
            {PDFView?.file && (
              <PDFViewer
                PDFView={PDFView}
                setPDFView={setPDFView}
                setChangeRequestToggle={setChangeRequestToggle}
              />
            )}
            {changeRequestToggle?.show && (
              <ChangeRequest
                data={changeRequestToggle}
                setPDFView={setPDFView}
                setChangeRequestToggle={setChangeRequestToggle}
                refetchList={() => {
                  bankAccountRefetch();
                  fetchViewAPI(data?.data, data?.index);
                }}
                setBankView={setBankView}
              />
            )}

            {/* TAB BLOCK */}
            <div
              className={`w-full lg2:w-${
                PDFView?.file || changeRequestToggle?.show ? "1/2" : "full"
              } h-full lg2:!overflow-auto !overflow-visible scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full`}
            >
              <div className="flex justify-around tab-panel bg-no-repeat bg-tradepattern bg-cover bg-indigo-500 bg-opacity-[15%] p-5 pb-0  after:content after:absolute after:bottom-0 after:left-0 after:w-full after:h-2 after:z-0 after:from-black/0 after:bg-gradient-to-b after:to-black/5 relative overflow-x-auto flex-nowrap overflow-hidden">
                <Tab
                  title="Fund In"
                  data={state?.fund}
                  handleActive={() => handleActive("fund")}
                />
                <Tab
                  title="Withdraw"
                  data={state?.withdraw}
                  handleActive={() => handleActive("withdraw")}
                />
                <Tab
                  title="Change request"
                  data={state?.changeRequest}
                  handleActive={() => handleActive("changeRequest")}
                />
              </div>
              <div
                className={`tab_vontent opacity-100 transition-opacity duration-150 ease-linear block`}
                role="tabpanel"
                data-te-tab-active
              >
                <div className="p-5">
                  <div className="">
                    {/* ACCOUNT DETAILS - FIELDS */}
                    <div className=" flex flex-wrap  border rounded relative mb-5 last-of-type:mb-0">
                      <HeaderView
                        title={
                          state?.fund?.status
                            ? "Fund In account details"
                            : state?.withdraw?.status
                            ? "Withdraw account details"
                            : "Change request details"
                        }
                        id={
                          state?.withdraw?.status
                            ? withdrawal_bank_account?.mc_recipient_id
                              ? withdrawal_bank_account?.mc_recipient_id
                              : "N/A"
                            : ""
                        }
                        isLockIconRequired={
                          state?.changeRequest?.status ? false : true
                        }
                        isLock={
                          state?.fund?.status
                            ? state?.fund?.lockStatus
                            : state?.withdraw?.lockStatus
                        }
                        handleLock={() => {
                          setState((current: any) => {
                            let dynamicKey = current?.fund?.status
                              ? "fund"
                              : "withdraw";
                            return {
                              ...current,
                              [dynamicKey]: {
                                ...current?.[dynamicKey],
                                lockStatus: !current?.[dynamicKey]?.lockStatus,
                              },
                            };
                          });
                        }}
                      />

                      <div className="inner-part px-3 py-3 flex flex-wrap w-full">
                        {state?.fund?.status ? (
                          <>
                            {/* Bank name */}
                            <CopytoChipboard
                              headerName="Bank name"
                              value={emptyFieldUpdate(
                                state?.fund?.data?.bank_name
                              )}
                              defaultValue={emptyFieldUpdate(
                                state?.fund?.data?.bank_name
                              )}
                              id={"bank_name"}
                              isLock={state?.fund?.lockStatus}
                              placeholder="Bank name"
                              inputFieldName={"bank_name"}
                              setState={setState}
                            />

                            {/* Account holder name */}
                            <CopytoChipboard
                              headerName="Account holder name"
                              value={emptyFieldUpdate(
                                state?.fund?.data?.bank_account_name
                              )}
                              defaultValue={emptyFieldUpdate(
                                state?.fund?.data?.bank_account_name
                              )}
                              id={"bank_account_name"}
                              isLock={state?.fund?.lockStatus}
                              placeholder="Account holder name"
                              inputFieldName="bank_account_name"
                              setState={setState}
                            />

                            {/* Account number */}
                            <CopytoChipboard
                              headerName="Account number"
                              value={emptyFieldUpdate(
                                state?.fund?.data?.account_number
                              )}
                              defaultValue={emptyFieldUpdate(
                                state?.fund?.data?.account_number
                              )}
                              id={"account_number"}
                              isLock={state?.fund?.lockStatus}
                              placeholder="Account number"
                              inputFieldName="account_number"
                              setState={setState}
                            />

                            {/* IBAN */}
                            <CopytoChipboard
                              headerName="IBAN"
                              value={emptyFieldUpdate(state?.fund?.data?.iban)}
                              defaultValue={emptyFieldUpdate(
                                state?.fund?.data?.iban
                              )}
                              id={"iban"}
                              isLock={state?.fund?.lockStatus}
                              placeholder="IBAN"
                              inputFieldName={"iban"}
                              setState={setState}
                            />

                            {/* Swift code */}
                            <CopytoChipboard
                              headerName="Swift code"
                              value={emptyFieldUpdate(
                                state?.fund?.data?.swift_bic_code
                              )}
                              defaultValue={emptyFieldUpdate(
                                state?.fund?.data?.swift_bic_code
                              )}
                              id={"swift_bic_code"}
                              isLock={state?.fund?.lockStatus}
                              placeholder="Swift code"
                              inputFieldName={"swift_bic_code"}
                              setState={setState}
                            />

                            {/* BRANCH CODE === SORT CODE */}
                            <CopytoChipboard
                              headerName="Branch code"
                              value={emptyFieldUpdate(
                                state?.fund?.data?.branch_code
                              )}
                              defaultValue={emptyFieldUpdate(
                                state?.fund?.data?.branch_code
                              )}
                              id={"branch_code"}
                              isLock={state?.fund?.lockStatus}
                              placeholder="Branch code"
                              inputFieldName={"branch_code"}
                              setState={setState}
                            />

                            {!state?.fund?.lockStatus && <></>}
                          </>
                        ) : state?.withdraw?.status ? (
                          <>
                            {/* Bank name */}
                            <CopytoChipboard
                              headerName="Bank name"
                              value={emptyFieldUpdate(
                                state?.withdraw?.data?.bank_name
                              )}
                              defaultValue={emptyFieldUpdate(
                                state?.withdraw?.data?.bank_name
                              )}
                              id={"bank_name"}
                              isLock={state?.withdraw?.lockStatus}
                              placeholder="Bank name"
                              inputFieldName={"bank_name"}
                              setState={setState}
                            />

                            {/* Account holder name */}
                            <CopytoChipboard
                              headerName="Account holder name"
                              value={emptyFieldUpdate(
                                state?.withdraw?.data?.bank_account_name
                              )}
                              defaultValue={emptyFieldUpdate(
                                state?.withdraw?.data?.bank_account_name
                              )}
                              id={"bank_account_name"}
                              isLock={state?.withdraw?.lockStatus}
                              placeholder="Account holder name"
                              inputFieldName="bank_account_name"
                              setState={setState}
                            />

                            {/* Account number */}
                            <CopytoChipboard
                              headerName="Account number"
                              value={emptyFieldUpdate(
                                state?.withdraw?.data?.account_number
                              )}
                              defaultValue={emptyFieldUpdate(
                                state?.withdraw?.data?.account_number
                              )}
                              id={"account_number"}
                              isLock={state?.withdraw?.lockStatus}
                              placeholder="Account number"
                              inputFieldName="account_number"
                              setState={setState}
                            />

                            {/* IBAN */}
                            <CopytoChipboard
                              headerName="IBAN"
                              value={emptyFieldUpdate(
                                state?.withdraw?.data?.iban
                              )}
                              defaultValue={emptyFieldUpdate(
                                state?.withdraw?.data?.iban
                              )}
                              id={"iban"}
                              isLock={state?.withdraw?.lockStatus}
                              placeholder="IBAN"
                              inputFieldName={"iban"}
                              setState={setState}
                            />

                            {/* Swift code */}
                            <CopytoChipboard
                              headerName="Swift code"
                              value={emptyFieldUpdate(
                                state?.withdraw?.data?.swift_bic_code
                              )}
                              defaultValue={emptyFieldUpdate(
                                state?.withdraw?.data?.swift_bic_code
                              )}
                              id={"swift_bic_code"}
                              isLock={state?.withdraw?.lockStatus}
                              placeholder="Swift code"
                              inputFieldName={"swift_bic_code"}
                              setState={setState}
                            />

                            {/* BRANCH CODE === SORT CODE */}
                            <CopytoChipboard
                              headerName="Branch code"
                              value={emptyFieldUpdate(
                                state?.withdraw?.data?.branch_code
                              )}
                              defaultValue={emptyFieldUpdate(
                                state?.withdraw?.data?.branch_code
                              )}
                              id={"branch_code"}
                              isLock={state?.withdraw?.lockStatus}
                              placeholder="Branch code"
                              inputFieldName={"branch_code"}
                              setState={setState}
                            />

                            {!state?.withdraw?.lockStatus && (
                              <>
                                {/* BANK ACCOUNT CURRENCY */}
                                <CopytoChipboard
                                  headerName="Bank account currency"
                                  value={emptyFieldUpdate(
                                    state?.withdraw?.data?.bank_account_currency
                                  )}
                                  defaultValue={emptyFieldUpdate(
                                    state?.withdraw?.data?.bank_account_currency
                                  )}
                                  id={"bank_account_currency"}
                                  isLock={true}
                                  placeholder="Bank account currency"
                                  inputFieldName={"bank_account_currency"}
                                  setState={setState}
                                />

                                {/* BANK ACCOUNT COUNTRY CODE */}
                                <CopytoChipboard
                                  headerName="Bank account country code"
                                  value={emptyFieldUpdate(
                                    state?.withdraw?.data
                                      ?.bank_account_country_code
                                  )}
                                  defaultValue={emptyFieldUpdate(
                                    state?.withdraw?.data
                                      ?.bank_account_country_code
                                  )}
                                  id={"bank_account_country_code"}
                                  isLock={state?.withdraw?.lockStatus}
                                  placeholder="Bank account country code"
                                  inputFieldName={"bank_account_country_code"}
                                  setState={setState}
                                />

                                {/* Recipient COUNTRY CODE */}
                                <CopytoChipboard
                                  headerName="Recipient country code"
                                  value={emptyFieldUpdate(
                                    state?.withdraw?.data
                                      ?.recipient_country_code
                                  )}
                                  defaultValue={emptyFieldUpdate(
                                    state?.withdraw?.data
                                      ?.recipient_country_code
                                  )}
                                  id={"recipient_country_code"}
                                  isLock={state?.withdraw?.lockStatus}
                                  placeholder="Recipient country code"
                                  inputFieldName={"recipient_country_code"}
                                  setState={setState}
                                />

                                {/* Recipient street */}
                                <CopytoChipboard
                                  headerName="Recipient street"
                                  value={emptyFieldUpdate(
                                    state?.withdraw?.data?.recipient_street
                                  )}
                                  defaultValue={emptyFieldUpdate(
                                    state?.withdraw?.data?.recipient_street
                                  )}
                                  id={"recipient_street"}
                                  isLock={state?.withdraw?.lockStatus}
                                  placeholder="Recipient street"
                                  inputFieldName={"recipient_street"}
                                  setState={setState}
                                />

                                {/* Recipient city */}
                                <CopytoChipboard
                                  headerName="Recipient city"
                                  value={emptyFieldUpdate(
                                    state?.withdraw?.data?.recipient_city
                                  )}
                                  defaultValue={emptyFieldUpdate(
                                    state?.withdraw?.data?.recipient_city
                                  )}
                                  id={"recipient_city"}
                                  isLock={state?.withdraw?.lockStatus}
                                  placeholder="Recipient city"
                                  inputFieldName={"recipient_city"}
                                  setState={setState}
                                />

                                {/* Recipient state */}
                                <CopytoChipboard
                                  headerName="Recipient state"
                                  value={emptyFieldUpdate(
                                    state?.withdraw?.data?.recipient_state
                                  )}
                                  defaultValue={emptyFieldUpdate(
                                    state?.withdraw?.data?.recipient_state
                                  )}
                                  id={"recipient_state"}
                                  isLock={state?.withdraw?.lockStatus}
                                  placeholder="Recipient state"
                                  inputFieldName={"recipient_state"}
                                  setState={setState}
                                />

                                {/* Recipient postcode */}
                                <CopytoChipboard
                                  headerName="Recipient postcode"
                                  value={emptyFieldUpdate(
                                    state?.withdraw?.data?.recipient_postcode
                                  )}
                                  defaultValue={emptyFieldUpdate(
                                    state?.withdraw?.data?.recipient_postcode
                                  )}
                                  id={"recipient_postcode"}
                                  isLock={state?.withdraw?.lockStatus}
                                  placeholder="Recipient postcode"
                                  inputFieldName={"recipient_postcode"}
                                  setState={setState}
                                />
                              </>
                            )}
                          </>
                        ) : (
                          state?.changeRequest?.status && (
                            <>
                              <TableBody
                                border="border"
                                renderArray={state?.changeRequest?.data}
                              >
                                <thead className="text-xs text-gray-400">
                                  <tr className="border-b">
                                    <TableHeader
                                      title="Account type"
                                      width="w-[15.625rem]"
                                    />
                                    <TableHeader
                                      title="Bank name"
                                      width="w-[15.625rem]"
                                    />
                                    <TableHeader
                                      title="Requested date"
                                      width="w-[15.625rem]"
                                    />
                                    {/* SCROLL BUTTONS */}
                                    <th className="sticky right-0 ml-auto  p-0 w-[5.0625rem]"></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {state?.changeRequest?.data &&
                                    size(state?.changeRequest?.data) > 0 &&
                                    state?.changeRequest?.data?.map(
                                      (item: any, requestIndex: number) => {
                                        return item?.displayData?.map(
                                          (
                                            tableData: any,
                                            tableIndex: number
                                          ) => {
                                            return (
                                              <tr
                                                className="border-b"
                                                key={`${requestIndex}-${tableIndex}`}
                                              >
                                                <DataCell
                                                  title={sentanceCase(
                                                    tableData?.accountType
                                                  )}
                                                />
                                                <DataCell
                                                  title={tableData?.bankName}
                                                />
                                                <DataCell
                                                  title={tableData?.requestDate}
                                                />
                                                <td
                                                  className={`relative min-w-[3.75rem] md:sticky md:right-0 ml-auto p-0 icon_td`}
                                                >
                                                  <div className="bg-opacity-[7%]">
                                                    <div
                                                      className="flex items-center justify-center left-shad relative w-full h-full border-l"
                                                      id="left-shad-list"
                                                    >
                                                      <button
                                                        type="button"
                                                        data-tooltip-id={`${requestIndex}-${tableIndex}-request`}
                                                        className={`group my-buttonflex items-center justify-center p-1 md:p-1 w-8 md:w-10`}
                                                        onClick={() => {
                                                          setChangeRequestToggle(
                                                            (current: any) => {
                                                              return {
                                                                ...current,
                                                                show: true,
                                                                data: {
                                                                  request_data:
                                                                    item?.request_data,
                                                                  id: item?.id,
                                                                  request_date:
                                                                    item?.request_date,
                                                                },
                                                              };
                                                            }
                                                          );
                                                          setPDFView(
                                                            PDFViewInitial
                                                          );
                                                        }}
                                                      >
                                                        <TooltipPortal
                                                          id={`${requestIndex}-${tableIndex}-request`}
                                                          content={"View"}
                                                          className="text-center !bg-white !opacity-100 shadow !py-0.5 !px-1.5 font-medium text-xs z-[45] max-w-[9rem] cursor-default "
                                                        />
                                                        {/* Edit */}
                                                        <IKTSVG
                                                          className="flex items-center justify-center fill-violet-500 group-hover:fill-indigo-500"
                                                          path={EyeSVG}
                                                          svgClassName="w-4 h-[.6875rem]"
                                                        />
                                                      </button>
                                                    </div>
                                                  </div>
                                                </td>
                                              </tr>
                                            );
                                          }
                                        );
                                      }
                                    )}
                                </tbody>
                              </TableBody>
                            </>
                          )
                        )}
                      </div>
                    </div>

                    {/* KYC LISTING */}
                    <div className=" flex flex-wrap  border rounded relative mb-5 last-of-type:mb-0">
                      <HeaderView
                        title={"KYC documents"}
                        isLockIconRequired={false}
                        isLock={state?.kycDocument?.lockStatus}
                      />
                      <div className="inner-part px-3 py-3 flex flex-wrap w-full">
                        <div
                          className={`flex flex-col items-center justify-center text-center  w-full min-h-[5rem] border border-dashed border-violet-500/10 rounded bg-violet-300/50 text-xs mb-[1rem]`}
                        >
                          <input {...getInputProps()} />
                          <div className="mb-2.5">Click to upload</div>
                          <button
                            type="button"
                            onClick={open}
                            className="flex items-center text-white bg-violet-500 hover:bg-indigo-500 transition rounded text-sm13 px-2.5 py-1"
                          >
                            <IKTSVG
                              className="fill-white mr-2"
                              path={UploadSVG}
                              svgClassName="w-[.8125rem] h-[.6875rem]"
                            />
                            Upload
                          </button>
                        </div>

                        {!(size(kyc_docs) > 0) && <NoData />}
                        {kyc_docs?.map((item: any, index: number) => {
                          return (
                            <div className="list_wrap flex flex-wrap gap-2.5 anim-fade w-full mb-2">
                              <div
                                className={`single-invoice border rounded flex flex-wrap justify-between px-2.5 py-1.5 text-sm12 ${
                                  PDFView?.file === item?.key
                                    ? "bg-violet-300/50"
                                    : "bg-white"
                                } w-full`}
                              >
                                <span className="flex-1 flex leading-4">
                                  {item?.name}
                                </span>

                                <button
                                  className="ml-2"
                                  type="button"
                                  data-tooltip-id={`pdf-view-account-${index}`}
                                  onClick={() => {
                                    setPDFView((current: any) => {
                                      return {
                                        ...current,
                                        fileName: item?.name,
                                        file: item?.key,
                                      };
                                    });
                                    setChangeRequestToggle((current: any) => {
                                      return {
                                        ...current,
                                        ...(current?.data
                                          ? { show: false }
                                          : {}),
                                      };
                                    });
                                  }}
                                >
                                  <TooltipPortal
                                    id={`pdf-view-account-${index}`}
                                    content={"View"}
                                    className="text-center !bg-white !opacity-100 shadow !py-0.5 !px-1.5 font-medium text-xs z-[45] max-w-[9rem] cursor-default "
                                  />
                                  <IKTSVG
                                    className="fill-violet-500 hover:fill-indigo-500 transition"
                                    path={eyeSVG}
                                    svgClassName="w-[.9375rem] h-2.5"
                                  />
                                </button>

                                {/* Download Icon download functionality is pending */}
                                <button
                                  className="ml-2"
                                  type="button"
                                  data-tooltip-id={`pdf-view-account-down-${index}`}
                                  onClick={() => {
                                    if (item?.key) {
                                      const link = document.createElement("a");
                                      link.href = item.key;
                                      link.target = `_blank`;
                                      link.download = item?.name || "file.pdf";
                                      document.body.appendChild(link);
                                      link.click();
                                      document.body.removeChild(link);
                                    } else {
                                      console.error(
                                        "File URL is not available"
                                      );
                                    }
                                  }}
                                >
                                  <TooltipPortal
                                    id={`pdf-view-account-down-${index}`}
                                    content={"Download"}
                                    className="text-center !bg-white !opacity-100 shadow !py-0.5 !px-1.5 font-medium text-xs z-[45] max-w-[9rem] cursor-default "
                                  />
                                  <IKTSVG
                                    className="fill-violet-500 hover:fill-indigo-500 transition"
                                    path={UploadSVG}
                                    svgClassName="w-[.9375rem] h-2.5 rotate-180"
                                  />
                                </button>

                                <button
                                  className="ml-2"
                                  type="button"
                                  data-tooltip-id="kyc-delete-file"
                                  onClick={() => handleDeleteKycFile(item)}
                                >
                                  <TooltipPortal
                                    id={`kyc-delete-file`}
                                    content={"Delete"}
                                    className="text-center !bg-white !opacity-100 shadow !py-0.5 !px-1.5 font-medium text-xs z-[45] max-w-[9rem] cursor-default "
                                  />
                                  <IKTSVG
                                    className="fill-violet-500 hover:fill-indigo-500 transition"
                                    path={CrossSVG}
                                    svgClassName="w-[.5625rem] h-[.5625rem]"
                                  />
                                </button>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>

                    {/* CARD TOKEN */}
                    <div className=" flex flex-wrap  border rounded relative mb-5 last-of-type:mb-0">
                      <HeaderView
                        title={"Card/Token"}
                        isLockIconRequired={false}
                        isLock={state?.kycDocument?.lockStatus}
                      />
                      <div className="inner-part px-3 py-3 flex flex-wrap w-full">
                        <CopytoChipboard
                          headerName="Card"
                          defaultValue={card_data?.display}
                          id={"bank_name"}
                          isLock={true}
                          placeholder="Card"
                        />
                        <CopytoChipboard
                          headerName="Created at"
                          defaultValue={
                            card_data?.created_at
                              ? moment
                                  .unix(card_data?.created_at)
                                  .format("DD MMMM YYYY")
                              : ""
                          }
                          id={"bank_name"}
                          isLock={true}
                          placeholder="Created at"
                        />
                        <CopytoChipboard
                          headerName="Card token"
                          defaultValue={card_data?.token}
                          id={"bank_name"}
                          isLock={true}
                          placeholder="Card token"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* FOOTER */}

          <div
            className={`top-full bottom-0 right-0 w-full flex 
             px-5 py-2.5 min-h-[3.125rem] bg-white z-10 border-t`}
          >
            {unLock && (PDFView?.file || changeRequestToggle?.show) && (
              <div className="flex w-full"></div>
            )}
            <div
              className={`flex w-full  ${
                unLock ? "justify-between" : "justify-end"
              }`}
            >
              {unLock && (
                <>
                  <div
                    className={`btns flex flex-wrap items-center tx-pay-module gap-3.5 ${
                      PDFView?.file || changeRequestToggle?.show ? "pl-5" : ""
                    }`}
                  >
                    <button
                      type="button"
                      className="btn flex-1 text-sm13 font-medium py-1 px-2.5"
                      onClick={() => {
                        setBankView({
                          show: false,
                          data: undefined,
                          index: undefined,
                        });
                        display(false);
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className=" false  text-sm13 font-medium py-1 px-2.5 bg-green-600 hover:bg-indigo-500 !text-white rounded border-hidden	 hover:border-hidden	 flex"
                      onClick={() => {
                        handleUpdate(state?.fund?.status ? "fund" : "withdraw");
                      }}
                    >
                      Update
                    </button>
                  </div>
                </>
              )}
              <div className="">
                <NavigationButtons
                  id="back"
                  imgPath={angleLeftSVG}
                  className={
                    data?.index === 0
                      ? "pointer-events-none"
                      : layout?.bankAccountList?.length - 1 === data?.index
                  }
                  svgClassName={
                    data?.index === 0
                      ? "!fill-gray-400"
                      : layout?.bankAccountList?.length - 1 === data?.index
                  }
                  handleNavigation={(data: any) => handleNavigation(data)}
                />
                <NavigationButtons
                  id="next"
                  imgPath={angleLeftSVG}
                  className={
                    layout?.bankAccountList?.length - 1 === data?.index
                      ? "pointer-events-none"
                      : ""
                  }
                  svgClassName={
                    layout?.bankAccountList?.length - 1 === data?.index
                      ? "!fill-gray-400"
                      : ""
                  }
                  handleNavigation={(data: any) => handleNavigation(data)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* LOADER */}
      {loader && <ProgressBardLoader secondCounter={3.5} />}
    </form>
  );
};

export default BankAccountsViewForm;
