import Cookies from "js-cookie";
import { SALES_NEST_ROUTES } from "pages/Sales/core/const";
import { CustomizeWrapper } from "pages/customize/CustomizeWrapper";
import SellerLevelsWrapper from "pages/subscribe/seller_levels/SellerLevelsWrapper";
import { TX_PAY_TAB_ROUTES } from "pages/tx_pay/core/const";
import ConfigurationTab from "pages/subscribe/seller_levels/component/ConfigurationTab";
import MovementTab from "pages/subscribe/seller_levels/component/MovementTab";
import { FC, lazy, Suspense, useContext } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { LayoutContext } from "../components/core/LayoutProvider";
import ForgetPassword from "../components/layout/auth/ForgetPassword";
import LoadingPage from "../components/layout/auth/LoadingPage";
import Login from "../components/layout/auth/Login";
import Logout from "../components/layout/auth/Logout";
import ResetPassword from "../components/layout/auth/ResetPassword";
import MasterLayout from "../components/layout/masterLayout/MasterLayout";
import PublicLayout from "../components/layout/publicLayout/PublicLayout";
import Typography from "../components/ui/typography/Typography";
import ConnectorWrapper from "../pages/API_connector/ConnectorWrapper";
import FAQWrapper from "../pages/Faq/FAQWrapper";
import FaqSearch from "../pages/Faq/FaqSearch";
import FaqStatic from "../pages/Faq/FaqStatic";
import FAQListing from "../pages/Faq/components/FAQListing";
import ReportsWrapper from "../pages/Reports/ReportsWrapper";
import SalesWrapper from "../pages/Sales/SalesWrapper";
import AddInventory from "../pages/Testing/AddInventory";
import PerformerVenue from "../pages/Testing/PerformerVenue";
import AddInventoryWrapper from "../pages/add_inventory/AddInventoryWrapper";
import DashboardWrapper from "../pages/dashboard/DashboardWrapper";
import InstructionLetterWrapper from "../pages/instruction_letter/InstructionLetterWrapper";
import InventoryWrapper from "../pages/inventory/InventoryWrapper";
import NotificationsWrapper from "../pages/notifications/NotificationsWrapper";
import PersonalDetailsWrapper from "../pages/subscribe/personal_details/PersonalDetailsWrapper";
import WalletAndBankDetailsWrapper from "../pages/subscribe/wallet-bank_Details/WalletAndBankDetailsWrapper";
import TxAccountWrapper from "../pages/tx_account/TxAccountWrapper";
import TXWrapper from "../pages/tx_pay/TXWrapper";

const TxpayStripeComponent = lazy(
  () => import("../pages/settings/SettingsWrapper")
);
const TxtradeStripeComponent = lazy(
  () => import("../pages/tx_trade/TxTradeWrapper")
);
const SingupStripeComponent = lazy(
  () => import("../components/layout/auth/SignUp")
);

const AppRoutes: FC = () => {
  const basePath = process.env.PUBLIC_URL;
  const layout = useContext(LayoutContext);
  const isLogged = layout.isLogged;
  const access = layout.allowedAccess?.allowed_access;
  let cookieTtoken = Cookies.get("authTokens") as string;
  const isGodAdmin = layout.allowedAccess?.account_info?.is_god_admin;

  if (cookieTtoken) {
    cookieTtoken = JSON.parse(cookieTtoken);
  }

  // CHECK IF ROUTE ACCESSISBLE
  const reportsItem = access?.some(
    (item: any) => item.id === "reports" && item.status === true
  );
  const addInventory = access?.some(
    (item: any) => item.id === "inventory" && item.status === true
  );
  const inventory = access?.some(
    (item: any) => item.id === "inventory" && item.status === true
  );
  const sales = access?.some(
    (item: any) => (item.id === "shipping" && item.status === true) || false
  );
  const txPay = access?.some(
    (item: any) => (item.id === "txpay" && item.status === true) || false
  );
  const txTrade = access?.some(
    (item: any) => (item.id === "txtrade" && item.status === true) || false
  );
  const settings = access?.some(
    (item: any) => (item.id === "settings" && item.status === true) || false
  );
  const txAccounts = access?.some(
    (item: any) => (item.id === "txaccounts" && item.status === true) || false
  );
  const subscribers = access?.some(
    (item: any) => (item.id === "subscribers" && item.status === true) || false
  );
  const apiConnector = access?.some(
    (item: any) =>
      (item.id === "api-connector" && item.status === true) || false
  );

  const instruction_letters = access?.some(
    (item: any) =>
      (item.id === "instruction_letters" && item.status === true) || false
  );

  const dashboardPath =
    layout.isTxTradeUser === 1 && layout.userDetailShow === false
      ? "/"
      : "/dashboard";

  return (
    <BrowserRouter basename={basePath}>
      <Routes>
        <Route
          element={
            isLogged || cookieTtoken ? <MasterLayout /> : <PublicLayout />
          }
        >
          {isLogged || cookieTtoken ? (
            <>
              <Route
                path={layout.isTxTradeUser === 1 ? dashboardPath : "/"}
                element={<DashboardWrapper />}
              />
              <Route path="*" element={<Navigate to="/" replace />} />
              <>
                <Route
                  path="/add-inventory"
                  element={
                    !isGodAdmin && addInventory === undefined ? (
                      <AddInventoryWrapper />
                    ) : !isGodAdmin && addInventory ? (
                      <AddInventoryWrapper />
                    ) : (
                      <Navigate to="/" replace />
                    )
                  }
                />
                <Route
                  path="/inventory"
                  element={
                    inventory === undefined ? (
                      <InventoryWrapper />
                    ) : inventory ? (
                      <InventoryWrapper />
                    ) : (
                      <Navigate to="/" replace />
                    )
                  }
                />
                <Route
                  path="/sales"
                  element={
                    sales === undefined ? (
                      <SalesWrapper />
                    ) : sales ? (
                      <SalesWrapper />
                    ) : (
                      <Navigate to="/" replace />
                    )
                  }
                />
                <Route
                  path="/reports"
                  element={
                    reportsItem === undefined ? (
                      <ReportsWrapper />
                    ) : reportsItem ? (
                      <ReportsWrapper />
                    ) : (
                      <Navigate to="/" replace />
                    )
                  }
                />
                <Route
                  path="/notifications"
                  element={<NotificationsWrapper />}
                />

                <Route
                  path="/add-inventory"
                  element={
                    addInventory === undefined ? (
                      <AddInventoryWrapper />
                    ) : addInventory ? (
                      <AddInventoryWrapper />
                    ) : (
                      <Navigate to="/" replace />
                    )
                  }
                />
                <Route
                  path="/inventory"
                  element={
                    inventory === undefined ? (
                      <InventoryWrapper />
                    ) : inventory ? (
                      <InventoryWrapper />
                    ) : (
                      <Navigate to="/" replace />
                    )
                  }
                />
                {Object.values(SALES_NEST_ROUTES).map((route) => (
                  <Route
                    path={`/sales/${route}`}
                    element={
                      sales === undefined ? (
                        <SalesWrapper />
                      ) : sales ? (
                        <SalesWrapper />
                      ) : (
                        <Navigate to="/" replace />
                      )
                    }
                  />
                ))}

                {/* TX-TRADE  */}
                <Route
                  path={layout.isTxTradeUser === 1 ? "/" : "/tx-trade"}
                  element={
                    <Suspense fallback={<></>}>
                      {txTrade === undefined ? (
                        <TxtradeStripeComponent />
                      ) : txTrade ? (
                        <TxtradeStripeComponent />
                      ) : (
                        <Navigate to="/" replace />
                      )}
                    </Suspense>
                  }
                />

                <Route
                  path={"/tx-trade-customise"}
                  element={
                    <Suspense fallback={<></>}>
                      {layout?.isTxTradeCustomize &&
                      isGodAdmin &&
                      (txTrade === undefined || txTrade) ? (
                        <CustomizeWrapper />
                      ) : (
                        <Navigate to="/" replace />
                      )}
                    </Suspense>
                  }
                />

                {isGodAdmin && txAccounts && (
                  <Route path="/tx-account" element={<TxAccountWrapper />} />
                )}
                {/* REPORTS  */}
                <Route
                  path="/reports"
                  element={
                    reportsItem === undefined ? (
                      <ReportsWrapper />
                    ) : reportsItem ? (
                      <ReportsWrapper />
                    ) : (
                      <Navigate to="/" replace />
                    )
                  }
                />

                {/* SUBSCRIBE */}
                <Route
                  path="/personal-details"
                  element={
                    subscribers === undefined ? (
                      <PersonalDetailsWrapper />
                    ) : subscribers ? (
                      <PersonalDetailsWrapper />
                    ) : (
                      <Navigate to="/" replace />
                    )
                  }
                />
                <Route
                  path="/wallet-&-bank-details"
                  element={
                    subscribers === undefined ? (
                      <WalletAndBankDetailsWrapper />
                    ) : subscribers ? (
                      <WalletAndBankDetailsWrapper />
                    ) : (
                      <Navigate to="/" replace />
                    )
                  }
                />

                <Route
                  path="/seller-levels/"
                  element={
                    subscribers === undefined ? (
                      <SellerLevelsWrapper />
                    ) : subscribers ? (
                      <SellerLevelsWrapper />
                    ) : (
                      <Navigate to="/" replace />
                    )
                  }
                >
                  <Route index element={<Navigate to="configuration" />} />
                  <Route
                    path="configuration"
                    index
                    element={<ConfigurationTab />}
                  />
                  <Route path="movement" element={<MovementTab />} />
                  <Route path="*" element={<Navigate to={"configuration"} />} />
                </Route>

                <Route
                  path="/notifications"
                  element={<NotificationsWrapper />}
                />

                {/* settings */}
                <Route
                  path="/settings"
                  element={
                    <Suspense fallback={<></>}>
                      {!settings === undefined ? (
                        <TxpayStripeComponent />
                      ) : settings ? (
                        <TxpayStripeComponent />
                      ) : (
                        <Navigate to="/" replace />
                      )}
                    </Suspense>
                  }
                />
                {Object.values(TX_PAY_TAB_ROUTES).map((route) => (
                  <Route
                    path={`/tx-pay${route}`}
                    element={
                      txPay === undefined ? (
                        <TXWrapper />
                      ) : txPay ? (
                        <TXWrapper />
                      ) : (
                        <Navigate to="/" replace />
                      )
                    }
                  />
                ))}

                {/* INSTRUCTION LETTER */}
                <Route
                  path="/instruction-letter"
                  element={
                    isGodAdmin && instruction_letters ? (
                      <InstructionLetterWrapper />
                    ) : (
                      <Navigate to="/" replace />
                    )
                  }
                />

                {/* API CONNECTOR */}
                <Route
                  path="/API-connector"
                  element={
                    apiConnector ? (
                      <ConnectorWrapper />
                    ) : (
                      <Navigate to="/" replace />
                    )
                  }
                />
              </>

              <Route path="/performer-venue" element={<PerformerVenue />} />
              <Route path="/add-inventory2" element={<AddInventory />} />
              <Route path="/typography" element={<Typography />} />
              <Route path="/settings" element={<TxpayStripeComponent />} />

              <Route path="/faq" element={<FAQWrapper />} />
              <Route path="faq/:slug" element={<FAQListing />} />
              <Route path="faq/search" element={<FAQListing />} />

              <Route path="/logout" element={<Logout />} />
              <Route path="/faq-static" element={<FaqStatic />} />
              <Route path="/faq-search" element={<FaqSearch />} />
              {/* <Route
                path="/tx-trade-static/track-listing-static"
                element={<TxTradeTrackListingStatic />}
              />
              <Route
                path="/tx-trade-static/purchases-static"
                element={<TxTradePurchasesStatic />}
              /> */}
            </>
          ) : (
            <Route path="/" element={<Navigate to="/login" />} />
          )}
        </Route>
        <Route element={<PublicLayout />}>
          {!isLogged && !cookieTtoken && (
            <>
              <Route path="/login" element={<Login />} />
              <Route path="*" element={<Navigate to="/login" replace />} />
              <Route path="/forgot-password" element={<ForgetPassword />} />
              <Route
                path="/reset-password/:token"
                element={<ResetPassword />}
              />
              {/* SIGN UP ROUTES */}
              <Route path="/sign-up" element={<SingupStripeComponent />} />
            </>
          )}
          <Route path="/loadingpage" element={<LoadingPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
