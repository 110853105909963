/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import _, { size } from "lodash";
import { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { LayoutContext } from "../../components/core/LayoutProvider";
import ProgressBardLoader from "../../components/ui/ProgressBardLoader";
import { QUERIES } from "../../helpers/const";
import {
  getReportsListing,
  getReportsheaderOptions,
} from "../Reports/core/requests";
import { getNotificationListing } from "../notifications/core/requests";
import DashBoardShimmerLoader from "./component/DashBoardShimmerLoader";
import HeaderComponent from "./component/HeaderComponent";
import NewsCard from "./component/NewsCard";
import NotificationsCard from "./component/NotificationsCard";
import OrderOutOffer from "./component/OrderOutOffer";
import SalesCard from "./component/SalesCard";
import ToBeFulfilledCard from "./component/ToBeFulfilledCard";
import TopSellingCard from "./component/TopSellingCard";
import TxTradeCard from "./component/TxTradeCard";
import ReportsCard from "./component/report-card/ReportsCard";
import { DashboardContext } from "./core/DashboardProvider";
import {
  getActivityListing,
  getCategoryList,
  getEventsListing,
  getNewsListing,
  getOfferedOrders,
  getPurchaseListings,
  getSalesListing,
  getTopSellingEvents,
  getTrackedListings,
  getfulfilledListing,
} from "./core/requests";
import OffCanvas from "./widgets/OffCanvas";
import OrderOutOfferDetailsModal from "./widgets/OrderOutOfferDetailsModal";

const Dashboard = () => {
  const coreLayout = useContext(LayoutContext);
  const layout = useContext(DashboardContext);

  const [toBeFulfillfedMeta, setToBeFulfiledMeta] = useState<any>({});
  const [topSellingEventData, setTopSellingEventData] = useState<any>();
  const [topSellingEventHandle, setTopSellingEventHandle] = useState<any>({
    page: 1,
    duration: 7,
  });
  const [toBefulfilledPage, setToBefulfilledPage] = useState<number>(1);
  const [fulfilledCardData, setFulfilledCardData] = useState<any>();
  const [customNewsLoaderFlag, setCustomNewsLoaderFlag] =
    useState<boolean>(false);
  const [customTopSellingLoaderFlag, setCustomTopSellingLoaderFlag] =
    useState<boolean>(false);

  const [selectedTeams, setSelectedTeams] = useState<any>();

  // const [orderOutOfferData, setOrderOutOfferData] = useState<any>([]);
  const [orderOutOfferListData, setOrderOutOfferListData] = useState<any>([]);
  const [orderOutOfferFirstLoading, setOrderOutOfferFirstLoading] =
    useState(true);
  const [ourderOutOfferCustomLoading, setOurderOutOfferCustomLoading] =
    useState(false);
  const [orderOutOfferFilters, setOrderOutOfferFilters] = useState({
    per_page: 8,
    page: 1,
    performer_ids: [],
    category_ids: [],
    event_date_range: null,
  });

  const [notificationMetaData, setNotificationMetaData] = useState<any>();
  const [notificationError, setNotificationError] = useState<any>();
  const [notificationPage, setNotificationPage] = useState<number>(1);
  const [newsPage, setNewsPage] = useState<number>(1);
  const newsOption = layout.newsOptions;
  const authUser = coreLayout.authUser;
  const access = coreLayout.allowedAccess?.allowed_access;
  const userSpecific = access?.some(
    (item: any) => item.id === "user-specific-data" && item.status === true
  );
  const isGodAdmin = coreLayout?.allowedAccess?.account_info?.is_god_admin;

  const inventoryAccess = access?.some(
    (item: any) => item.id === "inventory" && item.status === true
  );
  const sales = access?.some(
    (item: any) => item.id === "shipping" && item.status === true
  );
  const reportsAccess = access?.some(
    (item: any) => item.id === "reports" && item.status === true
  );
  const txTradeAccess = access?.some(
    (item: any) => item.id === "txtrade" && item.status === true
  );

  let tabValue = coreLayout.tabValue;

  // GET NOTIFICATIONS LISTING
  const {
    isLoading: dashboardLoading,
    data: listingData,
    refetch,
  } = useQuery(
    [
      `${QUERIES.NOTIFICATIONS_LISTING}`,
      tabValue,
      coreLayout.newNotification,
      coreLayout.notificationId,
      selectedTeams,
    ],
    () =>
      getNotificationListing({
        tab_name: tabValue,
        // page: notificationPage,
        team_id: selectedTeams,
        filters: {
          specific: !isGodAdmin && userSpecific && [authUser?.id],
        },
      }),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      // staleTime: Infinity,
      onError: (err) => {},
    }
  );

  // GET REPORTS LISTING
  const { isLoading: reportsLoading, data: reportsData } = useQuery(
    [`${QUERIES.REPORTS_LISTING}`, (tabValue = ""), selectedTeams],
    () =>
      getReportsListing({
        tabValue,
        team_id: selectedTeams,
        filters: {
          specific: !isGodAdmin && userSpecific && [authUser?.id],
        },
      }),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      onError: (err) => {},
    }
  );

  // GET REPORTS count
  const { isLoading: reportsOptionsLoading, data: reportsOptionsData } =
    useQuery(
      [`reports-count`, (tabValue = ""), selectedTeams],
      () =>
        getReportsheaderOptions({
          tabValue,
          team_id: selectedTeams,
          filters: {
            specific: !isGodAdmin && userSpecific && [authUser?.id],
          },
        }),
      {
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        retry: false,
        onError: (err) => {},
      }
    );

  // GET SALES LISTING
  const { isLoading: salesLoading, data: salesData } = useQuery(
    [`${QUERIES.SALES_LISTING}`, selectedTeams],
    () =>
      getSalesListing({
        optionsData: layout.salesOptions,
        team_id: selectedTeams,
      }),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      // staleTime: Infinity,
      onError: (err) => {},
    }
  );

  // GET TO BE FULFILLED LISTING
  const { isLoading: fulfilledLoading, data: fulfilledData } = useQuery(
    [`${QUERIES.FULFILLED_LISTING}`, selectedTeams],
    () =>
      getfulfilledListing({
        optionsData: layout.fulfilledOptions,
        team_id: selectedTeams,
      }),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      cacheTime: 0,
      retry: false,
      onError: (err) => {},
    }
  );

  // GET TX TRADE TRACKED LISTINGS API
  const { isLoading: txTradeTrackedLoading, data: txTradeTrackedLisingData } =
    useQuery(
      [`txTrade-tracked-listing`, selectedTeams],
      () =>
        getTrackedListings({
          team_id: selectedTeams,
        }),
      {
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        cacheTime: 0,
        retry: false,
        onError: (err) => {},
      }
    );

  // GET TX TRADE TRACKED PURCHASE API
  const { isLoading: txTradePurchaseLoading, data: txTradePurchaseLisingData } =
    useQuery(
      [`txTrade-purchase-listing`, selectedTeams],
      () =>
        getPurchaseListings({
          team_id: selectedTeams,
          page: 1,
          per_page: 10,
        }),
      {
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        cacheTime: 0,
        retry: false,
        onError: (err) => {},
      }
    );

  // EVENT LISTING API
  const { isLoading: eventsLoading, data: eventsData } = useQuery(
    [`${QUERIES.INVENTORY_EVENT_LIST}`],
    () => getEventsListing({}),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      onError: (err) => {},
    }
  );

  // ACTIVITY LISTING API
  const { isLoading: activityLoading, data: activityData } = useQuery(
    [`${QUERIES.ACTIVITY_LISTING}`, selectedTeams],

    () =>
      getActivityListing(
        _.size(selectedTeams) > 0 ? { team_id: _.map(selectedTeams, "id") } : {}
      ),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      onError: (err) => {},
    }
  );

  //ORDERS ON OFFERS LISTING

  const {
    isFetching: ourderOutOfferLoading,
    data: orderOutOfferData,
    refetch: refetchOrderOutOffer,
  } = useQuery(
    [QUERIES.DASHBOARD_OFFERED_ORDERS, orderOutOfferFilters, selectedTeams],
    () =>
      getOfferedOrders({ ...orderOutOfferFilters, team_ids: selectedTeams }),
    {
      refetchOnWindowFocus: false,
      retry: false,
      onSettled: (data: any) => {
        if (orderOutOfferFilters.page === 1) {
          setOrderOutOfferFirstLoading(false);
          setOrderOutOfferListData(data?.data?.orders);
        } else {
          setOrderOutOfferListData((prev: any) => {
            return [...prev, ...data?.data?.orders];
          });
        }
        setOurderOutOfferCustomLoading(false);
      },
    }
  );

  useEffect(() => {
    if (!ourderOutOfferLoading) {
      setOrderOutOfferFirstLoading(false);
      setOurderOutOfferCustomLoading(false);
    }
  }, [ourderOutOfferLoading, orderOutOfferFilters]);

  // PRICE MOVES LISTING API
  // const { isLoading: priceLoading, data: priceData } = useQuery(
  //   [`${QUERIES.PRICE_LISTING}`],
  //   () => getPriceListing({}),
  //   {
  //     refetchOnWindowFocus: false,
  //     refetchOnReconnect: false,
  //     retry: false,
  //     onError: (err) => {},
  //     enabled: !isGodAdmin,
  //   }
  // );
  // CATEGORY LIST GET
  const { isLoading: categoryLoading, data: categoryListData } = useQuery(
    [`${QUERIES.GET_CATEGORY_LIST}`, topSellingEventHandle?.duration],
    () => getCategoryList(topSellingEventHandle?.duration),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      enabled: coreLayout?.isTxTradeUser === 0,
      onError: (err) => {},
    }
  );

  useEffect(() => {
    setTopSellingEventHandle((current: any) => {
      return {
        ...current,
        page: 1,
      };
    });
    setOrderOutOfferFilters((curr) => {
      return {
        ...curr,
        page: 1,
      };
    });
    setOrderOutOfferFirstLoading(true);
  }, [selectedTeams]);

  // TOP SELLING EVENT - QUERY
  const { isLoading: topSellingLoading, data: topSellingData } = useQuery(
    [`${QUERIES.TOP_SELLING_EVENTS}`, topSellingEventHandle, selectedTeams],
    () =>
      getTopSellingEvents({
        data: topSellingEventHandle,
        team_id: selectedTeams,
      }),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      cacheTime: 0,
      retry: false,
      onError: (err) => {},
      onSettled(data, error) {
        if (data?.meta?.total === 0) {
          setCustomTopSellingLoaderFlag(true);
        }
      },
    }
  );

  // INDUSTRY NEWS LISTING API
  const {
    isLoading: newsLoading,
    data: queryNewsData,
    isFetching,
  } = useQuery(
    [`${QUERIES.NEWS_LISTING}`, newsOption, newsPage],
    () => getNewsListing({ newsOption }),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      onError: (err) => {},
    }
  );

  // Data Variables
  let notificationsData = listingData?.data;
  let reportsCardData = reportsData?.data;
  let salesCardData = salesData?.data;

  // Meta
  // let priceMeta = priceData?.meta;
  let eventsMeta = eventsData?.meta;
  let salesMetaData = salesData?.meta;
  let notificationMeta = listingData?.meta;
  let activityMetaData = activityData?.meta;
  let reportsMetaData = reportsData?.meta;
  let topSellingMetaData = topSellingData?.meta;

  useEffect(() => {
    if (topSellingData?.data?.status === "true") {
      setTopSellingEventData((current: any) => {
        if (
          topSellingMetaData?.current_page === 1 &&
          size(topSellingData?.data?.event_sell_list) === 0
        ) {
          return [];
        } else {
          return topSellingMetaData?.current_page === 1
            ? [...topSellingData?.data?.event_sell_list]
            : [
                ...(Array.isArray(current) && size(current) > 0 ? current : []),
                ...topSellingData?.data?.event_sell_list,
              ];
        }
      });
    }
  }, [topSellingData]);

  // LOAD EVENTS DATA IN GLOBAL STATE
  useEffect(() => {
    layout.setEventsData(eventsData?.data?.event);
  }, [eventsData]);

  // LOAD ACTIVIY LISTING DATA IN GLOBAL STATE
  useEffect(() => {
    layout.setListingActivityData(activityData?.data?.event_list);
  }, [activityData]);

  // LOAD NOTIFICATION DATA IN GLOBAL STATE
  useEffect(() => {
    if (listingData?.data?.notifications) {
      layout.setNotificationData(listingData?.data?.notifications);
    }
  }, [listingData]);

  // UPDATE THE META DATA OF NOTIFICATION CARD
  useEffect(() => {
    setNotificationMetaData(notificationMeta);
  }, [notificationMeta]);

  // INDUSTRY NEWS LISTING DATA FUNCTIONALITY
  useEffect(() => {
    if (queryNewsData) {
      let page = layout?.newsOptions?.page;
      layout.setIndustryNewsData((currentData: any) => {
        if (page === 1) {
          return queryNewsData?.data?.channel?.item;
        } else if (currentData && currentData?.length > 0) {
          return [...currentData, ...queryNewsData?.data?.channel?.item];
        }
      });
    }
  }, [queryNewsData]);

  useEffect(() => {
    _.size(layout.industryNewsData) > 0 &&
      customNewsLoaderFlag === false &&
      setCustomNewsLoaderFlag(true);
  }, [layout.industryNewsData]);

  useEffect(() => {
    topSellingEventData &&
      customTopSellingLoaderFlag === false &&
      setCustomTopSellingLoaderFlag(true);
  }, [topSellingEventData]);

  // UPDATE LISTING DATA & META DATA OF TO BE FULFILLED
  useEffect(() => {
    if (fulfilledData) {
      setToBeFulfiledMeta(fulfilledData?.meta);
      let page = layout?.fulfilledOptions?.page;

      layout.setToBeFulfilledData((current: any) => {
        return fulfilledData?.data;
      });
    }
  }, [fulfilledData]);

  const mainLoading =
    salesLoading ||
    fulfilledLoading ||
    reportsLoading ||
    !customNewsLoaderFlag ||
    dashboardLoading;

  const [secondCounter, setSecondCounter] = useState(3);

  useEffect(() => {
    if (mainLoading) {
      setTimeout(() => {
        setTimeout(() => {
          setSecondCounter((pre: any) => pre + 1);
        }, 200);
        setSecondCounter(1);
      }, 1900);
    }
  }, [mainLoading]);

  const scrollToTop = () => {
    const modalElement = document.getElementById("orderoutofferbody");
    if (modalElement) {
      modalElement.scrollTop = 0;
    }
  };

  return (
    <>
      <OffCanvas />
      <HeaderComponent
        teamOption={coreLayout.allowedAccess?.teams}
        setSelectedTeams={(data: any) => {
          setSelectedTeams(data);
          if ((!selectedTeams && data) || (selectedTeams && data)) {
            setCustomTopSellingLoaderFlag(false);
            setTimeout(() => {
              setCustomTopSellingLoaderFlag(true);
            }, 2000);
          }
        }}
        selectedTeams={selectedTeams}
        isGodAdmin={isGodAdmin}
        inventoryAccess={inventoryAccess}
      />

      <>
        {/* Cards */}
        <div className="flex flex-wrap mt-4 md:-mx-2 max-md:overflow-hidden">
          {salesLoading ? (
            <DashBoardShimmerLoader mainLoading={salesLoading} />
          ) : (
            sales && (
              <SalesCard
                salesCardData={salesCardData}
                salesMetaData={salesMetaData}
                refetch={refetch}
                salesLoading={salesLoading}
                selectedTeams={selectedTeams}
              />
            )
          )}
          {fulfilledLoading ? (
            <DashBoardShimmerLoader mainLoading={fulfilledLoading} />
          ) : (
            <>
              {" "}
              {/* TO BE FULFILLED: SETTING CARD */}
              {sales && (
                <ToBeFulfilledCard
                  // fulfilledCardData={fulfilledCardData}
                  fulfilledLoading={fulfilledLoading}
                  toBeFulfillfedMeta={toBeFulfillfedMeta}
                  selectedTeams={selectedTeams}
                  setToBeFulfiledMeta={setToBeFulfiledMeta}
                  setFulfilledCardData={setFulfilledCardData}
                  refetch={refetch}
                />
              )}
            </>
          )}

          {coreLayout?.isTxTradeUser === 0 &&
            (orderOutOfferFirstLoading ? (
              <DashBoardShimmerLoader
                mainLoading={orderOutOfferFirstLoading}
                width={true}
              />
            ) : (
              <OrderOutOffer
                categoryListData={categoryListData}
                ourderOutOfferCustomLoading={ourderOutOfferCustomLoading}
                setOurderOutOfferCustomLoading={setOurderOutOfferCustomLoading}
                ourderOutOfferLoading={ourderOutOfferLoading}
                orderOutOfferFilters={orderOutOfferFilters}
                setOrderOutOfferFilters={setOrderOutOfferFilters}
                orderOutOfferData={orderOutOfferData}
                orderOutOfferListData={orderOutOfferListData}
                setOrderOutOfferListData={setOrderOutOfferListData}
                scrollToTop={scrollToTop}
              />
            ))}

          {/* {layout.selectedOrderOutOffer && ( */}
          {/* {layout?.selectedOrderOutOffer?.status === true && ( */}
          <OrderOutOfferDetailsModal
            userOptions={orderOutOfferData?.data?.team_users}
            setOrderOutOfferListData={setOrderOutOfferListData}
            refetchOrderOutOffer={refetchOrderOutOffer}
            scrollToTop={scrollToTop}
          />
          {/* )} */}
          {/* )} */}

          {reportsLoading ? (
            <DashBoardShimmerLoader mainLoading={reportsLoading} />
          ) : (
            <>
              {/* REPORT CARD */}
              {reportsAccess && (
                <ReportsCard
                  reportsCardData={reportsCardData}
                  reportsLoading={reportsLoading}
                  reportsMetaData={reportsMetaData}
                  selectedTeams={selectedTeams}
                  reportsOptionsData={reportsOptionsData}
                />
              )}
            </>
          )}

          {txTradeTrackedLoading ? (
            <DashBoardShimmerLoader mainLoading={txTradeTrackedLoading} />
          ) : (
            <>
              {txTradeAccess && (
                <TxTradeCard
                  data={txTradeTrackedLisingData}
                  selectedTeams={selectedTeams}
                  reportsOptionsData={reportsOptionsData}
                  purchaseData={txTradePurchaseLisingData}
                />
              )}
            </>
          )}
          {/* TOP SELLING EVENT  */}
          {coreLayout?.isTxTradeUser === 0 &&
            (customTopSellingLoaderFlag === false ? (
              <DashBoardShimmerLoader
                blockShimmerCount={0}
                mainLoading={topSellingLoading}
                bodyShimmerCountSix={8}
              />
            ) : (
              <TopSellingCard
                isLoadingTopSelling={topSellingLoading}
                data={topSellingEventData}
                metaDataTopSelling={topSellingMetaData}
                options={categoryListData}
                topSellingEventHandle={topSellingEventHandle}
                setTopSellingEventHandle={setTopSellingEventHandle}
                isGodAdmin={isGodAdmin}
                selectedTeams={selectedTeams}
                categoryLoading={categoryLoading}
                coreLayout={coreLayout}
              />
            ))}

          {customNewsLoaderFlag === false ? (
            <DashBoardShimmerLoader
              mainLoading={newsLoading}
              blockShimmerCount={0}
              bodyShimmerCountSix
            />
          ) : (
            <>
              {/* NEWS CARD */}
              <NewsCard loader={newsLoading} />
            </>
          )}

          {dashboardLoading ? (
            <DashBoardShimmerLoader mainLoading={dashboardLoading} />
          ) : (
            <>
              {/* NOTIFICATION CARD */}
              <NotificationsCard
                notificationsData={notificationsData}
                notificationMetaData={notificationMetaData}
                refetch={refetch}
                dashboardLoading={dashboardLoading}
                selectedTeams={selectedTeams}
              />
            </>
          )}
        </div>
      </>
      {mainLoading && (
        <ProgressBardLoader
          LoadingText=" Loading your dashboard data"
          secondCounter={secondCounter}
        />
      )}
    </>
  );
};

export default Dashboard;
