import { size } from "lodash";
import React, { useState } from "react";
import { default as eyeSVG } from "../../../../../assets/media/icons/other_icons/Eye.svg";
import UploadSVG from "../../../../../assets/media/icons/other_icons/Upload.svg";
import { IKTSVG } from "../../../../../components/ui/IKTSVG";
import ProgressBardLoader from "../../../../../components/ui/ProgressBardLoader";
import { sentanceCase } from "../../../../../helpers/Functions";
import { showAlert } from "../../../../../helpers/ShowAlert";
import { copy } from "../../../../add_inventory/core/_functions";
import TooltipPortal from "../../../../tx_trade/table/component/TooltipPortal";
import CopytoChipboard from "../../component/bankViewComponent/CopytoChipboard";
import HeaderView from "../../component/bankViewComponent/HeaderView";
import { changeRequest } from "../../core/_request";


const ChangeRequest = ({
  data,
  setPDFView,
  setChangeRequestToggle,
  refetchList,
  setBankView,
}: any) => {
  const [loader, setLoader] = useState<boolean>(false);
  const [isConfirm, setIsConfirm] = useState<string>("");
  const [isCopied, setIsCopied] = useState<any>({
    id: "",
    value: "",
  });

  // HANDLE COPY
  const handleCopy = (data: any) => {
    setIsCopied({
      id: data?.id,
      defaultValue: copy(data?.defaultValue),
    });
    setTimeout(() => {
      setIsCopied({
        id: "",
        defaultValue: "",
      });
    }, 2000);
  };

  let renderData = data?.data?.request_data;
  let documentProof = renderData?.find(
    (item: any) => item?.key === "documents_proof"
  );

  // HANDLE CHANGE REQUEST
  const handleChangeRequest = (data: any, action: string) => {
    setLoader(true);
    let payload = {
      id: data?.id,
      action: action,
    };
    changeRequest(payload).then((response: any) => {
      setLoader(false);
      if (!response?.data?.status) {
        showAlert(response?.message, true);
      } else {
        showAlert(response?.message, false, 1500);

        setTimeout(() => {
          refetchList();
          setChangeRequestToggle((current: any) => {
            return {
              show: false,
              data: undefined,
            };
          });
        }, 1500);
      }
    });
  };

  // HANDLE CHANGE REQUEST
  const handleConfirmButton = (isConfirm: string) => {
    handleChangeRequest(data?.data, isConfirm);
    setIsConfirm("");
  };

  return (
    <div
      className={`transition lg2:border-r w-full lg2:h-full lg2:w-1/2 flex items-center !justify-start flex-col scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full max-lg2:fixed max-lg2:bg-white max-lg2:z-20 max-lg2:max-w-[92%] max-md:max-w-full max-lg2:top-[3.75rem] max-lg2:right-0 max-lg2:h-[calc(100vh-3.75rem)]`}
    >
      <div
        className={`tab_vontent opacity-100 transition-opacity duration-150 ease-linear block w-full`}
        role="tabpanel"
        data-te-tab-active
      >
        <div className="lg2:p-5">
          <div className="">
            {/* FIELDS */}
            <div className=" flex flex-wrap  border rounded relative mb-5 last-of-type:mb-0">
              {isConfirm ? (
                <>
                  <HeaderView
                    title={`Do you really want to ${isConfirm}?`}
                    isLockIconRequired={false}
                    isCloseBtnRequired={false}
                  >
                    <div className="edit_btn px-2.5 flex items-center">
                      <button
                        type="button"
                        className="mr-2 group inline-flex group items-center border text-violet-800 hover:bg-indigo-500 hover:border-indigo-500 hover:text-white transition font-medium rounded text-sm13 px-2.5 py-0.5"
                        onClick={() => setIsConfirm("")}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="group inline-flex group items-center text-white hover:text-white bg-green-500  hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5"
                        onClick={() =>
                          handleConfirmButton(
                            isConfirm === "Reject" ? "Rejected" : "Completed"
                          )
                        }
                      >
                        Confirm
                      </button>
                    </div>
                  </HeaderView>
                </>
              ) : (
                <HeaderView
                  title={"Change request"}
                  isLockIconRequired={false}
                  isCloseBtnRequired={true}
                  handleClose={() => {
                    setChangeRequestToggle((current: any) => {
                      return {
                        show: false,
                        data: undefined,
                      };
                    });
                  }}
                >
                  <div className="edit_btn px-2.5 flex items-center">
                    <button
                      type="button"
                      className="mr-2 group inline-flex group items-center text-rose-500 hover:text-white bg-white hover:bg-rose-500 border hover:border-rose-500 font-medium rounded text-sm13 px-2.5 py-0.5 false"
                      onClick={() => setIsConfirm("Reject")}
                    >
                      Reject
                    </button>
                    <button
                      type="button"
                      className="group inline-flex group items-center text-white hover:text-white bg-violet-500  hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5 false"
                      onClick={() => setIsConfirm("Approve")}
                    >
                      Approve
                    </button>
                  </div>
                </HeaderView>
              )}

              <div className="inner-part px-3 py-3 flex flex-wrap w-full">
                {renderData &&
                  size(renderData) > 0 &&
                  renderData?.map((item: any, index: number) => {
                    if (
                      item?.key !== "documents_proof" &&
                      item?.key !== "kyc_request"
                    ) {
                      return (
                        <React.Fragment key={index}>
                          <CopytoChipboard
                            headerName={sentanceCase(item?.label)}
                            defaultValue={
                              typeof item?.value === "string"
                                ? sentanceCase(item?.value)
                                : typeof item?.value === "object"
                                ? item?.value?.originalId
                                : item?.value
                            }
                            id={index}
                            isCopied={isCopied}
                            isLock={true}
                            placeholder={sentanceCase(item?.label)}
                            handleClick={(data: any) => handleCopy(data)}
                          />
                        </React.Fragment>
                      );
                    } else <></>;
                  })}
              </div>
            </div>

            {/* DOCUMENT PROOF */}
            {documentProof && size(documentProof) > 0 && (
              <div className=" flex flex-wrap  border rounded relative mb-5 last-of-type:mb-0">
                <HeaderView
                  title={"Document proof"}
                  isLockIconRequired={false}
                />
                <div className="inner-part px-3 py-3 flex flex-wrap w-full">
                  {documentProof?.value.map((item: any, index: number) => {
                    return (
                      <div className="list_wrap flex flex-wrap gap-2.5 anim-fade w-full mb-2">
                        <div className="single-invoice border rounded flex flex-wrap justify-between px-2.5 py-1.5 text-sm12 bg-white w-full">
                          <span className="flex-1 flex leading-4">
                            {item?.name}
                          </span>

                          {/* Download Icon download functionality is pending */}
                          <button
                                  className="ml-2"
                                  type="button"
                                  data-tooltip-id={`pdf-view-account-down-${index}`}
                                  onClick={() => {
                                    if (item?.key) {
                                      const link = document.createElement("a");
                                      link.href = item.key;
                                      link.target = `_blank`;
                                      link.download = item?.name || "file.pdf";
                                      document.body.appendChild(link);
                                      link.click();
                                      document.body.removeChild(link);
                                    } else {
                                      console.error(
                                        "File URL is not available"
                                      );
                                    }
                                  }}
                                >
                                  <TooltipPortal
                                    id={`pdf-view-account-down-${index}`}
                                    content={"Download"}
                                    className="text-center !bg-white !opacity-100 shadow !py-0.5 !px-1.5 font-medium text-xs z-[45] max-w-[9rem] cursor-default "
                                  />
                                  <IKTSVG
                                    className="fill-violet-500 hover:fill-indigo-500 transition"
                                    path={UploadSVG}
                                    svgClassName="w-[.9375rem] h-2.5 rotate-180"
                                  />
                                </button>

                          <button
                            className="ml-2"
                            type="button"
                            data-tooltip-id="pdf-view-account"
                            onClick={() => {
                              setPDFView((current: any) => {
                                return {
                                  ...current,
                                  fileName: item?.name,
                                  file: item?.key,
                                };
                              });
                              setChangeRequestToggle((current: any) => {
                                return {
                                  ...current,
                                  show: false,
                                };
                              });
                            }}
                          >
                            <TooltipPortal
                              id={`pdf-view-account`}
                              content={"View"}
                              className="text-center !bg-white !opacity-100 shadow !py-0.5 !px-1.5 font-medium text-xs z-[45] max-w-[9rem] cursor-default "
                            />
                            <IKTSVG
                              className="fill-violet-500 hover:fill-indigo-500 transition"
                              path={eyeSVG}
                              svgClassName="w-[.9375rem] h-2.5"
                            />
                          </button>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {loader && <ProgressBardLoader secondCounter={1} />}
    </div>
  );
};

export default ChangeRequest;
