import { NO_DEMO_STATUS } from "helpers/const";
import { isArray, isObject, isString } from "lodash";
import moment from "moment";

// Define a common interface for the expected structure
interface DemoItem {
  id: string;
}

// Helper to check for `id` safely
export const hasNoDemoValue = (value: any) => {
  if (isArray(value))
    return value.find((item: any) => item?.id !== NO_DEMO_STATUS);

  if (isObject(value)) return (value as DemoItem)?.id !== NO_DEMO_STATUS;

  if (isString(value)) return value !== NO_DEMO_STATUS;
  return false;
};

export const getDemoStatus = (list: any) => {
  if (!list || !list?.demo_status) return;

  if (!hasNoDemoValue(list?.demo_status)) return list?.demo_status;

  return `${list?.demo_status}`;
};

export const getValidDemoDateTime = (data: any, isFormatted = false) => {
  if (hasNoDemoValue(data?.demo_status)) {
    const demoDateTime = data?.demo_datetime
      ? new Date(data?.demo_datetime)
      : "";

    if (demoDateTime && !isNaN(demoDateTime?.getTime()))
      return isFormatted
        ? moment(demoDateTime).format("DD/MM/YYYY HH:mm")
        : demoDateTime;
  }

  return "";
};
