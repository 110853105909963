import DropDownField from "components/formComponent/DropDownField";
import TextInput from "components/formComponent/TextInput";
import { IKTSVG } from "components/ui/IKTSVG";
import TooltipPortal from "pages/tx_trade/table/component/TooltipPortal";
import infoIcon from "../../../../assets/media/icons/other_icons/info.svg";

const CommonDynamicComponent = ({
  index,
  obj,
  OptionData,
  formik,
  ClassName = false,
  fullWidthTitle = false,
  handleOnUpdate = () => {},
  formikErrorClass = false,
  component = null,
}: any) => {
  let newOriginalValue =
    obj?.value &&
    formik.values?.[obj?.key] &&
    component === "configurationTabs" &&
    obj?.value !== formik.values?.[obj?.key];

  return (
    <div
      key={index}
      className={` ${
        ClassName ? ClassName : "w-full"
      } bg-white mb-5 px-2.5 relative flex items-center`}
    >
      <div className={` ${fullWidthTitle ? "w-full mb-2" : "w-full md:w-1/3"}`}>
        <p className="text-sm13">{obj?.label} :</p>
      </div>

      <div className="w-full seller_level_drop_down relative">
        {obj?.component === "dropdown" ? (
          <DropDownField
            options={OptionData?.[obj?.key]}
            value={formik.values?.[obj?.key]}
            placeholder={obj?.label}
            name={obj?.key}
            getOptionLabel="name"
            getOptionValue="id"
            height=""
            className={`h-fourty ${
              obj?.originalValue || newOriginalValue
                ? "have_original_value"
                : ""
            }  ${
              formik.touched?.[obj?.key] &&
              formik.errors?.[obj?.key] &&
              "all-dropdown-errorField"
            } ${(obj?.originalValue || newOriginalValue) && "yellowBorder"}`}
            handleOnChange={(data: any) => {
              formik?.setFieldValue(obj?.key, data?.target?.value);
              handleOnUpdate(obj?.key, "update");
            }}
          />
        ) : obj?.component === "radio" ? (
          <div className="radiobtns flex flex-wrap gap-x-5">
            <label className="!text-sm14 flex items-center relative cursor-pointer !p-0">
              <input
                type="radio"
                name={obj?.key}
                checked={formik.values?.[obj?.key] === true}
                onChange={() => {
                  formik?.setFieldValue(obj?.key, true);
                  handleOnUpdate(obj?.key, "update", "radio");
                }}
                className="mr-1 peer h-4 w-4 rounded-full checked:bg-transparent p-1 transition cursor-pointer border checked:border-indigo-500 border-gray-300 hover:border-indigo-500 focus:ring-0 focus:ring-offset-0"
              />
              <span className="peer-checked:opacity-100 check w-2.5 h-2.5 rounded-full bg-indigo-500 absolute left-[.1875rem]  pointer-events-none"></span>
              Yes
            </label>
            <label className="!text-sm14 flex items-center relative cursor-pointer !p-0">
              <input
                type="radio"
                name={obj?.key}
                checked={formik.values?.[obj?.key] === false}
                // checked={persist_custom_seller_level_config === 0}
                onChange={() => {
                  formik?.setFieldValue(obj?.key, false);
                  handleOnUpdate(obj?.key, "update", "radio");
                }}
                className="mr-1 peer h-4 w-4 rounded-full checked:bg-transparent p-1 transition cursor-pointer border checked:border-indigo-500 border-gray-300 hover:border-indigo-500 focus:ring-0 focus:ring-offset-0"
              />
              <span className="peer-checked:opacity-100 check w-2.5 h-2.5 rounded-full bg-indigo-500 absolute left-[.1875rem]  pointer-events-none"></span>
              No
            </label>
          </div>
        ) : (
          <>
            <TextInput
              name={obj?.key}
              type={obj?.type === "numeric" ? "number" : "text"}
              placeholder={obj?.label}
              value={formik.values?.[obj?.key]}
              id={obj?.key}
              required={false}
              className={`${
                obj?.originalValue ? "!bg-indigo-50" : "!bg-white"
              }  `}
              handleOnChange={(e: any) => {
                formik.handleChange(e);
                handleOnUpdate(obj?.key, "update");
              }}
              errorClass={
                formik.touched?.[obj?.key] &&
                formik.errors?.[obj?.key] &&
                "border-rose-500"
              }
              handleClearValue={() => formik.setFieldValue([obj?.key], "")}
              yellowBorder={
                obj?.originalValue || newOriginalValue ? `yellowBorder` : null
              }
            />
          </>
        )}

        {formik.touched?.[obj?.key] && formik.errors?.[obj?.key] ? (
          <div
            className={`text-xs text-rose-500 error-msg  ${
              formikErrorClass ? "left-[1.375rem]" : "left-[1.375rem]"
            }  `}
          >
            {formik.errors?.[obj?.key]}
          </div>
        ) : null}
        {obj?.originalValue || newOriginalValue ? (
          <div
            className={`flex items-center justify-center absolute  ${
              obj?.component === "radio" ? "right-[16.75rem]" : "right-9"
            }  top-1/2 -translate-y-1/2`}
            data-tooltip-id={`infoTooltip-${index}`}
          >
            <TooltipPortal
              id={`infoTooltip-${index}`}
              content={`This value has been updated and orignal value is ${
                obj?.originalValue ||
                (typeof obj?.value === "object" ? obj?.value?.name : obj?.value)
              } `}
              className="text-center !bg-white !opacity-100 shadow !px-[.3125rem] !py-0.5 font-medium  !text-xxs z-[9999999999999] max-w-[10.625rem] cursor-default !text-violet-800"
            />
            <IKTSVG
              path={infoIcon}
              className="ml-1 fill-indigo-500 block"
              svgClassName="fill-yellow-100 hover:fill-indigo-500 w-3.5 h-3.5"
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default CommonDynamicComponent;
