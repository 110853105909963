import { addCommaInNumber } from "helpers/Functions";
import { useContext } from "react";
import { SellerLevelsProviderContex } from "../core/SellerLevelsProvider";

const ConfigurationCountBox = ({
  name,
  countValue,
  ticketCount,
  isCheckbox,
  extraProp = false,
  postKey,
  shimmerLoader,
  salesOptions,
}: any) => {
  return (
    <>
      <div className="w-full lg:w-1/3 mb-5 px-2.5 lg:max-w-[16.1875rem]">
        <label
          htmlFor={postKey}
          className={`border form-check items-center rounded group block min-w-[9.875rem]  [&.active-style]:bg-indigo-500/5 [&.active-style]:border-indigo-500  ${
            shimmerLoader && "shimmer-effect"
          } `}
        >
          <div className={` px-4 py-2.5 h-ful  `}>
            <div className="flex justify-between">
              <div>
                <h3
                  className={`text-2xl md:text-boxtitle md:leading-9  [&.active-style]:text-indigo-500`}
                >
                  {countValue ? addCommaInNumber(countValue) : "0"}
                </h3>
              </div>
              {ticketCount ? (
                <h5>
                  <span
                    className={`flex items-center bg-gray-100 text-xxs font-semibold px-2 py-0.5 rounded  [&.active-style]:text-indigo-500 [&.active-style]:bg-indigo-500/5 ml-1`}
                  >
                    {ticketCount ? addCommaInNumber(ticketCount) : "0"} tickets
                  </span>
                </h5>
              ) : (
                ""
              )}
            </div>
            <div className="flex justify-between items-center">
              <p
                className={`text-xs text-gray-500 py-1 ${
                  isCheckbox && "group-hover:text-indigo-500"
                }  [&.active-style]:text-indigo-500`}
              >
                {name ? name : "0"}
              </p>
            </div>
          </div>
        </label>
      </div>
    </>
  );
};

const ConfigurationFilters = ({ loading }: any) => {
  const layout: any = useContext(SellerLevelsProviderContex);
  const count = layout.sellerLevelData?.count;
  return (
    <>
      {/* <CheckboxFilter
        title="Total levels"
        trackedLoading={loading}
        count={count}
      /> */}

      <ConfigurationCountBox
        name={"Total levels"}
        countValue={count}
        isCheckbox={false}
        shimmerLoader={loading}
      />
    </>
  );
};

export default ConfigurationFilters;
