import { PropsWithChildren, createContext, useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import { LayoutContext } from "../../../components/core/LayoutProvider";
import { getHeaderFilter } from "../../../helpers/AssetHelpers";
import {
  NEW_ORDER_INITIAL_FILTER_STATE,
  ORDER_ASSIGN_TO_NEW_LISTING,
  ORDER_ASSIGN_TO_NEW_TEAM,
  ORDER_ON_OFFER,
  SALES_COLUMN_ITEMS,
  SALES_FILTER_ITEMS,
} from "../../../helpers/const";
import { QueryState } from "../../inventory/core/_models";
import { ACTIVE_TAB_KEY, SALES_NEST_ROUTES } from "./const";

export interface LayoutContextModel {
  tabValue: any;
  setTabValue: (data: any) => void;

  orders: Array<any>;
  setOrders: (data: any) => void;

  orderState: any;
  setOrderState: (data: any) => void;

  salesFilterHandle: any;
  setSalesFilterHandle: (data: any) => void;

  confirmRowDelete: boolean;
  setConfirmRowDelete: (data: any) => void;

  filterItems: Array<any>;
  setFilterItems: (data: any) => void;

  draggedItems: Array<any>;
  setDraggedItems: (data: any) => void;

  draggedColumns: Array<any>;
  setDraggedColumns: (data: any) => void;

  columnItems: Array<any>;
  setColumnItems: (data: any) => void;

  orderDateRange: any;
  setOrderDateRange: (data: any) => void;

  deliveryDateRange: any;
  setDeliveryDateRange: (data: any) => void;

  fullShow: any;
  setFullShow: (data: any) => void;

  ticketUploadData: any;
  setTicketUploadData: (data: any) => void;

  isSelectAll: boolean;
  setIsSelectAll: (data: any) => void;

  orderAssign: any;
  setOrderAssign: (data: any) => void;

  orderAssignNewTeam: any;
  setOrderAssignNewTeam: (data: any) => void;

  orderOnOffer: any;
  setOrderOnOffer: (data: any) => void;

  teamsOptions: any;
  setTeamsOptions: (data: any) => void;

  sellerLevelOptions: any;
  setSellerLevelOptions: (data: any) => void;

  salesOptions: any;
  setSalesOptions: (data: any) => void;

  orderNewFilterHandle: any;
  setOrderNewFilterHandle: (data: any) => void;

  customNewOrderLoader: any;
  setCustomNewOrderLoader: (data: any) => void;

  query: string;
  setQuery: (data: any) => void;
}

const SalesContext = createContext<LayoutContextModel>({
  tabValue: [],
  setTabValue: (data: any) => {},

  orders: [],
  setOrders: (data: any) => {},

  orderState: [],
  setOrderState: (data: any) => {},

  salesFilterHandle: [],
  setSalesFilterHandle: (data: any) => {},

  confirmRowDelete: false,
  setConfirmRowDelete: (data: any) => {},

  filterItems: [],
  setFilterItems: (data: any) => {},

  draggedItems: [],
  setDraggedItems: (data: any) => {},

  draggedColumns: [],
  setDraggedColumns: (data: any) => {},

  columnItems: [],
  setColumnItems: (data: any) => {},

  orderDateRange: [],
  setOrderDateRange: (data: any) => {},

  deliveryDateRange: [],
  setDeliveryDateRange: (data: any) => {},

  fullShow: [],
  setFullShow: (data: any) => {},

  ticketUploadData: [],
  setTicketUploadData: (data: any) => {},

  isSelectAll: false,
  setIsSelectAll: (data: any) => {},

  orderAssign: {},
  setOrderAssign: (data: any) => {},

  orderAssignNewTeam: {},
  setOrderAssignNewTeam: (data: any) => {},

  teamsOptions: {},
  setTeamsOptions: (data: any) => {},

  salesOptions: {},
  setSalesOptions: (data: any) => {},

  orderNewFilterHandle: {},
  setOrderNewFilterHandle: (data: any) => {},

  customNewOrderLoader: {},
  setCustomNewOrderLoader: (data: any) => {},

  query: "",
  setQuery: (data: any) => {},

  orderOnOffer: {},
  setOrderOnOffer: (data: any) => {},

  sellerLevelOptions: {},
  setSellerLevelOptions: (data: any) => {},
});

const SalesProvider = ({ children }: PropsWithChildren) => {
  const { pathname } = useLocation();

  const globalLayout = useContext(LayoutContext);
  const [tabValue, setTabValue] = useState<any>(
    ACTIVE_TAB_KEY[pathname] ??
      ACTIVE_TAB_KEY[`/sales/${SALES_NEST_ROUTES.PENDING}`]
  );
  const [query, setQuery] = useState<any>("");
  const isGodAdmin = globalLayout?.allowedAccess?.account_info?.is_god_admin;
  const access = globalLayout.allowedAccess?.allowed_access;
  const userSpecific = access?.some(
    (item: any) => item.id === "user-specific-data" && item.status === true
  );

  // FILTER - CONDITION
  let isFilterExistInDB =
    globalLayout?.allowedAccess?.ui_field_settings?.saveSalesFilter;

  let items = getHeaderFilter(
    "saveSalesFilter",
    isFilterExistInDB,
    SALES_FILTER_ITEMS
  );

  if (isGodAdmin) {
    // NOTHING CHANGE
    // items = isFilterExistInDB || JSON.parse(saved) || SALES_FILTER_ITEMS;
  } else if (userSpecific) {
    items = items?.filter(
      (item: any) =>
        item.name !== "Team member" &&
        item.name !== "All teams" &&
        item.name !== "Internal replacement status" &&
        item.name !== "Stores" &&
        item?.name !== "Internal order status" &&
        item?.name !== "Include webstores"
    );
  } else {
    items = items?.filter(
      (item: any) =>
        item.name !== "All teams" &&
        item.name !== "Marketplaces" &&
        item.name !== "Internal replacement status" &&
        item.name !== "Stores" &&
        item?.name !== "Internal order status" &&
        item?.name !== "Include webstores"
    );
  }

  // COLUMN FILTER - CONDITION
  let isColumnFilterExistInDB =
    globalLayout?.allowedAccess?.ui_field_settings?.salesTableColumn;

  let columnsItems = getHeaderFilter(
    "salesTableColumn",
    isColumnFilterExistInDB,
    SALES_COLUMN_ITEMS
  );

  if (isGodAdmin) {
  } else {
    columnsItems = columnsItems
      ?.filter(
        (item: any) =>
          item.name !== "Team name" &&
          item.name !== "Team member" &&
          item.name !== "Marketplace value" &&
          item.name !== "Settlement/net payout value" &&
          item?.name !== "Marketplace" &&
          item?.name !== "Marketplace ID" &&
          item?.name !== "Internal replacement status" &&
          item?.name !== "Internal order status"
      )
      ?.map((item: any) => ({
        ...item,
        name: item?.name === "Listing value" ? "Order value" : item?.name,
      }));
  }

  const [orders, setOrders] = useState([]);
  const [orderState, setOrderState] = useState<QueryState>({});
  const [salesFilterHandle, setSalesFilterHandle] = useState<any>({
    per_page: 20,
    category_ids: null,
    buyer_team_id: null,
  });
  const [confirmRowDelete, setConfirmRowDelete] = useState<boolean>(false);
  const [filterItems, setFilterItems] = useState(() => {
    return items;
  });

  const [draggedItems, setDraggedItems] = useState(filterItems);
  const [draggedColumns, setDraggedColumns] = useState([]);
  const [columnItems, setColumnItems] = useState(() => {
    return columnsItems;
  });

  const [orderDateRange, setOrderDateRange] = useState<any>({});
  const [deliveryDateRange, setDeliveryDateRange] = useState<any>({});
  const [fullShow, setFullShow] = useState<boolean>(false);
  const [ticketUploadData, setTicketUploadData] = useState<any>();
  const [isSelectAll, setIsSelectAll] = useState<boolean>(false);
  const [orderAssign, setOrderAssign] = useState<any>(
    ORDER_ASSIGN_TO_NEW_LISTING
  );
  const [orderAssignNewTeam, setOrderAssignNewTeam] = useState<any>(
    ORDER_ASSIGN_TO_NEW_TEAM
  );
  const [orderOnOffer, setOrderOnOffer] = useState(ORDER_ON_OFFER);

  const [teamsOptions, setTeamsOptions] = useState<any>();
  const [sellerLevelOptions, setSellerLevelOptions] = useState<any>();
  const [salesOptions, setSalesOptions] = useState<any>();
  const [orderNewFilterHandle, setOrderNewFilterHandle] = useState<any>(
    NEW_ORDER_INITIAL_FILTER_STATE
  );
  const [customNewOrderLoader, setCustomNewOrderLoader] = useState(true);

  const value: LayoutContextModel = {
    tabValue,
    setTabValue,

    orders,
    setOrders,

    orderState,
    setOrderState,

    salesFilterHandle,
    setSalesFilterHandle,

    confirmRowDelete,
    setConfirmRowDelete,

    filterItems,
    setFilterItems,

    draggedItems,
    setDraggedItems,

    draggedColumns,
    setDraggedColumns,

    columnItems,
    setColumnItems,

    orderDateRange,
    setOrderDateRange,

    deliveryDateRange,
    setDeliveryDateRange,

    fullShow,
    setFullShow,

    ticketUploadData,
    setTicketUploadData,

    isSelectAll,
    setIsSelectAll,

    orderAssign,
    setOrderAssign,

    teamsOptions,
    setTeamsOptions,

    salesOptions,
    setSalesOptions,

    orderNewFilterHandle,
    setOrderNewFilterHandle,

    customNewOrderLoader,
    setCustomNewOrderLoader,

    orderAssignNewTeam,
    setOrderAssignNewTeam,

    query,
    setQuery,

    orderOnOffer,
    setOrderOnOffer,

    sellerLevelOptions,
    setSellerLevelOptions,
  };

  return (
    <SalesContext.Provider value={value}>{children}</SalesContext.Provider>
  );
};

export { SalesContext, SalesProvider };
