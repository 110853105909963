/* eslint-disable jsx-a11y/alt-text */
import { isArray, size } from "lodash";
import AdditionalTemplate from "pages/Sales/component/AdditionalTemplate";
import React, { useContext, useEffect, useState } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { pdfjs } from "react-pdf";
import { Tooltip } from "react-tooltip";
import CloneSVG from "../../../assets/media/icons/other_icons/Clone.svg";
import EyeSVG from "../../../assets/media/icons/other_icons/Eye.svg";
import mobileSVG from "../../../assets/media/icons/other_icons/mobile.svg";
import pin from "../../../assets/media/icons/other_icons/pin.svg";
import remove from "../../../assets/media/icons/standard_icons/cross.svg";
import { LayoutContext } from "../../../components/core/LayoutProvider";
import { IKTSVG } from "../../../components/ui/IKTSVG";
import {
  checkingValidUrlForBoth,
  getExtension,
  isValidUrl,
  uploadFileWithPreAssignedURL,
} from "../../../helpers/AssetHelpers";
import { pdfViewer } from "../../../helpers/Functions";
import { AddInventoryContext } from "../core/AddInventoryProvider";
import {
  TicketError,
  copy,
  visibilityMeterCalculationForKeyBoard,
} from "../core/_functions";
import MobileUrlLink from "./MobileUrlLink";
import ETicketAccordionHeader from "./eticket_component/ETicketAccordionHeader";
import ETicketLeftPanelWrapper from "./eticket_component/ETicketLeftPanelWrapper";
import PreUpload from "./eticket_component/PreUpload";

// window.Buffer = window.Buffer || require("buffer").Buffer;
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const ETicketUploadKeyboard = ({
  isOpen,
  instructionLetterTemplates,
  closePopUp,
  setIsPopUpOpen,
}: any) => {
  const layout = useContext(AddInventoryContext);
  const globalLayout = useContext(LayoutContext);
  const [files, setFiles] = useState<any>([]);
  const [ticketFiles, setTicketFiles] = useState<any>([]);
  const [mobileLinkResponse, setMobileLinkResponse] = useState<any>();

  const initialTemplateState = {
    templateView: false,
    data: undefined,
    inputs: {},
  };

  const [templateState, setTemplateState] = useState<any>(initialTemplateState);
  const [isCopied, setIsCopied] = useState<any>({
    id: "",
    value: "",
  });

  function getCategoryValue(record: any, mainEvent: any) {
    return record?.category?.id
      ? record?.category
      : mainEvent?.categories &&
          mainEvent?.categories.find(
            (category: any) => Number(category.id) === Number(record?.category)
          );
  }

  function getSectionValue(record: any, mainEvent: any) {
    return record?.section && record?.section?.id
      ? record?.section
      : record?.section &&
          mainEvent?.categories &&
          Array.isArray(mainEvent?.categories) &&
          mainEvent?.categories
            .find(
              (category: any) =>
                Number(category.id) === Number(record?.category)
            )
            ?.sections?.find(
              (item: any) => Number(item?.id) === Number(record?.section)
            );
  }
  const deletePdfRightSide = (
    file: any,
    index: any,
    eventRecordIndex: number,
    eventIndex: number
  ) => {
    // setList((prev: any) => {
    let updatedEvent: any = isOpen?.event.map(
      (event: any, inEventIndex: number) => {
        if (inEventIndex === eventIndex) {
          return {
            ...event,
            eventRecords: event.eventRecords.map(
              (eventRecord: any, inEventRecordIndex: any) => {
                if (index === "additionalFile") {
                  delete eventRecord?.additionalFile;

                  return { ...eventRecord };
                } else if (inEventRecordIndex === eventRecordIndex) {
                  return {
                    ...eventRecord,
                    // ticketFiles: eventRecord.ticketFiles?.filter(
                    //   (ticketFile: any, ticketFileIndex: any) => {
                    //     if (ticketFileIndex !== index) {
                    //       return ticketFiles;
                    //     }
                    //   }
                    // ),
                    ticketFiles:
                      eventRecord?.quantity < eventRecord.ticketFiles?.length
                        ? eventRecord.ticketFiles?.filter(
                            (ticketFile: any, ticketFileIndex: any) => {
                              if (ticketFileIndex !== index) {
                                return ticketFiles;
                              }
                            }
                          )
                        : eventRecord.ticketFiles?.map(
                            (ticketFile: any, ticketFileIndex: any) => {
                              if (ticketFileIndex === index) {
                                return {};
                              } else {
                                return ticketFile;
                              }
                            }
                          ),
                  };
                } else {
                  return eventRecord;
                }
              }
            ),
          };
        } else {
          return event;
        }
      }
    );
    setIsPopUpOpen((pre: any) => ({
      ...pre,
      event: updatedEvent,
    }));
    layout?.setKeyboardField(updatedEvent?.[0]);

    setFiles((prev: any) => {
      return prev.map((inFile: any) => {
        if (inFile.id === file.id) {
          return Object.assign(inFile, { assigned: 0 });
        } else {
          return inFile;
        }
      });
    });
  };

  // Drag n Drop Functionality
  // a little function to help us with reordering the result
  const reorder = (list: any, startIndex: any, endIndex: any) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  /**
   * Moves an item from one list to another list.
   */
  const move = (
    source: any,
    destination: any,
    droppableSource: any,
    droppableDestination: any
  ) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);
    destClone.splice(droppableDestination.index, 0, removed);

    const result: any = {};

    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
  };

  var dragArea = document.querySelector<HTMLElement>("#drag_here");
  const onDragStart = () => {
    // for (var i = 0; i < dragArea.length; i++) {
    //   dragArea[i].style.borderColor = "#6F6CFF";
    // }
    // console.log(dragArea, "00000");
    // if (dragArea) {
    //   setTimeout(() => {
    //     dragArea?.classList.add("border-green-500");
    //   }, 0);
    // }
  };
  const markAssignedZero = (id: any) => {
    files.map((file: any, index: any) => {
      if (file?.id === id) {
        file.assigned = 0;
      }
    });
  };

  const uploadMobileLinkAWS = async (
    eventIndex: any,
    eventRecordIndex: any,
    arrItemIndex: any,
    finalData: any,
    dropData: any
  ) => {
    let ticketData = finalData;

    try {
      // const data = await s3Upload.uploadFile(dropData);
      const data = await uploadFileWithPreAssignedURL(
        dropData,
        `ticket-files/mobile-links/2`
      );
      setMobileLinkResponse((current: any) =>
        current && size(current) > 0
          ? [
              ...current,
              {
                eventIndex,
                eventRecordIndex,
                arrItemIndex,
                ticketData,
                url: data?.location,
              },
            ]
          : [
              {
                eventIndex,
                eventRecordIndex,
                arrItemIndex,
                ticketData,
                url: data?.location,
              },
            ]
      );
    } catch (error) {
      console.error("Error uploading file to AWS S3:", error);
    }
  };

  const deletePDFFromFiles = (id: any) => {
    markAssignedZero(id);
  };

  let onDragEnd = (result: any) => {
    const { destination, source } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      if (destination.droppableId === "eventTicketUploadedFiles") {
        const items = reorder(files, source.index, destination.index);
        setFiles(items);
      } else {
        const items = reorder(ticketFiles, source.index, destination.index);

        let updatedEvent: any = isOpen?.event?.map(
          (event: any, eventIndex: number) => {
            return {
              ...event,
              eventRecords: event["eventRecords"].map(
                (eventRecord: any, evnetRecordIndex: number) => {
                  if (eventRecord.ticketFiles) {
                    return {
                      ...eventRecord,
                      ticketFiles: reorder(
                        eventRecord?.ticketFiles,
                        source.index,
                        destination.index
                      ),
                    };
                  } else {
                    return {
                      ...eventRecord,
                    };
                  }
                }
              ),
            };
          }
        );

        setIsPopUpOpen((pre: any) => ({
          ...pre,
          event: updatedEvent,
        }));
        // layout?.setKeyboardField(updatedEvent?.[0]);
      }
    } else if (destination.droppableId === "eventTicketUploadedFiles") {
      // drag from right to left
      let sourceIndex = source.droppableId.split("-");

      let sourceFiles =
        isOpen?.event[sourceIndex[0]]["eventRecords"][sourceIndex[1]]
          ?.ticketFiles;

      sourceFiles.map((file: any, index: any) => {
        if (source.index === index) {
          file.assigned = 0;
        }
      });

      let updatedSourceFiles = sourceFiles.filter(
        (file: any, index: any) => source.index !== index
      );
      let updatedEvent: any = isOpen?.event?.map(
        (event: any, eventIndex: number) => {
          if (parseInt(sourceIndex[0]) === eventIndex) {
            return {
              ...event,
              eventRecords: event["eventRecords"].map(
                (eventRecord: any, evnetRecordIndex: number) => {
                  if (parseInt(sourceIndex[1]) === evnetRecordIndex) {
                    return {
                      ...eventRecord,
                      ticketFiles: updatedSourceFiles,
                    };
                  } else {
                    return eventRecord;
                  }
                }
              ),
            };
          } else {
            return event;
          }
        }
      );
      setIsPopUpOpen((pre: any) => ({
        ...pre,
        event: updatedEvent,
      }));
      // layout.setKeyboardField(updatedEvent?.[0]);
    } else if (source.droppableId === "eventTicketUploadedFiles") {
      let destinationIndexs = destination.droppableId.split("-");

      let destinationFiles: any;
      // let destinationFiles =
      //   destinationIndexs[2] === "additionalFile"
      //     ? isOpen?.event?.[destinationIndexs[0]]?.["eventRecords"]?.[
      //         destinationIndexs[1]
      //       ]
      //     : isOpen?.event?.[destinationIndexs[0]]?.["eventRecords"][
      //         destinationIndexs[1]
      //       ]?.ticketFiles?.[destinationIndexs[2]];

      const eventRecord =
        isOpen?.event?.[destinationIndexs[0]]?.eventRecords?.[
          destinationIndexs[1]
        ];

      if (destinationIndexs[2] === "additionalFile") {
        eventRecord.additionalFile ||= [{}];
        destinationFiles = eventRecord.additionalFile?.[0];
      } else {
        destinationFiles = eventRecord?.ticketFiles?.[destinationIndexs[2]];
      }

      const move_result = move(
        files,
        isArray(destinationFiles) ? destinationFiles : [],
        source,
        destination
      );

      const markAssigned = () => {
        files.map((file: any, index: any) => {
          if (source.index === index) {
            file.assigned = 1;
          }
        });
      };

      // LEFT TO RIGHT
      // let updateEvent: any = isOpen?.;
      setIsPopUpOpen((pre: any) => ({
        ...pre,
        event: pre?.event?.map((event: any, eventIndex: number) => {
          if (parseInt(destinationIndexs[0]) === eventIndex) {
            return {
              ...event,
              eventRecords: event["eventRecords"].map(
                (eventRecord: any, evnetRecordIndex: number) => {
                  if (
                    parseInt(destinationIndexs[1]) === evnetRecordIndex &&
                    destinationIndexs[2] === "additionalFile"
                  ) {
                    // if (
                    //   move_result[destination.droppableId]?.[0]?.type ===
                    //   "application/pdf"
                    // ) {
                    markAssigned();
                    if (
                      destinationIndexs[2] === "additionalFile" &&
                      eventRecord?.addtionalFile
                    ) {
                      deletePDFFromFiles(eventRecord?.addtionalFile?.id);
                    }
                    return {
                      ...eventRecord,
                      additionalFile: move_result[destination.droppableId]?.[0],
                    };
                    // }
                  } else if (
                    parseInt(destinationIndexs[1]) === evnetRecordIndex
                  ) {
                    return {
                      ...eventRecord,
                      ticketFiles: eventRecord?.ticketFiles?.map(
                        (arrItem: any, arrIndex: number) => {
                          if (
                            parseInt(destinationIndexs[2]) === arrIndex &&
                            eventRecord?.ticketType?.id === "mobile-link" &&
                            getExtension(
                              move_result[destination.droppableId]?.[0]?.path
                            ) === "pkpass"
                          ) {
                            arrItem && deletePDFFromFiles(arrItem?.id);
                            markAssigned();
                            let final = {
                              ...move_result[destination.droppableId]?.[0],
                            };
                            uploadMobileLinkAWS(
                              eventIndex,
                              evnetRecordIndex,
                              arrItem,
                              final,
                              move_result[destination.droppableId]?.[0]
                            );
                            return final;
                          }
                          // ETICKET / MOBILE
                          else if (
                            (eventRecord?.ticketType?.id === "pdf" ||
                              eventRecord?.ticketType?.id === "eticket" ||
                              eventRecord?.ticketType?.id === "mobile-qr") &&
                            parseInt(destinationIndexs[2]) === arrIndex &&
                            move_result[destination.droppableId]?.[0]?.type ===
                              "application/pdf"
                          ) {
                            arrItem && deletePDFFromFiles(arrItem?.id);
                            markAssigned();
                            return move_result[destination.droppableId]?.[0];
                          } else {
                            return arrItem;
                          }
                        }
                      ),
                      // ticketFiles: move_result[destination.droppableId],
                    };
                  } else {
                    return eventRecord;
                  }
                }
              ),
            };
          } else {
            return event;
          }
        }),
      }));
    } else {
      let sourceIndexs = source.droppableId.split("-");
      let sourceFiles =
        isOpen?.event?.[sourceIndexs[0]]?.["eventRecords"][sourceIndexs[1]]
          ?.ticketFiles;

      let destinationIndexs = destination.droppableId.split("-");
      let destinationFiles =
        isOpen?.event?.[destinationIndexs[0]]?.["eventRecords"][
          destinationIndexs[1]
        ]?.ticketFiles;

      const move_result = move(
        isArray(sourceFiles) ? sourceFiles : [],
        isArray(destinationFiles) ? destinationFiles : [],
        source,
        destination
      );

      const updatedEvents = isOpen?.event?.map(
        (event: any, eventIndex: number) => {
          if (parseInt(sourceIndexs[0]) === eventIndex) {
            return {
              ...event,
              eventRecords: event["eventRecords"].map(
                (eventRecord: any, evnetRecordIndex: number) => {
                  if (parseInt(sourceIndexs[1]) === evnetRecordIndex) {
                    return {
                      ...eventRecord,
                      ticketFiles: move_result[source.droppableId],
                    };
                  } else if (
                    parseInt(destinationIndexs[1]) === evnetRecordIndex
                  ) {
                    return {
                      ...eventRecord,
                      ticketFiles: move_result[destination.droppableId],
                    };
                  } else {
                    return eventRecord;
                  }
                }
              ),
            };
          } else if (parseInt(destinationIndexs[0]) === eventIndex) {
            return {
              ...event,
              eventRecords: event["eventRecords"].map(
                (eventRecord: any, evnetRecordIndex: number) => {
                  if (parseInt(destinationIndexs[1]) === evnetRecordIndex) {
                    return {
                      ...eventRecord,
                      ticketFiles: move_result[destination.droppableId],
                    };
                  }
                }
              ),
            };
          } else if (parseInt(destinationIndexs[0]) === eventIndex) {
            return {
              ...event,
              eventRecords: event["eventRecords"].map(
                (eventRecord: any, evnetRecordIndex: number) => {
                  if (parseInt(destinationIndexs[1]) === evnetRecordIndex) {
                    return {
                      ...eventRecord,
                      ticketFiles: move_result[destination.droppableId],
                    };
                  } else if (parseInt(sourceIndexs[1]) === evnetRecordIndex) {
                    return {
                      ...eventRecord,
                      ticketFiles: move_result[source.droppableId],
                    };
                  } else {
                    return eventRecord;
                  }
                }
              ),
            };
          } else {
            return event;
          }
        }
      );
      setIsPopUpOpen((pre: any) => ({
        ...pre,
        event: updatedEvents,
      }));
      // layout.setKeyboardField(updatedEvents?.[0]);
    }
    // for (var i = 0; i < dragArea.length; i++) {
    //   dragArea[i].style.borderColor = "inherit";
    // }
  };

  const handleEventConfirm = () => {
    const updatedEvent: any = isOpen?.event?.map((event: any) => {
      return {
        ...event,
        eventRecords: event?.eventRecords?.map(
          (eventRecord: any, index: any) => {
            setFiles([]);
            layout.setETicketDataField({
              btnEvent: {},
              isOpen: false,
            });
            const allTicketUploaded =
              eventRecord?.quantity &&
              eventRecord?.ticketFiles?.filter(
                (obj: any) => JSON.stringify(obj) !== "{}"
              )?.length > 0 &&
              Number(eventRecord?.quantity) ===
                eventRecord?.ticketFiles?.filter(
                  (obj: any) => JSON.stringify(obj) !== "{}"
                )?.length;

            const shouldClearQuantityAvailable =
              (eventRecord.errors?.category !== "" &&
                eventRecord.errors?.category !== undefined) ||
              (eventRecord.errors?.face_value !== "" &&
                eventRecord.errors?.face_value !== undefined) ||
              (eventRecord.errors?.sell_price !== "" &&
                eventRecord.errors?.sell_price !== undefined);

            let visibility: any = visibilityMeterCalculationForKeyBoard(
              event,
              eventRecord
            );

            return {
              ...eventRecord,
              ...(shouldClearQuantityAvailable
                ? {
                    errors: {
                      ...eventRecord.errors,
                      quantity_available: "",
                    },
                  }
                : { errors: {} }),
              selected: false,
              oldVisibility:
                eventRecord?.visibility === visibility
                  ? eventRecord?.oldVisibility
                  : eventRecord?.visibility,
              visibility: visibility,
              // ticketsInHands: allTicketUploaded ? true : false,
            };
          }
        ),
      };
    });
    setIsPopUpOpen((pre: any) => ({
      isOpen: false,
      event: updatedEvent,
    }));

    layout.setKeyboardField(updatedEvent?.[0]);
  };

  const getUploadedListing = () => {
    let count = 0;
    let totalListing = 0;
    isOpen?.event?.forEach((event: any, eventIndex: number) => {
      event?.["eventRecords"]?.map(
        (eventRecord: any, eventRecordIndex: any) => {
          if (
            ["eticket", "pdf", "mobile-qr", "mobile-link"].includes(
              eventRecord?.ticketType?.id
            )
          ) {
            totalListing++;

            const ticketFiles = eventRecord?.ticketFiles || {};
            const nonEmptyTicketFiles = Object.values(ticketFiles).filter(
              (obj: any) => JSON.stringify(obj) !== "{}"
            );
            const mobileLinkUploaded = nonEmptyTicketFiles?.every((obj: any) =>
              eventRecord?.pkpass
                ? obj?.path && obj?.isValid && obj?.mobileLink
                : obj?.isValid && obj?.mobileLink
            );

            const nonEmptyFilesCount = nonEmptyTicketFiles?.length;
            const requiredQuantity = eventRecord?.quantity || 0;

            if (
              eventRecord?.ticketType?.id === "mobile-link" &&
              mobileLinkUploaded &&
              nonEmptyFilesCount >= requiredQuantity &&
              nonEmptyFilesCount > 0
            ) {
              count++;
            } else if (
              eventRecord?.ticketType?.id !== "mobile-link" &&
              nonEmptyFilesCount >= requiredQuantity &&
              nonEmptyFilesCount > 0
            ) {
              count++;
            }
          }
        }
      );
    });
    return [count, totalListing];
  };

  function confirmButtonDisabled(flag: any) {
    let mainFlag = true;

    if (!flag) {
      isOpen?.event?.forEach((event: any, eventIndex: number) => {
        event?.["eventRecords"]?.map(
          (eventRecord: any, eventRecordIndex: any) => {
            if (
              eventRecord?.template &&
              eventRecord?.template?.id &&
              eventRecord?.template?.id !== "0" &&
              eventRecord?.template?.value !== 0
            ) {
              const allKeysHaveValues: any = eventRecord?.templateKeys.every(
                (key: number) => {
                  return (
                    eventRecord?.inputs?.[key] !== "" &&
                    eventRecord?.inputs?.[key] !== undefined &&
                    eventRecord?.inputs?.[key] !== null
                  );
                }
              );

              mainFlag = !allKeysHaveValues;
            } else {
              mainFlag = false;
            }
          }
        );
      });
    }
    return mainFlag;
  }

  useEffect(() => {
    if (size(mobileLinkResponse) > 0) {
      setIsPopUpOpen((prev: any) => {
        return {
          ...prev,
          event: prev?.event?.map((event: any, eventIndex: number) => {
            let mobileEventIndex = mobileLinkResponse?.find(
              (item: any) => item?.eventIndex === eventIndex
            );

            if (eventIndex === mobileEventIndex?.eventIndex) {
              return {
                ...event,
                eventRecords: event?.eventRecords?.map(
                  (record: any, recordIndex: number) => {
                    let eventRecordIndex = mobileLinkResponse?.find(
                      (item: any) => item?.eventRecordIndex === recordIndex
                    );

                    if (eventRecordIndex) {
                      return {
                        ...record,
                        ticketFiles: record?.ticketFiles?.map(
                          (item: any, itemIndex: number) => {
                            let ticketID = mobileLinkResponse?.find(
                              (ticketItem: any) =>
                                ticketItem?.ticketData?.id === item?.id
                            );
                            if (ticketID?.ticketData?.id) {
                              setMobileLinkResponse((current: any) => {
                                return current?.filter(
                                  (mobileResponse: any) =>
                                    mobileResponse?.ticketData?.id !== item?.id
                                );
                              });
                              return {
                                ...item,
                                mobileLink: ticketID?.url,
                                isValid: true,
                              };
                            } else return item;
                          }
                        ),
                      };
                    } else return record;
                  }
                ),
              };
            } else return event;
          }),
        };
      });
    }
  }, [mobileLinkResponse]);

  // HANDLE PKPASS TOGGLE
  const handlePKPASS = (e: any, eventIndex: any, recordIndex: any) => {
    setIsPopUpOpen((prev: any) => {
      return {
        ...prev,
        event: prev?.event?.map((inEvent: any, eIndex: any) => {
          if (eIndex === eventIndex) {
            return {
              ...inEvent,
              eventRecords: inEvent?.eventRecords?.map(
                (record: any, rIndex: number) => {
                  if (rIndex === recordIndex) {
                    // Replace ticket files without a path with empty objects if the checkbox is checked
                    const updatedTicketFiles = record?.ticketFiles?.map(
                      (obj: any) => ({})
                    );
                    return {
                      ...record,
                      pkPass: e?.target?.checked,
                      ticketFiles: updatedTicketFiles,
                      separate_links: record?.separate_links
                        ? false
                        : undefined,
                    };
                  } else {
                    return record;
                  }
                }
              ),
            };
          } else {
            return inEvent;
          }
        }),
      };
    });
    files.map((file: any, index: any) => {
      file.assigned = 0;
    });
  };

  // HANDLE Separate links for ios/android? TOGGLE
  const handleSeparateLinks = (e: any, eventIndex: any, recordIndex: any) => {
    setIsPopUpOpen((prev: any) => {
      return {
        ...prev,
        event: prev?.event?.map((inEvent: any, eIndex: any) => {
          if (eIndex === eventIndex) {
            return {
              ...inEvent,
              eventRecords: inEvent?.eventRecords?.map(
                (record: any, rIndex: number) => {
                  if (rIndex === recordIndex) {
                    const updatedTicketFiles =
                      record?.ticketFiles?.length > 0
                        ? record?.ticketFiles.map(() => ({}))
                        : record?.ticketFiles;
                    // const mobileLinkId: any = `mobile-link-${recordIndex}-${ticketFileIndex}`;
                    // const mobileLinkElement: any = document.getElementById(
                    //   mobileLinkId
                    // ) as HTMLInputElement | null;

                    // const mobileLinkId_separate: any = `mobile-link-${recordIndex}-${ticketFileIndex}-separate`;
                    // const mobileLinkElement_separate: any =
                    //   document.getElementById(
                    //     mobileLinkId_separate
                    //   ) as HTMLInputElement | null;
                    // if (mobileLinkElement || mobileLinkElement_separate) {
                    //   mobileLinkElement.value = "";
                    //   mobileLinkElement_separate.value = "";
                    // }

                    return {
                      ...record,
                      separate_links: e?.target?.checked,
                      ticketFiles: updatedTicketFiles,
                      // separateTicketFiles: separateTicketFiles,
                    };
                  } else {
                    return record;
                  }
                }
              ),
            };
          } else {
            return inEvent;
          }
        }),
      };
    });
  };

  // REMOVE DRAGGABLE MOBILE LINK FILE
  const handleMobileLinkRemove = (
    eventId: any,
    recordId: any,
    ticketFileIndex: any
  ) => {
    setIsPopUpOpen((prev: any) => {
      return {
        ...prev,
        event: prev?.event?.map((events: any, eventIndex: number) => {
          if (eventIndex === eventId) {
            return {
              ...events,
              eventRecords: events?.eventRecords?.map(
                (record: any, recordIndex: number) => {
                  if (recordIndex === recordId) {
                    return {
                      ...record,
                      ticketFiles: record?.ticketFiles?.map(
                        (ticket: any, ticketIndex: number) => {
                          if (ticketIndex === ticketFileIndex) {
                            deletePDFFromFiles(ticket?.id);
                            delete ticket?.isValid;
                            return {};
                          } else return ticket;
                        }
                      ),
                    };
                  } else return record;
                }
              ),
            };
          } else return events;
        }),
      };
    });
  };

  // MOBILE LINK HANDLE
  const handleMobileLinkChange = (
    eventId: any,
    recordId: any,
    event: any,
    isConfirm: any,
    isDelete: any,
    ticketFileIndex: any,
    ticketType: any
  ) => {
    const mobileLinkId: any = `mobile-link-${recordId}-${ticketFileIndex}`;
    const mobileLinkElement: any = document.getElementById(
      mobileLinkId
    ) as HTMLInputElement | null;

    const mobileLinkId_separate: any = `mobile-link-${recordId}-${ticketFileIndex}-separate`;
    const mobileLinkElement_separate: any = document.getElementById(
      mobileLinkId_separate
    ) as HTMLInputElement | null;

    setIsPopUpOpen((prev: any) => {
      return {
        ...prev,
        event: prev?.event?.map((events: any, eventIndex: number) => {
          if (eventIndex === eventId) {
            return {
              ...events,
              eventRecords: events?.eventRecords?.map(
                (record: any, recordIndex: number) => {
                  if (recordIndex === recordId) {
                    let ticketFiles = record?.ticketFiles?.map(
                      (ticket: any, ticketIndex: number) => {
                        if (ticketIndex === ticketFileIndex) {
                          if (isDelete) {
                            if (record?.separate_links) {
                              delete ticket?.isValid;
                              if (ticketType === "android") {
                                delete ticket?.isValidAndroid;
                                if (mobileLinkElement) {
                                  mobileLinkElement.value = "";
                                }
                                const final = {
                                  ...ticket,
                                  mobileLink: {
                                    ...ticket?.mobileLink,
                                    android: "",
                                  },
                                };
                                return final;
                              } else if (ticketType === "ios") {
                                delete ticket?.isValidIos;
                                if (mobileLinkElement_separate) {
                                  mobileLinkElement_separate.value = "";
                                }
                                const final = {
                                  ...ticket,
                                  mobileLink: {
                                    ...ticket?.mobileLink,
                                    ios: "",
                                  },
                                };
                                return final;
                              }
                              deletePDFFromFiles(ticket?.id);
                              if (
                                !ticket?.mobileLink?.android &&
                                !ticket?.mobileLink?.ios
                              ) {
                                return {};
                              }
                              // return ticket;
                            } else {
                              delete ticket?.isValid;
                              deletePDFFromFiles(ticket?.id);
                              if (mobileLinkElement) {
                                mobileLinkElement.value = "";
                              }
                              return {};
                            }
                          } else if (isConfirm) {
                            if (record?.separate_links) {
                              return {
                                ...ticket,
                                isValidAndroid: isValidUrl(
                                  ticket?.mobileLink?.android
                                ),
                                isValidIos: isValidUrl(ticket?.mobileLink?.ios),
                                isValid: checkingValidUrlForBoth(
                                  ticket?.mobileLink
                                ),
                              };
                            }
                            return {
                              ...ticket,
                              isValid: isValidUrl(ticket?.mobileLink),
                            };
                          } else {
                            delete ticket?.isValid;
                            const mobileLinkData = {
                              android:
                                ticketType === "android"
                                  ? event?.target?.value
                                  : ticket?.mobileLink?.android || null,
                              ios:
                                ticketType === "ios"
                                  ? event?.target?.value
                                  : ticket?.mobileLink?.ios || null,
                            };

                            return {
                              ...ticket,
                              mobileLink: record?.separate_links
                                ? mobileLinkData
                                : event?.target?.value,
                            };
                          }
                        } else return ticket;
                      }
                    );

                    let final = {
                      ...record,
                      ticketFiles: ticketFiles,
                      isFull: ticketFiles?.every((item: any) => item?.isValid),
                    };
                    return final;
                  } else return record;
                }
              ),
            };
          } else return events;
        }),
      };
    });
  };

  function uploadTicketTooltipWithAssignTickets(
    eventIndex: any,
    eventRecordIndex: any,
    eventRecord: any,
    events: any
  ) {
    // Extract ticket type
    const ticketType = eventRecord?.ticketType?.id;

    // Determine tooltip based on ticket type
    let tooltip = "";
    if (ticketType === "mobile-qr") {
      tooltip = "Mobile Screenshot";
    } else if (ticketType === "pdf") {
      tooltip = "E-ticket";
    } else if (ticketType === "mobile-link") {
      tooltip = "Mobile Link/PKPASS";
    }

    // Count assigned tickets
    const assignedTicketsCount =
      eventRecord?.ticketFiles?.filter(
        (obj: any) => JSON.stringify(obj) !== "{}"
      )?.length ?? 0;

    // Total tickets
    const totalTickets = eventRecord?.quantity ?? 0;

    // Compose tooltip text
    const tooltipText = `<div class="text-center">${tooltip}<br>${assignedTicketsCount} of ${totalTickets} tickets assigned</div>`;

    return tooltipText;
  }

  // HANDLE TEMPLATE CHANGE
  const handleChangeTemplate = (
    template: any,
    eventRecordIndex: number,
    eventId: number
  ) => {
    setIsPopUpOpen((prev: any) => {
      return {
        ...prev,
        event: prev?.event?.map((events: any, eventIndex: number) => {
          if (eventIndex === eventId) {
            return {
              ...events,
              eventRecords: events?.eventRecords?.map(
                (record: any, recordIndex: number) => {
                  if (recordIndex === eventRecordIndex) {
                    const regex = /\[([^\]:]*):?DYNAMIC_CONTENT\]/g;
                    const foundKeys = new Set();
                    let match;
                    while (
                      (match = regex.exec(template?.html_content)) !== null
                    ) {
                      // Trim any trailing whitespace and ensure no colon is included
                      foundKeys.add((match[1] || "Dynamic content").trim());
                    }
                    return {
                      ...record,
                      template: template,
                      templateKeys: Array.from(foundKeys),
                      inputs: {},
                    };
                  } else return record;
                }
              ),
            };
          } else return events;
        }),
      };
    });
  };

  const handleInputChanges = (
    e: any,
    eventRecordIndex: any,
    eventIndex: any,
    key: any
  ) => {
    setIsPopUpOpen((prev: any) => {
      return {
        ...prev,
        event: prev?.event?.map((events: any, inEventIndex: number) => {
          if (inEventIndex === eventIndex) {
            return {
              ...events,
              eventRecords: events?.eventRecords?.map(
                (record: any, recordIndex: number) => {
                  if (recordIndex === eventRecordIndex) {
                    let inputs = {
                      ...record?.inputs,

                      [key]: e?.target?.value,
                    };
                    setTemplateState((pre: any) => {
                      return {
                        ...pre,
                        inputs: inputs,
                      };
                    });
                    return {
                      ...record,
                      inputs: inputs,
                    };
                  } else {
                    return record;
                  }
                }
              ),
            };
          } else return events;
        }),
      };
    });
  };

  return (
    <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
      <div
        className={`offcanvas show offcanvas-end upload-ticket-popup fixed bottom-0 top-[2.75rem] md:top-[3.75rem] right-0 w-[calc(100%-3.75rem)] shadow-lg bg-clip-padding outline-none transition duration-300 ease-in-out font-medium bg-black/70 ${
          isOpen?.isOpen === true ? "vis" : "hid"
        } `}
        tabIndex={-1}
        id="ppeRightCanvas"
      >
        <div
          className={`relative flex flex-col  h-full bg-white ml-auto max-w-[93rem] w-[95%]`}
        >
          <div className="offcanvas-header flex items-center justify-between border-t border-b">
            <h5
              className="offcanvas-title mb-0 leading-normal text-sm15 font-semibold py-2 px-5"
              id="offcanvasRightLabel"
            >
              Upload tickets
            </h5>
            <button
              type="button"
              className="flex items-center justify-center w-9 border-l box-content h-full group"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              onClick={closePopUp}
            >
              <IKTSVG
                className="fill-violet-800 group-hover:fill-indigo-500 h-2.5 w-2.5 transition"
                path={remove}
                svgClassName="w-[.5625rem] h-[.5938rem]"
              />
            </button>
          </div>
          {/* PDF VIEWER - LEFT PANEL*/}
          <div className="offcanvas-body h-full w-full flex flex-col lg2:flex-row overflow-x-auto scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full">
            <ETicketLeftPanelWrapper
              files={files}
              setFiles={setFiles}
              isOpen={isOpen}
            />
            {templateState?.templateView && (
              <AdditionalTemplate
                template={templateState?.data}
                templateInputes={templateState?.inputs}
                handleOnClose={(data: any) => {
                  setTemplateState(initialTemplateState);
                }}
              />
            )}

            <div
              className={`w-full  px-4 py-2 lg2:py-4 scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full 
                 lg2:w-1/2
              `}
            >
              {/* RIGHT SIDE - HEADER */}
              {isOpen?.isOpen &&
                isOpen?.event?.map((event: any, eventIndex: any) => {
                  return (
                    <React.Fragment key={eventIndex}>
                      <ETicketAccordionHeader
                        event={event}
                        eventIndex={eventIndex}
                      />

                      <div
                        id={`eTicketEvent${eventIndex}`}
                        className="accordion-collapse collapse show"
                        data-te-collapse-item
                        data-te-collapse-show
                      >
                        {/* RIGHT INNER DRAG */}
                        {event["eventRecords"].map(
                          (eventRecord: any, eventRecordIndex: any) => {
                            if (
                              eventRecord?.ticketType?.id === "eticket" ||
                              eventRecord?.ticketType?.id === "pdf" ||
                              eventRecord?.ticketType?.id === "mobile-qr" ||
                              eventRecord?.ticketType?.id === "mobile-link"
                            ) {
                              const isAllFilesUploaded =
                                isOpen?.event?.[eventIndex]?.eventRecords[
                                  eventRecordIndex
                                ]?.ticketFiles &&
                                Object.keys(
                                  isOpen?.event?.[eventIndex]?.eventRecords[
                                    eventRecordIndex
                                  ]?.ticketFiles
                                ).length &&
                                Object.keys(
                                  isOpen?.event?.[eventIndex]?.eventRecords[
                                    eventRecordIndex
                                  ]?.ticketFiles?.filter((obj: any) => {
                                    if (obj?.mobileLink) {
                                      return obj?.isValid;
                                    } else {
                                      return JSON.stringify(obj) !== "{}";
                                    }
                                  })
                                ).length >=
                                  (eventRecord?.quantity
                                    ? eventRecord?.quantity
                                    : 0) &&
                                Object.keys(
                                  isOpen?.event?.[eventIndex]?.eventRecords[
                                    eventRecordIndex
                                  ]?.ticketFiles?.filter((obj: any) => {
                                    if (obj?.mobileLink) {
                                      return obj?.isValid;
                                    } else {
                                      return JSON.stringify(obj) !== "{}";
                                    }
                                  })
                                ).length > 0
                                  ? true
                                  : false;

                              let ticketFileIndex =
                                eventRecord?.ticketFiles?.findIndex(
                                  (item: any) => {
                                    return !item?.path;
                                  }
                                );

                              const getIsDropDisabled = () => {
                                if (
                                  eventRecord?.ticketType?.id == "mobile-link"
                                ) {
                                  return !eventRecord?.pkPass;
                                } else {
                                  return ticketFileIndex === -1;
                                }
                              };

                              return (
                                <React.Fragment key={eventRecordIndex}>
                                  <Droppable
                                    droppableId={`${eventIndex}-${eventRecordIndex}-${ticketFileIndex}`}
                                    key={`${eventIndex}-${eventRecordIndex}-${ticketFileIndex}`}
                                    isDropDisabled={getIsDropDisabled()}
                                  >
                                    {(provided: any, snapshot: any) => {
                                      // let isPathExist =
                                      //   ticketFile?.path;

                                      return (
                                        <div
                                          ref={provided.innerRef}
                                          className=""
                                        >
                                          <div
                                            className="drag_items px-2 flex items-center gap-2"
                                            key={ticketFileIndex}
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                          >
                                            <div
                                              id="drag_here"
                                              className={`grid grid-cols-[25%_1fr_11%_11%] md:grid-cols-[1fr_19%_1fr_19%_6%] border border-solid ${
                                                snapshot?.isDraggingOver &&
                                                "border-green-500"
                                              } rounded w-full text-xs mb-2.5 
                                    ${
                                      eventRecord?.errors?.[
                                        "quantity_available"
                                      ] === TicketError
                                        ? "bg-rose-500 bg-opacity-[7%] border-rose-700"
                                        : ""
                                    } ${
                                                isAllFilesUploaded
                                                  ? `!bg-green-600 !bg-opacity-[7%] !border-green-700`
                                                  : `bg-gray-100/50 border-solid`
                                              } `}
                                            >
                                              <div className="p-3.5">
                                                <div className="text-gray-400">
                                                  Listing ID
                                                </div>
                                                <div>
                                                  {eventRecord?.id
                                                    ? eventRecord?.id
                                                    : "N/A"}
                                                </div>
                                              </div>
                                              <div className="p-3.5 border-t border-dashed md:border-t-0">
                                                <div className="text-gray-400">
                                                  Quantity
                                                </div>
                                                <div>
                                                  {eventRecord?.quantity
                                                    ? eventRecord?.quantity
                                                    : "0"}
                                                </div>
                                              </div>
                                              <div className="p-3.5">
                                                <div className="text-gray-400">
                                                  Ticket details
                                                </div>
                                                <div>
                                                  {(() => {
                                                    const categoryName =
                                                      getCategoryValue(
                                                        eventRecord,
                                                        event
                                                      )?.name;
                                                    const sectionName =
                                                      getSectionValue(
                                                        eventRecord,
                                                        event
                                                      )?.name;

                                                    return categoryName
                                                      ? `${categoryName}, ${
                                                          sectionName || "N/A"
                                                        }`
                                                      : "N/A";
                                                  })()}
                                                </div>
                                              </div>
                                              <div className="p-3.5 border-t border-dashed md:border-t-0">
                                                <div className="text-gray-400">
                                                  Row (Seat)
                                                </div>
                                                <div>
                                                  {eventRecord?.row
                                                    ? eventRecord?.row
                                                    : "0"}{" "}
                                                  (
                                                  {eventRecord?.firstSeat
                                                    ? eventRecord?.firstSeat
                                                    : "0"}
                                                  )
                                                </div>
                                              </div>

                                              {/* RIGHT SIDE BUTTONS (INFO & UPLOAD) */}
                                              {/* <div
                                      // className="flex items-center justify-center border-t md:border-t-0 border-l border-dashed border-violet-500/10 p-3.5"
                                      className={`flex items-center justify-center border-t md:border-t-0 border-l border-solid  p-3.5 ${
                                        isAllFilesUploaded &&
                                        `!border-green-700`
                                      } ${
                                        eventRecord?.errors?.[
                                          "quantity_available"
                                        ] === TicketError && "border-rose-700"
                                      }`}
                                    >
                                      <Tooltip
                                        anchorId={`assigned-tooltip${eventIndex}${eventRecordIndex}`}
                                        // content="1/2 tickets assigned"
                                        content={`${
                                          isOpen?.event?.[eventIndex]
                                            ?.eventRecords[eventRecordIndex]
                                            ?.ticketFiles &&
                                          Object.keys(
                                            isOpen?.event?.[
                                              eventIndex
                                            ]?.eventRecords[
                                              eventRecordIndex
                                            ]?.ticketFiles?.filter(
                                              (obj: any) =>
                                                JSON.stringify(obj) !== "{}"
                                            )
                                          )?.length > 0
                                            ? isOpen?.event?.[
                                                eventIndex
                                              ]?.eventRecords[
                                                eventRecordIndex
                                              ]?.ticketFiles.filter(
                                                (obj: any) =>
                                                  JSON.stringify(obj) !== "{}"
                                              )?.length
                                            : 0
                                        } of ${
                                          eventRecord?.quantity
                                            ? eventRecord?.quantity
                                            : 0
                                        }  tickets assigned `}
                                        place="top"
                                        variant="light"
                                        className="!bg-white !opacity-100 shadow !px-1.5 !py-0.5 !text-xxs font-medium text-xs z-10"
                                        clickable
                                      />
                                      <button
                                        id={`assigned-tooltip${eventIndex}${eventRecordIndex}`}
                                      >
                                        <KTSVG
                                          // className="fill-violet-500 hover:fill-indigo-500 transition"
                                          className={`${
                                            isOpen?.event?.[eventIndex]
                                              ?.eventRecords[eventRecordIndex]
                                              ?.ticketFiles &&
                                            isAllFilesUploaded
                                              ? `fill-green-600 hover:fill-green-500 transition`
                                              : `fill-violet-500 hover:fill-indigo-500 transition`
                                          } `}
                                          svgClassName="w-[.875rem] h-[.875rem]"
                                          path="other_icons/info.svg"
                                        />
                                      </button>
                                    </div> */}
                                              <div
                                                // className="flex items-center justify-center border-t md:border-t-0 border-l border-dashed border-violet-500/10 p-3.5"
                                                className={`flex items-center justify-center border-t md:border-t-0 border-l border-solid  p-3.5 ${
                                                  isAllFilesUploaded &&
                                                  `!border-green-700`
                                                } ${
                                                  eventRecord?.errors?.[
                                                    "quantity_available"
                                                  ] === TicketError &&
                                                  "border-rose-700"
                                                }
                                      
                                      `}
                                              >
                                                <Tooltip
                                                  anchorId={`upload${eventIndex}${eventRecordIndex}`}
                                                  html={uploadTicketTooltipWithAssignTickets(
                                                    eventIndex,
                                                    eventRecordIndex,
                                                    eventRecord,
                                                    event
                                                  )}
                                                  place="top"
                                                  variant="light"
                                                  className="!bg-white !opacity-100 shadow !px-1.5 !py-0.5 !text-xxs font-medium text-xs z-10"
                                                />
                                                <button
                                                  className=""
                                                  // onClick={() =>
                                                  //   handleRightUpload(
                                                  //     eventIndex,
                                                  //     eventRecordIndex
                                                  //   )
                                                  // }
                                                  disabled={
                                                    isAllFilesUploaded ||
                                                    eventRecord?.quantity <= 0
                                                  }
                                                  id={`upload${eventIndex}${eventRecordIndex}`}
                                                >
                                                  <IKTSVG
                                                    // className="fill-violet-500 hover:fill-indigo-500 transition"
                                                    className={`${
                                                      isAllFilesUploaded
                                                        ? `fill-green-600 hover:fill-green-500 transition`
                                                        : `fill-violet-500 hover:fill-indigo-500 transition`
                                                    } `}
                                                    path={
                                                      [
                                                        "eticket",
                                                        "pdf",
                                                        "mobile-qr",
                                                      ].includes(
                                                        eventRecord?.ticketType
                                                          ?.id
                                                      )
                                                        ? pin
                                                        : mobileSVG
                                                    }
                                                    svgClassName="w-[.6875rem] h-[.875rem]"
                                                  />
                                                </button>
                                              </div>
                                              {/* END --------------------- */}

                                              {/* BODY */}
                                              {(eventRecord?.ticketType?.id ===
                                                "eticket" ||
                                                eventRecord?.ticketType?.id ===
                                                  "pdf" ||
                                                eventRecord?.ticketType?.id ===
                                                  "mobile-qr") && (
                                                <div
                                                  className={`px-1.5 col-span-full w-full border-t border-solid flex flex-col flex-wrap gap-2.5 py-2.5 ${
                                                    isAllFilesUploaded
                                                      ? `!border-green-700`
                                                      : `border-gray-200`
                                                  } ${
                                                    eventRecord?.errors?.[
                                                      "quantity_available"
                                                    ] === TicketError &&
                                                    "border-rose-700"
                                                  }`}
                                                >
                                                  {Number(
                                                    eventRecord?.quantity
                                                  ) > 0 && (
                                                    <>
                                                      {isOpen?.event?.[
                                                        eventIndex
                                                      ]?.eventRecords?.[
                                                        eventRecordIndex
                                                      ]?.ticketFiles?.map(
                                                        (
                                                          ticketFile: any,
                                                          ticketFileIndex: any
                                                        ) => {
                                                          let isPathExist =
                                                            ticketFile?.path;
                                                          return (
                                                            // <Droppable
                                                            //   droppableId={`${eventIndex}-${eventRecordIndex}-${ticketFileIndex}`}
                                                            //   key={`${eventIndex}-${eventRecordIndex}-${ticketFileIndex}`}
                                                            //   // isDropDisabled={
                                                            //   //   isAllFilesUploaded
                                                            //   // }
                                                            // >
                                                            //   {(
                                                            //     provided: any,
                                                            //     snapshot: any
                                                            //   ) => {
                                                            //     let isPathExist =
                                                            //       ticketFile?.path;

                                                            //     return (
                                                            //       <div
                                                            //         ref={
                                                            //           provided.innerRef
                                                            //         }
                                                            //         className=""
                                                            //       >
                                                            //         <div
                                                            //           className="drag_items px-2 flex items-center gap-2"
                                                            //           key={
                                                            //             ticketFileIndex
                                                            //           }
                                                            //           ref={
                                                            //             provided.innerRef
                                                            //           }
                                                            //           {...provided.draggableProps}
                                                            //           {...provided.dragHandleProps}
                                                            //         >
                                                            <div className="drag_items px-2 flex items-center gap-2">
                                                              <span>
                                                                Ticket{" "}
                                                                {ticketFileIndex +
                                                                  1}
                                                              </span>
                                                              <div className="single_drag flex flex-wrap col-span-full items-center flex-1 ">
                                                                <div
                                                                  className={`${
                                                                    isAllFilesUploaded &&
                                                                    "!border-solid !border-green-500 hover:!border-indigo-500 hover:bg-violet-50 transition"
                                                                  } ${
                                                                    isPathExist &&
                                                                    "!border-solid !border-green-500 hover:!border-indigo-500 hover:bg-violet-50 transition"
                                                                  }  drag_here border border-dashed rounded bg-white px-2 py-[3px] flex-1 transition`}
                                                                >
                                                                  <span
                                                                    className={`${
                                                                      isAllFilesUploaded &&
                                                                      "!text-black !inline-flex items-center justify-between w-full"
                                                                    }  ${
                                                                      isPathExist &&
                                                                      "!text-black !inline-flex items-center justify-between w-full"
                                                                    } 
                                                                  flex justify-between   text-sm12 font-medium text-gray-400 block `}
                                                                  >
                                                                    {isPathExist
                                                                      ? isPathExist
                                                                      : " Drag file here"}

                                                                    {isPathExist && (
                                                                      <span
                                                                        key={`key-${eventIndex}-${eventRecordIndex}-${ticketFileIndex}`}
                                                                        className="flex items-center gap-x-2"
                                                                      >
                                                                        <button
                                                                          onClick={() => {
                                                                            setTemplateState(
                                                                              initialTemplateState
                                                                            );
                                                                            pdfViewer(
                                                                              globalLayout.setFilePreviewState,
                                                                              ticketFile
                                                                            );
                                                                          }}
                                                                          id={`${eventIndex}-${eventRecordIndex}-${ticketFileIndex}`}
                                                                        >
                                                                          <IKTSVG
                                                                            className="fill-violet-500 hover:fill-indigo-500 transition"
                                                                            path={
                                                                              EyeSVG
                                                                            }
                                                                            svgClassName="w-[1rem] h-[.6875rem]"
                                                                          />
                                                                          <Tooltip
                                                                            anchorId={`${eventIndex}-${eventRecordIndex}-${ticketFileIndex}`}
                                                                            content="View"
                                                                            place="top"
                                                                            variant="light"
                                                                            className="!bg-white !opacity-100 shadow !px-1.5 !py-0.5 !text-xxs font-medium text-xs z-10"
                                                                          />
                                                                        </button>

                                                                        <button
                                                                          // className="h-[0.5625rem]"
                                                                          onClick={() =>
                                                                            deletePdfRightSide(
                                                                              ticketFile,
                                                                              ticketFileIndex,
                                                                              eventRecordIndex,
                                                                              eventIndex
                                                                            )
                                                                          }
                                                                          id={`delete${eventIndex}${eventRecordIndex}${ticketFileIndex}`}
                                                                        >
                                                                          <IKTSVG
                                                                            className="fill-violet-500 hover:fill-indigo-500 h-2.5 w-2.5 transition"
                                                                            path={
                                                                              remove
                                                                            }
                                                                          />
                                                                          <Tooltip
                                                                            anchorId={`delete${eventIndex}${eventRecordIndex}${ticketFileIndex}`}
                                                                            content="Remove"
                                                                            place="top"
                                                                            variant="light"
                                                                            className="!bg-white !opacity-100 shadow !px-1.5 !py-0.5 !text-xxs font-medium text-xs z-10"
                                                                          />

                                                                          {/* {files.some(
                                                                            (
                                                                              file: any
                                                                            ) =>
                                                                              file.id ===
                                                                              ticketFile.id
                                                                          ) && (
                                                                            <IKTSVG
                                                                              className="fill-violet-500 hover:fill-indigo-500 transition 1"
                                                                              path={
                                                                                remove
                                                                              }
                                                                              svgClassName="w-[.5625rem] h-[.5938rem]"
                                                                            />
                                                                          )} */}
                                                                          {/* // ) : (
                                                                          //   <IKTSVG
                                                                          //     className="fill-violet-500 hover:fill-indigo-500 transition 2"
                                                                          //     path={
                                                                          //       DeleteSVG
                                                                          //     }
                                                                          //     svgClassName="w-2.5 h-[.8125rem]"
                                                                          //   />
                                                                          // )} */}
                                                                        </button>
                                                                        {/* <Tooltip
                                                                      anchorId={`delete${eventIndex}${eventRecordIndex}`}
                                                                      content="Remove"
                                                                      place="top"
                                                                      variant="light"
                                                                      className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10"
                                                                    /> */}
                                                                      </span>
                                                                    )}
                                                                  </span>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          );
                                                        }
                                                        //         </div>
                                                        //       </div>
                                                        //     );
                                                        //   }}
                                                        // </Droppable>
                                                        //   )
                                                        // }
                                                      )}
                                                    </>
                                                  )}
                                                </div>
                                              )}

                                              {/* MOBILE LINK - PKPASS */}
                                              {eventRecord?.quantity > 0 &&
                                                eventRecord?.ticketType?.id ===
                                                  "mobile-link" && (
                                                  <>
                                                    {/* PKPASS TOGGLE */}
                                                    <div className="px-1.5 col-span-full w-full border-t border-solid flex flex-row flex-wrap gap-2.5 py-2.5">
                                                      <div
                                                        className="flex items-center px-2 
                                             "
                                                      >
                                                        <span className="font-medium mr-4">
                                                          Separate links for
                                                          Android/iOS
                                                        </span>
                                                        <div>
                                                          <label
                                                            className={`relative inline-flex items-center cursor-pointer false [&amp;.disabled>div:after]:bg-gray-400 [&amp;.disabled]:pointer-events-none false ${
                                                              eventRecord?.pkPass &&
                                                              "pointer-events-none  "
                                                            }`}
                                                          >
                                                            <input
                                                              type="checkbox"
                                                              name={`separate_mobile_link-${eventIndex}-${eventRecordIndex}`}
                                                              className="sr-only peer"
                                                              checked={
                                                                eventRecord?.separate_links
                                                              }
                                                              onChange={(
                                                                e: any
                                                              ) =>
                                                                handleSeparateLinks(
                                                                  e,
                                                                  eventIndex,
                                                                  eventRecordIndex
                                                                )
                                                              }
                                                            />
                                                            <div
                                                              className={`bg-gray-200 peer-checked:bg-indigo-500 peer-checked:after:bg-indigo-500  peer-checked:bg-opacity-50 after:bg-violet-500 w-7 h-3  rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full  after:content-[''] after:absolute after:-top-0.5 after:-left-0.5  after:rounded-full after:h-4 after:w-4 after:transition-all after:shadow-md peer-checked:bg-100 peer-checked:after:bg-tick after:bg-center after:bg-no-repeat after:bg-8  ${
                                                                eventRecord?.pkPass &&
                                                                "pointer-events-none after:!bg-gray-400 "
                                                              }`}
                                                            ></div>
                                                          </label>
                                                        </div>
                                                      </div>

                                                      <div className="flex items-center px-2  ">
                                                        <span className="font-medium mr-4">
                                                          PKPASS
                                                        </span>
                                                        <div>
                                                          <label className="relative inline-flex items-center cursor-pointer false [&amp;.disabled>div:after]:bg-gray-400 [&amp;.disabled]:pointer-events-none false">
                                                            <input
                                                              type="checkbox"
                                                              name={`pkPass-${eventIndex}-${eventRecordIndex}`}
                                                              className="sr-only peer"
                                                              checked={
                                                                eventRecord?.pkPass
                                                              }
                                                              onChange={(
                                                                e: any
                                                              ) =>
                                                                handlePKPASS(
                                                                  e,
                                                                  eventIndex,
                                                                  eventRecordIndex
                                                                )
                                                              }
                                                            />
                                                            <div className="bg-gray-200 peer-checked:bg-indigo-500 peer-checked:after:bg-indigo-500  peer-checked:bg-opacity-50 after:bg-violet-500 w-7 h-3  rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full  after:content-[''] after:absolute after:-top-0.5 after:-left-0.5  after:rounded-full after:h-4 after:w-4 after:transition-all after:shadow-md peer-checked:bg-100 peer-checked:after:bg-tick after:bg-center after:bg-no-repeat after:bg-8"></div>
                                                          </label>
                                                        </div>
                                                      </div>
                                                    </div>

                                                    {/* PKPASS - CONDITION (DRAG / TEXT) */}
                                                    {eventRecord?.pkPass ? (
                                                      <>
                                                        <div className="px-1.5 col-span-full w-full border-t border-solid flex flex-col flex-wrap gap-2.5 py-2.5">
                                                          {isOpen?.event?.[
                                                            eventIndex
                                                          ]?.eventRecords?.[
                                                            eventRecordIndex
                                                          ]?.ticketFiles?.map(
                                                            (
                                                              ticketFile: any,
                                                              ticketFileIndex: any
                                                            ) => {
                                                              return (
                                                                // <Droppable
                                                                //   droppableId={`${eventIndex}-${eventRecordIndex}-${ticketFileIndex}`}
                                                                //   key={`${eventIndex}-${eventRecordIndex}-${ticketFileIndex}`}
                                                                //   isDropDisabled={
                                                                //     ticketFile?.path &&
                                                                //     ticketFile?.mobileLink &&
                                                                //     ticketFile.hasOwnProperty(
                                                                //       "isValid"
                                                                //     ) &&
                                                                //     ticketFile?.isValid
                                                                //       ? true
                                                                //       : false
                                                                //   }
                                                                // >
                                                                //   {(
                                                                //     provided: any,
                                                                //     snapshot: any
                                                                //   ) => {
                                                                //     return (
                                                                //       <div
                                                                //         ref={
                                                                //           provided.innerRef
                                                                //         }
                                                                //         className=""
                                                                //       >
                                                                //         <div
                                                                //           className="drag_items px-2 flex items-center gap-2"
                                                                //           key={
                                                                //             ticketFileIndex
                                                                //           }
                                                                //           ref={
                                                                //             provided.innerRef
                                                                //           }
                                                                //           {...provided.draggableProps}
                                                                //           {...provided.dragHandleProps}
                                                                //         >
                                                                <>
                                                                  <div className="drag_items px-2 flex items-center gap-2">
                                                                    <span>
                                                                      {" "}
                                                                      Ticket{" "}
                                                                      {ticketFileIndex +
                                                                        1}
                                                                    </span>
                                                                    <div className="single_drag flex flex-wrap col-span-full items-center flex-1 ">
                                                                      <div
                                                                        className={`  ${
                                                                          ticketFile?.path &&
                                                                          !ticketFile?.mobileLink
                                                                            ? "min-h-[1.5rem] drag_here border border-dashed rounded bg-white px-2 py-[3px] flex-1 transition border-green-500"
                                                                            : ticketFile?.path &&
                                                                              ticketFile?.mobileLink
                                                                            ? "!border-solid !border-green-500 hover:!border-indigo-500 hover:bg-violet-50 transition  drag_here border rounded bg-white px-2 py-[3px] flex-1 "
                                                                            : "min-h-[1.5rem] drag_here border border-dashed rounded bg-white px-2 py-[3px] flex-1 transition"
                                                                        } ${
                                                                          ticketFile
                                                                            ?.ticketFiles
                                                                            ?.path &&
                                                                          !ticketFile.hasOwnProperty(
                                                                            "isValid"
                                                                          ) &&
                                                                          "border-1 !border-dashed !border-green-500"
                                                                        }  `}
                                                                      >
                                                                        <span
                                                                          className={`text-sm12 font-medium block ${
                                                                            ticketFile?.path &&
                                                                            !ticketFile?.mobileLink
                                                                              ? ""
                                                                              : ticketFile?.path &&
                                                                                ticketFile?.mobileLink
                                                                              ? "!text-black !inline-flex items-center justify-between w-full falsetext-sm12 font-medium"
                                                                              : "text-gray-400"
                                                                          }`}
                                                                        >
                                                                          {ticketFile?.path &&
                                                                          !ticketFile.hasOwnProperty(
                                                                            "isValid"
                                                                          ) ? (
                                                                            <div className="flex items-center flex-nowrap">
                                                                              <span className="flex-none mr-2.5">
                                                                                Generating
                                                                                URL
                                                                              </span>
                                                                              <span className="flex-1">
                                                                                <div className="page-loader">
                                                                                  <div className="loader-stripe bg-gray-200 rounded w-full h-2">
                                                                                    <div
                                                                                      className="load-line h-full bg-green-500"
                                                                                      style={{
                                                                                        animation: ` loaderAnim 2s linear forwards`,
                                                                                      }}
                                                                                    ></div>
                                                                                  </div>
                                                                                </div>
                                                                              </span>
                                                                            </div>
                                                                          ) : ticketFile?.path &&
                                                                            ticketFile?.mobileLink ? (
                                                                            <>
                                                                              {ticketFile?.mobileLink
                                                                                ?.split(
                                                                                  "/"
                                                                                )
                                                                                .pop()}
                                                                              <span
                                                                                key={
                                                                                  ticketFileIndex
                                                                                }
                                                                                className="flex s-center gap-x-2 "
                                                                              >
                                                                                <button
                                                                                  type="button"
                                                                                  id={`copy-mobile-link-${eventIndex}-${eventRecordIndex}-${ticketFileIndex}`}
                                                                                  onClick={() => {
                                                                                    let copiedValues =
                                                                                      copy(
                                                                                        ticketFile?.mobileLink
                                                                                      );
                                                                                    setIsCopied(
                                                                                      {
                                                                                        id: `copy-mobile-link-${eventIndex}-${eventRecordIndex}-${ticketFileIndex}`,
                                                                                        value:
                                                                                          copiedValues,
                                                                                      }
                                                                                    );
                                                                                    setTimeout(
                                                                                      () => {
                                                                                        setIsCopied(
                                                                                          {
                                                                                            id: "",
                                                                                            value:
                                                                                              "",
                                                                                          }
                                                                                        );
                                                                                      },
                                                                                      200
                                                                                    );
                                                                                  }}
                                                                                >
                                                                                  <IKTSVG
                                                                                    className={`flex items-center justify-center transition ${
                                                                                      isCopied.value ===
                                                                                        ticketFile?.mobileLink &&
                                                                                      isCopied.id ===
                                                                                        `copy-mobile-link-${eventIndex}-${eventRecordIndex}-${ticketFileIndex}`
                                                                                        ? "fill-green-600  hover:fill-green-600"
                                                                                        : "fill-violet-500 hover:fill-indigo-500"
                                                                                    }`}
                                                                                    path={
                                                                                      CloneSVG
                                                                                    }
                                                                                    svgClassName="w-[1rem] h-[.6875rem]"
                                                                                  />
                                                                                  <Tooltip
                                                                                    anchorId={`copy-mobile-link-${eventIndex}-${eventRecordIndex}-${ticketFileIndex}`}
                                                                                    content="Copy"
                                                                                    place="top"
                                                                                    variant="light"
                                                                                    className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10"
                                                                                  />
                                                                                </button>

                                                                                <button
                                                                                  type="button"
                                                                                  className="h-[0.5625rem]"
                                                                                  id={`delete-mobile-link-${eventIndex}-${eventRecordIndex}-${ticketFileIndex}`}
                                                                                  onClick={(
                                                                                    e: any
                                                                                  ) =>
                                                                                    handleMobileLinkRemove(
                                                                                      eventIndex,
                                                                                      eventRecordIndex,
                                                                                      ticketFileIndex
                                                                                    )
                                                                                  }
                                                                                >
                                                                                  <IKTSVG
                                                                                    className="fill-violet-500 hover:fill-indigo-500 h-2.5 w-2.5 transition"
                                                                                    path={
                                                                                      remove
                                                                                    }
                                                                                    svgClassName="w-[.5938rem]  h-[.5938rem]"
                                                                                  />
                                                                                  <Tooltip
                                                                                    anchorId={`delete-mobile-link-${eventIndex}-${eventRecordIndex}-${ticketFileIndex}`}
                                                                                    content="Unassign"
                                                                                    place="top"
                                                                                    variant="light"
                                                                                    className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10"
                                                                                  />
                                                                                </button>
                                                                              </span>
                                                                            </>
                                                                          ) : (
                                                                            "Drop PKPASS file here"
                                                                          )}
                                                                        </span>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </>
                                                              );
                                                            }
                                                          )}
                                                        </div>
                                                      </>
                                                    ) : (
                                                      <MobileUrlLink
                                                        isOpen={isOpen}
                                                        eventIndex={eventIndex}
                                                        eventRecordIndex={
                                                          eventRecordIndex
                                                        }
                                                        handleMobileLinkChange={
                                                          handleMobileLinkChange
                                                        }
                                                        setIsCopied={
                                                          setIsCopied
                                                        }
                                                        copy={copy}
                                                        isCopied={isCopied}
                                                        eventRecord={
                                                          eventRecord
                                                        }
                                                      />
                                                    )}
                                                  </>
                                                )}
                                            </div>
                                          </div>

                                          <div className="px-2">
                                            {/* PRE UPLOAD */}
                                            <PreUpload
                                              eventRecord={eventRecord}
                                              eventIndex={eventIndex}
                                              eventRecordIndex={
                                                eventRecordIndex
                                              }
                                              instructionLetterTemplates={
                                                instructionLetterTemplates
                                              }
                                              initialTemplateState={
                                                initialTemplateState
                                              }
                                              templateState={templateState}
                                              setTemplateState={
                                                setTemplateState
                                              }
                                              handleChangeTemplate={
                                                handleChangeTemplate
                                              }
                                              handleInputChanges={
                                                handleInputChanges
                                              }
                                              deletePdfRightSide={
                                                deletePdfRightSide
                                              }
                                              files={files}
                                            />
                                          </div>
                                        </div>
                                      );
                                    }}
                                  </Droppable>
                                </React.Fragment>
                              );
                            }
                          }
                        )}
                      </div>
                    </React.Fragment>
                  );
                })}
            </div>
          </div>

          <div className="inline-flex items-center justify-end border-t py-2.5 px-5 text-sm13 w-full">
            <button
              type="button"
              className="border border-gray-300 hover:border-indigo-500 text-violet-800 hover:text-white bg-white hover:bg-indigo-500 transition rounded text-sm13 py-0.5 px-2.5 mr-2.5 my-0.5"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              onClick={closePopUp}
            >
              Cancel
            </button>
            <button
              type="submit"
              className={` 
              ${
                confirmButtonDisabled(
                  getUploadedListing()[0] !== getUploadedListing()[1]
                ) &&
                "pointer-events-none !border-0 !text-gray-400 !bg-gray-100 "
              } 
              border border-green-500 bg-green-500 text-white hover:bg-white hover:text-green-500 rounded hover:border hover:border-green-500 transition p-0.5 px-2.5`}
              data-bs-dismiss="offcanvas"
              onClick={handleEventConfirm}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </DragDropContext>
  );
};

export default ETicketUploadKeyboard;
