import { LayoutContext } from "components/core/LayoutProvider";
import { getDefaultPaginationFilters } from "helpers/Functions";
import { useContext, useMemo } from "react";
import HeaderSection from "../../../components/fullPopup/HeaderSection";
import ProgressBardLoader from "../../../components/ui/ProgressBardLoader";
import Tab from "../component/Tab";
import { ConnectorContext } from "../core/ConnectorProvider";
import ConfigurationTab from "./component/ConfigurationTab";
import EventsTab from "./component/EventsTab";
import { ListingTab } from "./component/ListingTab";
import { OrdersTab } from "./component/OrdersTab/OrdersTab";

const tabWiseComponent: any = {
  Configuration: ConfigurationTab,
  Events: EventsTab,
  Listing: ListingTab,
  Orders: OrdersTab,
};

const tabData = [
  { title: "Configuration" },
  { title: "Events" },
  { title: "Listing" },
  { title: "Orders" },
];

const handleTabChange = (layout: any, title: any) => () => {
  const {
    setIsTabActive,
    setEventsFilters,
    setListingFilters,
    setOrderFilters,
    setEventsMappingState,
  } = layout;

  // Define filters based on the tab
  const filters: any = {
    Events: () => {
      setEventsMappingState(false);
      setEventsFilters({ page: 1 });
    },
    Listing: () => setListingFilters(getDefaultPaginationFilters()),
    Orders: () => setOrderFilters(getDefaultPaginationFilters()),
  };

  // Reset filters for the tab that is not currently active
  Object.keys(filters).forEach((tab) => {
    if (title !== tab && filters[tab]) {
      let resetFilter = filters[tab];
      resetFilter && resetFilter();
    }
  });

  setIsTabActive(title);
};

const ConnectorView = ({ data, handleClosePopup }: any) => {
  const layout = useContext(ConnectorContext);

  const ActiveTabComponent = useMemo(
    () => tabWiseComponent[layout.isTabActive],
    [layout.isTabActive]
  );

  const globalLayout = useContext(LayoutContext);

  return (
    <form>
      <div
        id="connectorView"
        className={`whiteFormFields overflow-y-hidden show outline-black overflow-x-auto scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full !h-full upload-ticket-popup offcanvas s_ticket offcanvas-end fixed bottom-0 top-[3.75rem] right-0 shadow-lg transition-all duration-200 ease-in-out font-medium w-full  z-[50]  max-h-[calc(100%-3.75rem)] !bg-gray-650 ${
          globalLayout?.asideMenuOpen
            ? "md:max-w-[calc(100%_-_13.8125rem)]"
            : "md:max-w-[calc(100%_-_3.75rem)]"
        } 1233`}
      >
        <div className="relative flex w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none dark:bg-neutral-600 h-full">
          <div className=" main-content-wrap  min-h-full bg-gray-650 ">
            {/* HEADER */}
            <HeaderSection
              title={data?.data?.name}
              handleClosePopup={handleClosePopup}
              id="api-connector-header-title"
            />

            {/* TABS */}
            <div
              className="topFilters connector sticky-element top-[60px] z-[11] bg-gray-650"
              id="topFilters"
            >
              <div className="top-accor shadow-op2">
                <div
                  className="bg-white rounded-b accordion-collapse font-medium collapse  show 123"
                  data-te-collapse-item
                  data-te-collapse-show
                  aria-labelledby="headingOne"
                >
                  <div className="tab-panel bg-no-repeat bg-tradepattern bg-cover bg-indigo-500 z-[12] bg-opacity-[15%] p-5 pb-0 flex after:content after:absolute after:bottom-0 after:left-0 after:w-full after:h-2 after:z-0 after:from-black/0 after:bg-gradient-to-b after:to-black/5 relative overflow-x-auto flex-nowrap overflow-hidden scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full">
                    {tabData.map(({ title }) => (
                      <Tab
                        key={title}
                        title={title}
                        imgPath={""}
                        handleTab={handleTabChange(layout, title)}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>

            {/* BODY */}
            <div className="p-0">
              <ActiveTabComponent
                data={layout.isTabActive === "Events" ? data?.data : data}
              />
            </div>
          </div>
        </div>
      </div>

      {/* LOADER */}
      {layout.loader && <ProgressBardLoader secondCounter={1.5} />}
    </form>
  );
};

export default ConnectorView;
