import NoDataComponent from "components/tableComponent/NoDataComponent";
import { IKTSVG } from "components/ui/IKTSVG";
import ProgressBardLoader from "components/ui/ProgressBardLoader";
import { isMobile, QUERIES } from "helpers/const";
import { useContext, useState } from "react";
import { useQuery } from "react-query";
import { Tooltip } from "react-tooltip";
import AddIconSVG from "../../../../assets/media/icons/other_icons/add_icon.svg";
import EDITSVG from "../../../../assets/media/icons/other_icons/edit-square.svg";
import { SellerLevelsProviderContex } from "../core/SellerLevelsProvider";
import {
  sellerLevelConfiguration,
  sellerLevelOptionsWithConfig,
} from "../core/request";
import DynamicDataTable from "../table/DynamicDataTable";
import ConfigurationRowAction from "../table/component/ConfigurationRowAction";
import Footer from "../table/component/Footer";
import ConfigurationFilters from "./ConfigurationFilters";
import FilterChips from "./FilterChips";
import HeaderFilter from "./HeaderFilter";
import LevelConfigurationPopup from "./LevelConfigurationPopup";
import SellerLevelHeader from "./SellerLevelHeader";

const getColumns: any = ({ handOnDeleteLevel }: any) => [
  {
    title: "Levels name",
    width: "w-[9.375rem] min-w-[9.375rem]",
    isArrow: false,
    postKey: "name",
    padding: "pl-5 p-1.5",
    getValue: ({ list }: any) => list?.title,
  },
  {
    title: "Team count",
    width: "w-[6.25rem] min-w-[6.25rem]",
    isArrow: false,
    postKey: "team_count",
    padding: " p-1.5",
    getValue: ({ list }: any) => list?.team_count,
  },
  {
    title: "Weightage",
    width: "w-[6.25rem] min-w-[6.25rem]",
    isArrow: false,
    postKey: "weightage",
    padding: " p-1.5",
    getValue: ({ list }: any) => list?.weightage,
  },
  {
    title: "",
    width:
      "md:w-[11.8125rem] md:max-w-[11.8125rem] md:min-w-[11.8125rem] w-[9.375rem] max-w-[9.375rem] min-w-[9.375rem]",
    isArrow: false,
    postKey: "actions",
    padding: "",
    getValue: ({ list, sIndex, matchedView }: any) => (
      <ConfigurationRowAction
        list={list}
        sIndex={sIndex}
        matchedView={matchedView}
        handOnDeleteLevel={handOnDeleteLevel}
      />
    ),
  },
  isMobile && {
    title: (
      <>
        {/* {globalLayout?.isMobile && (
            <th className="sticky right-0 ml-auto  p-0 w-10 min-w-[2.5rem]">
              <div
                className="py-[.4375rem] px-4 flex justify-end h-[2.5rem]  bg-white max-md:pointer-events-none"
                id=""
              ></div>
            </th>
          )} */}
      </>
    ),
    width:
      "sticky right-0 ml-auto p-0 icon_td max-md:min-w-[2.5rem] md:hidden ",
    isArrow: false,
    postKey: "actions",
    renderDirect: true,
    getValue: ({ list, sIndex, matchedView }: any) => (
      <>
        <div className="-ml-px border-l w-full">
          <button
            className={` group inline-flex group items-center justify-center text-white hover:text-white bg-violet-500 hover:bg-indigo-500 transition font-medium rounded text-sm13 gap-2 p-1 w-7 h-7  `}
            type="button"
            id={`record-view-tooltip${sIndex}`}
            data-bs-toggle="offcanvas"
            data-bs-target="#level_confi_popup"
            aria-controls="level_confi_popup_label"
            // onClick={() => {
            //   layout.setSellerLevelEditData(list);
            // }}
          >
            <Tooltip
              anchorId={`record-view-tooltip${sIndex}`}
              content={`${"Edit"}`}
              place={`${sIndex === 0 ? "left" : "top"}`}
              variant="light"
              className={`!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10  ${
                sIndex === 0 ? "mt-0" : "mt-1.5"
              }`}
            />
            <IKTSVG
              className={`flex items-center justify-center fill-white group-hover:fill-white transition`}
              svgClassName="w-4 h-[.6875rem]"
              path={EDITSVG}
            />
          </button>
        </div>
      </>
    ),
  },
];

const headerFilters = () => {
  return (
    <div className="btn">
      <button
        className="group inline-flex group items-center text-white hover:text-white bg-violet-500 hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5 false"
        type="button"
        data-bs-toggle="offcanvas"
        data-bs-target="#level_confi_popup"
        aria-controls="level_confi_popup_label"
      >
        <IKTSVG
          className=" mr-1.5 flex items-center justify-center fill-white"
          path={AddIconSVG}
        />
        Add level
      </button>
    </div>
  );
};

const ConfigurationTab = () => {
  const layout = useContext(SellerLevelsProviderContex);
  const tableData: any = layout.sellerLevelData?.tableData || [];
  const [deleteLevelLoader, setDeleteLevelLoader] = useState<any>(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState<any>(false);

  // GET LISTINGS
  const {
    isLoading: loading,
    refetch: refetchLevelAPi,
    isFetching,
  } = useQuery([QUERIES.SELLER_LEVEL_LIST], () => sellerLevelConfiguration(), {
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: false,
    cacheTime: 0,
    // enabled: true,
    onSettled(data, error) {
      const newData = {
        count: data?.data?.count,
        tableData: data?.data?.lists || [],
        options: data?.data?.options,
        metaData: data?.meta,
      };
      layout.setSellerLevelData(newData);
    },
  });

  // GET options
  const { data: levelConfigData } = useQuery(
    [QUERIES.SELLER_LEVEL_OPTION],
    () => sellerLevelOptionsWithConfig(),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      cacheTime: 0,
    }
  );

  const handOnDeleteLevel = async (data: any) => {
    setDeleteConfirmation({
      status: true,
      id: data?.id,
    });
  };

  return (
    <div>
      <SellerLevelHeader
        headerFilters={HeaderFilter}
        countFilters={<ConfigurationFilters loading={loading} />}
        // loading={loading || isFetching}
        headerFilter={headerFilters()}
        filterChips={
          <FilterChips
            loading={loading}
            paginateData={{
              total: layout.sellerLevelData?.count,
            }}
            totalName={"levels"}
          />
        }
        // totalName="levels"
        // paginateData={{
        //   total: layout.sellerLevelData?.count,
        // }}
      />

      {/* <div> */}
      {loading || isFetching ? (
        Array.from({ length: 3 }, (v, i) => (
          <div
            className={`accordion font-medium  mb-2.5 mx-5 ${
              i === 0 && "mt-0"
            } `}
          >
            <div
              key={i}
              className={`accordion-item bg-white !rounded overflow-hidden ${
                loading && "shimmer-effect"
              } `}
              style={{ height: "40px" }}
            ></div>
          </div>
        ))
      ) : (
        // <DataTable refetch={refetchLevelAPi} />
        <DynamicDataTable
          refetch={refetchLevelAPi}
          columns={getColumns({
            handOnDeleteLevel,
          }).filter(Boolean)}
          data={tableData}
          paginateData={layout.sellerLevelData?.metaData}
        />
      )}

      {deleteConfirmation?.status ? (
        <Footer
          setDeleteLevelLoader={setDeleteLevelLoader}
          refetch={refetchLevelAPi}
          deleteConfirmation={deleteConfirmation}
          setDeleteConfirmation={setDeleteConfirmation}
        />
      ) : null}

      {!loading && !isFetching && tableData?.length === 0 ? (
        <NoDataComponent />
      ) : null}

      {deleteLevelLoader && <ProgressBardLoader secondCounter={2} />}

      <LevelConfigurationPopup
        configData={levelConfigData}
        refetch={refetchLevelAPi}
      />
    </div>
  );
};

export default ConfigurationTab;
