/* eslint-disable eqeqeq */
import { createContext, PropsWithChildren, useMemo, useState } from "react";

export interface LayoutContextModel {
  tabValue: any;
  setTabValue: (data: any) => void;

  title: string;
  setTitle: (title: string) => void;

  username: string;
  setUsername: (title: string) => void;

  notificationId: number;
  setNotificationId: (id: any) => void;

  isLogged: any;
  setIsLogged: (data: any) => void;

  htmlFontSize: number;
  setHtmlFontSize: (data: number) => void;

  notifications: Array<any>;
  setNotifications: (data: any) => void;

  newNotification: any;
  setNewNotification: (data: any) => void;

  activities: Array<any>;
  setActivities: (data: any) => void;

  isLoading: any;
  setIsLoading: (data: any) => void;

  currentPage: number;
  setCurrentPage: (data: number) => void;

  userDetail: any;
  setUserDetail: (data: any) => void;

  listingData: any;
  setListingData: (data: any) => void;

  // authToken: any;
  // setAuthToken: (data: any) => void;

  formData: any;
  setFormdata: (data: any) => void;

  customError: any;
  setCustomError: (data: any) => void;

  clientSecret: any;
  setClientSecret: (data: any) => void;
  allowedAccess: any;
  SetAllowedAccess: (data: any) => void;

  settingOptions: any;
  setSettingOptions: (data: any) => void;

  isFetching: any;
  setIsFetching: (data: any) => void;

  globalEvent: any;
  setGlobalEvent: (data: any) => void;

  globalSales: any;
  setGlobalSales: (data: any) => void;

  globalReports: any;
  setGlobalReports: (data: any) => void;

  globalFulfilled: any;
  setGlobalFulfilled: (data: any) => void;

  globalNotification: any;
  setGlobalNotification: (data: any) => void;

  isPusherCall: boolean;
  setIsPusherCall: (data: any) => void;

  userDetailShow: any;
  setUserDetailShow: (data: any) => void;

  currencyLabel: any;
  setCurrencyLabel: (data: any) => void;

  conversationRate: any;
  setConversationRate: (data: any) => void;

  currencySymbol: any;
  setCurrencySymbol: (data: any) => void;

  userEmail: any;
  setUserEmail: (data: any) => void;

  //docusign in register
  registerDocuSign: any;
  setRegisterDocuSign: (data: any) => void;

  removeFilterTag: any;
  setRemoveFilterTag: (data: any) => void;

  authUser: any;
  setAuthUser: (data: any) => void;

  cloneEvents: any;
  setCloneEvents: (data: any) => void;

  infoAPIRefetch: boolean;
  setInfoAPIRefetch: (data: any) => void;

  isNotificationPopupActive: boolean;
  setIsNotificationPopupActive: (data: any) => void;

  isTxTradeUser: any;
  setIsTxTradeUser: (data: any) => void;

  headerDraggableFields: any;
  setHeaderDraggableFields: (data: any) => void;

  globalTxTradeData: any;
  setGlobalTxTradeData: (data: any) => void;

  KYCPopup: any;
  setKYCPopup: (data: any) => void;

  isMobile: boolean;
  setIsMobile: (data: any) => void;

  filePreviewState: any;
  setFilePreviewState: (data: any) => void;

  editListings: any;
  setEditListings: (data: any) => void;

  isTxTradeCustomize: any;

  levelGlobal: any;
  setLevelGlobal: (data: any) => void;

  sellerLevelData: any;

  globalCount: any;
  setGlobalCount: (data: any) => void;

  sellerLevelConfigEdit: any;
  setSellerLevelConfigEdit: (data: any) => void;

  asideMenuOpen: boolean;
  setAsideMenuOpen: (data: any) => void;
}

const LayoutContext = createContext<LayoutContextModel>({
  tabValue: [],
  setTabValue: (data: any) => {},
  title: "",
  setTitle: (title: string) => {},

  username: "",
  setUsername: (title: string) => {},

  notificationId: 0,
  setNotificationId: (notification: any) => {},

  isLogged: "",
  setIsLogged: (data: boolean) => {},

  htmlFontSize: 100,
  setHtmlFontSize: (data: number) => {},
  notifications: [],
  setNotifications: (data: any) => {},
  activities: [],
  setActivities: (data: any) => {},
  newNotification: "",
  setNewNotification: (data: any) => {},
  isLoading: "",
  setIsLoading: (data: any) => {},

  currentPage: 1,
  setCurrentPage: (data: any) => {},

  userDetail: {},
  setUserDetail: (data: any) => {},

  listingData: {},
  setListingData: (data: any) => {},

  // authToken: {},
  // setAuthToken: (data: any) => {},

  formData: {},
  setFormdata: (data: any) => {},

  customError: {},
  setCustomError: (data: any) => {},

  clientSecret: {},
  setClientSecret: (data: any) => {},
  allowedAccess: {},
  SetAllowedAccess: (data: any) => {},

  settingOptions: {},
  setSettingOptions: (data: any) => {},
  isFetching: {},
  setIsFetching: (data: any) => {},
  globalEvent: {},
  setGlobalEvent: (data: any) => {},
  globalSales: {},
  setGlobalSales: (data: any) => {},
  globalReports: {},
  setGlobalReports: (data: any) => {},
  globalFulfilled: {},
  setGlobalFulfilled: (data: any) => {},
  globalNotification: {},
  setGlobalNotification: (data: any) => {},
  isPusherCall: false,
  setIsPusherCall: (data: any) => {},
  userDetailShow: [],
  setUserDetailShow: (data: any) => {},

  currencyLabel: "",
  setCurrencyLabel: (data: any) => {},
  currencySymbol: "",
  setCurrencySymbol: (data: any) => {},

  userEmail: "",
  setUserEmail: (data: any) => {},

  registerDocuSign: "",
  setRegisterDocuSign: (data: any) => {},
  removeFilterTag: [],
  setRemoveFilterTag: (data: any) => {},

  authUser: [],
  setAuthUser: (data: any) => {},

  cloneEvents: [],
  setCloneEvents: (data: any) => {},

  infoAPIRefetch: false,
  setInfoAPIRefetch: (data: any) => {},

  isNotificationPopupActive: false,
  setIsNotificationPopupActive: (data: any) => {},

  isTxTradeUser: [],
  setIsTxTradeUser: (data: any) => {},
  headerDraggableFields: [],
  setHeaderDraggableFields: (data: any) => {},

  conversationRate: "",
  setConversationRate: (data: any) => {},

  globalTxTradeData: {},
  setGlobalTxTradeData: (data: any) => {},

  KYCPopup: false,
  setKYCPopup: (data: any) => {},

  isMobile: false,
  setIsMobile: (data: any) => {},

  filePreviewState: {},
  setFilePreviewState: (data: any) => {},

  editListings: [],
  setEditListings: (data: any) => {},

  isTxTradeCustomize: false,

  levelGlobal: {},
  setLevelGlobal: (data: any) => {},

  sellerLevelData: {},

  globalCount: {},
  setGlobalCount: (data: any) => {},

  sellerLevelConfigEdit: {},
  setSellerLevelConfigEdit: (data: any) => {},

  asideMenuOpen: false,
  setAsideMenuOpen: (data: any) => {},
});

const LayoutProvider = ({ children }: PropsWithChildren) => {
  const [tabValue, setTabValue] = useState<any>("notification");
  const [title, setTitle] = useState("");
  const [username, setUsername] = useState("");
  const [headerDraggableFields, setHeaderDraggableFields] = useState<any>();
  const [isNotificationPopupActive, setIsNotificationPopupActive] =
    useState(false);
  const [infoAPIRefetch, setInfoAPIRefetch] = useState<boolean>(false);
  const [notificationId, setNotificationId] = useState<any>();
  const [isLogged, setIsLogged] = useState<any>();
  const [htmlFontSize, setHtmlFontSize] = useState(() => {
    // GET HTML FONT SIZE FROM LOCAL STORAGE
    const saved: any = localStorage.getItem("htmlFontSize");
    return JSON.parse(saved) || 100;
  });
  const [notifications, setNotifications] = useState([]);
  const [activities, setActivities] = useState([]);
  const [newNotification, setNewNotification] = useState<any>();
  const [isLoading, setIsLoading] = useState<any>();
  const [authUser, setAuthUser] = useState<any>();
  const [conversationRate, setConversationRate] = useState<any>();
  const [currentPage, setCurrentPage] = useState<any>(1);

  const [globalTxTradeData, setGlobalTxTradeData] = useState<any>({});
  const [sellerLevelConfigEdit, setSellerLevelConfigEdit] = useState({});

  // USE IN SIGNUP
  const [userDetail, setUserDetail] = useState<any>((current: any) => {
    return {
      useOfTixstock: "",
      seller_type: "",
      tx_trade_user: "0",
      firstname: "",
      lastname: "",
      formData: "",
      username: "",
      password: "",
      confirm_password: "",
      email: "",
      confirmEmail: "",
      countryCode: { id: "CH +41", name: "CH +41" },
      phone: "",
      country_id: "",
      address_line_1: "",
      address_line_2: "",
      address_line_3: "",
      city: "",
      postcode: "",
      agreed: false,
      exchanges: [],
      is_preferred_partner: 0,
      package_id: "",
      funding_bank_name: "",
      funding_ac_holder_name: "",
      funding_iban: "",
      funding_swift: "",
      // funding_tx_type: "",
      // funding_ac_number: "",
      withdrawal_bank_name: "",
      withdrawal_ac_holder_name: "",
      withdrawal_iban: "",
      withdrawal_swift: "",
      withdrawal_ac_number: "",
      withdrawal_branch_code: "",
      // withdrawal_bank_ac_currency: "",
      // withdrawal_bank_ac_country_code: null,
      // recipient_country_code: null,
      // recipient_street: "",
      // recipient_city: "",
      // recipient_state: "",
      // recipient_postcode: "",
      // withdrawal_tx_type: "",

      vat_registered: "",
      vat_number: "",
      base_currency: "",
      estimated_annual_revenue: "",
      use_as_same: true,
      identity_proof: [],
      // business_proof: [],
      step: 1,
      isPayment: "",
      card_token: "",
      docusign_envelope_id: "",
      funding_iban_type: "",
      withdrawal_iban_type: "",
      company: "",
      conversationRate,
      kyc: null,
      stripeConfirm: false,
      saved: undefined,
      withdrawal_bank_identifier: false,
      funding_bank_identifier: false,
    };
  });
  const [listingData, setListingData] = useState<any>({});
  // const [formData, setFormdata] = useLocalStorage("formData", "");
  const [formData, setFormdata] = useState<any>();
  // const [authToken, setAuthToken] = useLocalStorage("authToken", "");
  const [customError, setCustomError] = useState<any>();
  const [clientSecret, setClientSecret] = useState<string>();
  const [allowedAccess, SetAllowedAccess] = useState<any>(() => {
    // GET ALLOWED ACCESS FROM LOCAL STORAGE
    const saved: any = localStorage.getItem("access");
    return JSON.parse(saved) || [];
  });
  const [settingOptions, setSettingOptions] = useState<any>();
  const [isFetching, setIsFetching] = useState<any>();
  const [globalEvent, setGlobalEvent] = useState<any>();
  const [globalSales, setGlobalSales] = useState<any>();
  const [globalReports, setGlobalReports] = useState<any>();
  const [globalFulfilled, setGlobalFulfilled] = useState<any>();
  const [globalNotification, setGlobalNotification] = useState<any>();
  const [isPusherCall, setIsPusherCall] = useState<boolean>(false);
  const [userDetailShow, setUserDetailShow] = useState<boolean>(() => {
    // GET ALLTX PAY STATUS OWED ACCESS FROM LOCAL STORAGE
    let txPayStatus: any = localStorage.getItem("txPayStatus");
    const saved: boolean =
      txPayStatus != "undefined"
        ? JSON.parse(txPayStatus)
        : {
            txPayStatus: false,
            txPayData: "",
          };
    return saved;
  });
  const [isTxTradeUser, setIsTxTradeUser] = useState<any>(() => {
    // GET ALLTX PAY STATUS OWED ACCESS FROM LOCAL STORAGE
    let txtradeuser: any = localStorage.getItem("isTxTradeUser");
    const saved: any = txtradeuser != "undefined" ? JSON.parse(txtradeuser) : 0;
    return saved;
  });

  const [userEmail, setUserEmail] = useState<string>("");
  const [registerDocuSign, setRegisterDocuSign] = useState<any>("");
  const [currencyLabel, setCurrencyLabel] = useState<string>("");
  const [currencySymbol, setCurrencySymbol] = useState<string>("");
  const [removeFilterTag, setRemoveFilterTag] = useState<string>("");
  const [cloneEvents, setCloneEvents] = useState<any>([]);
  const [KYCPopup, setKYCPopup] = useState<boolean>(false);
  const [isMobile, setIsMobile] = useState<any>(window.innerWidth <= 767);
  const [filePreviewState, setFilePreviewState] = useState({
    page: 1,
    file: null,
    status: false,
    numPages: 1,
  });
  const [editListings, setEditListings] = useState<any>([]);
  const sidemenuOpen = localStorage.getItem("sideMenuOpen");

  const [asideMenuOpen, setAsideMenuOpen] = useState(
    !isMobile && sidemenuOpen ? JSON.parse(sidemenuOpen) : false
  );

  const sellerLevelData = useMemo(
    () => allowedAccess?.team_data?.seller_level ?? {},
    [allowedAccess?.team_data?.seller_level]
  );

  const isTxTradeCustomize = useMemo(
    () =>
      Boolean(
        allowedAccess?.allowed_access &&
          allowedAccess?.allowed_access?.find(
            (item: any) => item.id === "txtrade-admin" && item.status
          )
      ),
    [allowedAccess?.allowed_access]
  );
  const [levelGlobal, setLevelGlobal] = useState<any>({});
  const [globalCount, setGlobalCount] = useState<any>({});

  const value: LayoutContextModel = {
    tabValue,
    setTabValue,
    title,
    setTitle,
    username,
    setUsername,
    notificationId,
    setNotificationId,
    isLogged,
    setIsLogged,
    htmlFontSize,
    setHtmlFontSize,
    notifications,
    setNotifications,
    newNotification,
    setNewNotification,
    activities,
    setActivities,
    isLoading,
    setIsLoading,
    currentPage,
    setCurrentPage,
    userDetail,
    setUserDetail,
    listingData,
    setListingData,
    formData,
    setFormdata,
    // authToken,
    // setAuthToken,
    customError,
    setCustomError,
    clientSecret,
    setClientSecret,
    allowedAccess,
    SetAllowedAccess,
    settingOptions,
    setSettingOptions,
    isFetching,
    setIsFetching,
    globalEvent,
    setGlobalEvent,
    globalSales,
    setGlobalSales,
    globalReports,
    setGlobalReports,
    globalFulfilled,
    setGlobalFulfilled,
    globalNotification,
    setGlobalNotification,
    isPusherCall,
    setIsPusherCall,
    userDetailShow,
    setUserDetailShow,
    currencyLabel,
    setCurrencyLabel,
    currencySymbol,
    setCurrencySymbol,
    userEmail,
    setUserEmail,
    registerDocuSign,
    setRegisterDocuSign,
    removeFilterTag,
    setRemoveFilterTag,
    authUser,
    setAuthUser,
    cloneEvents,
    setCloneEvents,
    infoAPIRefetch,
    setInfoAPIRefetch,
    isNotificationPopupActive,
    setIsNotificationPopupActive,
    isTxTradeUser,
    setIsTxTradeUser,
    headerDraggableFields,
    setHeaderDraggableFields,
    conversationRate,
    setConversationRate,
    globalTxTradeData,
    setGlobalTxTradeData,
    KYCPopup,
    setKYCPopup,
    isMobile,
    setIsMobile,
    filePreviewState,
    setFilePreviewState,
    editListings,
    setEditListings,
    isTxTradeCustomize,
    levelGlobal,
    setLevelGlobal,
    sellerLevelData,
    globalCount,
    setGlobalCount,
    sellerLevelConfigEdit,
    setSellerLevelConfigEdit,
    asideMenuOpen,
    setAsideMenuOpen,
  };

  return (
    <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>
  );
};

export { LayoutContext, LayoutProvider };
