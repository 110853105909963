import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useState,
} from "react";
import { LayoutContext } from "../../../components/core/LayoutProvider";
import { getHeaderFilter } from "../../../helpers/AssetHelpers";
import {
  INVENTORY_BULK_ITEMS,
  INVENTORY_FILTER_ITEMS,
  INVENTRY_COLUMN_ITEMS,
} from "../../../helpers/const";
import { getDefaultFilters } from "./_functions";
import { QueryState } from "./_models";

export interface LayoutContextModel {
  events: Array<any>;
  setEvents: (data: any) => void;

  //use: Edit
  isTableEditable: [];
  setIsTableEditable: (data: any) => void;

  tableDataEditChanging: any;
  setTableDataEditChanging: (data: any) => void;

  //delet event record
  confirmRowDelete: boolean;
  setConfirmRowDelete: (data: any) => void;
  // Count selected for edit
  countSelectedEdit: any;
  setCountSelectedEdit: (data: any) => void;

  teamMembers: any;
  setTeamMembers: (data: any) => void;

  transferOwnershipTeamMembers: any;
  setTransferOwnershipTeamMembers: (data: any) => void;

  ticketType: any;
  setTicketType: (data: any) => void;

  deliveryType: any;
  setDeliveryType: (data: any) => void;

  splitType: any;
  setSplitType: (data: any) => void;

  benefits: any;
  setBenefits: (data: any) => void;

  restrictions: any;
  setRestrictions: (data: any) => void;

  currencyRates: any;
  setCurrencyRates: (data: any) => void;

  addAllEvents: boolean;
  setAddAllEvents: (data: boolean) => void;

  isPublish: boolean;
  setIsPublish: (data: boolean) => void;

  pusherData: any;
  setpusherData: (data: any) => void;

  orderState: any;
  setOrderState: (data: any) => void;

  collapsedAll: any;
  setCollapsedAll: (data: any) => void;

  filterItems: Array<any>;
  setFilterItems: (data: any) => void;

  draggedItems: Array<any>;
  setDraggedItems: (data: any) => void;

  draggedColumns: Array<any>;
  setDraggedColumns: (data: any) => void;

  columnItems: Array<any>;
  setColumnItems: (data: any) => void;

  bulkItems: Array<any>;
  setBulkItems: (data: any) => void;

  tableAlert: any;
  setTableAlert: (data: any) => void;

  // eticket upload
  tempEvent: any;
  setTempEvent: (data: any) => void;

  eTicketDataField: any;
  setETicketDataField: (data: any) => void;

  eventId: Array<any>;
  setEventId: (data: any) => void;

  isDeleteActive: boolean;
  SetIsDeleteActive: (data: any) => void;

  updateAll: any;
  setUpdateAll: (data: any) => void;

  isEditPopUpActive: boolean;
  setIsEditPopUpActive: (data: any) => void;

  refreshList: any;
  setRefreshList: (data: any) => void;
  users: any;
  setUsers: (data: any) => void;

  teamSelectedGlobal: any;
  setTeamSelectedGlobal: (data: any) => void;

  isBulkActionLoader: any;
  setisBulkActionLoader: (data: any) => void;
  updateAllLoader: any;
  setUpdateAllLoader: (data: any) => void;

  customLoader: boolean;
  setCustomLoader: (data: any) => void;

  selectAllLoader: boolean;
  setSelectAllLoader: (data: any) => void;

  pusherDeleteLoader: boolean;
  setPusherDeleteLoader: (data: any) => void;

  selectAllConfirmation: boolean;
  setSelectAllConfirmation: (data: any) => void;

  selectAllLoaderCurrentEvent: {};
  setSelectAllLoaderCurrentEvent: (data: any) => void;

  inventoryNewFilterValue: any;
  setInventoryNewFilterValue: (data: any) => void;

  proceedPriceToggle: any;
  setProceedPriceToggle: (data: any) => void;

  animationForVisibility: any;
  setAnimationForVisibility: (data: any) => void;

  virtuosoRecordIndex: any;
  setVirtuosoRecordIndex: (data: any) => void;

  currentIndex: any;
  setCurrentIndex: (data: any) => void;

  isLoader: boolean;
  setIsLoader: (data: any) => void;

  logsData: any;
  setLogsData: (data: any) => void;
}

const InventoryContext = createContext<LayoutContextModel>({
  updateAll: false,
  setUpdateAll: (data: any) => {},

  customLoader: false,
  setCustomLoader: (data: any) => {},

  transferOwnershipTeamMembers: false,
  setTransferOwnershipTeamMembers: (data: any) => {},

  selectAllLoader: false,
  setSelectAllLoader: (data: any) => {},

  events: [],
  setEvents: (data: any) => {},

  isTableEditable: [],
  setIsTableEditable: (data: any) => {},

  //delete event record
  confirmRowDelete: false,
  setConfirmRowDelete: (data: any) => {},

  tableDataEditChanging: [],
  setTableDataEditChanging: (data: string) => {},

  // For selected edit
  countSelectedEdit: [],
  setCountSelectedEdit: (data: any) => {},

  teamMembers: [],
  setTeamMembers: (data: string) => {},

  ticketType: [],
  setTicketType: (data: string) => {},

  deliveryType: [],
  setDeliveryType: (data: string) => {},

  splitType: [],
  setSplitType: (data: string) => {},

  benefits: [],
  setBenefits: (data: string) => {},

  restrictions: [],
  setRestrictions: (data: string) => {},

  currencyRates: [],
  setCurrencyRates: (data: any) => {},

  addAllEvents: false,
  setAddAllEvents: (data: boolean) => {},

  isPublish: false,
  setIsPublish: (data: boolean) => {},

  pusherData: [],
  setpusherData: (data: any) => {},

  orderState: [],
  setOrderState: (data: any) => {},

  collapsedAll: [],
  setCollapsedAll: (data: any) => {},

  filterItems: [],
  setFilterItems: (data: any) => {},

  draggedItems: [],
  setDraggedItems: (data: any) => {},

  draggedColumns: [],
  setDraggedColumns: (data: any) => {},

  columnItems: [],
  setColumnItems: (data: any) => {},

  bulkItems: [],
  setBulkItems: (data: any) => {},

  tableAlert: {},
  setTableAlert: (data: any) => {},

  // eticket upload
  tempEvent: [],
  setTempEvent: (data: any) => {},
  eTicketDataField: {},
  setETicketDataField: (data: any) => {},

  eventId: [],
  setEventId: (data: any) => {},

  isDeleteActive: false,
  SetIsDeleteActive: (data: any) => {},

  isEditPopUpActive: false,
  setIsEditPopUpActive: (data: any) => {},

  refreshList: "",
  setRefreshList: (data: any) => {},
  users: [],
  setUsers: (data: any) => {},

  teamSelectedGlobal: [],
  setTeamSelectedGlobal: (data: any) => {},

  isBulkActionLoader: false,
  setisBulkActionLoader: (data: any) => {},
  updateAllLoader: false,
  setUpdateAllLoader: (data: any) => {},

  pusherDeleteLoader: false,
  setPusherDeleteLoader: (data: any) => {},

  selectAllConfirmation: false,
  setSelectAllConfirmation: (data: any) => {},

  selectAllLoaderCurrentEvent: {},
  setSelectAllLoaderCurrentEvent: (data: any) => {},

  inventoryNewFilterValue: {},
  setInventoryNewFilterValue: (data: any) => {},

  proceedPriceToggle: false,
  setProceedPriceToggle: (data: any) => {},

  animationForVisibility: {},
  setAnimationForVisibility: (data: any) => {},

  virtuosoRecordIndex: {},
  setVirtuosoRecordIndex: (data: any) => {},

  currentIndex: {},
  setCurrentIndex: (data: any) => {},

  isLoader: false,
  setIsLoader: (data: any) => {},

  logsData: null,
  setLogsData: (data: any) => {},
});

const InventoryProvider = ({ children }: PropsWithChildren) => {
  const coreLayout = useContext(LayoutContext);
  const isGodAdmin = coreLayout?.allowedAccess?.account_info?.is_god_admin;
  const access = coreLayout.allowedAccess?.allowed_access;
  const userSpecific = access?.some(
    (item: any) => item.id === "user-specific-data" && item.status === true
  );

  // FILTER - CONDITION
  let isFilterExistInDB =
    coreLayout?.allowedAccess?.ui_field_settings?.saveInventoryFilter;

  let items = getHeaderFilter(
    "saveInventoryFilter",
    isFilterExistInDB,
    INVENTORY_FILTER_ITEMS
  );

  // update filter as per user type
  items = getDefaultFilters(items, isGodAdmin, userSpecific);

  // COLUMN FILTER - CONDITION
  let isColumnFilterExistInDB =
    coreLayout?.allowedAccess?.ui_field_settings?.saveInventoryTableColumn;

  let columnsItems = getHeaderFilter(
    "",
    isColumnFilterExistInDB,
    INVENTRY_COLUMN_ITEMS
  );

  if (isGodAdmin) {
  } else {
    columnsItems = columnsItems?.filter(
      (item: any) => item.name !== "Team name" && item.name !== "Team member"
    );
  }

  const [logsData, setLogsData] = useState(null);

  const [updateAll, setUpdateAll] = useState<any>(false);
  const [customLoader, setCustomLoader] = useState<any>(false);
  const [selectAllLoader, setSelectAllLoader] = useState<any>(false);
  const [events, setEvents] = useState([]);
  const [transferOwnershipTeamMembers, setTransferOwnershipTeamMembers] =
    useState([]);

  const [teamMembers, setTeamMembers] = useState([]);
  const [ticketType, setTicketType] = useState([]);
  const [deliveryType, setDeliveryType] = useState([]);
  const [splitType, setSplitType] = useState([]);
  const [benefits, setBenefits] = useState([]);
  const [restrictions, setRestrictions] = useState([]);
  const [currencyRates, setCurrencyRates] = useState([]);

  const [isTableEditable, setIsTableEditable] = useState<[]>([]);
  const [tableDataEditChanging, setTableDataEditChanging] = useState([]);
  const [confirmRowDelete, setConfirmRowDelete] = useState<boolean>(false);
  const [countSelectedEdit, setCountSelectedEdit] = useState([]);

  const [orderState, setOrderState] = useState<QueryState>({});
  const [collapsedAll, setCollapsedAll] = useState<boolean>();

  const [addAllEvents, setAddAllEvents] = useState(false);
  const [isPublish, setIsPublish] = useState<boolean>(false);
  const [pusherData, setpusherData] = useState([]);

  const [draggedColumns, setDraggedColumns] = useState([]);

  const [inventoryNewFilterValue, setInventoryNewFilterValue] = useState({});

  const [proceedPriceToggle, setProceedPriceToggle] = useState<any>(false);

  const [filterItems, setFilterItems] = useState(() => {
    return items;
  });
  const [draggedItems, setDraggedItems] = useState(filterItems);

  const [columnItems, setColumnItems] = useState(() => {
    return columnsItems;
  });
  const [bulkItems, setBulkItems] = useState(INVENTORY_BULK_ITEMS);

  const [tableAlert, setTableAlert] = useState({});

  // eticket upload
  const [tempEvent, setTempEvent] = useState([]);
  const [eTicketDataField, setETicketDataField] = useState<any>({
    btnEvent: {},
    isOpen: false,
  });

  const [eventId, setEventId] = useState([]);
  const [isDeleteActive, SetIsDeleteActive] = useState<boolean>(false);

  const [isEditPopUpActive, setIsEditPopUpActive] = useState<boolean>(false);
  const [refreshList, setRefreshList] = useState<any>("");
  const [users, setUsers] = useState<any>(() => {
    let getAuthData: any = localStorage.getItem("authData") as string;
    // Cookies.get("authData") as string;
    let allAutData = getAuthData ? JSON.parse(getAuthData) : {};
    if (!isGodAdmin) {
      let final = [
        {
          id: allAutData?.id,
          name:
            `${coreLayout.allowedAccess?.account_info?.firstname} ${coreLayout.allowedAccess?.account_info?.surname}` ||
            allAutData?.name,
        },
      ];
      return final;
    }
  });
  const [teamSelectedGlobal, setTeamSelectedGlobal] = useState<any>();
  const [updateAllLoader, setUpdateAllLoader] = useState(false);

  const [isBulkActionLoader, setisBulkActionLoader] = useState<any>(false);

  const [pusherDeleteLoader, setPusherDeleteLoader] = useState<any>(false);

  const [selectAllConfirmation, setSelectAllConfirmation] =
    useState<boolean>(false);

  const [selectAllLoaderCurrentEvent, setSelectAllLoaderCurrentEvent] =
    useState<boolean>(false);
  const [animationForVisibility, setAnimationForVisibility] = useState<any>({
    eIndex: "",
    rIndex: "",
    flag: "",
  });
  const [virtuosoRecordIndex, setVirtuosoRecordIndex] = useState<any>({});

  const [currentIndex, setCurrentIndex] = useState({
    rIndex: "",
    eIndex: "",
  });

  const [isLoader, setIsLoader] = useState(false);

  const value: LayoutContextModel = React.useMemo(
    () => ({
      events,
      setEvents,

      teamMembers,
      setTeamMembers,
      transferOwnershipTeamMembers,
      setTransferOwnershipTeamMembers,

      ticketType,
      setTicketType,

      deliveryType,
      setDeliveryType,

      splitType,
      setSplitType,

      benefits,
      setBenefits,

      restrictions,
      setRestrictions,

      currencyRates,
      setCurrencyRates,

      isTableEditable,
      setIsTableEditable,

      confirmRowDelete,
      setConfirmRowDelete,

      addAllEvents,
      setAddAllEvents,

      countSelectedEdit,
      setCountSelectedEdit,

      tableDataEditChanging,
      setTableDataEditChanging,

      isPublish,
      setIsPublish,

      pusherData,
      setpusherData,

      orderState,
      setOrderState,

      collapsedAll,
      setCollapsedAll,

      filterItems,
      setFilterItems,

      draggedItems,
      setDraggedItems,

      columnItems,
      setColumnItems,

      draggedColumns,
      setDraggedColumns,

      bulkItems,
      setBulkItems,

      tableAlert,
      setTableAlert,

      tempEvent,
      setTempEvent,

      eTicketDataField,
      setETicketDataField,

      eventId,
      setEventId,

      isDeleteActive,
      SetIsDeleteActive,
      updateAll,
      setUpdateAll,

      isEditPopUpActive,
      setIsEditPopUpActive,

      refreshList,
      setRefreshList,
      users,
      setUsers,

      teamSelectedGlobal,
      setTeamSelectedGlobal,

      isBulkActionLoader,
      setisBulkActionLoader,
      updateAllLoader,
      setUpdateAllLoader,
      customLoader,
      setCustomLoader,
      pusherDeleteLoader,
      setPusherDeleteLoader,
      selectAllConfirmation,
      setSelectAllConfirmation,
      selectAllLoader,
      setSelectAllLoader,

      selectAllLoaderCurrentEvent,
      setSelectAllLoaderCurrentEvent,

      inventoryNewFilterValue,
      setInventoryNewFilterValue,

      proceedPriceToggle,
      setProceedPriceToggle,

      animationForVisibility,
      setAnimationForVisibility,
      virtuosoRecordIndex,
      setVirtuosoRecordIndex,
      currentIndex,
      setCurrentIndex,
      isLoader,
      setIsLoader,

      logsData,
      setLogsData,
    }),
    [
      // events,
      teamMembers,
      ticketType,
      deliveryType,
      splitType,
      benefits,
      restrictions,
      currencyRates,
      isTableEditable,
      confirmRowDelete,
      addAllEvents,
      countSelectedEdit,
      tableDataEditChanging,
      isPublish,
      pusherData,
      orderState,
      collapsedAll,
      filterItems,
      draggedItems,
      columnItems,
      draggedColumns,
      bulkItems,
      tableAlert,
      tempEvent,
      eTicketDataField,
      eventId,
      isDeleteActive,
      updateAll,
      isEditPopUpActive,
      refreshList,
      users,
      teamSelectedGlobal,
      isBulkActionLoader,
      updateAllLoader,
      customLoader,
      selectAllLoader,
      pusherDeleteLoader,
      setPusherDeleteLoader,
      selectAllConfirmation,

      selectAllLoaderCurrentEvent,
      setSelectAllLoaderCurrentEvent,

      inventoryNewFilterValue,
      setInventoryNewFilterValue,
      transferOwnershipTeamMembers,
      setTransferOwnershipTeamMembers,

      proceedPriceToggle,
      setProceedPriceToggle,

      animationForVisibility,
      setAnimationForVisibility,
      currentIndex,
      setCurrentIndex,
      // isLoader, setIsLoader
      logsData,
      setLogsData,
    ]
  );
  return (
    <InventoryContext.Provider value={value}>
      {children}
    </InventoryContext.Provider>
  );
};

export { InventoryContext, InventoryProvider };
