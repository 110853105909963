import React from "react";

const TableRow = React.memo(({ activeId, ...props }: any) => {

  const matched = props.item?.id === activeId;
  return (
    <>
      {false ? (
        <tr {...props} className="shimmer-effect highlight matchView" />
      ) : (
        <tr
          {...props}
          className={`border-b bg-white ${matched && "matchView"}
          `}
        />
      )}
    </>
  );
});

export default TableRow;
