import axios from "../../axois/SetupAxios";

// NOTIFICATIONS LISTING
const getNotificationListing = (data: any): Promise<any> => {
  return axios
    .post(`notifications/listing`, data)
    .then((response) => response.data);
};

// STORE FILTERS
const storeFields = async (data: any): Promise<any> => {
  let payload = data;
  const response = await axios.post(`settings/store-field-settings`, payload);
  return response.data;
};

// GENERATE PRE ASSIGNED URL
const generatePreassignedURL = async (data: any): Promise<any> => {
  let payload = {
    key: data,
  };
  const response = await axios.post(`auth/generate-s3-presigned-url`, payload);
  return response.data;
};

export { generatePreassignedURL, getNotificationListing, storeFields };
