/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useContext, useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import CircleQuestion from "../../../../assets/media/icons/menu_icons/Circle-question.svg";
import AccountGear from "../../../../assets/media/icons/menu_icons/gear.svg";
import LogoutSVG2 from "../../../../assets/media/icons/menu_icons/logout2.svg";
import AngleRight from "../../../../assets/media/icons/standard_icons/angle_right.svg";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

import { LayoutContext } from "components/core/LayoutProvider";
import { handleLogout } from "helpers/Functions";
import { Link } from "react-router-dom";
import { Navigation } from "swiper/modules";
import SellerLevelBenefitsSlider from "../SellerLevelBenefitsSlider";
import { LEVEL_CHECK_COLOR, SELLER_LEVEL_KEY } from "./const";
import { useProcessInfo } from "./hooks/useProcessInfo";
import { useQuarterData } from "./hooks/useQuarterData";
import { ProgressBlock } from "./ProgressBlock/ProgressBlock";
import {
  formatCurrencyAmount,
  getLevelStatusMessage,
  getSellerTitle,
} from "./utils";

export const UserSellerInfo = () => {
  const { globalCount } = useContext(LayoutContext);
  const [amount, setAmount] = useState(0);
  const [currentDate, setCurrentDate] = useState(new Date());

  const { daysLeftInQuater, startMonth, endMonth } =
    useQuarterData(currentDate);

  useEffect(() => {
    if (globalCount?.sales_count?.current_quarter_gtv)
      setAmount(globalCount?.sales_count?.current_quarter_gtv);
  }, [globalCount?.sales_count?.current_quarter_gtv]);

  useEffect(() => {
    if (globalCount?.sales_count?.server_timestamp)
      setCurrentDate(new Date(globalCount?.sales_count?.server_timestamp));
  }, [globalCount?.sales_count?.server_timestamp]);

  const {
    allowedAccess,
    userSellerLevel,
    memoizedAccess,
    activeSellerLevel,
    levelProgressPercentage,
    isEligibleForCongrats,
    isBelowRequiredGTV,
    requireGtVMessage,
    levelThresholds,
    currencyLabel,
    sellerLeveldata,
    initialSlide,
    userLevelIndex,
  } = useProcessInfo(amount, currentDate);

  return (
    <>
      <div className="absolute arrow top-0 left-0 w-5 h-5 bg-violet-600"></div>
      <div className="absolute arrow bottom-0 left-0 w-5 h-5 bg-violet-600"></div>
      <div className="md:w-[20.8125rem] w-[19.625rem] h-dvh flex flex-col relative duration-400 bg-white border-l pointer-events-auto rounded-l-2xl overflow-auto scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full">
        <div className="w-full py-[1.1875rem] px-5">
          <h2 className="w-full text-sm18 leading-[1.3125rem] font-medium mb-1.5 capitalize">
            {/* Scott Welch */}
            {allowedAccess?.account_info?.firstname}
            {"  "}
            {allowedAccess?.account_info?.surname}
          </h2>
          <p className="flex items-baseline w-full text-sm12 leading-[0.9375rem] text-gray-500">
            {getSellerTitle(userSellerLevel)}

            {LEVEL_CHECK_COLOR.map(({ getBgColor }, index) => {
              // Determine the class to apply based on the current level index
              const levelClass = getBgColor(index <= userLevelIndex);

              return (
                <span
                  key={index}
                  className={`ml-1 w-[0.5625rem] h-[0.5625rem] rounded-sm ${levelClass}`}
                ></span>
              );
            })}
          </p>
        </div>
        <div className="w-full pb-2.5 border-b border-gray-200">
          {memoizedAccess &&
          memoizedAccess?.find(
            (item: any) => item.id === "settings" && item.status
          ) ? (
            // <a className="w-full hover:bg-gray-100/50 inline-flex items-center text-sm13 leading-4 font-medium px-5 h-[2.125rem]">
            <a
              href="/settings"
              target="_self"
              className="w-full hover:bg-gray-100/50 inline-flex items-center text-sm13 leading-4 font-medium px-5 h-[2.125rem]"
              rel="noreferrer"
            >
              <SVG
                src={AccountGear}
                className={`w-[1.5rem] h-[1.5rem] fill-violet-500 mr-2.5`}
              />
              Settings
            </a>
          ) : null}

          <a
            href="https://help.tixstock.com"
            target="_blank"
            className="w-full hover:bg-gray-100/50 inline-flex items-center text-sm13 leading-4 font-medium px-5 h-[2.125rem]"
            rel="noreferrer"
          >
            <SVG
              src={CircleQuestion}
              className={`w-[1.5rem] h-[1.5rem] fill-violet-500 mr-2.5`}
            />
            Help Centre
          </a>
          <button
            className="w-full hover:bg-gray-100/50 inline-flex items-center text-sm13 leading-4 font-medium px-5 h-[2.125rem]"
            onClick={handleLogout}
          >
            <SVG
              src={LogoutSVG2}
              className={`w-[1.5rem] h-[1.5rem] fill-violet-500 mr-2.5`}
            />
            Logout
          </button>
        </div>
        <div className="w-full p-5">
          <h4 className="w-full  text-sm15 leading-[1.375rem] font-medium mb-[0.875rem]">
            {getLevelStatusMessage(
              activeSellerLevel,
              levelProgressPercentage,
              isEligibleForCongrats,
              isBelowRequiredGTV,
              userSellerLevel
            )}
          </h4>
          <p className="w-full text-sm12 leading-[1.0625rem] font-normal text-gray-500">
            Your GTV activity during the current calendar quarter will determine
            your Seller Level in the next calendar quarter.{" "}
            <Link
              to={"javscript:void(0);"}
              className="font-medium text-violet-800 hover:text-indigo-500"
            >
              Learn more
            </Link>
          </p>
          <div className="w-full border border-gray-200 rounded my-5">
            <div className="flex gap-x-[0.3125rem] w-full border-b border-gray-200 p-[0.6875rem] flex-shrink-0">
              <ProgressBlock
                level={SELLER_LEVEL_KEY.LEVEL3}
                amount={amount}
                currentDate={currentDate}
              />
              <ProgressBlock
                level={SELLER_LEVEL_KEY.LEVEL2}
                amount={amount}
                currentDate={currentDate}
              />
              <ProgressBlock
                level={SELLER_LEVEL_KEY.LEVEL1}
                amount={amount}
                currentDate={currentDate}
              />
              <ProgressBlock
                level={SELLER_LEVEL_KEY.SUPER_SELLER}
                amount={amount}
                currentDate={currentDate}
              />
            </div>
            <div className="px-[0.9375rem] py-3">
              <div className="flex w-full">
                <div className="w-full">
                  <p className="text-sm12 leading-[0.9375rem] w-full mb-1.5">
                    GTV {startMonth} - {endMonth}
                  </p>
                  <p className="text-sm12 leading-[0.9375rem] text-gray-500 w-full">
                    {daysLeftInQuater} days left
                  </p>
                </div>
                <p className="text-end w-full text-sm15 leading-[1.1875rem]">
                  {formatCurrencyAmount(currencyLabel, amount)}
                </p>
              </div>
              <p className="w-full text-sm12 leading-[0.9375rem] font-medium text-gray-500 mt-1.5">
                {requireGtVMessage}
              </p>
            </div>
          </div>
          <div className="levelSLider w-full">
            <Swiper
              key={initialSlide}
              className="w-full border rounded"
              modules={[Navigation]}
              navigation={{
                prevEl: ".swiper-button-prev-new",
                nextEl: ".swiper-button-next-new",
              }}
              initialSlide={initialSlide}
            >
              {sellerLeveldata?.map((sellerLevel: any, index: any) => {
                return (
                  <SwiperSlide key={index}>
                    <SellerLevelBenefitsSlider
                      data={sellerLevel}
                      sellerLevels={levelThresholds}
                      level={userSellerLevel}
                    />
                  </SwiperSlide>
                );
              })}
              <div className="px-[.9375rem] pb-[.9375rem] flex">
                {Array.from({ length: 2 }, (_v, i) => (
                  <button
                    className={`flex items-center justify-center w-6 h-6 ${
                      i === 0
                        ? "rounded-s swiper-button-prev-new"
                        : "rounded-e swiper-button-next-new"
                    } bg-gray-100 group hover:bg-indigo-500 disabled:hover:bg-gray-100 `}
                    key={i}
                  >
                    <SVG
                      src={AngleRight}
                      className={`h-[0.5625rem] w-1.5 ${
                        i === 0 ? "rotate-180" : ""
                      } group-hover:fill-white group-disabled:fill-gray-400 fill-violet-500`}
                    />
                  </button>
                ))}
              </div>
            </Swiper>
          </div>
        </div>
      </div>
    </>
  );
};
