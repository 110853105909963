import DatePickerField from "components/formComponent/DatePickerField";
import MultiDropDownField from "components/formComponent/MultiDropDownField";
import { QUERIES } from "helpers/const";
import { handleSelectDeselectAll } from "helpers/Functions";
import { size } from "lodash";
import moment from "moment";
import useDebounce from "pages/add_inventory/core/useDebounce";
import { algoliaSearch } from "pages/Reports/core/requests";
import TooltipPortal from "pages/tx_trade/table/component/TooltipPortal";
import { useContext, useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useQuery } from "react-query";
import Card from "../../../components/ui/Card";
import { DashboardContext } from "../core/DashboardProvider";
import DashBoardShimmerLoader from "./DashBoardShimmerLoader";
import OrderOutOfferCard from "./OrderOutOfferCard";

const flattenOptions = (channels: any) => {
  let options: any = [];

  const flatten = (channel: any, depth = 0, parentName = "") => {
    options.push({
      id: channel.id,
      name: channel.name,
      depth: depth, // Add indentation based on depth
      parent: parentName,
    });

    if (channel.children && channel.children.length > 0) {
      channel.children.forEach((child: any) =>
        flatten(child, depth + 1, channel.name)
      );
    }
  };
  channels?.forEach((channel: any) => flatten(channel));
  return options;
};

const OrderOutOffer = ({
  categoryListData,
  orderOutOfferFilters,
  setOrderOutOfferFilters,
  ourderOutOfferLoading,
  orderOutOfferData,
  ourderOutOfferCustomLoading,
  setOurderOutOfferCustomLoading,
  orderOutOfferListData,
  scrollToTop,
}: any) => {
  const layout = useContext(DashboardContext);

  const [performerInput, setPerformerInput] = useState("");
  const [performerAlgoliaOptions, setPerformerAlgoliaOptions] = useState([]);
  const performerSelectedRef: any = useRef();
  const performerDebounce = useDebounce(performerInput, 600);
  const [total, setTotal] = useState(orderOutOfferData?.meta?.total);
  const [expiringSoon, setExpiringSoon] = useState(
    orderOutOfferData?.data?.events_expiring_soon
  );

  useEffect(() => {
    if (orderOutOfferData?.meta) {
      setTotal(orderOutOfferData?.meta?.total);
    }

    if (orderOutOfferData?.data) {
      setExpiringSoon(orderOutOfferData?.data?.events_expiring_soon);
    }
  }, [
    orderOutOfferData?.meta?.total,
    orderOutOfferData?.data?.events_expiring_soon,
  ]);

  const handleOnChange = (data: any) => {
    // setCustomLoading(true);
    setOurderOutOfferCustomLoading(true);
    setOrderOutOfferFilters((currentValue: any) => {
      return {
        ...currentValue,
        [data?.target?.name]: data?.target?.value,
        page: 1,
      };
    });
    // layout.setIsSelectAll(false);
  };

  const handleOnSelection = (
    data: any,
    fieldName: string,
    options: any,
    visibleOptions: any = null
  ) => {
    if (performerSelectedRef && performerSelectedRef?.current) {
      performerSelectedRef.current.blur();
    }

    // setOurderOutOfferCustomLoading(true);

    handleSelectDeselectAll(
      data,
      fieldName,
      options,
      visibleOptions,
      setOrderOutOfferFilters
    );
  };

  // LOAD PERFORMER ALGOLIA
  const { isFetching: isPerformerLoading } = useQuery(
    [`${QUERIES.ALGOLIA_PERFORMERS}`, performerDebounce],
    () => {
      if (performerInput && performerInput.trim() !== "") {
        return algoliaSearch({
          inputChange: performerInput,
          searchIndex: "performers",
        });
      }
      return Promise.resolve(); // Return an empty resolved promise when algoliaDebounce is falsy or an empty string
    },
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: true,
      enabled: performerDebounce && performerDebounce.length > 2 ? true : false,
      onError: (err) => {},
      onSettled: (data: any) => {
        if (data && data?.hits?.length > 0) {
          setPerformerAlgoliaOptions(data.hits);
        }
      },
    }
  );

  useEffect(() => {
    if (!performerInput) {
      setPerformerAlgoliaOptions(orderOutOfferFilters?.performer_ids || []);
    }
  }, [orderOutOfferFilters?.performer_ids, performerInput]);

  const FiltersComponent = (
    <>
      <div className="h-TwentyFour min-w-[7rem]">
        <MultiDropDownField
          name="category_ids"
          classNamePrefix={"form_multiDropDown"}
          placeholder={
            size(orderOutOfferFilters?.category_ids) > 0
              ? "Category"
              : "All categories"
          }
          value={orderOutOfferFilters?.category_ids}
          handleOnChange={(data: any) => handleOnChange(data)}
          options={orderOutOfferData?.data?.event_category}
          isClearable={true}
          //isMenuPortalTarget={shimmerLoader ? false : true}
          selectedRef={performerSelectedRef}
          isMultiSelection={true}
          handleOnSelection={(data: any, visibleOptions: any) => {
            handleOnSelection(
              data,
              "category_ids",
              performerAlgoliaOptions,
              visibleOptions
            );
          }}
          isNone={false}
        />
      </div>
      <div className={`h-TwentyFour min-w-[7.25rem]`}>
        <div className={` rounded`}>
          <MultiDropDownField
            name="performer_ids"
            classNamePrefix={"form_multiDropDown"}
            placeholder={
              size(orderOutOfferFilters?.performer_ids) > 0
                ? "Performer"
                : "All performers"
            }
            value={orderOutOfferFilters?.performer_ids}
            isLoading={isPerformerLoading}
            handleInputChangeCallback={(input: string) => {
              setPerformerInput(input);
            }}
            noOptionsMessageText={
              !performerInput
                ? "Search performer"
                : performerInput.length <= 2
                ? "Minimum 3 Characters"
                : "No Options"
            }
            handleOnChange={(data: any) => handleOnChange(data)}
            options={performerAlgoliaOptions}
            isClearable={true}
            //isMenuPortalTarget={shimmerLoader ? false : true}
            selectedRef={performerSelectedRef}
            isMultiSelection={true}
            handleOnSelection={(data: any, visibleOptions: any) => {
              handleOnSelection(
                data,
                "performer_ids",
                performerAlgoliaOptions,
                visibleOptions
              );
            }}
            isNone={false}
          />
        </div>
      </div>
      <>
        <div
          data-tooltip-id={"order-out-offer-event_date_range"}
          className="h-TwentyFour"
        >
          <DatePickerField
            // className="block overflow-ellipsis overflow-hidden w-auto py-1 text-xs font-medium text-gray-900 border-1.5 border-gray-300 rounded focus:ring-indigo-300 focus:border-indigo-300 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            height={`!h-6 !max-w-[6.4375rem]`}
            // height={"!max-w-[8.75rem] "}
            name={"event_date_range"}
            placeholder={"Event date"}
            value={orderOutOfferFilters?.event_date_range}
            handleOnChange={(data: any) => handleOnChange(data)}
            isDateRange={true}
            IconColor={true}
            format="dd/MM/yyyy"
          />
        </div>

        {orderOutOfferFilters?.event_date_range && (
          <TooltipPortal
            id={"order-out-offer-event_date_range"}
            content={`${moment(
              orderOutOfferFilters?.event_date_range?.startDate
            )?.format("DD/MM/YYYY")} - ${moment(
              orderOutOfferFilters?.event_date_range?.endDate
            )?.format("DD/MM/YYYY")}`}
            className="text-center  !bg-white !opacity-100 shadow !py-0.5 !px-1.5 font-medium text-xs z-100 max-w-[9rem] cursor-default "
          />
        )}
      </>
    </>
  );

  return (
    <>
      <div className="w-full md:px-2 md:mb-4 mb-2  orderOfferWidget">
        <Card
          title="Orders out to offer"
          isLoading={ourderOutOfferCustomLoading}
          Filters={FiltersComponent}
          threeDot={false}
        >
          {ourderOutOfferCustomLoading ? (
            <DashBoardShimmerLoader
              mainLoading={true}
              headerShimmer={false}
              isLoading={ourderOutOfferCustomLoading}
              width={true}
            />
          ) : (
            <>
              <div className="divide-gray-200 divide-y-2 ">
                <div className="flex flex-nowrap p-5 -mx-2.5 overflow-auto scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full">
                  <div className="px-2.5 max-md:min-w-[50%] md:w-1/2 lg2:w-1/4 w-full">
                    <div className="h-full outline outline-1 outline-gray-200 bg-gray-100/25 rounded px-2.5 py-[.5625rem]">
                      <div className="flex flex-wrap sm:flex-nowrap justify-between">
                        <h3 className="text-sm18 leading-[1.3125rem] font-medium">
                          {total}
                        </h3>
                      </div>

                      <p className="flex items-center text-xs w-full sm:w-auto font-medium text-gray-500 pt-1.5 pr-2">
                        <span className="flex-none sm:flex-1 sm:w-full items-center mr-2 leading-[.9375rem]">
                          Orders
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="px-2.5 max-md:min-w-[50%] md:w-1/2 lg2:w-1/4 w-full">
                    <div className="h-full outline outline-1 outline-gray-200 bg-gray-100/25 rounded px-2.5 py-[.5625rem]">
                      <div className="flex flex-wrap sm:flex-nowrap justify-between">
                        <h3 className="text-sm18 leading-[1.3125rem] font-medium">
                          {expiringSoon}
                        </h3>
                      </div>

                      <p className="flex items-center text-xs w-full sm:w-auto font-medium text-gray-500 pt-1.5 pr-2">
                        <span className="flex-none sm:flex-1 sm:w-full items-center mr-2 leading-[.9375rem]">
                          Ending soon
                        </span>
                      </p>
                    </div>
                  </div>
                  {/* <div className="px-2.5 max-md:min-w-[50%] md:w-1/2 lg2:w-1/4 w-full">
                    <label
                      htmlFor="check"
                      className=" outline outline-1 outline-gray-200 bg-gray-100/25 rounded px-2.5 pt-[7px] pb-[9px]
                  
                  items-center group block  [&.active-style]:bg-indigo-500/5 [&.active-style]:outline-indigo-500  hover:bg-indigo-500/5 hover:outline-indigo-500 hover:text-indigo-500 cursor-pointer "
                    >
                      <h3 className="text-sm18 leading-6 font-medium">
                        {expiringSoon}
                      </h3>
                      <div className="flex justify-between items-center">
                        <p className="text-xs font-medium text-gray-500 pt-1 group-hover:text-indigo-500 false [&.active-style]:text-indigo-500">
                          Ending soon
                        </p>
                        <div className="flex">
                          <input
                            className="form-check-input h-3 w-3 rounded-sm bg-gray-100 border-gray-300 checked:bg-indigo-500 transition ml-2 cursor-pointer border checked:border-indigo-500 hover:border-indigo-500 group-hover:border-indigo-500 focus:ring-0 focus:ring-offset-0"
                            type="checkbox"
                            name="expiry_soon"
                            id={"check"}
                            checked={orderOutOfferFilters?.expiry_soon}
                            onChange={(event: any) => {
                              const data = {
                                target: {
                                  name: event?.target?.name,
                                  value: event?.target?.checked,
                                },
                              };
                              handleOnChange(data);
                            }}
                          />
                        </div>
                      </div>
                      <p className="text-xs font-medium text-gray-500 py-1">
                        Ending soon
                      </p>
                    </label>
                  </div> */}
                </div>
              </div>

              <InfiniteScroll
                dataLength={orderOutOfferListData?.length || 0}
                next={() => {
                  setOrderOutOfferFilters((prev: any) => {
                    return {
                      ...prev,
                      page: prev?.page + 1,
                    };
                  });
                }}
                hasMore={
                  orderOutOfferData?.meta?.current_page <
                  orderOutOfferData?.meta?.last_page
                }
                loader={<></>}
                scrollThreshold="1.25rem"
                scrollableTarget="ordersonoffer"
              >
                <div
                  id="ordersonoffer"
                  className="orderList px-2.5 flex flex-wrap max-h-[420px] overflow-auto scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full "
                >
                  {orderOutOfferListData?.length > 0 ? (
                    orderOutOfferListData?.map((item: any) => {
                      return (
                        <OrderOutOfferCard
                          order={item}
                          scrollToTop={scrollToTop}
                        />
                      );
                    })
                  ) : (
                    <div className="w-full h-full flex items-center justify-center">
                      <div className="text-center text-sm py-4">
                        No records could be found at the moment.
                      </div>
                    </div>
                  )}
                  {ourderOutOfferLoading && (
                    <>
                      <div className="singleOrder w-full md:w-1/2 lg2:w-1/4 px-2.5 mb-5 h-[17.25rem]">
                        <div className="shimmer-effect h-full w-full"></div>
                      </div>
                      <div className="singleOrder w-full md:w-1/2 lg2:w-1/4 px-2.5 mb-5 h-[17.25rem]">
                        <div className="shimmer-effect h-full w-full"></div>
                      </div>
                      <div className="singleOrder w-full md:w-1/2 lg2:w-1/4 px-2.5 mb-5 h-[17.25rem]">
                        <div className="shimmer-effect h-full w-full"></div>
                      </div>
                      <div className="singleOrder w-full md:w-1/2 lg2:w-1/4 px-2.5 mb-5 h-[17.25rem]">
                        <div className="shimmer-effect h-full w-full"></div>
                      </div>
                    </>
                  )}
                </div>
              </InfiniteScroll>
            </>
          )}
        </Card>
      </div>
    </>
  );
};

export default OrderOutOffer;
