/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
import _, { isArray, size } from "lodash";
import moment from "moment";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useDropzone } from "react-dropzone";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { Tooltip } from "react-tooltip";
import styled from "styled-components";
import clearSVG from "../../../assets/media/icons/other_icons/Clear.svg";
import CloneSVG from "../../../assets/media/icons/other_icons/Clone.svg";
import collapseSVG from "../../../assets/media/icons/other_icons/Collapse.svg";
import deleteSVG from "../../../assets/media/icons/other_icons/Delete.svg";
import eyeSVG from "../../../assets/media/icons/other_icons/Eye.svg";
import publishGraySVG from "../../../assets/media/icons/other_icons/Publish-Gray.svg";
import uploadSVG from "../../../assets/media/icons/other_icons/Upload.svg";
import checkSVG from "../../../assets/media/icons/other_icons/check.svg";
import mobileSVG from "../../../assets/media/icons/other_icons/mobile.svg";
import pinSVG from "../../../assets/media/icons/other_icons/pin.svg";
import {
  default as Process,
  default as processSVG,
} from "../../../assets/media/icons/other_icons/process.svg";

import angleLeftSVG from "../../../assets/media/icons/standard_icons/angle_left.svg";
import angleRightSVG from "../../../assets/media/icons/standard_icons/angle_right.svg";
import Calendar from "../../../assets/media/icons/standard_icons/calendar.svg";
import ClockOutline from "../../../assets/media/icons/standard_icons/clock-outline.svg";
import {
  default as Cross,
  default as crossSVG,
} from "../../../assets/media/icons/standard_icons/cross.svg";
import Geo from "../../../assets/media/icons/standard_icons/geo_icon.svg";
import ticketSVG from "../../../assets/media/icons/standard_icons/ticket-1.svg";
import warningSVG from "../../../assets/media/icons/standard_icons/warning.svg";
import { LayoutContext } from "../../../components/core/LayoutProvider";
import DropDownField from "../../../components/formComponent/DropDownField";
import Textfield from "../../../components/formComponent/Textfield";
import TableDropdown from "../../../components/tableComponent/TableDropdown";
import { IKTSVG } from "../../../components/ui/IKTSVG";
import {
  checkingValidUrlForBoth,
  MobileInsturction,
  uploadFileWithPreAssignedURL,
} from "../../../helpers/AssetHelpers";
import {
  getUserConfig,
  hasExtension,
  ticketTypeRenameForSalesReport,
} from "../../../helpers/Functions";
import Loader from "../../../helpers/Loader";
import { showAlert } from "../../../helpers/ShowAlert";
import { PDFFailedLoad } from "../../../helpers/const";
import { copy, generateUniqueId } from "../../add_inventory/core/_functions";
import AdditionalTemplate from "../component/AdditionalTemplate";
import UploadInstruction from "../component/UploadInstruction";
import { SalesContext } from "../core/SalesProvider";
import { getDynamicHTML } from "../core/_functions";
import { salesFulfillment, shippingLabel } from "../core/_requests";
import SalesMobilelinkUrl from "./SalesMobilelinkUrl";
// window.Buffer = window.Buffer || require("buffer").Buffer;

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

//Styled Component for Styling the Assigned List
const List: any = styled.li`
  background-color: ${(props) =>
    // @ts-ignore
    props?.checkedState || props?.assigned ? "" : "white"};
`;
const ETicketUpload = ({
  data,
  handleUploadTicket,
  optionsData,
  instructionLetterTemplates,
  setInstructionLetterTemplates,
}: any) => {
  const [files, setFiles] = useState<any>([]);
  const [pdfView, setPdfView] = useState<boolean>(false);
  const [filePreview, setFilePreview] = useState<any>();
  const [numPages, setNumPages] = useState<any>(null);
  const [pageNumber, setPageNumber] = useState<any>(1);
  const [hoverFile, setHoverFile] = useState<any>("");
  const [checkedState, setCheckedState] = useState(false);
  const [grouping, setGrouping] = useState<any>();
  const [possibleTicketTypes, setPossibleTicketTypes] = useState<any>([]);
  const [filterOrders, setFilterOrders] = useState<any>();
  const [isCopied, setIsCopied] = useState<any>({
    id: "",
    value: "",
  });
  const [template, setTemplate] = useState<any>(null);
  const [templateView, setTemplateView] = useState(false);
  const [templateHTML, setTemplateHTML] = useState("");
  const [templateInputes, setTemplateInputes] = useState<any>({});
  const [fullfilledOrders, setFullfilledOrders] = useState<any>([]);

  const eventRef = useRef<any>();

  // Function to handle input changes
  const handleInputChange = (
    e: any,
    eventRecordIndex: any,
    eventIndex: any,
    key: any
  ) => {
    setGrouping((prev: any) => {
      return prev.map((orders: any, inEventIndex: number) => {
        if (inEventIndex === eventIndex) {
          return {
            ...orders,
            orders: orders?.orders?.map(
              (order: any, evnetRecordIndexi: number) => {
                if (eventRecordIndex === evnetRecordIndexi) {
                  let inputs = {
                    ...order?.inputs,

                    [key]: e?.target?.value,
                  };
                  setTemplateInputes(inputs);
                  return {
                    ...order,
                    inputs: inputs,
                  };
                } else {
                  return order;
                }
              }
            ),
          };
        } else {
          return orders;
        }
      });
    });
  };

  const layout = useContext(SalesContext);
  const globalLayout = useContext(LayoutContext);
  const orders = layout.orders;
  const deliveryPartners = optionsData?.data?.delivery_partner;

  const activeTemplates =
    instructionLetterTemplates?.instruction_letter_templetes?.filter(
      (activeTemplate: any) => activeTemplate.status === 1
    );

  const urlRegex: any = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;

  function isValidUrl(url: any) {
    return urlRegex.test(url);
  }

  // All Functions to handle the PDF's
  const { getRootProps, getInputProps, open } = useDropzone({
    // HANDLE FILE SIZE
    maxSize: 5244899, // 5MB in bytes
    onDrop: (acceptedFiles, rejectedFiles) => {
      const uniqueCodes = Array.from(
        new Set(
          rejectedFiles.flatMap((item: any) =>
            item.errors.map((error: any) =>
              error.code === "file-too-large"
                ? "Please upload less then 5MB"
                : "Invalid File Type"
            )
          )
        )
      );
      if (uniqueCodes && uniqueCodes.length > 0) {
        showAlert(uniqueCodes?.join(", <br/>"), true);
      }

      // HANDLE FILE UPLOAD IN STATE
      setFiles([
        ...files,
        ...acceptedFiles?.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            id: generateUniqueId(),
          })
        ),
      ]);
    },
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    accept: {
      "application/pdf": [],
      "image/jpeg": [],
      "image/jpg": [],
      "image/png": [],
      "vnd.apple.pkpass": [".pkpass", "pkpass"],
    },
  });

  //Function to Handle File Upload from Right side Component
  var evnIndex: any;
  var eveRecordIndex: any;
  var Order_Id: any;
  const handleRightUpload = (
    eventIndex: any,
    eventRecordIndex: any,
    orderId: any
  ) => {
    evnIndex = eventIndex;
    eveRecordIndex = eventRecordIndex;
    Order_Id = orderId;
    rightSideUpload.open();
  };

  const rightSideUpload = useDropzone({
    onDrop: (acceptedFiles) => {
      setGrouping((currentFile: any) => {
        let finalResult = currentFile?.map((orders: any, inEventIndex: any) => {
          if (inEventIndex === evnIndex) {
            return {
              ...orders,
              orders: orders?.orders?.map((order: any) => {
                if (order.order_id === Order_Id) {
                  let asd = order?.items?.map(
                    (item: any, itemIndex: number, itemArr: any) => {
                      if (acceptedFiles.length <= itemArr.length) {
                        return {
                          ...item,
                          ...(item?.ticketFiles
                            ? {
                                ticketFiles: [
                                  ...item?.ticketFiles,
                                  ...acceptedFiles?.map((file) =>
                                    Object.assign(file, {
                                      preview: URL.createObjectURL(file),
                                      id: generateUniqueId(),
                                      assigned: 1,
                                    })
                                  ),
                                ],
                              }
                            : {
                                ticketFiles: [
                                  ...acceptedFiles?.map((file) =>
                                    Object.assign(file, {
                                      preview: URL.createObjectURL(file),
                                      id: generateUniqueId(),
                                      assigned: 1,
                                    })
                                  ),
                                ],
                              }),
                        };
                      } else {
                        return item;
                      }
                    }
                  );

                  return {
                    ...order,
                    items: asd,
                  };
                } else {
                  return order;
                }
              }),
            };
          } else {
            return orders;
          }
        });

        return finalResult;
      });

      setFiles([
        ...files,
        ...acceptedFiles?.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            id: generateUniqueId(),
            assigned: 1,
          })
        ),
      ]);
    },
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    accept: {
      "application/pdf": [],
    },
  });

  // Function to view PDF
  const pdfViewer = (file: any) => {
    setPageNumber(1);
    setFilePreview(file);
    setPdfView(true);
    setTemplateView(false);

    setTimeout(() => {
      if (globalLayout?.isMobile) {
        const element = document.getElementById("pdfScroll");
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }
    }, 400);
  };

  // Function for Deleting PDF
  const deletePdf = (file: any, index: number) => {
    setFiles((currentPDF: any) =>
      currentPDF.filter(
        (item: any, filterIndex: number) => index !== filterIndex
      )
    );
  };

  const handleClosePopup = () => {
    setFiles([]);
  };

  function onDocumentLoadSuccess({ numPages }: any) {
    setNumPages(numPages);
  }

  // a little function to help us with reordering the result
  const reorder = (list: any, startIndex: any, endIndex: any) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const [mobileLinkResponse, setMobileLinkResponse] = useState<any>();

  // MOBILE LINK UPLOAD
  const uploadMobileLinkAWS = async (
    event: any,
    order: any,
    data: any,
    dropData: any
  ) => {
    let eventId = event?.event_id;
    let orderId = order?.order_id;
    let dataID = data?.id;
    let ticketData = data;

    try {
      // const data = await s3Upload.uploadFile(dropData);
      const data = await uploadFileWithPreAssignedURL(
        dropData,
        `ticket-files/${order?.order_id}`
      );
      setMobileLinkResponse((current: any) =>
        current && size(current) > 0
          ? [
              ...current,
              {
                eventId,
                orderId,
                ticketData,
                url: data?.location,
              },
            ]
          : [
              {
                eventId,
                orderId,
                ticketData,
                url: data?.location,
              },
            ]
      );
    } catch (error) {}
  };

  useEffect(() => {
    if (size(mobileLinkResponse) > 0) {
      setGrouping((current: any) => {
        return current?.map((event: any) => {
          let eventId = mobileLinkResponse?.find(
            (item: any) => item?.eventId === event?.event_id
          );
          if (event?.event_id === eventId?.eventId) {
            return {
              ...event,
              orders: event?.orders?.map((order: any) => {
                let orderID = mobileLinkResponse?.find(
                  (item: any) => item?.orderId === order?.order_id
                );
                if (orderID) {
                  return {
                    ...order,
                    items: order?.items?.map((item: any) => {
                      let ticketID = mobileLinkResponse?.find(
                        (ticketItem: any) =>
                          ticketItem?.ticketData?.id === item?.id
                      );

                      if (ticketID?.ticketData?.id) {
                        setMobileLinkResponse((current: any) => {
                          return current?.filter(
                            (mobileResponse: any) =>
                              mobileResponse?.ticketData?.id !== item?.id
                          );
                        });
                        return {
                          ...item,
                          link: ticketID?.url,
                          isValid: true,
                        };
                      } else return item;
                    }),
                  };
                } else return order;
              }),
            };
          } else return event;
        });
      });
    }
  }, [mobileLinkResponse]);

  /**
   * Moves an item from one list to another list.
   */

  const move = (
    source: any,
    destination: any,
    droppableSource: any,
    droppableDestination: any
  ) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);
    destClone.splice(droppableDestination.index, 0, removed);

    const result: any = {};

    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
  };

  function getExtension(filename: any) {
    return filename?.split(".").pop();
  }

  let onDragEnd = (result: any) => {
    for (var i = 0; i < dragArea.length; i++) {
      dragArea[i].classList.remove("!border-green-500");
    }
    const { destination, source } = result;
    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      // LEFT SIDE INNER DRAG
      if (destination.droppableId === "eventTicketUploadedFiles") {
        const items = reorder(files, source.index, destination.index);
        setFiles(items);
      }
    }
    // DRAG FROM RIGHT TO LEFT
    else if (destination.droppableId === "eventTicketUploadedFiles") {
    }
    // DRAG FROM LEFT TO RIGHT
    else if (source.droppableId === "eventTicketUploadedFiles") {
      let destinationIndexs = destination.droppableId.split("-");
      let orderRecord: any =
        grouping?.[destinationIndexs[0]]?.["orders"][destinationIndexs[1]];

      let destinationFiles = null;

      // for addtional file
      if (destinationIndexs[2] === "additionalFile") {
        destinationFiles =
          orderRecord?.ticket_type === "eticket" ||
          orderRecord?.ticket_type === "PDF" ||
          orderRecord?.ticket_type === "paper" ||
          orderRecord?.ticket_type === "mobile" ||
          orderRecord?.ticket_type === "local-delivery" ||
          orderRecord?.ticket_type === "mobile-link" ||
          orderRecord?.ticket_type === "season-cards"
            ? grouping?.[destinationIndexs[0]]?.["orders"][destinationIndexs[1]]
            : grouping?.[destinationIndexs[0]]?.["orders"]?.addtionalFile;
      } else {
        destinationFiles =
          orderRecord?.ticket_type === "eticket" ||
          orderRecord?.ticket_type === "PDF" ||
          orderRecord?.ticket_type === "mobile-link"
            ? grouping?.[destinationIndexs[0]]?.["orders"][destinationIndexs[1]]
                ?.items?.[destinationIndexs[2]]
            : grouping?.[destinationIndexs[0]]?.["orders"][destinationIndexs[1]]
                ?.ticketFiles
            ? grouping?.[destinationIndexs[0]]?.["orders"][destinationIndexs[1]]
                ?.ticketFiles
            : {};
      }

      const move_result = move(
        files,
        isArray(destinationFiles) ? destinationFiles : [],
        source,
        destination
      );

      const markAssigned = () => {
        files.map((file: any, index: any) => {
          if (source.index === index) {
            file.assigned = 1;
          }
        });
      };

      setFulfilled(false);

      setGrouping((currentOrders: any) => {
        let finalResult = currentOrders?.map(
          (orders: any, eventIndex: number) => {
            if (parseInt(destinationIndexs[0]) === eventIndex) {
              const allItemsAreNotPkpass = move_result[
                destination.droppableId
              ]?.every((item: any) => getExtension(item.path) !== "pkpass");
              return {
                ...orders,
                orders: orders?.orders?.map(
                  (order: any, evnetRecordIndex: number, arr: any) => {
                    if (parseInt(destinationIndexs[1]) === evnetRecordIndex) {
                      if (
                        orderRecord?.ticket_type === "mobile" &&
                        allItemsAreNotPkpass
                      ) {
                        markAssigned();
                      }
                      if (orderRecord?.ticket_type === "local-delivery") {
                        markAssigned();
                      }
                      // addtional file
                      if (destinationIndexs[2] === "additionalFile")
                        markAssigned();
                      // addtional file
                      if (
                        destinationIndexs[2] === "additionalFile" &&
                        orderRecord?.addtionalFile
                      ) {
                        deletePDFFromFiles(orderRecord?.addtionalFile?.id);
                      }

                      let final = {
                        ...order,
                        errors: "",
                        // addtional file
                        ...(destinationIndexs[2] === "additionalFile" &&
                        (orderRecord?.ticket_type === "eticket" ||
                          orderRecord?.ticket_type === "PDF" ||
                          orderRecord?.ticket_type === "paper" ||
                          orderRecord?.ticket_type === "mobile" ||
                          orderRecord?.ticket_type === "local-delivery" ||
                          orderRecord?.ticket_type === "season-cards" ||
                          orderRecord?.ticket_type === "mobile-link")
                          ? {
                              addtionalFile:
                                move_result[destination.droppableId]?.[0],
                            }
                          : (orderRecord?.ticket_type === "eticket" ||
                              orderRecord?.ticket_type === "PDF") &&
                            allItemsAreNotPkpass
                          ? {
                              items: order?.items?.map(
                                (arrItem: any, arrIndex: number) => {
                                  if (
                                    parseInt(destinationIndexs[2]) === arrIndex
                                  ) {
                                    // FILE REPLACE
                                    arrItem?.ticketFiles &&
                                      deletePDFFromFiles(
                                        arrItem?.ticketFiles?.id
                                      );

                                    // RESTRICT TO UPLOAD IMAGES
                                    if (
                                      move_result[destination.droppableId]?.[0]
                                        ?.type !== "image/jpeg" &&
                                      move_result[destination.droppableId]?.[0]
                                        ?.type !== "image/png" &&
                                      move_result[destination.droppableId]?.[0]
                                        ?.type !== "image/jpg"
                                    ) {
                                      markAssigned();
                                    }
                                    const file =
                                      move_result[destination.droppableId]?.[0];
                                    const fileType = file?.type;
                                    const filePath = file?.path;

                                    const isValidFileType = (type: any) =>
                                      [
                                        "application/pdf",
                                        "image/jpeg",
                                        "image/jpg",
                                        "image/png",
                                      ].includes(type);

                                    const isValidFileExtension = (
                                      path: any
                                    ) => {
                                      const extension =
                                        getExtension(path)?.toLowerCase();
                                      return [
                                        "pdf",
                                        "jpeg",
                                        "jpg",
                                        "png",
                                      ].includes(extension);
                                    };

                                    return {
                                      ...arrItem,
                                      ticketFiles:
                                        move_result[
                                          destination.droppableId
                                        ]?.[0]?.type === "application/pdf" ||
                                        getExtension(
                                          move_result[
                                            destination.droppableId
                                          ]?.[0]?.path
                                        )?.toLowerCase() === "pdf"
                                          ? move_result[
                                              destination.droppableId
                                            ]?.[0]
                                          : undefined,
                                    };
                                  } else {
                                    return arrItem;
                                  }
                                }
                              ),
                            }
                          : orderRecord?.ticket_type === "mobile-link"
                          ? {
                              items: order?.items?.map(
                                (arrItem: any, arrIndex: number) => {
                                  if (
                                    parseInt(destinationIndexs[2]) === arrIndex
                                  ) {
                                    // FILE REPLACE
                                    arrItem?.ticketFiles &&
                                      deletePDFFromFiles(
                                        arrItem?.ticketFiles?.id
                                      );
                                    markAssigned();

                                    let final = {
                                      ...arrItem,
                                      link: "",
                                      ticketFiles:
                                        getExtension(
                                          move_result[
                                            destination.droppableId
                                          ]?.[0]?.path
                                        )?.toLowerCase() === "pkpass"
                                          ? move_result[
                                              destination.droppableId
                                            ]?.[0]
                                          : undefined,
                                    };
                                    delete final?.isValid;
                                    uploadMobileLinkAWS(
                                      orders,
                                      order,
                                      final,
                                      move_result[destination.droppableId]?.[0]
                                    );

                                    return final;
                                  } else {
                                    return arrItem;
                                  }
                                }
                              ),
                            }
                          : {
                              // FOR ticket_type === "mobile"
                              ticketFiles: allItemsAreNotPkpass
                                ? move_result[destination.droppableId]
                                : order?.ticketFiles,
                              errors: "",
                            }),
                      };

                      return {
                        ...final,
                        isFull:
                          orderRecord?.ticket_type === "paper"
                            ? final.isFull
                            : (orderRecord?.ticket_type === "mobile" ||
                                orderRecord?.ticket_type ===
                                  "local-delivery") &&
                              final?.ticketFiles?.length > 0
                            ? true
                            : final?.items?.every((item: any) => {
                                if (
                                  orderRecord?.ticket_type === "mobile-link" &&
                                  !item?.ticketFiles
                                ) {
                                  return (
                                    item?.link &&
                                    item?.isValid &&
                                    !item?.ticketFiles?.path
                                  );
                                } else {
                                  return item?.ticketFiles?.path;
                                }
                              }),
                      };
                    } else {
                      return order;
                    }
                  }
                ),
              };
            } else {
              return orders;
            }
          }
        );
        return finalResult;
      });
    }
  };
  const [paperLoader, setPaperLoader] = useState<boolean>(false);
  // HANDLE SHIPPING
  const handleShippingLabel = (order: any, item: any) => {
    setPaperLoader(true);
    shippingLabel({ order_id: item?.order_id })
      .then((response: any) => {
        if (response?.data?.status === "true") {
          setPaperLoader(false);
          setGrouping((current: any) => {
            return current?.map((orders: any) => {
              if (orders?.event_id === order?.event_id) {
                return {
                  ...orders,
                  orders: orders?.orders?.map((record: any) => {
                    if (record?.order_id === item?.order_id) {
                      return {
                        ...record,
                        shippmentTrakingNo:
                          response?.data?.shipping_tracking_id,
                        isFull: true,
                        shipping_download: response?.data?.shipping_label
                          ? response?.data?.shipping_label
                          : "",
                      };
                    } else {
                      return record;
                    }
                  }),
                };
              } else {
                return orders;
              }
            });
          });
        } else {
          setPaperLoader(false);
        }
      })
      .catch((e: any) => {})
      .finally(() => {
        setPaperLoader(false);
      });
  };

  // HANDLE SHIPPING TRACKING NO - PAPER
  const handleTicketNo = (
    eventId: any,
    recordId: any,
    event: any,
    isConfirm: any,
    isDelete: any
  ) => {
    setGrouping((currentOrders: any) => {
      setFulfilled(false);
      let finalResult = currentOrders?.map(
        (orders: any, eventIndex: number) => {
          if (parseInt(eventId) === eventIndex) {
            let finalOrder = {
              ...orders,
              orders: orders?.orders?.map(
                (order: any, evnetRecordIndex: number) => {
                  if (parseInt(recordId) === evnetRecordIndex) {
                    return {
                      ...order,
                      isFull: isConfirm,
                      errors: isDelete && "",
                      shippmentTrakingNo: isConfirm
                        ? order?.shippmentTrakingNo
                        : isDelete
                        ? ""
                        : event?.target?.value,
                      shipping_download:
                        order?.shipping_label_required === "true" && isDelete
                          ? ""
                          : order?.shipping_download,
                    };
                  } else {
                    return order;
                  }
                }
              ),
            };
            return finalOrder;
          } else {
            return orders;
          }
        }
      );
      return finalResult;
    });
  };

  // HANDLE DELVIERY PARTNER - PAPER
  const handleDeliverypartner = (eventId: any, recordId: any, event: any) => {
    setGrouping((currentOrders: any) => {
      setFulfilled(false);
      let finalResult = currentOrders?.map(
        (orders: any, eventIndex: number) => {
          if (parseInt(eventId) === eventIndex) {
            let finalOrder = {
              ...orders,
              orders: orders?.orders?.map(
                (order: any, evnetRecordIndex: number) => {
                  if (parseInt(recordId) === evnetRecordIndex) {
                    if (event?.target?.value?.id) {
                      handleTicketNo(eventId, recordId, event, false, true);
                    }
                    return {
                      ...order,
                      deliveryPartner: event?.target?.value?.id,
                      shippmentTrakingNo: event?.target?.value?.id
                        ? order?.shippmentTrakingNo
                        : "",
                      isFull: event?.target?.value?.id ? order?.isFull : false,
                    };
                  } else {
                    return order;
                  }
                }
              ),
            };
            return finalOrder;
          } else {
            return orders;
          }
        }
      );
      return finalResult;
    });
  };

  const handleMobileLinkChange = (
    eventId: any,
    recordId: any,
    event: any,
    isConfirm: any,
    isDelete: any,
    ticketFileIndex: any,
    ticketType: any
  ) => {
    setGrouping((currentOrders: any) => {
      setFulfilled(false);
      let finalResult = currentOrders?.map(
        (orders: any, eventIndex: number) => {
          if (parseInt(eventId) === eventIndex) {
            let finalOrder = {
              ...orders,
              orders: orders?.orders?.map(
                (order: any, evnetRecordIndex: number) => {
                  if (parseInt(recordId) === evnetRecordIndex) {
                    let items = order?.items?.map(
                      (item: any, itemIndex: number) => {
                        if (itemIndex === ticketFileIndex) {
                          if (isDelete) {
                            if (order?.separate_links) {
                              markAssignedZero(item?.ticketFiles?.id);
                              delete item?.isValid;
                              delete item?.ticketFiles;

                              if (ticketType === "android") {
                                delete item?.isValidAndroid;

                                const final = {
                                  ...item,
                                  link: {
                                    ...item?.link,
                                    android: "",
                                  },
                                };
                                return final;
                              } else if (ticketType === "ios") {
                                delete item?.isValidIos;

                                const final = {
                                  ...item,
                                  link: {
                                    ...item?.link,
                                    ios: "",
                                  },
                                };
                                return final;
                              }
                            } else {
                              markAssignedZero(item?.ticketFiles?.id);
                              delete item?.isValid;
                              delete item?.ticketFiles;
                              return { ...item, link: "" };
                            }
                          } else if (isConfirm) {
                            if (order?.separate_links) {
                              return {
                                ...item,
                                isValidAndroid: isValidUrl(item?.link?.android),
                                isValidIos: isValidUrl(item?.link?.ios),
                                isValid: checkingValidUrlForBoth(item?.link),
                              };
                            }

                            return {
                              ...item,
                              isValid: isValidUrl(item?.link),
                            };
                          } else {
                            markAssignedZero(item?.ticketFiles?.id);
                            delete item?.isValid;
                            delete item?.ticketFiles;

                            const mobileLinkData = {
                              android:
                                ticketType === "android"
                                  ? event?.target?.value
                                  : item?.link?.android || null,
                              ios:
                                ticketType === "ios"
                                  ? event?.target?.value
                                  : item?.link?.ios || null,
                            };

                            return {
                              ...item,
                              link: order?.separate_links
                                ? mobileLinkData
                                : event?.target?.value,
                            };
                          }
                        } else {
                          return item;
                        }
                      }
                    );
                    let final = {
                      ...order,
                      isFull: items?.every((item: any) => item?.isValid),
                      errors: isDelete && "",
                      items: items,
                    };
                    return final;
                  } else {
                    return order;
                  }
                }
              ),
            };
            return finalOrder;
          } else {
            return orders;
          }
        }
      );
      return finalResult;
    });
  };

  // REMOVE MOBILE LINK FILES
  const deleteMobileLink = (
    eventIndex: any,
    eventRecordIndex: any,
    ticketFileIndex: any
  ) => {
    setGrouping((current: any) => {
      return current?.map((event: any, eventInd: number) => {
        if (eventInd === eventIndex) {
          return {
            ...event,
            orders: event?.orders?.map((order: any, orderIndex: number) => {
              if (orderIndex === eventRecordIndex) {
                return {
                  ...order,
                  isFull: false,
                  items: order?.items?.map((item: any, itemIndex: number) => {
                    if (itemIndex === ticketFileIndex) {
                      markAssignedZero(item?.ticketFiles?.id);
                      delete item?.isValid;
                      return {
                        ...item,
                        link: null,
                        ticketFiles: [],
                      };
                    } else return item;
                  }),
                };
              } else return order;
            }),
          };
        } else return event;
      });
    });
  };

  const markAssignedZero = (id: any) => {
    files.map((file: any, index: any) => {
      if (file?.id === id) {
        file.assigned = 0;
      }
    });
  };

  const deletePDFFromFiles = (id: any) => {
    markAssignedZero(id);
    // setFiles((prev: any) => {

    //   return prev.map((inFile: any) => {

    //     if (inFile.id === id) {
    //       return {
    //         ...inFile,
    //         assigned: 0,
    //         // added
    //         type: inFile?.type,
    //         name: inFile.name,
    //         lastModified: inFile.lastModified,
    //         size: inFile.size,
    //         lastModifiedDate: inFile.lastModifiedDate,
    //         webkitRelativePath: inFile.webkitRelativePath,
    //       };
    //     } else {
    //       return inFile;
    //     }
    //   });
    // });
  };

  // Function for Deleting PDF's from Right Side Component
  const handleChangeTemplate = (
    template: any,
    eventRecordIndex: number,
    eventIndex: number
  ) => {
    setGrouping((prev: any) => {
      return prev.map((orders: any, inEventIndex: number) => {
        if (inEventIndex === eventIndex) {
          return {
            ...orders,
            orders: orders?.orders?.map(
              (order: any, evnetRecordIndexi: number) => {
                if (eventRecordIndex === evnetRecordIndexi) {
                  const regex = /\[([^\]:]*):?DYNAMIC_CONTENT\]/g;
                  const foundKeys = new Set();
                  let match;
                  while (
                    (match = regex.exec(template?.html_content)) !== null
                  ) {
                    // Trim any trailing whitespace and ensure no colon is included
                    foundKeys.add((match[1] || "Dynamic content").trim());
                  }
                  return {
                    ...order,
                    template: template,
                    templateKeys: Array.from(foundKeys),
                    inputs: {},
                  };
                } else {
                  return order;
                }
              }
            ),
          };
        } else {
          return orders;
        }
      });
    });
    // markAssignedZero(file?.id);
  };

  // Function for Deleting PDF's from Right Side Component
  const deletePdfRightSide = (
    file: any,
    index: any,
    eventRecordIndex: number,
    eventIndex: number
  ) => {
    // addtional file
    if (index === "additionalFile") {
      setGrouping((prev: any) => {
        return prev.map((orders: any, inEventIndex: number) => {
          if (inEventIndex === eventIndex) {
            return {
              ...orders,
              orders: orders?.orders?.map(
                (order: any, evnetRecordIndexi: number) => {
                  if (eventRecordIndex === evnetRecordIndexi) {
                    if (
                      order?.ticket_type === "mobile" ||
                      order?.ticket_type === "local-delivery"
                    ) {
                      return {
                        ...order,

                        addtionalFile: "",
                      };
                    } else {
                      // FOR PDF / ETICKET
                      return {
                        ...order,
                        addtionalFile: "",
                      };
                    }
                  } else {
                    return order;
                  }
                }
              ),
            };
          } else {
            return orders;
          }
        });
      });
      markAssignedZero(file?.id);
      return;
    }

    setGrouping((prev: any) => {
      return prev.map((orders: any, inEventIndex: number) => {
        if (inEventIndex === eventIndex) {
          return {
            ...orders,
            orders: orders?.orders?.map(
              (order: any, evnetRecordIndexi: number) => {
                if (eventRecordIndex === evnetRecordIndexi) {
                  if (
                    order?.ticket_type === "mobile" ||
                    order?.ticket_type === "local-delivery"
                  ) {
                    let items = {
                      ...order,
                      ticketFiles: order?.ticketFiles.filter(
                        (ticketFile: any, ticketFileIndex: any) =>
                          ticketFileIndex !== index
                      ),
                    };
                    return {
                      ...items,
                      isFull:
                        items?.ticketFiles && items?.ticketFiles?.length > 0
                          ? true
                          : false,
                    };
                  } else {
                    // FOR PDF / ETICKET
                    let items = {
                      ...order,
                      items: order?.items?.map(
                        (ticketFile: any, ticketFileIndex: any) => {
                          if (index === ticketFileIndex) {
                            return {
                              ...ticketFile,
                              ticketFiles: "",
                            };
                          } else {
                            return ticketFile;
                          }
                        }
                      ),
                    };
                    return {
                      ...items,
                      isFull: items?.items?.every(
                        (item: any) => item?.ticketFiles?.path
                      ),
                    };
                  }
                } else {
                  return order;
                }
              }
            ),
          };
        } else {
          return orders;
        }
      });
    });
    markAssignedZero(file?.id);
  };

  const HeaderInfo = ({
    title,
    imgPath,
    location = false,
    className = " flex items-center pr-4 text-xs",
  }: any) => {
    return (
      <div
        className={`${className} ${
          location ? "flex-auto max-md:w-full pr-0`" : ""
        }`}
      >
        {imgPath && (
          <IKTSVG
            className="flex items-center justify-center min-w-[0.75] fill-white mr-2"
            path={imgPath}
          />
        )}
        <p title={title}>{title && title}</p>
      </div>
    );
  };

  // CREATE GROUP OF EVENTS
  useEffect(() => {
    setGrouping((current: any) => {
      let ticketTypes: any = [];
      // GET ONLY SELECTED RECORDS
      let filteredOrders = orders.filter((order) => order.selected === true);

      filteredOrders = filteredOrders?.map((item: any) => {
        if (item?.ticket_type === "mobile-transfer") {
          ticketTypes.push("mobile");
          return {
            ...item,
            ticket_type: "mobile",
          };
        } else {
          ticketTypes.push(item?.ticket_type);
          return {
            ...item,
          };
        }
      });

      setFilterOrders(filteredOrders);

      setPossibleTicketTypes(ticketTypes);

      // MAKE GROUP OF SELECTED GROUPS OF EVENT
      const categorizedOrders = filteredOrders.reduce((acc, order) => {
        const eventOrders = acc.find(
          (category: any) => category.event_id === order.event_id
        );
        if (eventOrders) {
          eventOrders.orders.push({
            ...order,
            name: eventOrders.name,
            date: eventOrders.date,
            address: eventOrders.address,
            qty: eventOrders.qty,
          });
        } else {
          acc.push({
            event_id: order.event_id,
            name: order.event_description,
            date: order.order_date_time,
            time: order.order_datetime,
            address: order.venue,
            qty: order.items?.length,
            orders: [{ ...order, event_description: "event description" }],
          });
        }
        return acc;
      }, []);

      let finalResult = categorizedOrders?.map((group: any) => {
        return {
          ...group,
          orders: group?.orders?.map((order: any) => {
            if (!order?.can_be_fulfilled) {
              // PDF && ETICKE
              if (order?.ticket_type === "mobile-link") {
                return {
                  ...order,
                  // can_be_fulfilled: true
                };
              }
              if (
                order?.ticket_type === "PDF" ||
                order?.ticket_type === "eticket"
              ) {
                return {
                  ...order,
                  items: order?.items?.map((item: any, index: number) => {
                    return {
                      ...item,
                      ticketFiles:
                        order?.fulfilled_ticket_files?.e_tickets[index]
                          ?.is_url === 0
                          ? {
                              preview:
                                order?.fulfilled_ticket_files?.e_tickets[index]
                                  ?.file,
                            }
                          : order?.fulfilled_ticket_files?.e_tickets[index]
                              ?.file,
                    };
                  }),
                };
              }

              // MOBILE
              if (
                order?.ticket_type === "mobile" ||
                order?.ticket_type === "local-delivery"
              ) {
                return {
                  ...order,
                  ticketFiles:
                    order?.fulfilled_ticket_files?.mobile_transfer_proofs,
                };
              }
              // PAPER
              if (
                order?.ticket_type === "paper" ||
                order?.ticket_type === "Paper" ||
                order?.ticket_type === "season-cards"
              ) {
                return {
                  ...order,
                  shippmentTrakingNo:
                    order?.fulfilled_ticket_files?.shipping_tracking_id,
                };
              }
            } else {
              if (
                order?.ticket_type === "mobile" ||
                order?.ticket_type === "local-delivery"
              ) {
                return {
                  ...order,
                  isActive: false,
                };
              } else {
                return order;
              }
            }
          }),
        };
      });

      return finalResult?.map((orders: any) => {
        return {
          ...orders,
          orders: orders?.orders?.map((record: any) => {
            if (
              record?.ticket_type === "paper" ||
              record?.ticket_type === "Paper" ||
              record?.ticket_type === "season-cards"
            ) {
              // shippmentTrakingNo
              // shipping_label_required
              let trackNo =
                record?.fulfilled_ticket_files?.shipping_tracking_id
                  ?.shipping_tracking_id;
              let printLabel =
                record?.fulfilled_ticket_files?.shipping_tracking_id
                  ?.shipping_label;
              let isShippingLabelRequired = record?.is_shipping_label_provided;
              return {
                ...record,
                ...(isShippingLabelRequired
                  ? {
                      shippmentTrakingNo: trackNo,
                      isFull: true,
                      printLabel: printLabel,
                    }
                  : {}),
                // shipping_label_required: "true",
              };
            } else {
              return record;
            }
          }),
        };
      });
    });
    setFulfilled(false);
  }, [orders, data]);

  // GET measurements
  const getLength = orders.filter((order: any) => order.selected === true);
  const potRequired = useMemo(
    () => getUserConfig(globalLayout, "pot_required"),
    []
  );

  console.log(potRequired, "potRequireds");

  let isAllPaper = getLength?.every(
    (item: any) =>
      item?.selected &&
      (item?.ticket_type === "paper" ||
        item?.ticket_type === "Paper" ||
        item?.ticket_type === "season-cards")
  );

  // CHECK IS ORDER IS FULL OR NOT
  let fullOrdersLength =
    grouping &&
    grouping?.flatMap((group: any) =>
      group?.orders?.filter((order: any) => {
        //check if template is selected and all input fields should be fill up
        let templateCheck = true;
        if (
          order?.template &&
          order?.template?.id &&
          order?.template?.id !== "0" &&
          order?.template?.value !== 0
        ) {
          const allKeysHaveValues: any = order?.templateKeys.every(
            (key: number) => {
              return (
                order?.inputs?.[key] !== "" &&
                order?.inputs?.[key] !== undefined &&
                order?.inputs?.[key] !== null
              );
            }
          );
          templateCheck = allKeysHaveValues;
        }
        if (order?.ticket_type === "mobile-link") {
          return (
            order?.items?.every((obj: any) => obj?.isValid) && templateCheck
          );
        } else {
          return order?.isFull === true && templateCheck;
        }
      })
    )?.length;

  // ERROR LENGTH
  let errorLength =
    grouping &&
    grouping?.flatMap((group: any) =>
      group?.orders?.filter((order: any) => order?.errors?.length > 0)
    )?.length;

  const [isFulfilled, setFulfilled] = useState<any>(false);
  const [isFulfilledLoader, setFulfilledLoader] = useState<any>(false);

  // ON SUBMIT
  const submitFulfill = () => {
    setFulfilled(true);
    setFulfilledLoader(true);
    setTemplateView(false);

    let promises: any[] = [];

    grouping?.map((group: any) => {
      group?.orders?.map(async (order: any) => {
        console.log(order, "first");
        if (
          (potRequired?.value === false && data?.ticket_type === "mobile"
            ? true
            : order?.isFull) &&
          order?.selected
        ) {
          // MOBILE
          if (
            order?.ticket_type === "mobile" ||
            order?.ticket_type === "local-delivery"
          ) {
            const orderResponse = {
              order_id: order?.order_id,
              event_id: order?.event_id,
              mobile_transfer_proofs: [],
              additional_file: [],
              ...(order?.template &&
                order?.template?.value != 0 && {
                  additional_file_html: getDynamicHTML(
                    order?.template,
                    order?.inputs
                  ),
                }),
            };

            let normalFiles;
            if (size(order?.ticketFiles) > 0) {
              normalFiles = Promise.all(
                order?.ticketFiles?.map(async (item: any) => {
                  // const res: any = await mobileS3.uploadFile(item);
                  const res: any = await uploadFileWithPreAssignedURL(
                    item,
                    `delivery-proof/${order?.order_id}`
                  );
                  return {
                    mobile_transfer_proofs: res?.key,
                  };
                })
              );
            } else {
              normalFiles = [];
            }

            const additionalFile = new Promise((resolve, reject) => {
              const addtionalFetch = async () => {
                // const additionalFileRes: any = await additonalFileS3
                //   .uploadFile(order?.addtionalFile)
                //   .then((response: any) => response);
                const additionalFileRes: any =
                  await uploadFileWithPreAssignedURL(
                    order?.addtionalFile,
                    `additional-file/${order?.order_id}`
                  );
                // @ts-ignore
                orderResponse.additional_file.push({
                  file: additionalFileRes?.key,
                });

                resolve(additionalFileRes);
              };
              setTimeout(() => {
                order?.addtionalFile && addtionalFetch();
              }, 1 * 500);
            });

            let mobilePromise;
            if (order?.addtionalFile) {
              mobilePromise = Promise.all([normalFiles, additionalFile])?.then(
                async ([normalResponse, additionalResponse]: any) => {
                  // checking for extension start below

                  const newArray = [
                    ...orderResponse?.additional_file,
                    ...normalResponse,
                  ];

                  const statusArray = newArray.map((dataObject) => {
                    for (const key in dataObject) {
                      return hasExtension(dataObject[key]);
                    }
                    return false;
                  });
                  const allTrue = statusArray.every(
                    (status) => status === true
                  );
                  let response: any;

                  if (allTrue) {
                    response = await salesFulfillment({
                      ...orderResponse,
                      mobile_transfer_proofs: _.map(
                        normalResponse,
                        "mobile_transfer_proofs"
                      ),
                    });

                    if (response?.data?.status == true) {
                      setFullfilledOrders((prev: any) => [
                        ...prev,
                        order.order_id,
                      ]);
                    }
                  } else {
                    showAlert(
                      "Something went wrong during file upload. Please try again",
                      true,
                      2500
                    );
                    return;
                  }
                  // checking for extension end  here

                  setGrouping((currentData: any) => {
                    let finalResult = currentData?.map(
                      (events: any, eventIndex: number) => {
                        if (events?.event_id === orderResponse?.event_id) {
                          return {
                            ...events,
                            orders: events?.orders?.map(
                              (orders: any, orderIndex: number) => {
                                // CHECK IF ERROR OCCUR
                                let error: any;
                                if (
                                  !response?.data?.status &&
                                  Array.isArray(response?.data?.errors)
                                ) {
                                  error = response?.data?.errors;
                                } else if (
                                  !response?.data?.status &&
                                  response?.data?.errors &&
                                  response?.data?.errors?.title
                                ) {
                                  error = response?.data?.errors?.title;
                                } else {
                                  error = "";
                                }

                                // UNASSIGNED THE FILE IF ERROR OCCUR
                                if (error) {
                                  orders?.addtionalFile &&
                                    markAssignedZero(orders?.addtionalFile?.id);
                                  orders?.ticketFiles?.length > 0 &&
                                    // setFiles((prev: any) => {
                                    //   let final = prev?.map((inFile: any) => {
                                    //     const matchingTicketFile =
                                    //       orders?.ticketFiles?.find(
                                    //         (ticketFile: any) =>
                                    //           inFile?.id === ticketFile?.id ||
                                    //           // for addtional
                                    //           orders?.addtionalFile?.id
                                    //       );
                                    //     if (matchingTicketFile) {
                                    //       return {
                                    //         ...inFile,
                                    //         assigned: 0,
                                    //         type: inFile?.type,
                                    //         name: inFile.name,
                                    //         lastModified: inFile.lastModified,
                                    //         size: inFile.size,
                                    //         lastModifiedDate:
                                    //           inFile.lastModifiedDate,
                                    //         webkitRelativePath:
                                    //           inFile.webkitRelativePath,
                                    //       };
                                    //     }
                                    //     return inFile;
                                    //   });
                                    //   return final;
                                    // });
                                    orders?.ticketFiles?.map((obj: any) => {
                                      markAssignedZero(obj?.id);
                                    });
                                }

                                if (
                                  orders?.order_id === orderResponse?.order_id
                                ) {
                                  let res = {
                                    ...orders,
                                    isFull: response?.data?.status
                                      ? true
                                      : false,
                                    errors: error,
                                    ticketFiles: "",
                                    addtionalFile: "",
                                    selected: error ? true : false,
                                  };
                                  return res;
                                } else {
                                  return orders;
                                }
                              }
                            ),
                          };
                        } else {
                          return events;
                        }
                      }
                    );
                    let isSame = getLength?.every(
                      (item: any, index: number) =>
                        index === 0 ||
                        item.ticket_type === getLength[0].ticket_type
                    );
                    if (isSame) {
                      // // setFulfilledLoader(false);;
                    }
                    return finalResult;
                  });

                  return response;
                }
              );
            } else if (!order?.addtionalFile) {
              mobilePromise = Promise.all([normalFiles])?.then(
                async (normalResponse: any) => {
                  // checking for extension start below
                  const newArray: any = normalResponse?.[0];
                  const statusArray = newArray.map((dataObject: any) => {
                    for (const key in dataObject) {
                      return hasExtension(dataObject[key]);
                    }
                    return false;
                  });
                  const allTrue = statusArray.every(
                    (status: any) => status === true
                  );
                  let response: any;

                  if (allTrue) {
                    response = await salesFulfillment({
                      ...orderResponse,
                      mobile_transfer_proofs: _.map(
                        normalResponse?.[0],
                        "mobile_transfer_proofs"
                      ),
                    });

                    if (response?.data?.status == true) {
                      setFullfilledOrders((prev: any) => [
                        ...prev,
                        order.order_id,
                      ]);
                    }
                  } else {
                    showAlert(
                      "Something went wrong during file upload. Please try again",
                      true,
                      2500
                    );
                    return;
                  }
                  // checking for extension end  here

                  setGrouping((currentData: any) => {
                    let finalResult = currentData?.map(
                      (events: any, eventIndex: number) => {
                        if (events?.event_id === orderResponse?.event_id) {
                          return {
                            ...events,
                            orders: events?.orders?.map(
                              (orders: any, orderIndex: number) => {
                                // CHECK IF ERROR OCCUR
                                let error: any;
                                if (
                                  !response?.data?.status &&
                                  Array.isArray(response?.data?.errors)
                                ) {
                                  error = response?.data?.errors;
                                } else if (
                                  !response?.data?.status &&
                                  response?.data?.errors &&
                                  response?.data?.errors?.title
                                ) {
                                  error = response?.data?.errors?.title;
                                } else {
                                  error = "";
                                }

                                // UNASSIGNED THE FILE IF ERROR OCCUR
                                if (error) {
                                  orders?.addtionalFile &&
                                    markAssignedZero(orders?.addtionalFile?.id);
                                  orders?.ticketFiles?.length > 0 &&
                                    // setFiles((prev: any) => {
                                    //   let final = prev?.map((inFile: any) => {
                                    //     const matchingTicketFile =
                                    //       orders?.ticketFiles?.find(
                                    //         (ticketFile: any) =>
                                    //           inFile?.id === ticketFile?.id ||
                                    //           // for addtional
                                    //           orders?.addtionalFile?.id
                                    //       );
                                    //     if (matchingTicketFile) {
                                    //       return {
                                    //         ...inFile,
                                    //         assigned: 0,
                                    //         type: inFile?.type,
                                    //         name: inFile.name,
                                    //         lastModified: inFile.lastModified,
                                    //         size: inFile.size,
                                    //         lastModifiedDate:
                                    //           inFile.lastModifiedDate,
                                    //         webkitRelativePath:
                                    //           inFile.webkitRelativePath,
                                    //       };
                                    //     }
                                    //     return inFile;
                                    //   });
                                    //   return final;
                                    // });
                                    orders?.ticketFiles?.map((obj: any) => {
                                      markAssignedZero(obj?.id);
                                    });
                                }
                                if (
                                  orders?.order_id === orderResponse?.order_id
                                ) {
                                  let res = {
                                    ...orders,
                                    isFull: response?.data?.status
                                      ? true
                                      : false,
                                    errors: error,
                                    ticketFiles: "",
                                    addtionalFile: "",
                                    selected: error ? true : false,
                                  };
                                  return res;
                                } else {
                                  return orders;
                                }
                              }
                            ),
                          };
                        } else {
                          return events;
                        }
                      }
                    );
                    let isSame = getLength?.every(
                      (item: any, index: number) =>
                        index === 0 ||
                        item.ticket_type === getLength[0].ticket_type
                    );
                    if (isSame) {
                      // setFulfilledLoader(false);;
                    }
                    return finalResult;
                  });
                  return response;
                }
              );
            }

            if (mobilePromise) {
              promises.push(mobilePromise);
            }
          }
          // ETICKET / PDF
          if (
            order?.ticket_type === "eticket" ||
            order?.ticket_type === "PDF"
          ) {
            const orderResponse = {
              order_id: order?.order_id,
              event_id: order?.event_id,
              e_tickets: [],
              additional_file: [],
              ...(order?.template &&
                order?.template?.value != 0 && {
                  additional_file_html: getDynamicHTML(
                    order?.template,
                    order?.inputs
                  ),
                }),
            };

            const normalFiles = Promise.all(
              order?.items?.map(async (item: any) => {
                // const res: any = await eTicketAWSFolder.uploadFile(
                //   item?.ticketFiles
                // );
                const res: any = await uploadFileWithPreAssignedURL(
                  item?.ticketFiles,
                  `ticket-files/${order?.order_id}`
                );
                return {
                  id: item?.id,
                  file: res?.key,
                };
              })
            );

            const additionalFile = new Promise((resolve, reject) => {
              const addtionalFetch = async () => {
                // const additionalFileRes: any = await additonalFileS3
                //   .uploadFile(order?.addtionalFile)
                //   .then((response: any) => response);
                const additionalFileRes: any =
                  await uploadFileWithPreAssignedURL(
                    order?.addtionalFile,
                    `additional-file/${order?.order_id}`
                  );
                // @ts-ignore
                orderResponse.additional_file.push({
                  file: additionalFileRes?.key,
                });

                resolve(additionalFileRes);
              };
              setTimeout(() => {
                order?.addtionalFile && addtionalFetch();
              }, 1 * 500);
            });

            let pdfPromise;
            if (order?.addtionalFile) {
              pdfPromise = Promise.all([normalFiles, additionalFile])?.then(
                async ([normalResponse, additionalResponse]: any) => {
                  // checking for extension start below
                  const newArray = [
                    ...orderResponse?.additional_file,
                    ...normalResponse,
                  ];

                  const statusArray = newArray.map((dataObject) => {
                    const file = dataObject?.file;

                    if (file) {
                      return hasExtension(file);
                    }

                    return false;
                  });
                  const allTrue = statusArray.every(
                    (status) => status === true
                  );

                  let response: any;

                  if (allTrue) {
                    response = await salesFulfillment({
                      ...orderResponse,
                      e_tickets: normalResponse,
                    });

                    if (response?.data?.status == true) {
                      setFullfilledOrders((prev: any) => [
                        ...prev,
                        order.order_id,
                      ]);
                    }
                  } else {
                    showAlert(
                      "Something went wrong during file upload. Please try again",
                      true,
                      2500
                    );
                    return;
                  }
                  // checking for extension end  here

                  setGrouping((currentData: any) => {
                    let finalResult = currentData?.map(
                      (events: any, eventIndex: number) => {
                        if (events?.event_id === orderResponse?.event_id) {
                          return {
                            ...events,
                            orders: events?.orders?.map(
                              (orders: any, orderIndex: number) => {
                                if (
                                  orders?.order_id === orderResponse?.order_id
                                ) {
                                  // CHECK IF ERROR OCCUR
                                  let error: any;
                                  if (
                                    !response?.data?.status &&
                                    Array.isArray(response?.data?.errors)
                                  ) {
                                    error = response?.data?.errors;
                                  } else if (
                                    !response?.data?.status &&
                                    response?.data?.errors &&
                                    response?.data?.errors?.title
                                  ) {
                                    error = response?.data?.errors?.title;
                                  } else {
                                    error = "";
                                  }
                                  // UNASSIGNED THE FILE IF ERROR OCCUR
                                  if (error) {
                                    orders?.addtionalFile &&
                                      markAssignedZero(
                                        orders?.addtionalFile?.id
                                      );
                                    orders?.items?.length > 0 &&
                                      // setFiles((prev: any) => {
                                      //   let final = prev?.map((inFile: any) => {
                                      //     const matchingTicketFile =
                                      //       orders?.items?.find(
                                      //         (ticketFile: any) =>
                                      //           inFile?.id ===
                                      //             ticketFile?.ticketFiles?.id ||
                                      //           // for addtional
                                      //           orders?.addtionalFile?.id
                                      //       );
                                      //     if (matchingTicketFile) {
                                      //       return {
                                      //         ...inFile,
                                      //         assigned: 0,
                                      //         type: inFile?.type,
                                      //         name: inFile.name,
                                      //         lastModified: inFile.lastModified,
                                      //         size: inFile.size,
                                      //         lastModifiedDate:
                                      //           inFile.lastModifiedDate,
                                      //         webkitRelativePath:
                                      //           inFile.webkitRelativePath,
                                      //       };
                                      //     }
                                      //     return inFile;
                                      //   });
                                      //   return final;
                                      // });
                                      // orders?.items?.map((obj: any) => {
                                      //   markAssignedZero(
                                      //     obj?.ticketFile?.id ||
                                      //       orders?.addtionalFile?.id
                                      //   );
                                      // });
                                      orders?.items?.map((obj: any) => {
                                        markAssignedZero(
                                          obj?.ticketFiles.id ||
                                            orders?.addtionalFile?.id
                                        );
                                      });
                                  }
                                  let res = {
                                    ...orders,
                                    addtionalFile: "",
                                    errors: error,
                                    items: orders?.items?.map(
                                      (eticket: any) => {
                                        return {
                                          ...eticket,
                                          ticketFiles: "",
                                        };
                                      }
                                    ),
                                    isFull: response?.data?.status
                                      ? true
                                      : false,
                                    selected: error ? true : false,
                                  };
                                  return res;
                                } else {
                                  return orders;
                                }
                              }
                            ),
                          };
                        } else {
                          return events;
                        }
                      }
                    );
                    let isSame = getLength?.every(
                      (item: any, index: number) =>
                        index === 0 ||
                        item.ticket_type === getLength[0].ticket_type
                    );
                    if (isSame) {
                      // setFulfilledLoader(false);;
                    }
                    return finalResult;
                  });
                  return response;
                }
              );
            } else if (!order?.addtionalFile) {
              pdfPromise = Promise.all([normalFiles])?.then(
                async (normalResponse: any) => {
                  // checking for extension start below

                  const newArray: any = normalResponse?.[0];

                  const statusArray = newArray.map((dataObject: any) => {
                    const file = dataObject?.file;

                    if (file) {
                      return hasExtension(file);
                    }

                    return false;
                  });

                  const allTrue = statusArray.every(
                    (status: any) => status === true
                  );

                  let response: any;

                  if (allTrue) {
                    response = await salesFulfillment({
                      ...orderResponse,
                      e_tickets: normalResponse?.[0],
                    });

                    if (response?.data?.status == true) {
                      setFullfilledOrders((prev: any) => [
                        ...prev,
                        order.order_id,
                      ]);
                    }
                  } else {
                    showAlert(
                      "Something went wrong during file upload. Please try again",
                      true,
                      2500
                    );
                    return;
                  }
                  // checking for extension end  here

                  setGrouping((currentData: any) => {
                    let finalResult = currentData?.map(
                      (events: any, eventIndex: number) => {
                        if (events?.event_id === orderResponse?.event_id) {
                          return {
                            ...events,
                            orders: events?.orders?.map(
                              (orders: any, orderIndex: number) => {
                                if (
                                  orders?.order_id === orderResponse?.order_id
                                ) {
                                  // CHECK IF ERROR OCCUR
                                  let error: any;
                                  if (
                                    !response?.data?.status &&
                                    Array.isArray(response?.data?.errors)
                                  ) {
                                    error = response?.data?.errors;
                                  } else if (
                                    !response?.data?.status &&
                                    response?.data?.errors &&
                                    response?.data?.errors?.title
                                  ) {
                                    error = response?.data?.errors?.title;
                                  } else {
                                    error = "";
                                  }
                                  // UNASSIGNED THE FILE IF ERROR OCCUR
                                  if (error) {
                                    orders?.addtionalFile &&
                                      markAssignedZero(
                                        orders?.addtionalFile?.id
                                      );
                                    orders?.items?.length > 0 &&
                                      // setFiles((prev: any) => {
                                      //   let final = prev?.map((inFile: any) => {
                                      //     const matchingTicketFile =
                                      //       orders?.items?.find(
                                      //         (ticketFile: any) =>
                                      //           inFile?.id ===
                                      //             ticketFile?.ticketFiles?.id ||
                                      //           // for addtional
                                      //           orders?.addtionalFile?.id
                                      //       );
                                      //     if (matchingTicketFile) {
                                      //       return {
                                      //         ...inFile,
                                      //         assigned: 0,

                                      //         type: inFile?.type,
                                      //         name: inFile.name,
                                      //         lastModified: inFile.lastModified,
                                      //         size: inFile.size,
                                      //         lastModifiedDate:
                                      //           inFile.lastModifiedDate,
                                      //         webkitRelativePath:
                                      //           inFile.webkitRelativePath,
                                      //       };
                                      //     }
                                      //     return inFile;
                                      //   });
                                      //   return final;
                                      // });
                                      orders?.items?.map((obj: any) => {
                                        markAssignedZero(obj?.ticketFiles?.id);
                                      });
                                  }
                                  let res = {
                                    ...orders,
                                    addtionalFile: "",
                                    errors: error,
                                    items: orders?.items?.map(
                                      (eticket: any) => {
                                        return {
                                          ...eticket,
                                          ticketFiles: "",
                                        };
                                      }
                                    ),
                                    isFull: response?.data?.status
                                      ? true
                                      : false,
                                    selected: error ? true : false,
                                  };
                                  return res;
                                } else {
                                  return orders;
                                }
                              }
                            ),
                          };
                        } else {
                          return events;
                        }
                      }
                    );
                    let isSame = getLength?.every(
                      (item: any, index: number) =>
                        index === 0 ||
                        item.ticket_type === getLength[0].ticket_type
                    );
                    if (isSame) {
                      // setFulfilledLoader(false);;
                    }
                    return finalResult;
                  });
                  return response;
                }
              );
            }
            if (pdfPromise) {
              promises.push(pdfPromise);
            }
          }
          // PAPER (HARD)
          if (
            order?.ticket_type === "paper" ||
            order?.ticket_type === "Paper" ||
            order?.ticket_type === "season-cards"
          ) {
            let order_id = order?.order_id;
            let event_id = order?.event_id;
            let shipping_tracking_id = order?.shippmentTrakingNo;
            let delivery_partner_name = order?.deliveryPartner;
            if (
              order?.isFull &&
              (order?.ticket_type === "paper" ||
                order?.ticket_type === "Paper" ||
                order?.ticket_type === "season-cards")
            ) {
              const additional_file: any[] = [];

              const additionalFile: Promise<any> =
                order?.addtionalFile &&
                new Promise(async (resolve, reject) => {
                  try {
                    const additionalFileRes: any =
                      // await additonalFileS3.uploadFile(order?.addtionalFile);
                      await uploadFileWithPreAssignedURL(
                        order?.addtionalFile,
                        `additional-file/${order?.order_id}`
                      );
                    additional_file.push({
                      file: additionalFileRes?.key,
                    });
                    resolve(additionalFileRes);
                  } catch (error) {
                    reject(error);
                  }
                });

              try {
                let response: any = "";
                if (order?.addtionalFile) {
                  await additionalFile;
                  response = await salesFulfillment({
                    order_id,
                    shipping_tracking_id,
                    additional_file,
                    delivery_partner_name,
                  });
                } else {
                  response = await salesFulfillment({
                    order_id,
                    shipping_tracking_id,
                    additional_file,
                    delivery_partner_name,
                    ...(order?.template &&
                      order?.template?.value != 0 && {
                        additional_file_html: getDynamicHTML(
                          order?.template,
                          order?.inputs
                        ),
                      }),
                  });
                }
                if (response) {
                  promises.push(response);
                }

                if (response?.data?.status == true) {
                  setFullfilledOrders((prev: any) => [...prev, order.order_id]);
                }

                if (response) {
                  setGrouping((currentValue: any) => {
                    let final = currentValue?.map(
                      (events: any, eventIndex: number) => {
                        if (events?.event_id === event_id) {
                          let error: any;
                          if (
                            !response?.data?.status &&
                            Array.isArray(response?.data?.errors)
                          ) {
                            error = response?.data?.errors;
                          } else if (
                            !response?.data?.status &&
                            response?.data?.errors &&
                            response?.data?.errors?.title
                          ) {
                            error = response?.data?.errors?.title;
                          } else {
                            error = "";
                          }

                          return {
                            ...events,
                            orders: events?.orders?.map(
                              (orders: any, orderIndex: number) => {
                                if (orders?.order_id === order_id) {
                                  return {
                                    ...orders,
                                    errors: error,
                                    selected: error ? true : false,
                                    isFull:
                                      response?.data?.status ||
                                      order?.is_shipping_label_provided
                                        ? true
                                        : false,
                                  };
                                } else {
                                  return orders;
                                }
                              }
                            ),
                          };
                        } else {
                          return events;
                        }
                      }
                    );
                    return final;
                  });
                }

                let isSame = getLength?.every(
                  (item: any, index: number) =>
                    index === 0 || item.ticket_type === getLength[0].ticket_type
                );

                if (_.size(promises) === 0) {
                  // setFulfilledLoader(false);;
                } else if (isSame) {
                  // setFulfilledLoader(false);;
                }

                // return response; // added return statement
              } catch (error) {
                console.warn(error);
              }
            }
          }
          if (order?.ticket_type === "mobile-link") {
            let order_id = order?.order_id;
            let event_id = order?.event_id;
            const additional_file: any[] = [];

            const additionalFile: Promise<any> =
              order?.addtionalFile &&
              new Promise(async (resolve, reject) => {
                try {
                  const additionalFileRes: any =
                    // await additonalFileS3.uploadFile(order?.addtionalFile);
                    await uploadFileWithPreAssignedURL(
                      order?.addtionalFile,
                      `additional-file/${order?.order_id}`
                    );
                  additional_file.push({
                    file: additionalFileRes?.key,
                  });
                  resolve(additionalFileRes);
                } catch (error) {
                  reject(error);
                }
              });

            const extractedData = order?.items?.map(({ seat, link }: any) => ({
              seat,
              link,
            }));

            try {
              let response: any = "";
              if (order?.addtionalFile) {
                await additionalFile;
                response = await salesFulfillment({
                  order_id,
                  mobile_links: extractedData,
                  additional_file,
                  ...(order?.template &&
                    order?.template?.value != 0 && {
                      additional_file_html: getDynamicHTML(
                        order?.template,
                        order?.inputs
                      ),
                    }),
                });
              } else {
                response = await salesFulfillment({
                  order_id,
                  mobile_links: extractedData,
                  additional_file,
                  ...(order?.template &&
                    order?.template?.value != 0 && {
                      additional_file_html: getDynamicHTML(
                        order?.template,
                        order?.inputs
                      ),
                    }),
                });
              }

              if (response) {
                promises.push(response);
              }

              if (response?.data?.status == true) {
                setFullfilledOrders((prev: any) => [...prev, order.order_id]);
              }

              if (response) {
                setGrouping((currentValue: any) => {
                  let final = currentValue?.map(
                    (events: any, eventIndex: number) => {
                      if (events?.event_id === event_id) {
                        let error: any;
                        if (
                          !response?.data?.status &&
                          Array.isArray(response?.data?.errors)
                        ) {
                          error = response?.data?.errors;
                        } else if (
                          !response?.data?.status &&
                          response?.data?.errors &&
                          response?.data?.errors?.title
                        ) {
                          error = response?.data?.errors?.title;
                        } else {
                          error = "";
                        }

                        return {
                          ...events,
                          orders: events?.orders?.map(
                            (orders: any, orderIndex: number) => {
                              if (orders?.order_id === order_id) {
                                const items = orders?.items?.map((obj: any) => {
                                  return {
                                    ...obj,
                                    isValid: response?.data?.status
                                      ? true
                                      : orders?.isValid,
                                  };
                                });

                                return {
                                  ...orders,
                                  errors: error,
                                  selected: error ? true : false,
                                  isFull: response?.data?.status ? true : false,
                                  items: items,
                                };
                              } else {
                                return orders;
                              }
                            }
                          ),
                        };
                      } else {
                        return events;
                      }
                    }
                  );
                  return final;
                });
              }

              let isSame = getLength?.every(
                (item: any, index: number) =>
                  index === 0 || item.ticket_type === getLength[0].ticket_type
              );

              if (_.size(promises) === 0) {
                // setFulfilledLoader(false);;
              } else if (isSame) {
                // setFulfilledLoader(false);;
              }

              // return response; // added return statement
            } catch (error) {
              console.error(error);
            }
          }

          let ans = await Promise.all(promises);
          if (ans && promises && ans?.length === promises.length) {
            setTimeout(() => {
              setFulfilledLoader(false);
            }, 2000);
          }
        }
      });
    });
  };

  const handleClose = () => {
    handleUploadTicket();

    layout.setOrders((currentOrder: any) => {
      let finalResult = currentOrder.filter((item: any) => {
        return !fullfilledOrders?.includes(item?.order_id);
      });
      return finalResult;
    });
    setFilePreview([]);
    setFiles([]);
    layout.setIsSelectAll(false);
  };

  var dragArea = document.querySelectorAll<HTMLElement>(".drag_here");
  const onDragStart = () => {
    for (var i = 0; i < dragArea.length; i++) {
      dragArea[i].classList.add("!border-green-500");
    }
  };

  // IS ACTIVE HANDLE FOR MOBILE
  const handleIsActive = (data: any, item: any) => {
    setGrouping((current: any) => {
      return current?.map((orders: any) => {
        if (data?.event_id === orders?.event_id) {
          return {
            ...orders,
            orders: orders?.orders?.map((order: any) => {
              if (order?.order_id === item?.order_id) {
                return {
                  ...order,
                  isActive: true,
                };
              } else {
                return order;
              }
            }),
          };
        } else {
          return orders;
        }
      });
    });
  };

  const [collapse, setCollapse] = useState<boolean>(true);

  const handleCollapse = (index: any) => {
    const element: any = document.querySelector(
      `[aria-controls="eTicketEvent${index}"]`
    );
    if (element) {
      const ariaExpandedValue = element.getAttribute("aria-expanded");
      const isExpanded = ariaExpandedValue === "true";
      setCollapse(isExpanded);
    } else {
      setCollapse(false);
    }
  };

  useEffect(() => {
    var popup = document.querySelector("#ppeRightCanvas");
    var popup_contain = popup?.classList.contains("vis");
    var popup_contain_hid = popup?.classList.remove("vis");
    var body = document.querySelector("body");
    if (popup_contain) {
      body?.classList.add("overflow-hidden");
    }
    return () => {
      body?.classList?.remove("overflow-hidden");
    };
  }, []);

  const uploadPreviewVisible = !isFulfilledLoader;

  const orderFullFill =
    !isFulfilledLoader &&
    isFulfilled &&
    errorLength === 0 &&
    fullOrdersLength === getLength?.length;

  // HANDLE PKPASS
  const handlePKPASS = (e: any, orderId: any, eventId: any) => {
    setGrouping((current: any) => {
      return current?.map((event: any) => {
        if (event?.event_id === eventId) {
          return {
            ...event,
            orders: event?.orders?.map((order: any) => {
              if (order?.order_id === orderId) {
                const items = order?.items?.map((obj: any) => {
                  markAssignedZero(obj?.ticketFiles?.id);
                  delete obj?.isValid;
                  delete obj?.ticketFiles;
                  return {
                    ...obj,
                    link: "",
                  };
                });
                return {
                  ...order,
                  pkPass: e.target?.checked,
                  isFull: false,
                  items: items,
                  separate_links: false,
                };
              } else
                return {
                  ...order,
                };
            }),
          };
        } else
          return {
            ...event,
          };
      });
    });
  };

  const handleSeparateLinks = (e: any, orderId: any, eventId: any) => {
    setGrouping((current: any) => {
      return current?.map((event: any) => {
        if (event?.event_id === eventId) {
          return {
            ...event,
            orders: event?.orders?.map((order: any) => {
              if (order?.order_id === orderId) {
                const items = order?.items?.map((obj: any) => {
                  delete obj?.isValid;

                  return {
                    ...obj,
                    link: "",
                  };
                });

                return {
                  ...order,
                  isFull: false,
                  separate_links: e.target?.checked,
                  items: items,
                };
              } else
                return {
                  ...order,
                };
            }),
          };
        } else
          return {
            ...event,
          };
      });
    });
  };

  return (
    <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
      <div
        className={`show offcanvas offcanvas-end visible upload-ticket-popup fixed bottom-0 top-[3.75rem] right-0 shadow-lg bg-clip-padding outline-none transition-all duration-300 ease-in-out font-medium  w-full md:max-w-[43.125rem]  ${
          layout.ticketUploadData ? "vis" : "hid"
        } ${
          globalLayout?.asideMenuOpen
            ? "lg2:max-w-[calc(100%-13.875rem)] md:max-lg:max-w-[36rem]"
            : "lg2:max-w-[calc(100%-4rem)] md:max-w-[43.125rem]"
        }`}
        tabIndex={-1}
        id="ppeRightCanvas"
        aria-labelledby="ppeRightCanvasLabel"
      >
        <div
          className={`relative flex flex-col max-w-[${
            // isAllPaper || isFulfilledLoader
            isFulfilledLoader || orderFullFill ? "46.25rem" : "93rem"
          }] ${
            // isAllPaper || isFulfilledLoader
            isFulfilledLoader || orderFullFill
              ? "md:w-[43.125rem]"
              : "md:w-[95%]"
          } w-full  h-full bg-white ml-auto`}
        >
          <div className="offcanvas-header flex items-center justify-between border-t border-b">
            <h5
              className="offcanvas-title mb-0 leading-normal text-sm15 font-semibold py-2 px-5"
              id="offcanvasRightLabel"
            >
              {/* Fulfill {getLength?.length}{" "}
              {getLength?.length === 1 ? "order" : "orders"} */}
              Deliver tickets
            </h5>
            <button
              type="button"
              className="flex items-center justify-center w-9 border-l box-content h-full group"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              onClick={(data: any) => {
                handleClose();
              }}
            >
              <IKTSVG
                className="fill-violet-800 group-hover:fill-indigo-500 h-2.5 w-2.5 transition"
                path={crossSVG}
                svgClassName="h-[.625rem] w-[.625rem]"
              />
            </button>
          </div>
          {/* PDF VIEWER */}
          <div className="offcanvas-body relative h-full w-full flex flex-col lg2:flex-row overflow-x-auto scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full">
            {/* PDF VIEW */}
            {/* !isAllPaper && !isFulfilledLoader && pdfView */}
            {!isFulfilledLoader && pdfView && !orderFullFill ? (
              <div
                id="pdfScroll"
                className={`transition lg2:border-r w-full lg2:h-full lg2:w-1/2 p-4 md:p-5 flex items-center !justify-start flex-col lg2:scrollbar-thin lg2:scrollbar-thumb-violet-800 lg2:scrollbar-thumb-rounded-full`}
              >
                <div className="border rounded w-full">
                  <div className="flex w-full border-b min-h-[2.5rem]">
                    <div className="flex items-center justify-between w-full px-4 py-1.5">
                      <div className="text-xxs md:text-xs">
                        {filePreview?.name}
                      </div>
                      {numPages > 1 && (
                        <div className="flex">
                          <button
                            className={`group rounded-l  hover: transition px-[.5625rem] py-2 ${
                              pageNumber === 1 && "pointer-events-none"
                            }`}
                            type="button"
                            onClick={(data: any) =>
                              setPageNumber(
                                (count: any) => count !== 1 && count - 1
                              )
                            }
                          >
                            <IKTSVG
                              className={`flex items-center justify-center  transition group-hover:fill-indigo-500 ${
                                pageNumber === 1
                                  ? "fill-gray-400"
                                  : "fill-violet-500"
                              }`}
                              path={angleLeftSVG}
                            />
                          </button>
                          <button
                            className={`group rounded-r  transition px-[.5625rem] py-2 ${
                              numPages === pageNumber && "pointer-events-none"
                            }`}
                            type="button"
                            onClick={(data: any) =>
                              setPageNumber(
                                (count: any) => count !== numPages && count + 1
                              )
                            }
                          >
                            <IKTSVG
                              className={`flex items-center justify-center  transition group-hover:fill-indigo-500 ${
                                numPages === pageNumber
                                  ? "fill-gray-400"
                                  : "fill-violet-500"
                              }`}
                              path={angleRightSVG}
                            />
                          </button>
                        </div>
                      )}
                    </div>
                    <button
                      className="flex items-center justify-center border-l w-11 group"
                      type="button"
                      onClick={(data: any) => {
                        setPdfView(false);
                      }}
                    >
                      <IKTSVG
                        svgClassName="h-4 w-4"
                        className="flex items-center justify-center fill-violet-800 group-hover:fill-indigo-500 transition"
                        path={clearSVG}
                      />
                    </button>
                  </div>
                  <div className="uploaded-file-preview overflow-hidden">
                    <Document
                      file={
                        filePreview?.id
                          ? filePreview?.preview
                          : "data:application/jpeg;base64," +
                            filePreview?.preview
                      }
                      onLoadSuccess={onDocumentLoadSuccess}
                      loading={
                        <IKTSVG
                          className="flex items-center justify-center mr-2 mt-5 fill-violet-500 transition animate-spin"
                          path={Process}
                        />
                      }
                      error={
                        filePreview?.path?.split(".").pop() === "pdf" ? (
                          PDFFailedLoad
                        ) : (
                          <img src={filePreview?.preview} />
                        )
                      }
                    >
                      <Page pageNumber={pageNumber} pageIndex={pageNumber} />
                    </Document>
                  </div>
                  {filePreview?.path?.split(".").pop() === "pdf" && (
                    <div className="ml-auto p-2 w-8 text-xs text-gray-400">
                      {pageNumber}
                    </div>
                  )}
                </div>
              </div>
            ) : (
              // !isAllPaper &&

              uploadPreviewVisible &&
              !orderFullFill && (
                <div
                  className={`lg2:border-r w-full lg2:w-1/2 p-4 md:p-5 flex items-center flex-col transition lg2:!overflow-y-auto !overflow-visible scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full ${
                    files && files.length > 0 && "!justify-start"
                  }`}
                >
                  <div
                    {...getRootProps({ className: "dropzone" })}
                    className={`flex flex-col items-center justify-center text-center h-20 w-full min-h-[5rem] border border-dashed border-violet-500/10 rounded bg-violet-300/50 text-xs`}
                  >
                    <input {...getInputProps()} />
                    <div className="mb-2.5">Upload or drop files here</div>
                    <button
                      type="button"
                      onClick={open}
                      className="flex items-center text-white bg-violet-500 hover:bg-indigo-500 transition rounded text-sm13 px-2.5 py-1"
                    >
                      <IKTSVG
                        className="fill-white mr-2"
                        path={uploadSVG}
                        svgClassName="w-[.8125rem] h-[.6875rem]"
                      />
                      Upload
                    </button>
                  </div>

                  {/** INSTRUCTION OF TICKE TYPE FOR UPLOAD */}

                  {!(files && files.length > 0) &&
                    (possibleTicketTypes.includes("eticket") ||
                      possibleTicketTypes.includes("pdf") ||
                      possibleTicketTypes.includes("PDF")) && (
                      <div className="w-full py-2.5">
                        <UploadInstruction
                          data={{
                            heading: `${
                              filterOrders?.[0]?.sub_ticket_type === "mobile-qr"
                                ? "Mobile Screenshot"
                                : "E-ticket"
                            } delivery instructions`,
                            listing: [
                              "Use this window to upload individual tickets for each e-ticket order (PDF format)",
                              "Drag and drop each file - ensuring any designated seat matches the ticket",
                              "Confirm all orders as fulfilled by clicking the green ‘Confirm’ button",
                            ],
                            type: "E-Ticket",
                          }}
                        />
                      </div>
                    )}

                  {!(files && files.length > 0) &&
                    (possibleTicketTypes.includes("mobile") ||
                      possibleTicketTypes.includes("Mobile")) && (
                      <div className="w-full py-2.5">
                        <UploadInstruction
                          data={{
                            heading: `External Transfer delivery instructions`,
                            listing: [
                              "External Transfer must be delivered directly to the customer’s email address or telephone number found in order details",
                              "Confirm external transfer for each mobile ticket order on the right-hand side",
                              "Upload a proof of transfer screenshot for each mobile ticket order on the right-hand side",
                              "Confirm all orders as fulfilled by clicking the green ‘Confirm’ button",
                            ],
                            type: `Mobile`,
                          }}
                        />
                      </div>
                    )}

                  {!(files && files.length > 0) &&
                    (possibleTicketTypes.includes("local-delivery") ||
                      possibleTicketTypes.includes("local-delivery")) && (
                      <div className="w-full py-2.5">
                        <UploadInstruction
                          data={{
                            heading: `Local delivery instructions`,
                            listing: [
                              "Tickets must be delivered directly to the customer by hand",
                              "Confirm local delivery for each order on the right-hand side",
                              "Upload a proof of transfer each ticket on the right-hand side",
                              "Confirm all orders as fulfilled by clicking the green ‘Confirm’ button",
                            ],
                            type: `Paper`,
                          }}
                        />
                      </div>
                    )}

                  {!(files && files.length > 0) &&
                    possibleTicketTypes.includes("mobile-link") && (
                      <div className="w-full py-2.5">
                        <UploadInstruction
                          data={{
                            heading: `Mobile Link/PKPASS delivery instructions`,
                            listing: MobileInsturction,
                            type: `Mobile`,
                          }}
                        />
                      </div>
                    )}

                  {!(files && files.length > 0) &&
                    possibleTicketTypes.includes("season-cards") && (
                      <div className="w-full py-2.5">
                        <UploadInstruction
                          data={{
                            heading: `Season / Member Card Ticket instructions`,
                            listing: [],
                            type: `Paper`,
                          }}
                        >
                          <p className="text-sm12 break-words mb-1 w-full">
                            Delivery Instructions:
                          </p>
                          <ol className="list-decimal ml-6">
                            {[
                              "Any Physical Copy of a Season Ticket or Members Card, which has been listed and sold, should be delivered via an in person hand over of the tickets.",
                            ].map((item: any, index: any) => {
                              return (
                                <li
                                  key={index}
                                  className="text-sm12 break-words"
                                >
                                  {item}
                                </li>
                              );
                            })}
                          </ol>
                          <p className="text-sm12 break-words mt-4 mb-1 w-full">
                            Proof of Delivery Criteria:
                          </p>
                          <ol className="list-decimal ml-6">
                            {[
                              "Proof of Delivery should be a clear image of the customers ID, alongside the tickets they have purchased for that event.",
                              "Please note, any image that does not match the above criteria will not be accepted as proof of delivery.",
                            ].map((item: any) => {
                              return (
                                <li className="text-sm12 break-words">
                                  {item}
                                </li>
                              );
                            })}
                          </ol>
                        </UploadInstruction>
                      </div>
                    )}

                  {!(files && files.length > 0) &&
                    possibleTicketTypes.includes("paper") && (
                      <div className="w-full py-2.5">
                        <UploadInstruction
                          data={{
                            heading: `Paper ticket fulfillment instructions`,
                            listing: [],
                            type: `Paper`,
                          }}
                        >
                          <p className="text-sm12 break-words mb-1 w-full">
                            If you see a button that says 'Generate shipping
                            label':
                          </p>
                          <ol className="list-decimal ml-6">
                            {[
                              "Click on the button, this will generate a shipping label for you. A tracking number will also be generated and auto-filled",
                              "Print the shipping label by clicking Download Shipping Label button",
                              "Attach the shipping label to your tickets, and ship via the courier provider",
                              "Confirm all orders as fulfilled by clicking the green 'Confirm' button",
                            ].map((item: any, index: any) => {
                              return (
                                <li
                                  key={index}
                                  className="text-sm12 break-words"
                                >
                                  {item}
                                </li>
                              );
                            })}
                          </ol>
                          <p className="text-sm12 break-words mt-4 mb-1 w-full">
                            If you don't see the 'Generate shipping label'
                            button:
                          </p>
                          <ol className="list-decimal ml-6">
                            {[
                              "Send the tickets to the customer’s shipping address using tracked delivery",
                              "Enter the tracking number for each paper ticket order on the right-hand side",
                              "Confirm all orders as fulfilled by clicking the green 'Confirm' button",
                            ].map((item: any) => {
                              return (
                                <li className="text-sm12 break-words">
                                  {item}
                                </li>
                              );
                            })}
                          </ol>
                        </UploadInstruction>
                      </div>
                    )}

                  {/* FIRST INNER DRAG  */}
                  {files && files.length > 0 && (
                    <aside className="w-full mt-5">
                      <div className="flex flex-wrap gap-1 mb-5">
                        <h4 className="text-sm flex-auto">
                          {files.length} files uploaded,{" "}
                          {files.filter((file: any) => file.assigned).length}{" "}
                          assigned
                        </h4>
                        <div className="flex items-center gap-x-2">
                          <span className="text-xs">Show assigned</span>
                          <label className="relative inline-flex items-center cursor-pointer">
                            <input
                              type="checkbox"
                              value=""
                              className="sr-only peer"
                              checked={checkedState}
                              onChange={() => setCheckedState(!checkedState)}
                            />
                            <div className="w-7 h-3 bg-gray-200 peer-checked:bg-green-600/40 rounded-full transition-all peer peer-checked:after:translate-x-full peer-checked:after:bg-green-600 after:content-[''] after:absolute after:-top-0.5 after:-left-0.5 after:bg-gray-400 after:rounded-full after:h-4 after:w-4 after:transition-all after:shadow-md peer-checked:bg-100"></div>
                          </label>
                        </div>
                      </div>
                      <Droppable
                        droppableId="eventTicketUploadedFiles"
                        key="eventTicketUploadedFiles"
                      >
                        {(provided: any, snapshot: any) => (
                          <ul ref={provided.innerRef}>
                            {files.map((file: any, index: number) => {
                              return (
                                (file?.assigned !== 1 || checkedState) && (
                                  <Draggable
                                    // key={file.path}
                                    // draggableId={file?.path}
                                    key={index}
                                    draggableId={`${file?.path}${index}`}
                                    index={index}
                                    isDragDisabled={
                                      file.assigned ? true : false
                                    }
                                  >
                                    {(provided: any, snapshot: any) => {
                                      if (snapshot?.isDragging) {
                                        // dragging time make space of that draggable item
                                        eventRef?.current?.classList?.add(
                                          "max-lg2:mt-[36px]"
                                        );

                                        // remove space of grabing draggable item
                                        if (
                                          provided.draggableProps.style &&
                                          "top" in provided.draggableProps.style
                                        ) {
                                          provided.draggableProps.style.top -= 50;
                                        }
                                      }
                                      // not dragging time remove space
                                      else
                                        eventRef?.current?.classList?.remove(
                                          "max-lg2:mt-[36px]"
                                        );

                                      return (
                                        // @ts-ignore
                                        <List checkedState={checkedState}>
                                          <span
                                            key={index}
                                            className={`${
                                              file?.id === hoverFile
                                                ? "flex bg-violet-50 border border-indigo-500 rounded gap-x-2 px-2 py-1 mb-2.5 transition"
                                                : file.assigned === 1
                                                ? "flex   bg-green-800/5 border border-green-600 hover:border-green-600 rounded gap-x-2 px-2 py-1 mb-2.5 transition"
                                                : "flex bg-gray-100/50 hover:bg-violet-50 border hover:border-indigo-500 rounded gap-x-2 px-2 py-1 mb-2.5 transition"
                                            }`}
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            isdragging={snapshot.isdragging}
                                            style={{
                                              ...provided.draggableProps.style,
                                              left: "auto !important",
                                            }}
                                          >
                                            <span
                                              className="text-xs flex-auto truncate"
                                              title={file.path}
                                            >
                                              {" "}
                                              {file.path}{" "}
                                            </span>
                                            <span className="flex s-center gap-x-2">
                                              {file.type && (
                                                <button
                                                  type="button"
                                                  onClick={() =>
                                                    pdfViewer(file)
                                                  }
                                                  id={`View-${index}`}
                                                >
                                                  <Tooltip
                                                    anchorId={`View-${index}`}
                                                    content="Preview"
                                                    place="top"
                                                    variant="light"
                                                    className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10"
                                                  />
                                                  <IKTSVG
                                                    className="fill-violet-500 hover:fill-indigo-500 transition"
                                                    path={eyeSVG}
                                                    svgClassName="w-4 h-[.6875rem]"
                                                  />
                                                </button>
                                              )}

                                              <button
                                                type="button"
                                                onClick={() =>
                                                  deletePdf(file, index)
                                                }
                                                id={`delete-${index}`}
                                              >
                                                <IKTSVG
                                                  className="fill-violet-500 hover:fill-indigo-500 transition"
                                                  path={deleteSVG}
                                                  svgClassName="w-3 h-3"
                                                />
                                              </button>
                                              <Tooltip
                                                anchorId={`delete-${index}`}
                                                content="Delete"
                                                place="top"
                                                variant="light"
                                                className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10"
                                              />
                                            </span>
                                          </span>
                                        </List>
                                      );
                                    }}
                                  </Draggable>
                                )
                              );
                            })}
                          </ul>
                        )}
                      </Droppable>
                    </aside>
                  )}
                </div>
              )
            )}

            {templateView && (
              <AdditionalTemplate
                setTemplateView={setTemplateView}
                template={template}
                setTemplateHTML={setTemplateHTML}
                templateInputes={templateInputes}
              />
            )}

            {/* HEADER */}
            <div
              className={`${isFulfilledLoader && "pointer-events-none"}
                w-full lg2:${
                  // isAllPaper || isFulfilledLoader
                  isFulfilledLoader || orderFullFill ? "w-full" : "w-1/2"
                } px-5 py-2 lg2:py-5 h-full lg2:!overflow-auto !overflow-visible scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full`}
            >
              {/* FULFILLED */}
              {isFulfilled && (
                <div className="fullfill_process shadow-op2 rounded flex flex-wrap mb-2.5">
                  <div
                    className={`left p-3 ${
                      !isFulfilledLoader &&
                      errorLength === 0 &&
                      fullOrdersLength === getLength?.length
                        ? ""
                        : "border-r"
                    } flex w-1/2`}
                  >
                    {isFulfilledLoader ? (
                      <IKTSVG
                        className="flex items-center justify-center fill-violet-500 transition animate-spin"
                        path={processSVG}
                      />
                    ) : (
                      <IKTSVG
                        className="flex items-center justify-center fill-green-600 transition"
                        svgClassName="w-4 h-4"
                        path={publishGraySVG}
                      />
                    )}

                    {!isFulfilledLoader &&
                    errorLength === 0 &&
                    fullOrdersLength === getLength?.length ? (
                      <h4 className="text-sm14 ml-3 font-medium">
                        All orders fulfilled
                      </h4>
                    ) : (
                      <h4 className="text-sm14 ml-3 font-medium">
                        {`${isFulfilledLoader ? "0" : fullOrdersLength}/${
                          getLength?.length
                        } orders`}{" "}
                        fulfilled
                      </h4>
                    )}
                  </div>
                  {!isFulfilledLoader &&
                  errorLength === 0 &&
                  fullOrdersLength === getLength?.length ? (
                    <></>
                  ) : (
                    <div className="left p-3 border-r flex w-1/2 items-center">
                      <IKTSVG
                        className="flex items-center justify-center fill-rose-500 transition"
                        svgClassName="w-3.5 h-3.5"
                        path={warningSVG}
                      />
                      <h4 className="text-sm14 ml-3 font-medium">
                        {`${
                          errorLength > 1
                            ? `${errorLength} orders`
                            : `${errorLength} order`
                        }`}{" "}
                        failed
                      </h4>
                    </div>
                  )}
                </div>
              )}

              {grouping &&
                Array.isArray(grouping) &&
                grouping?.map((order: any, eventIndex: number) => {
                  let orderDate = moment(order.date).format("ddd, D MMM YYYY");
                  const parsedTime = moment(order.time).format("HH:mm");

                  return (
                    <React.Fragment key={eventIndex}>
                      <div
                        className="flex accordion-header event__header bg-violet-700 [&[data-te-collapse-collapsed]]:rounded rounded-t md:!rounded text-white md:mb-2.5 title-accordion  "
                        id=""
                        data-te-collapse-init
                        data-te-target={`#eTicketEvent${eventIndex}`}
                        aria-expanded="true"
                        ref={eventRef}
                        aria-controls={`eTicketEvent${eventIndex}`}
                        onMouseEnter={() => handleCollapse(eventIndex)}
                        onMouseLeave={() => handleCollapse("")}
                        onClick={() => handleCollapse(eventIndex)}
                      >
                        <div className="flex w-full">
                          <div className="flex flex-wrap md:flex-nowrap flex-auto max-md:max-w-[calc(100%-2.5rem)]">
                            <HeaderInfo
                              className="flex flex-auto items-center leading-4 md:w-auto md:max-w-[11.25rem] w-full py-2 md:py-3 px-4 text-sm md:border-r border-white/20 ellips-text md:whitespace-nowrap whitespace-normal"
                              title={order.name}
                            />
                            <div className="flex w-full md:w-auto md:py-3 px-4 max-md:flex-wrap max-md:gap-y-2 max-md:pb-2">
                              <HeaderInfo
                                title={orderDate}
                                imgPath={Calendar}
                              />
                              <HeaderInfo
                                title={parsedTime}
                                imgPath={ClockOutline}
                              />
                              {!isFulfilledLoader && !isAllPaper && (
                                <HeaderInfo
                                  location={true}
                                  title={order.address}
                                  imgPath={Geo}
                                />
                              )}
                            </div>
                          </div>
                          <div className="flex ml-auto">
                            <Tooltip
                              // anchorId={`eTicketEvent${eventIndex}`}
                              id={`eTicketEvent${eventIndex}`}
                              content={`${collapse ? `Collapse` : "Expand"}`}
                              place="top"
                              variant="light"
                              className="!bg-white !opacity-100 !py-0.5 !px-1 !text-xxs font-medium  z-10 mt-3 "
                            />
                            <button
                              className="group relative flex items-center p-3 px-4 text-base text-gray-800 text-left border-l border-white/20 !transition focus:outline-none [&[aria-expanded='false'] > .icon ]:rotate-180"
                              type="button"
                              data-te-collapse-init
                              data-te-target={`#eTicketEvent${eventIndex}`}
                              aria-expanded="true"
                              data-tooltip-id={`eTicketEvent${eventIndex}`}
                              // id={`eTicketEvent${eventIndex}`}
                              aria-controls={`eTicketEvent${eventIndex}`}
                            >
                              <IKTSVG
                                className="flex items-center justify-center fill-white transition group-[[data-te-collapse-collapsed]]:rotate-180"
                                path={collapseSVG}
                              />
                            </button>
                          </div>
                        </div>
                      </div>

                      {/* BODY */}
                      <div
                        id={`eTicketEvent${eventIndex}`}
                        className="accordion-collapse collapse show"
                        data-te-collapse-item
                        data-te-collapse-show
                      >
                        {order?.orders.map(
                          (
                            item: any,
                            eventRecordIndex: number,
                            orderArr: any
                          ) => {
                            // console.log(item, "item");
                            let ticketType: any;

                            if (
                              item?.ticket_type === "mobile" ||
                              item?.ticket_type === "local-delivery"
                            ) {
                              ticketType = "mobile";
                            } else if (
                              item?.ticket_type === "paper" ||
                              item?.ticket_type === "Paper"
                            ) {
                              ticketType = "paper";
                            } else if (
                              item?.ticket_type === "eticket" ||
                              item?.ticket_type === "PDF"
                            ) {
                              ticketType = "eticket";
                            } else if (item?.ticket_type === "mobile-link") {
                              ticketType = "mobile-link";
                            } else if (item?.ticket_type === "season-cards") {
                              ticketType = "season-cards";
                            }

                            let printShippingLabelDisabled =
                              item?.is_shipping_label_provided;

                            let paperShippingDisabled =
                              !item?.can_be_fulfilled ||
                              item?.shipping_label_required === "true" ||
                              printShippingLabelDisabled ||
                              (typeof item?.deliveryPartner === "undefined" &&
                                item?.shipping_label_required === "false" &&
                                !item?.is_shipping_label_provided);

                            if (item?.selected) {
                              const mobileLinkAsine = item?.items?.filter(
                                (obj: any) => obj?.isValid && obj?.link
                              );
                              if (ticketType !== "mobile") {
                                let seat = _.map(item?.items, "seat").filter(
                                  (filt: any) => filt !== ""
                                );

                                let individualLength = item?.items?.length;
                                let noOfFileExit = item?.items?.filter(
                                  (files: any) => files?.ticketFiles
                                );

                                const checckIfTicketUpload =
                                  item?.items?.filter(
                                    (obj: any) => obj?.ticketFiles
                                  );
                                // PDF && E-TICKET
                                let ticketFileIndex = item?.items?.findIndex(
                                  (i: any) => !i?.ticketFiles?.path
                                );

                                const getIsDropDisabled = () => {
                                  if (ticketType == "mobile-link") {
                                    return !item?.pkPass;
                                  } else {
                                    return !item?.can_be_fulfilled && true;
                                  }
                                };

                                return (
                                  <Droppable
                                    droppableId={`${eventIndex}-${eventRecordIndex}-${ticketFileIndex}`}
                                    key={`${eventIndex}-${eventRecordIndex}-${ticketFileIndex}`}
                                    isDropDisabled={getIsDropDisabled()}
                                  >
                                    {(provided: any, snapshot: any) => {
                                      return (
                                        <div ref={provided.innerRef}>
                                          <div
                                            className="drag_items "
                                            key={ticketFileIndex}
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                          >
                                            <>
                                              <div
                                                className={`
                                      ${
                                        ticketType === "paper" ||
                                        ticketType === "Paper" ||
                                        ticketType === "season-cards"
                                          ? "md:grid-cols-[1fr_17%_1fr_2.5rem] grid-cols-[25%_1fr_2.5rem]"
                                          : "md:grid-cols-[1fr_17%_1fr_2.5rem_2.5rem] grid-cols-[25%_1fr_2.5rem_2.5rem]"
                                      } 
                                      flex flex-wrap
                                      border  ${
                                        snapshot?.isDraggingOver
                                          ? "border-green-500"
                                          : "border-violet-500/10"
                                      } max-md:border-t-0 max-md:rounded-t-none rounded w-full text-xs mb-2.5  justify-between
                                      ${
                                        ticketType === "paper" ||
                                        ticketType === "season-cards"
                                          ? "border-solid"
                                          : "border-solid"
                                      } 
                                      ${
                                        item?.errors?.length > 0
                                          ? "!bg-rose-800/5 !border-rose-500 !border-solid"
                                          : item?.isFull &&
                                            "!bg-green-800/5 !border-green-500 !border-solid"
                                      }
                                      ${
                                        checckIfTicketUpload?.length > 0
                                          ? "bg-violet-300/50"
                                          : "bg-gray-100/50"
                                      }
                                      
                                      `}
                                              >
                                                {/* SEAT INFO. */}
                                                <div
                                                  className={`p-3.5 col-span-full md:col-span-1 md:border-0 border-b border-dashed md:w-[25%] w-1/2
                                       max-md:border-solid max-md:border-r ${
                                         item?.errors?.length > 0
                                           ? "max-md:!border-rose-500"
                                           : item?.isFull &&
                                             "max-md:!border-green-500"
                                       }   `}
                                                >
                                                  <div className="text-gray-400">
                                                    Order ID
                                                  </div>
                                                  <div className="mb-2 break-words">
                                                    {" "}
                                                    {item?.order_id}
                                                  </div>
                                                </div>
                                                <div
                                                  className={`p-3.5 md:border-dashed md:border-t-0 md:w-[10%] w-1/2 max-md:border-b ${
                                                    item?.errors?.length > 0
                                                      ? "max-md:!border-rose-500"
                                                      : item?.isFull &&
                                                        "max-md:!border-green-500"
                                                  }`}
                                                >
                                                  <div className="text-gray-400">
                                                    Quantity
                                                  </div>
                                                  <div>
                                                    {item?.ticket_quantity
                                                      ? item?.ticket_quantity
                                                      : "0"}
                                                  </div>
                                                </div>
                                                <div
                                                  className={`p-3.5 col-span-full md:col-span-1 md:border-0  md:border-dashed md:w-[28%]  ${
                                                    item?.errors?.length > 0
                                                      ? "max-md:!border-rose-500"
                                                      : item?.isFull &&
                                                        "max-md:!border-green-500"
                                                  } ${
                                                    item?.row
                                                      ? "w-full border-b"
                                                      : "max-md:w-[calc(100%-2.5rem)]"
                                                  }`}
                                                >
                                                  <div className="text-gray-400">
                                                    Ticket details
                                                  </div>
                                                  <div>
                                                    {item?.seating_location}
                                                  </div>
                                                </div>

                                                {item?.row && (
                                                  <div className="p-3.5 border-dashed md:border-t-0 md:w-[21%] max-md:w-[calc(100%-2.5rem)]">
                                                    <div className="text-gray-400">
                                                      Row (Seat)
                                                    </div>
                                                    <div>
                                                      {/* ROW ISSUE ---------------------------------------------- */}
                                                      {item?.row
                                                        ? item?.row + " "
                                                        : "N/A "}{" "}
                                                      (
                                                      {seat && seat.length > 0
                                                        ? seat.join(", ")
                                                        : "N/A"}
                                                      )
                                                    </div>
                                                  </div>
                                                )}

                                                {/* BUTTON */}
                                                {/* {ticketType !== "paper" &&
                                        ticketType !== "mobile-link" && (
                                          <div
                                            className={`${
                                              item?.errors?.length > 0
                                                ? "border-rose-500 !bg-rose-50 !border-solid opacity-0"
                                                : item?.isFull &&
                                                  "!border-green-500 !bg-green-50 !border-solid"
                                            } ${
                                              checckIfTicketUpload?.length > 0
                                                ? "border-violet-80"
                                                : "border-gray-200"
                                            } flex items-center justify-center md:border-t-0  border-l border-solid  p-3.5  w-10 ml-auto`}
                                          >
                                            <button
                                              type="button"
                                              id={`eticket-info-${eventIndex}${eventRecordIndex}`}
                                            >
                                              <IKTSVG
                                                className={`fill-${
                                                  item?.isFull
                                                    ? "green"
                                                    : "violet"
                                                }-500 hover:fill-indigo-500  transition `}
                                                svgClassName="w-3.5 h-3.5"
                                                path={infoSVG}
                                              />
                                              <Tooltip
                                                anchorId={`eticket-info-${eventIndex}${eventRecordIndex}`}
                                                content={`${
                                                  (noOfFileExit &&
                                                  noOfFileExit?.length > 0
                                                    ? noOfFileExit?.length
                                                    : "0") +
                                                  "/" +
                                                  individualLength
                                                } tickets assigned`}
                                                place="top"
                                                variant="light"
                                                className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10"
                                              />
                                            </button>
                                          </div>
                                        )} */}

                                                {/* UPLOAD BUTTON CONDITIONS */}
                                                {ticketType === "eticket" ||
                                                ticketType === "PDF" ? (
                                                  <>
                                                    <div
                                                      className={`${
                                                        item?.errors?.length > 0
                                                          ? "!border-rose-500 !bg-rose-50 !border-solid"
                                                          : item?.isFull &&
                                                            "!border-green-500 !bg-green-50 !border-solid"
                                                      } ${
                                                        checckIfTicketUpload?.length >
                                                        0
                                                          ? "border-violet-80"
                                                          : "border-gray-200"
                                                      } flex items-center justify-center md:border-t-0 border-l border-solid  p-3.5  w-10  `}
                                                    >
                                                      <button
                                                        type="button"
                                                        id={`eticket${eventIndex}${eventRecordIndex}`}
                                                      >
                                                        {" "}
                                                        <IKTSVG
                                                          className={`min-w-4 max-w-4 flex items-center justify-center fill-violet-500 transition ${
                                                            item?.errors
                                                              ?.length > 0
                                                              ? "fill-rose-500"
                                                              : item?.isFull &&
                                                                "!fill-green-500"
                                                          }`}
                                                          svgClassName="w-[.6875rem] h-3.5"
                                                          path={pinSVG}
                                                        />
                                                        <Tooltip
                                                          anchorId={`eticket${eventIndex}${eventRecordIndex}`}
                                                          // content="E-ticket"
                                                          content={ticketTypeRenameForSalesReport(
                                                            item?.ticket_type,
                                                            item?.sub_ticket_type
                                                          )}
                                                          place="top"
                                                          variant="light"
                                                          className="!bg-white hover:fill-indigo-500 !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10"
                                                        />
                                                      </button>
                                                    </div>
                                                  </>
                                                ) : ticketType === "paper" ||
                                                  ticketType ===
                                                    "season-cards" ? (
                                                  <>
                                                    <div
                                                      className={`${
                                                        item?.errors?.length > 0
                                                          ? "!border-rose-500 !bg-rose-50"
                                                          : item?.isFull &&
                                                            item?.shippmentTrakingNo &&
                                                            "!border-green-500 !bg-green-50"
                                                      }  flex items-center justify-center md:border-t-0 border-l border-solid border-violet-500/10 p-3.5  w-10 ml-auto`}
                                                    >
                                                      <button
                                                        type="button"
                                                        id={`assigned-ticket${eventIndex}${eventRecordIndex}`}
                                                      >
                                                        <IKTSVG
                                                          className={`min-w-4 max-w-4 flex items-center justify-center ${
                                                            item?.errors
                                                              ?.length > 0
                                                              ? "fill-rose-500"
                                                              : item?.isFull &&
                                                                item?.shippmentTrakingNo
                                                              ? "fill-green-500"
                                                              : "fill-violet-500"
                                                          } transition`}
                                                          svgClassName="w-[.8125rem] h-[.6875rem]"
                                                          path={ticketSVG}
                                                        />
                                                        <Tooltip
                                                          anchorId={`assigned-ticket${eventIndex}${eventRecordIndex}`}
                                                          content={
                                                            ticketType ===
                                                            "paper"
                                                              ? "Paper"
                                                              : "Member/Season Card"
                                                          }
                                                          place="top"
                                                          variant="light"
                                                          className="!bg-white hover:fill-indigo-500 !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10"
                                                        />
                                                      </button>
                                                    </div>
                                                    {item?.errors && (
                                                      <div
                                                        className={`${
                                                          item?.errors?.length >
                                                            0 &&
                                                          "flex flex-wrap border-t-rose-500 "
                                                        } con_transform w-full col-span-full px-4 pr-4 py-3 text-sm12 border-t border-rose-600 flex flex-wrap items-center`}
                                                      >
                                                        <IKTSVG
                                                          className="flex items-center justify-center fill-rose-500 transition"
                                                          svgClassName="w-3.5 h-3.5"
                                                          path={warningSVG}
                                                        />
                                                        <h4 className="text-sm12 ml-3 font-medium">
                                                          {item?.errors}
                                                        </h4>
                                                      </div>
                                                    )}
                                                  </>
                                                ) : (
                                                  // MOBILE-LINK
                                                  ticketType ===
                                                    "mobile-link" && (
                                                    <div
                                                      className={`${
                                                        item?.errors?.length > 0
                                                          ? "border-rose-500 !bg-rose-50 !border-solid border-l-rose-500"
                                                          : item?.isFull &&
                                                            "border-green-500 !bg-green-50 !border-solid"
                                                      } flex items-center justify-center md:border-t-0 border-l border-solid border-violet-500/10 p-3.5 border-dashed ml-auto`}
                                                    >
                                                      <button
                                                        type="button"
                                                        id={`mobile-${eventIndex}${eventRecordIndex}-link`}
                                                      >
                                                        <IKTSVG
                                                          className={`min-w-4 max-w-4 hover:fill-indigo-500 flex items-center justify-center fill-${
                                                            item?.errors
                                                              ?.length > 0
                                                              ? "rose"
                                                              : item?.isFull
                                                              ? "green"
                                                              : "violet"
                                                          }-500 transition`}
                                                          svgClassName="w-4 h-4"
                                                          path={mobileSVG}
                                                        />
                                                        {/* <Tooltip
                                                anchorId={`mobile-${eventIndex}${eventRecordIndex}-link`}
                                                content="Mobile Link"
                                                place="top"
                                                variant="light"
                                                className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10"
                                              /> */}

                                                        <Tooltip
                                                          anchorId={`mobile-${eventIndex}${eventRecordIndex}-link`}
                                                          html={`<div>
                                         <p class='text-center'>
                                         Mobile link/PKPASS
                                         </P>
                                         <p class='text-gray-500'>
                                         ${
                                           mobileLinkAsine?.length
                                             ? mobileLinkAsine?.length
                                             : "0"
                                         } of ${item?.items?.length} assigned 
                                         </p>
                                          </div>`}
                                                          place="top"
                                                          variant="light"
                                                          className="!bg-white !opacity-100  shadow !px-1.5 !py-0.5 !text-xxs font-medium text-xs z-10"
                                                        />
                                                      </button>
                                                    </div>
                                                  )
                                                )}

                                                {/* RIGHT INNER DRAG */}
                                                {/* PDF & ETICKET */}
                                                {item?.errors &&
                                                  ticketType !== "paper" &&
                                                  ticketType !==
                                                    "season-cards" && (
                                                    <div
                                                      className={`${
                                                        item?.errors?.length >
                                                          0 &&
                                                        "flex flex-wrap border-t-rose-500 "
                                                      } con_transform w-full col-span-full px-4 pr-4 py-3 text-sm12 border-t border-rose-600 flex flex-wrap items-center`}
                                                    >
                                                      <IKTSVG
                                                        className="flex items-center justify-center fill-rose-500 transition"
                                                        svgClassName="w-3.5 h-3.5"
                                                        path="standard_icons/warning.svg"
                                                      />
                                                      <h4 className="text-sm12 ml-3 font-medium">
                                                        {/* BE CODE */}
                                                        <span
                                                          dangerouslySetInnerHTML={{
                                                            __html:
                                                              item?.errors?.join(
                                                                "<br/>"
                                                              ),
                                                          }}
                                                        ></span>
                                                      </h4>
                                                    </div>
                                                  )}
                                                {item?.ticket_type ===
                                                  "mobile-link" && (
                                                  <>
                                                    <div className="px-1.5 col-span-full w-full border-t border-solid flex flex-row flex-wrap gap-2.5 py-2.5">
                                                      <div className="flex items-center px-2">
                                                        <span className="font-medium mr-4">
                                                          Separate links for
                                                          Android/iOS
                                                        </span>
                                                        <div>
                                                          <label
                                                            className={`relative inline-flex items-center cursor-pointer false [&amp;.disabled>div:after]:bg-gray-400 [&amp;.disabled]:pointer-events-none false ${
                                                              item?.pkPass &&
                                                              "pointer-events-none "
                                                            } `}
                                                          >
                                                            <input
                                                              type="checkbox"
                                                              name={`separate_links-${item.order_id}`}
                                                              className="sr-only peer"
                                                              checked={
                                                                item?.separate_links
                                                              }
                                                              onChange={(
                                                                e: any
                                                              ) =>
                                                                handleSeparateLinks(
                                                                  e,
                                                                  item?.order_id,
                                                                  order?.event_id
                                                                )
                                                              }
                                                            />
                                                            <div
                                                              className={`bg-gray-200 peer-checked:bg-indigo-500 peer-checked:after:bg-indigo-500  peer-checked:bg-opacity-50 after:bg-violet-500 w-7 h-3  rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full  after:content-[''] after:absolute after:-top-0.5 after:-left-0.5  after:rounded-full after:h-4 after:w-4 after:transition-all after:shadow-md peer-checked:bg-100 peer-checked:after:bg-tick after:bg-center after:bg-no-repeat after:bg-8  ${
                                                                item?.pkPass &&
                                                                "pointer-events-none after:!bg-gray-400 "
                                                              }`}
                                                            ></div>
                                                          </label>
                                                        </div>
                                                      </div>

                                                      <div className="flex items-center px-2  ">
                                                        <span className="font-medium mr-4">
                                                          PKPASS
                                                        </span>
                                                        <div>
                                                          <label className="relative inline-flex items-center cursor-pointer false [&amp;.disabled>div:after]:bg-gray-400 [&amp;.disabled]:pointer-events-none false">
                                                            <input
                                                              type="checkbox"
                                                              name={`pkPass-${item.order_id}`}
                                                              className={`sr-only peer ${
                                                                isFulfilledLoader &&
                                                                "pointer-events-none"
                                                              }`}
                                                              checked={
                                                                item?.pkPass
                                                              }
                                                              onChange={(
                                                                e: any
                                                              ) =>
                                                                handlePKPASS(
                                                                  e,
                                                                  item?.order_id,
                                                                  order?.event_id
                                                                )
                                                              }
                                                              disabled={
                                                                isFulfilledLoader
                                                              }
                                                            />
                                                            <div className="bg-gray-200 peer-checked:bg-indigo-500 peer-checked:after:bg-indigo-500  peer-checked:bg-opacity-50 after:bg-violet-500 w-7 h-3  rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full  after:content-[''] after:absolute after:-top-0.5 after:-left-0.5  after:rounded-full after:h-4 after:w-4 after:transition-all after:shadow-md peer-checked:bg-100 peer-checked:after:bg-tick after:bg-center after:bg-no-repeat after:bg-8"></div>
                                                          </label>
                                                        </div>
                                                      </div>
                                                    </div>

                                                    {/* <div className="flex items-center px-3.5 py-3 col-span-full w-full border-t border-solid">
                                            <span className="font-medium mr-4">
                                              PKPASS
                                            </span>
                                            <div>
                                              <label className="relative inline-flex items-center cursor-pointer false [&amp;.disabled>div:after]:bg-gray-400 [&amp;.disabled]:pointer-events-none false">
                                                <input
                                                  type="checkbox"
                                                  name={`pkPass-${item.order_id}`}
                                                  className={`sr-only peer ${
                                                    isFulfilledLoader &&
                                                    "pointer-events-none"
                                                  }`}
                                                  checked={item?.pkPass}
                                                  onChange={(e: any) =>
                                                    handlePKPASS(
                                                      e,
                                                      item?.order_id,
                                                      order?.event_id
                                                    )
                                                  }
                                                  disabled={isFulfilledLoader}
                                                />
                                                <div className="bg-gray-200 peer-checked:bg-indigo-500 peer-checked:after:bg-indigo-500  peer-checked:bg-opacity-50 after:bg-violet-500 w-7 h-3  rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full  after:content-[''] after:absolute after:-top-0.5 after:-left-0.5  after:rounded-full after:h-4 after:w-4 after:transition-all after:shadow-md peer-checked:bg-100 peer-checked:after:bg-tick after:bg-center after:bg-no-repeat after:bg-8"></div>
                                              </label>
                                            </div>
                                          </div> */}
                                                  </>
                                                )}
                                                <div
                                                  className={`px-3.5 py-2.5 col-span-full w-full flex flex-col gap-2.5 ${
                                                    !isFulfilledLoader &&
                                                    "border-t"
                                                  }	 border-solid ${
                                                    item?.errors?.length > 0
                                                      ? "border-rose-500 !bg-rose-50"
                                                      : item?.isFull &&
                                                        "border-green-500 !bg-green-50"
                                                  }`}
                                                >
                                                  {/* ETICKET && PDF && MOBILE LINK */}
                                                  {!isFulfilledLoader &&
                                                    item?.items?.map(
                                                      (
                                                        ticketFile: any,
                                                        ticketFileIndex: number,
                                                        ticketArray: any
                                                      ) => {
                                                        let isPathExist =
                                                          ticketFile
                                                            ?.ticketFiles?.path;
                                                        return ticketType ===
                                                          "eticket" ||
                                                          ticketType ===
                                                            "PDF" ? (
                                                          // <div
                                                          //   ref={provided.innerRef}
                                                          // >
                                                          //   <div
                                                          //     className="drag_items "
                                                          //     key={ticketFileIndex}
                                                          //     ref={
                                                          //       provided.innerRef
                                                          //     }
                                                          //     {...provided.draggableProps}
                                                          //     {...provided.dragHandleProps}
                                                          //   >
                                                          <div className="single_drag flex flex-wrap items-center ">
                                                            {}
                                                            <span className="mr-2 text-sm12">
                                                              {ticketFile?.row &&
                                                              ticketFile?.row !==
                                                                "N/A"
                                                                ? ticketFile?.row
                                                                : ""}{" "}
                                                              {ticketFile?.seat &&
                                                                ticketFile?.seat !==
                                                                  "N/A" &&
                                                                `(${ticketFile?.seat})`}
                                                            </span>
                                                            <div
                                                              onMouseEnter={() => {
                                                                setHoverFile(
                                                                  ticketFile
                                                                    ?.ticketFiles
                                                                    ?.id
                                                                );
                                                              }}
                                                              onMouseLeave={() => {
                                                                setHoverFile(
                                                                  ""
                                                                );
                                                              }}
                                                              className={`${
                                                                isPathExist &&
                                                                "!border-solid !border-green-500 hover:!border-indigo-500 hover:bg-violet-50 transition"
                                                              } border border-dashed rounded bg-white px-2 py-[3px] flex-1 transition`}
                                                            >
                                                              <span
                                                                className={`${
                                                                  isPathExist &&
                                                                  "!text-black !inline-flex items-center justify-between w-full"
                                                                } ${
                                                                  !item?.can_be_fulfilled &&
                                                                  "inline-flex justify-between w-full "
                                                                }text-sm12 font-medium text-gray-400 block `}
                                                              >
                                                                {isPathExist
                                                                  ? isPathExist
                                                                  : !item?.can_be_fulfilled
                                                                  ? "Already uploaded"
                                                                  : " Drag file here"}
                                                                <span
                                                                  className={`flex s-center gap-x-2 `}
                                                                >
                                                                  {(isPathExist ||
                                                                    !item?.can_be_fulfilled) && (
                                                                    <button
                                                                      type="button"
                                                                      id={`view-${eventIndex}-${eventRecordIndex}-${ticketFileIndex}`}
                                                                      onClick={() =>
                                                                        pdfViewer(
                                                                          ticketFile?.ticketFiles
                                                                        )
                                                                      }
                                                                    >
                                                                      <IKTSVG
                                                                        className="fill-violet-500 hover:fill-indigo-500 transition"
                                                                        path={
                                                                          eyeSVG
                                                                        }
                                                                        svgClassName="w-4 h-4"
                                                                      />
                                                                      <Tooltip
                                                                        anchorId={`view-${eventIndex}-${eventRecordIndex}-${ticketFileIndex}`}
                                                                        content="Preview"
                                                                        place="top"
                                                                        variant="light"
                                                                        className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10"
                                                                      />
                                                                    </button>
                                                                  )}

                                                                  {isPathExist &&
                                                                    item?.can_be_fulfilled && (
                                                                      <button
                                                                        type="button"
                                                                        onClick={() => {
                                                                          deletePdfRightSide(
                                                                            ticketFile?.ticketFiles,
                                                                            ticketFileIndex,
                                                                            eventRecordIndex,
                                                                            eventIndex
                                                                          );
                                                                        }}
                                                                        className=""
                                                                        id={`delete-${eventIndex}-${eventRecordIndex}-${ticketFileIndex}`}
                                                                      >
                                                                        <IKTSVG
                                                                          className="fill-violet-500 hover:fill-indigo-500 h-2.5 w-2.5 transition"
                                                                          svgClassName="h-2.5 w-2.5"
                                                                          path={
                                                                            Cross
                                                                          }
                                                                        />
                                                                        <Tooltip
                                                                          anchorId={`delete-${eventIndex}-${eventRecordIndex}-${ticketFileIndex}`}
                                                                          content="Unassign"
                                                                          place="top"
                                                                          variant="light"
                                                                          className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10"
                                                                        />
                                                                      </button>
                                                                    )}
                                                                </span>
                                                              </span>
                                                            </div>
                                                          </div>
                                                        ) : (
                                                          //   </div>
                                                          // </div>

                                                          //   }}
                                                          // </Droppable>
                                                          ticketType ===
                                                            "mobile-link" && (
                                                            <>
                                                              {item?.pkPass ? (
                                                                <>
                                                                  {/* <Droppable
                                                                    droppableId={`${eventIndex}-${eventRecordIndex}-${ticketFileIndex}`}
                                                                    key={`${eventIndex}-${eventRecordIndex}-${ticketFileIndex}`}
                                                                    isDropDisabled={
                                                                      ticketFile
                                                                        ?.ticketFiles
                                                                        ?.path &&
                                                                      ticketFile?.link &&
                                                                      ticketFile.hasOwnProperty(
                                                                        "isValid"
                                                                      ) &&
                                                                      ticketFile?.isValid
                                                                        ? true
                                                                        : false
                                                                    }
                                                                  >
                                                                    {(
                                                                      provided: any,
                                                                      snapshot: any
                                                                    ) => {
                                                                      return (
                                                                        <div
                                                                          ref={
                                                                            provided.innerRef
                                                                          }
                                                                        >
                                                                          {" "}
                                                                          <div
                                                                            className="drag_items "
                                                                            key={
                                                                              ticketFileIndex
                                                                            }
                                                                            ref={
                                                                              provided.innerRef
                                                                            }
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                          > */}
                                                                  <div className="single_drag flex flex-wrap items-center ">
                                                                    <span className="mr-2 text-sm12">
                                                                      Ticket{" "}
                                                                      {ticketFileIndex +
                                                                        1}
                                                                    </span>
                                                                    <div
                                                                      className={`  ${
                                                                        ticketFile
                                                                          ?.ticketFiles
                                                                          ?.path &&
                                                                        !ticketFile?.link
                                                                          ? "min-h-[1.5rem]  border border-dashed rounded bg-white px-2 py-[3px] flex-1 transition border-green-500"
                                                                          : ticketFile
                                                                              ?.ticketFiles
                                                                              ?.path &&
                                                                            ticketFile?.link
                                                                          ? "!border-solid !border-green-500 hover:!border-indigo-500 hover:bg-violet-50 transition   border rounded bg-white px-2 py-[3px] flex-1 "
                                                                          : "min-h-[1.5rem]  border border-dashed rounded bg-white px-2 py-[3px] flex-1 transition"
                                                                      }  ${
                                                                        ticketFile
                                                                          ?.ticketFiles
                                                                          ?.path &&
                                                                        !ticketFile.hasOwnProperty(
                                                                          "isValid"
                                                                        ) &&
                                                                        "border-1 !border-dashed !border-green-500"
                                                                      }   `}
                                                                      onMouseEnter={() => {
                                                                        setHoverFile(
                                                                          ticketFile
                                                                            ?.ticketFiles
                                                                            ?.id
                                                                        );
                                                                      }}
                                                                      onMouseLeave={() => {
                                                                        setHoverFile(
                                                                          ""
                                                                        );
                                                                      }}
                                                                    >
                                                                      <span
                                                                        className={`text-sm12 font-medium block ${
                                                                          ticketFile
                                                                            ?.ticketFiles
                                                                            ?.path &&
                                                                          !ticketFile?.link
                                                                            ? ""
                                                                            : ticketFile
                                                                                ?.ticketFiles
                                                                                ?.path &&
                                                                              ticketFile?.link
                                                                            ? "!text-black !inline-flex items-center justify-between w-full falsetext-sm12 font-medium"
                                                                            : "text-gray-400"
                                                                        } `}
                                                                      >
                                                                        {ticketFile
                                                                          ?.ticketFiles
                                                                          ?.path &&
                                                                        !ticketFile.hasOwnProperty(
                                                                          "isValid"
                                                                        ) ? (
                                                                          <div className="flex items-center flex-nowrap ">
                                                                            <span className="flex-none mr-2.5">
                                                                              Generating
                                                                              URL
                                                                            </span>
                                                                            <span className="flex-1 ">
                                                                              <div className="page-loader">
                                                                                <div className="loader-stripe bg-gray-200  rounded w-full h-2">
                                                                                  <div
                                                                                    className="load-line h-full bg-green-500"
                                                                                    style={{
                                                                                      animation: ` loaderAnim 3s linear forwards`,
                                                                                    }}
                                                                                  ></div>
                                                                                </div>
                                                                              </div>
                                                                            </span>
                                                                          </div>
                                                                        ) : ticketFile
                                                                            ?.ticketFiles
                                                                            ?.path &&
                                                                          ticketFile?.link ? (
                                                                          <>
                                                                            {ticketFile?.link
                                                                              ?.split(
                                                                                "/"
                                                                              )
                                                                              .pop()}
                                                                            <span className="flex s-center gap-x-2 ">
                                                                              <button
                                                                                type="button"
                                                                                id={`copy-mobile-link-${eventIndex}-${eventRecordIndex}-${ticketFileIndex}`}
                                                                                onClick={() => {
                                                                                  let copiedValues =
                                                                                    copy(
                                                                                      ticketFile?.link
                                                                                    );
                                                                                  setIsCopied(
                                                                                    {
                                                                                      id: `copy-mobile-link-${eventIndex}-${eventRecordIndex}-${ticketFileIndex}`,
                                                                                      value:
                                                                                        copiedValues,
                                                                                    }
                                                                                  );
                                                                                  setTimeout(
                                                                                    () => {
                                                                                      setIsCopied(
                                                                                        {
                                                                                          id: "",
                                                                                          value:
                                                                                            "",
                                                                                        }
                                                                                      );
                                                                                    },
                                                                                    200
                                                                                  );
                                                                                }}
                                                                              >
                                                                                <IKTSVG
                                                                                  className={`flex items-center justify-center transition ${
                                                                                    isCopied.value ===
                                                                                      ticketFile?.link &&
                                                                                    isCopied.id ===
                                                                                      `copy-mobile-link-${eventIndex}-${eventRecordIndex}-${ticketFileIndex}`
                                                                                      ? "fill-green-600  hover:fill-green-600"
                                                                                      : "fill-violet-500 hover:fill-indigo-500"
                                                                                  }`}
                                                                                  path={
                                                                                    CloneSVG
                                                                                  }
                                                                                  svgClassName="w-4 h-4"
                                                                                />
                                                                                <Tooltip
                                                                                  anchorId={`copy-mobile-link-${eventIndex}-${eventRecordIndex}-${ticketFileIndex}`}
                                                                                  content="Copy"
                                                                                  place="top"
                                                                                  variant="light"
                                                                                  className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10"
                                                                                />
                                                                              </button>

                                                                              <button
                                                                                type="button"
                                                                                id={`delete-mobile-link-${eventIndex}-${eventRecordIndex}-${ticketFileIndex}`}
                                                                                onClick={(
                                                                                  e: any
                                                                                ) =>
                                                                                  deleteMobileLink(
                                                                                    eventIndex,
                                                                                    eventRecordIndex,
                                                                                    ticketFileIndex
                                                                                  )
                                                                                }
                                                                              >
                                                                                <IKTSVG
                                                                                  className="fill-violet-500 hover:fill-indigo-500 h-2.5 w-2.5 transition"
                                                                                  svgClassName="h-2.5 w-2.5"
                                                                                  path={
                                                                                    Cross
                                                                                  }
                                                                                />
                                                                                <Tooltip
                                                                                  anchorId={`delete-mobile-link-${eventIndex}-${eventRecordIndex}-${ticketFileIndex}`}
                                                                                  content="Unassign"
                                                                                  place="top"
                                                                                  variant="light"
                                                                                  className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10"
                                                                                />
                                                                              </button>
                                                                            </span>
                                                                          </>
                                                                        ) : (
                                                                          "Drop PKPASS file here"
                                                                        )}
                                                                      </span>
                                                                    </div>
                                                                  </div>
                                                                  {/* </div>
                                                                        </div>
                                                                      );
                                                                    }}
                                                                  </Droppable> */}
                                                                </>
                                                              ) : (
                                                                <>
                                                                  <SalesMobilelinkUrl
                                                                    ticketFileIndex={
                                                                      ticketFileIndex
                                                                    }
                                                                    ticketFile={
                                                                      ticketFile
                                                                    }
                                                                    item={item}
                                                                    eventIndex={
                                                                      eventIndex
                                                                    }
                                                                    eventRecordIndex={
                                                                      eventRecordIndex
                                                                    }
                                                                    handleMobileLinkChange={
                                                                      handleMobileLinkChange
                                                                    }
                                                                    copy={copy}
                                                                    setIsCopied={
                                                                      setIsCopied
                                                                    }
                                                                    isCopied={
                                                                      isCopied
                                                                    }
                                                                  />
                                                                </>
                                                              )}
                                                            </>
                                                          )
                                                        );
                                                      }
                                                    )}

                                                  {/* PAPER (HARD) */}
                                                  {(ticketType === "paper" ||
                                                    ticketType ===
                                                      "season-cards") && (
                                                    <>
                                                      {(item?.shipping_download ||
                                                        item?.shipping_label_required ===
                                                          "true") && (
                                                        <div className="flex">
                                                          {item?.shipping_label_required ===
                                                            "true" && (
                                                            <button
                                                              type="button"
                                                              className={`${
                                                                item?.isFull ||
                                                                item?.shipping_download
                                                                  ? "pointer-events-none opacity-60"
                                                                  : ""
                                                              } bg-violet-500 hover:bg-indigo-500 mt-4 mb-4 flex items-center text-white  transition rounded text-sm13 px-2.5 py-1`}
                                                              onClick={() =>
                                                                handleShippingLabel(
                                                                  order,
                                                                  item
                                                                )
                                                              }
                                                            >
                                                              Generate shipping
                                                              label
                                                            </button>
                                                          )}

                                                          {/* SHIPPING LABEL BUTTON */}
                                                          {item?.shipping_download && (
                                                            <button
                                                              type="button"
                                                              className=" ml-2 bg-violet-500 hover:bg-indigo-500 mt-4 mb-4 flex items-center text-white  transition rounded text-sm13 px-2.5 py-1"
                                                              onClick={() => {
                                                                window.open(
                                                                  item?.shipping_download
                                                                );
                                                              }}
                                                            >
                                                              Download Shipping
                                                              Label
                                                            </button>
                                                          )}
                                                        </div>
                                                      )}

                                                      {item?.shipping_label_required ===
                                                        "false" && (
                                                        <div className="con_transform w-full text-sm12 border-violet-900 flex flex-wrap items-center relative td-dd-auto-width">
                                                          {item?.printLabel ? (
                                                            <button
                                                              type="button"
                                                              className="flex items-center text-white bg-violet-500 hover:bg-indigo-500 transition rounded text-sm13 px-2.5 py-1"
                                                              onClick={() => {
                                                                window.open(
                                                                  item?.printLabel
                                                                );
                                                              }}
                                                            >
                                                              Print shipping
                                                              label
                                                            </button>
                                                          ) : (
                                                            <>
                                                              <div className="mr-2">
                                                                Delivery
                                                                partner:
                                                              </div>
                                                              <TableDropdown
                                                                options={
                                                                  deliveryPartners
                                                                }
                                                                name="delivery_option"
                                                                placeholder="Delivery option"
                                                                value={deliveryPartners.find(
                                                                  (
                                                                    option: any
                                                                  ) =>
                                                                    option.id ===
                                                                    item
                                                                      ?.deliveryPartner
                                                                      ?.id
                                                                )}
                                                                // isClearable={true}
                                                                handleOnChange={(
                                                                  e: any
                                                                ) => {
                                                                  handleDeliverypartner(
                                                                    eventIndex,
                                                                    eventRecordIndex,
                                                                    e
                                                                  );
                                                                }}
                                                                isCapital={true}
                                                                isValueCapital={
                                                                  true
                                                                }
                                                              />
                                                            </>
                                                          )}
                                                        </div>
                                                      )}
                                                      <div className="con_transform w-full  text-sm12 border-violet-900 flex flex-wrap items-center relative">
                                                        {/* INPUT BOX */}
                                                        <input
                                                          id={`select-delivery-partner-${eventIndex}${eventRecordIndex}`}
                                                          type="text"
                                                          value={
                                                            item?.shippmentTrakingNo
                                                          }
                                                          placeholder={`${
                                                            !item?.can_be_fulfilled &&
                                                            item?.shippmentTrakingNo
                                                              ? item?.shippmentTrakingNo
                                                              : !item?.can_be_fulfilled &&
                                                                !item?.shippmentTrakingNo
                                                              ? "Already Uploaded"
                                                              : "Enter tracking number"
                                                          }`}
                                                          className={`${
                                                            !item?.can_be_fulfilled &&
                                                            "opacity-60"
                                                          } truncate h-6 flex-1 text-sm13 font-medium py-0 border-gray-300 rounded   ${
                                                            paperShippingDisabled
                                                              ? `bg-gray-100 pointer`
                                                              : `focus:border-violet-400 focus:bg-violet-300/50`
                                                          }  mr-1 ${
                                                            item?.errors
                                                              ?.length > 0
                                                              ? "border-rose-500"
                                                              : item?.isFull &&
                                                                item?.shippmentTrakingNo &&
                                                                "border-green-500"
                                                          }`}
                                                          disabled={
                                                            paperShippingDisabled
                                                              ? true
                                                              : false
                                                          }
                                                          onChange={(e: any) =>
                                                            handleTicketNo(
                                                              eventIndex,
                                                              eventRecordIndex,
                                                              e,
                                                              false,
                                                              false
                                                            )
                                                          }
                                                        />
                                                        {paperShippingDisabled &&
                                                          item?.shipping_label_required ===
                                                            "false" &&
                                                          !item?.printLabel && (
                                                            <Tooltip
                                                              anchorId={`select-delivery-partner-${eventIndex}${eventRecordIndex}`}
                                                              content='Please select "Delivery partner" field first'
                                                              place="top"
                                                              variant="light"
                                                              className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10"
                                                            />
                                                          )}
                                                        {/* CLEAR BUTTON INSIDE INPUT BOX*/}
                                                        {item?.isFull &&
                                                          item?.shippmentTrakingNo &&
                                                          !paperShippingDisabled && (
                                                            <span className="flex items-center gap-x-2 absolute right-4">
                                                              <button
                                                                type="button"
                                                                id={`paper-${eventIndex}-${eventRecordIndex}-clear`}
                                                                onClick={(
                                                                  e: any
                                                                ) =>
                                                                  handleTicketNo(
                                                                    eventIndex,
                                                                    eventRecordIndex,
                                                                    e,
                                                                    false,
                                                                    true
                                                                  )
                                                                }
                                                              >
                                                                <Tooltip
                                                                  anchorId={`paper-${eventIndex}-${eventRecordIndex}-clear`}
                                                                  content="Clear"
                                                                  place="top"
                                                                  variant="light"
                                                                  className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10"
                                                                />
                                                                <IKTSVG
                                                                  className="fill-violet-500 hover:fill-indigo-500 transition"
                                                                  path={
                                                                    crossSVG
                                                                  }
                                                                  svgClassName="w-[.5625rem] h-[.5625rem]"
                                                                />
                                                              </button>
                                                            </span>
                                                          )}
                                                        {/* CHECK & CROSS BUTTON */}
                                                        {item?.shipping_label_required !==
                                                          "true" &&
                                                          !item?.isFull && (
                                                            <>
                                                              {/* <button
                                                      type="button"
                                                      className={`${
                                                        !item?.shippmentTrakingNo?.trim() &&
                                                        "pointer-events-none opacity-50"
                                                      } group flex mx-1 items-center justify-center h-6 w-6 border border-gray-300 hover:border-violet-500 text-white bg-white hover:bg-violet-500 transition rounded`}
                                                      onClick={(e: any) =>
                                                        handleTicketNo(
                                                          eventIndex,
                                                          eventRecordIndex,
                                                          e,
                                                          false,
                                                          true
                                                        )
                                                      }
                                                    >
                                                      <IKTSVG
                                                        className="fill-violet-500 group-hover:fill-white transition"
                                                        path={crossSVG}
                                                      />
                                                    </button> */}
                                                              <button
                                                                type="button"
                                                                className={`${
                                                                  // item && !item?.shippmentTrakingNo?.trim() &&

                                                                  (!item?.shippmentTrakingNo ||
                                                                    (Array.isArray(
                                                                      item?.shippmentTrakingNo
                                                                    ) &&
                                                                      _.size(
                                                                        item?.shippmentTrakingNo
                                                                      ) ===
                                                                        0)) &&
                                                                  "pointer-events-none opacity-50"
                                                                }  group flex mx-1 items-center justify-center h-6 w-6 border border-gray-300 hover:border-indigo-500 text-white bg-white hover:bg-indigo-500 transition rounded mr-0`}
                                                                onClick={(
                                                                  e: any
                                                                ) =>
                                                                  handleTicketNo(
                                                                    eventIndex,
                                                                    eventRecordIndex,
                                                                    e,
                                                                    true,
                                                                    false
                                                                  )
                                                                }
                                                              >
                                                                <IKTSVG
                                                                  path={
                                                                    checkSVG
                                                                  }
                                                                  className={` group-hover:fill-white transition ${
                                                                    !item?.shippmentTrakingNo
                                                                      ? "fill-gray-400"
                                                                      : "fill-violet-500"
                                                                  }`}
                                                                  svgClassName="w-[.75rem] h-[.625rem]"
                                                                />
                                                              </button>
                                                            </>
                                                          )}
                                                      </div>
                                                    </>
                                                  )}
                                                </div>
                                              </div>

                                              {/* Addtional File started for etickit paper pdf */}
                                              {/* Addtional File started for etickit paper pdf */}

                                              <Droppable
                                                droppableId={`${eventIndex}-${eventRecordIndex}-additionalFile`}
                                                key={`${eventIndex}-${eventRecordIndex}-additionalFile`}
                                                isDropDisabled={
                                                  (!item?.can_be_fulfilled ||
                                                    !(
                                                      activeTemplates?.length <=
                                                        0 ||
                                                      item?.template?.value ===
                                                        0
                                                    )) &&
                                                  true
                                                }
                                              >
                                                {(
                                                  provided: any,
                                                  snapshot: any
                                                ) => {
                                                  let isPathExist =
                                                    item?.addtionalFile?.path;

                                                  let viewButtonVisible =
                                                    (isPathExist &&
                                                      getExtension(
                                                        isPathExist
                                                      ) !== "pkpass") ||
                                                    !item?.can_be_fulfilled;

                                                  return (
                                                    <div
                                                      ref={provided.innerRef}
                                                      className={`   addtional_file_drag ${
                                                        item?.template
                                                          ?.value === 0 &&
                                                        "!ps-0 !pt-0"
                                                      } w-full `}
                                                    >
                                                      <div
                                                        className="drag_items"
                                                        key={eventRecordIndex}
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                      >
                                                        {!isFulfilledLoader && (
                                                          <div
                                                            className={`
                                      
                                                    ${
                                                      ticketType === "paper" ||
                                                      ticketType === "Paper" ||
                                                      ticketType ===
                                                        "season-cards"
                                                        ? "md:grid-cols-[1fr_17%_1fr_2.5rem] grid-cols-[25%_1fr_2.5rem]"
                                                        : "md:grid-cols-[1fr_17%_1fr_2.5rem_2.5rem] grid-cols-[25%_1fr_2.5rem_2.5rem]"
                                                    } 
                                                    px-2 flex ${
                                                      activeTemplates?.length >
                                                      0
                                                        ? "flex-col"
                                                        : ""
                                                    } ${
                                                              (activeTemplates >
                                                                0 ||
                                                                (item?.template &&
                                                                  (item
                                                                    ?.templateKeys
                                                                    ?.length >
                                                                    0 ||
                                                                    item
                                                                      ?.template
                                                                      ?.value ===
                                                                      0))) &&
                                                              "gap-2.5"
                                                            } ${
                                                              item?.template
                                                                ?.value !== 0 &&
                                                              item?.templateKeys
                                                                ?.length > 0
                                                                ? "pb-2"
                                                                : ""
                                                            }
                                      border border-solid  ${
                                        snapshot?.isDraggingOver
                                          ? "border-green-500"
                                          : "border-violet-500/10"
                                      }  rounded w-full text-xs mb-2.5 bg-gray-100/50 
                                      ${
                                        ticketType === "paper" ||
                                        ticketType === "season-cards"
                                          ? "border-solid"
                                          : "border-solid"
                                      } 
                                      ${
                                        item?.addtionalFile &&
                                        "!bg-green-800/5 !border-green-500 !border-solid"
                                      }
                                     
                                      `}
                                                          >
                                                            <div
                                                              className={`flex items-center ${
                                                                activeTemplates?.length >
                                                                0
                                                                  ? "w-full"
                                                                  : "w-[5.6rem]"
                                                              } gap-2 pt-2 ${
                                                                // (!item?.template ||
                                                                //   item
                                                                //     ?.templateKeys
                                                                //     ?.length ===
                                                                //     0) &&
                                                                item?.template
                                                                  ?.value !==
                                                                  0 && "pb-2"
                                                              }`}
                                                            >
                                                              <p>
                                                                Additional file
                                                              </p>

                                                              {activeTemplates?.length >
                                                                0 && (
                                                                <DropDownField
                                                                  options={[
                                                                    ...activeTemplates,
                                                                    {
                                                                      title:
                                                                        "upload your own",
                                                                      value: 0,
                                                                      id: "0",
                                                                    },
                                                                  ]}
                                                                  placeholder="Template"
                                                                  name="template"
                                                                  value={
                                                                    item?.template
                                                                  }
                                                                  getOptionLabel="title"
                                                                  handleOnChange={(
                                                                    data: any
                                                                  ) => {
                                                                    if (
                                                                      data
                                                                        ?.target
                                                                        ?.value
                                                                        ?.value !==
                                                                      0
                                                                    ) {
                                                                      setTemplateView(
                                                                        true
                                                                      );
                                                                    } else {
                                                                      setTemplateView(
                                                                        false
                                                                      );
                                                                    }
                                                                    setTemplate(
                                                                      data
                                                                        .target
                                                                        .value
                                                                    );
                                                                    setTemplateInputes(
                                                                      {}
                                                                    );
                                                                    let canvasBody: any =
                                                                      document.getElementsByClassName(
                                                                        "offcanvas-body"
                                                                      )?.[0];
                                                                    if (
                                                                      canvasBody
                                                                    ) {
                                                                      canvasBody.scrollTop = 0;
                                                                    }
                                                                    handleChangeTemplate(
                                                                      data
                                                                        ?.target
                                                                        ?.value,
                                                                      eventRecordIndex,
                                                                      eventIndex
                                                                    );
                                                                    deletePdfRightSide(
                                                                      item?.addtionalFile,
                                                                      "additionalFile",
                                                                      eventRecordIndex,
                                                                      eventIndex
                                                                    );
                                                                  }}
                                                                  isClearable={
                                                                    false
                                                                  }
                                                                />
                                                              )}
                                                              {item?.template &&
                                                                item?.template
                                                                  ?.value !==
                                                                  0 && (
                                                                  <button
                                                                    type="button"
                                                                    id={`view-${eventIndex}-${eventRecordIndex}-additionalFile`}
                                                                    onClick={() => {
                                                                      setTemplate(
                                                                        item?.template
                                                                      );
                                                                      setTemplateInputes(
                                                                        item?.inputs
                                                                      );
                                                                      setTemplateView(
                                                                        true
                                                                      );
                                                                      let canvasBody: any =
                                                                        document.getElementsByClassName(
                                                                          "offcanvas-body"
                                                                        )?.[0];
                                                                      if (
                                                                        canvasBody
                                                                      ) {
                                                                        canvasBody.scrollTop = 0;
                                                                      }
                                                                    }}
                                                                  >
                                                                    <IKTSVG
                                                                      className="fill-violet-500 hover:fill-indigo-500 transition"
                                                                      path={
                                                                        eyeSVG
                                                                      }
                                                                      svgClassName="w-4 h-[11px]"
                                                                    />
                                                                    <Tooltip
                                                                      anchorId={`view-${eventIndex}-${eventRecordIndex}-additionalFile`}
                                                                      content="Preview"
                                                                      place="top"
                                                                      variant="light"
                                                                      className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10"
                                                                    />
                                                                  </button>
                                                                )}
                                                            </div>

                                                            {
                                                              <div
                                                                className={`flex flex-col w-full gap-2.5 gray-bg-label`}
                                                              >
                                                                {item
                                                                  ?.templateKeys
                                                                  ?.length >
                                                                  0 &&
                                                                  item?.templateKeys?.map(
                                                                    (
                                                                      key: any
                                                                    ) => (
                                                                      <div
                                                                        key={`${item?.order_id}-${key}`}
                                                                      >
                                                                        {/* <label htmlFor={key}>
                                                {key.charAt(0).toUpperCase() +
                                                  key.slice(1)}
                                                :
                                              </label> */}
                                                                        {/* <input
                                                type="text"
                                                id={key}
                                                name={key}
                                                value={
                                                  templateInputes[key] || ""
                                                }
                                                onChange={handleInputChange}
                                              /> */}

                                                                        <Textfield
                                                                          type="text"
                                                                          id={`${item?.order_id}-${key}`}
                                                                          name={
                                                                            key
                                                                          }
                                                                          value={
                                                                            item
                                                                              ?.inputs?.[
                                                                              key
                                                                            ] ||
                                                                            ""
                                                                          }
                                                                          // placeholder={key}
                                                                          label={
                                                                            key
                                                                          }
                                                                          handleOnChange={(
                                                                            e: any
                                                                          ) => {
                                                                            setTemplate(
                                                                              item?.template
                                                                            );
                                                                            handleInputChange(
                                                                              e,
                                                                              eventRecordIndex,
                                                                              eventIndex,
                                                                              key
                                                                            );
                                                                          }}
                                                                        />
                                                                      </div>
                                                                    )
                                                                  )}

                                                                {(activeTemplates?.length <=
                                                                  0 ||
                                                                  item?.template
                                                                    ?.value ===
                                                                    0) && (
                                                                  // <Droppable
                                                                  //   droppableId={`${eventIndex}-${eventRecordIndex}-additionalFile`}
                                                                  //   key={`${eventIndex}-${eventRecordIndex}-additionalFile`}
                                                                  //   isDropDisabled={
                                                                  //     !item?.can_be_fulfilled &&
                                                                  //     true
                                                                  //   }
                                                                  // >
                                                                  //   {(
                                                                  //     provided: any,
                                                                  //     snapshot: any
                                                                  //   ) => {
                                                                  //     let isPathExist =
                                                                  //       item
                                                                  //         ?.addtionalFile
                                                                  //         ?.path;

                                                                  //     let viewButtonVisible =
                                                                  //       (isPathExist &&
                                                                  //         getExtension(
                                                                  //           isPathExist
                                                                  //         ) !==
                                                                  //           "pkpass") ||
                                                                  //       !item?.can_be_fulfilled;
                                                                  //     return (
                                                                  //       <div
                                                                  //         ref={
                                                                  //           provided.innerRef
                                                                  //         }
                                                                  //         className={`addtional_file_drag p-2 ${
                                                                  //           item?.template
                                                                  //             ?.value ===
                                                                  //             0 &&
                                                                  //           "!ps-0 !pt-0"
                                                                  //         } w-full`}
                                                                  //       >
                                                                  //         <div
                                                                  //           className="drag_items "
                                                                  //           key={
                                                                  //             eventRecordIndex
                                                                  //           }
                                                                  //           ref={
                                                                  //             provided.innerRef
                                                                  //           }
                                                                  //           {...provided.draggableProps}
                                                                  //           {...provided.dragHandleProps}
                                                                  //         >
                                                                  <div className="single_drag flex flex-wrap items-center ">
                                                                    {}
                                                                    {/* <span className="mr-2 text-sm12">
                                                                {ticketFile?.row &&
                                                                ticketFile?.row !==
                                                                  "N/A"
                                                                  ? ticketFile?.row
                                                                  : ""}{" "}
                                                                {ticketFile?.seat &&
                                                                  ticketFile?.seat !==
                                                                    "N/A" &&
                                                                  `(${ticketFile?.seat})`}
                                                              </span> */}
                                                                    <div
                                                                      onMouseEnter={() => {
                                                                        setHoverFile(
                                                                          item
                                                                            ?.addtionalFile
                                                                            ?.id
                                                                        );
                                                                      }}
                                                                      onMouseLeave={() => {
                                                                        setHoverFile(
                                                                          ""
                                                                        );
                                                                      }}
                                                                      className={`${
                                                                        isPathExist &&
                                                                        "!border-solid !border-green-500 hover:!border-indigo-500 hover:bg-violet-50 transition"
                                                                      }  border border-dashed rounded bg-white px-2 py-[3px] mb-2 flex-1 transition`}
                                                                    >
                                                                      <span
                                                                        className={`${
                                                                          isPathExist &&
                                                                          "!text-black !inline-flex items-center justify-between w-full"
                                                                        } ${
                                                                          !item?.can_be_fulfilled &&
                                                                          "inline-flex justify-between w-full "
                                                                        }text-sm12 font-medium text-gray-400 block `}
                                                                      >
                                                                        {isPathExist
                                                                          ? isPathExist
                                                                          : !item?.can_be_fulfilled
                                                                          ? "Already uploaded"
                                                                          : " Drag file here"}
                                                                        <span
                                                                          className={`flex s-center gap-x-2 `}
                                                                        >
                                                                          {viewButtonVisible && (
                                                                            <button
                                                                              type="button"
                                                                              id={`view-${eventIndex}-${eventRecordIndex}-additionalFile`}
                                                                              onClick={() =>
                                                                                pdfViewer(
                                                                                  item?.addtionalFile
                                                                                )
                                                                              }
                                                                            >
                                                                              <IKTSVG
                                                                                className="fill-violet-500 hover:fill-indigo-500 transition"
                                                                                path={
                                                                                  eyeSVG
                                                                                }
                                                                                svgClassName="w-4 h-4"
                                                                              />
                                                                              <Tooltip
                                                                                anchorId={`view-${eventIndex}-${eventRecordIndex}-additionalFile`}
                                                                                content="Preview"
                                                                                place="top"
                                                                                variant="light"
                                                                                className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10"
                                                                              />
                                                                            </button>
                                                                          )}

                                                                          {isPathExist &&
                                                                            item?.can_be_fulfilled && (
                                                                              <button
                                                                                type="button"
                                                                                onClick={() => {
                                                                                  deletePdfRightSide(
                                                                                    item?.addtionalFile,
                                                                                    "additionalFile",
                                                                                    eventRecordIndex,
                                                                                    eventIndex
                                                                                  );
                                                                                }}
                                                                                id={`delete-${eventIndex}-${eventRecordIndex}-additionalFile`}
                                                                              >
                                                                                <IKTSVG
                                                                                  className="fill-violet-500 hover:fill-indigo-500 h-2.5 w-2.5 transition"
                                                                                  svgClassName="h-2.5 w-2.5"
                                                                                  path={
                                                                                    Cross
                                                                                  }
                                                                                />
                                                                                <Tooltip
                                                                                  anchorId={`delete-${eventIndex}-${eventRecordIndex}-additionalFile`}
                                                                                  content="Unassign"
                                                                                  place="top"
                                                                                  variant="light"
                                                                                  className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10"
                                                                                />
                                                                              </button>
                                                                            )}
                                                                        </span>
                                                                      </span>
                                                                    </div>
                                                                  </div>
                                                                  //   </div>
                                                                  // </div>
                                                                  //     );
                                                                  //   }}
                                                                  // </Droppable>
                                                                )}
                                                              </div>
                                                            }
                                                          </div>
                                                        )}
                                                      </div>
                                                    </div>
                                                  );
                                                }}
                                              </Droppable>

                                              {/*Addtional File ended */}
                                              {/*Addtional File ended */}
                                            </>
                                          </div>
                                        </div>
                                      );
                                    }}
                                  </Droppable>
                                );
                              }

                              // MOBILE
                              else {
                                let seat = _.map(item?.items, "seat").filter(
                                  (filt: any) => filt !== ""
                                );

                                const checckIfTicketUpload =
                                  item?.items?.filter(
                                    (obj: any) => obj?.ticketFiles
                                  );

                                if (item?.ticket_quantity > 0) {
                                  return (
                                    <>
                                      <Droppable
                                        droppableId={`${eventIndex}-${eventRecordIndex}`}
                                        key={`${eventIndex}-${eventRecordIndex}`}
                                        isDropDisabled={
                                          !item?.isActive ? true : false
                                        }
                                      >
                                        {(provided: any, snapshot: any) => (
                                          <>
                                            <div
                                              ref={provided.innerRef}
                                              className={`${
                                                item?.errors?.length > 0
                                                  ? "!bg-rose-500/5 !border-rose-500 !border-solid"
                                                  : item?.isFull &&
                                                    "!bg-green-800/5 !border-green-500 !border-solid"
                                              } flex flex-wrap grid-cols-[25%_1fr_2.5rem_2.5rem] md:grid-cols-[1fr_17%_1fr_2.5rem_2.5rem] border border-violet-500/10 rounded w-full text-xs mb-2.5 ${
                                                checckIfTicketUpload?.length > 0
                                                  ? "bg-violet-300/50"
                                                  : "bg-gray-100/50"
                                              } justify-between `}
                                            >
                                              <div className="p-3.5 col-span-full md:col-span-1 md:border-0 border-b  md:w-[25%] w-full">
                                                <div className="text-gray-400">
                                                  Order ID
                                                </div>
                                                <div className="mb-2 break-words">
                                                  {item?.order_id}
                                                </div>
                                              </div>
                                              <div className="p-3.5 border-dashed border-t-0 w-[10%]">
                                                <div className="text-gray-400">
                                                  Quantity
                                                </div>
                                                <div>
                                                  {item?.ticket_quantity
                                                    ? item?.ticket_quantity
                                                    : "0"}
                                                </div>
                                              </div>
                                              <div className="p-3.5 col-span-full md:col-span-1 md:border-0 border-b border-dashed md:w-[28%] w-full">
                                                <div className="text-gray-400">
                                                  Ticket details
                                                </div>
                                                <div>
                                                  {item?.seating_location}
                                                </div>
                                              </div>

                                              <div className="p-3.5 border-dashed border-t-0 w-[21%]">
                                                <div className="text-gray-400">
                                                  Row (Seat)
                                                </div>
                                                <div>
                                                  {item?.row
                                                    ? item?.row + " "
                                                    : "N/A "}
                                                  {Array.isArray(seat) &&
                                                  seat.length > 0
                                                    ? `(${seat.join(", ")})`
                                                    : "(N/A)"}
                                                </div>
                                              </div>

                                              {/* BUTTON */}
                                              {/* <div
                                                className={`${
                                                  item?.errors?.length > 0
                                                    ? "border-rose-500 !bg-rose-50 !border-solid opacity-0"
                                                    : item?.isFull &&
                                                      "border-green-500 !bg-green-50 !border-solid"
                                                }  flex items-center justify-center md:border-t-0 border-l border-solid border-violet-500/10 p-3.5  ml-auto`}
                                              >
                                                <button
                                                  type="button"
                                                  id={`info${eventIndex}${eventRecordIndex}`}
                                                >
                                                  <IKTSVG
                                                    className={`${
                                                      item?.isFull &&
                                                      "!fill-green-500"
                                                    } fill-violet-500 hover:fill-indigo-500 transition`}
                                                    path={infoSVG}
                                                  />
                                                  <Tooltip
                                                    anchorId={`info${eventIndex}${eventRecordIndex}`}
                                                    content="Proof of transfer required"
                                                    place="top"
                                                    variant="light"
                                                    className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10"
                                                  />
                                                </button>
                                              </div> */}
                                              <div
                                                className={`${
                                                  item?.errors?.length > 0
                                                    ? "border-rose-500 !bg-rose-50 !border-solid border-l-rose-500"
                                                    : item?.isFull &&
                                                      "border-green-500 !bg-green-50 !border-solid"
                                                } flex items-center justify-center md:border-t-0 border-l border-solid border-violet-500/10 p-3.5 `}
                                              >
                                                <button
                                                  type="button"
                                                  id={`mobile${eventIndex}${eventRecordIndex}`}
                                                >
                                                  <IKTSVG
                                                    className={`min-w-4 max-w-4 flex items-center justify-center fill-${
                                                      item?.errors?.length > 0
                                                        ? "rose"
                                                        : item?.isFull
                                                        ? "green"
                                                        : "violet"
                                                    }-500 transition`}
                                                    path={
                                                      item?.ticket_type ===
                                                      "mobile"
                                                        ? mobileSVG
                                                        : ticketSVG
                                                    }
                                                    svgClassName="w-[.625rem] h-[.9375rem]"
                                                  />
                                                  <Tooltip
                                                    anchorId={`mobile${eventIndex}${eventRecordIndex}`}
                                                    content={
                                                      item?.ticket_type ===
                                                      "mobile"
                                                        ? "External Transfer"
                                                        : "Local delivery"
                                                    }
                                                    place="top"
                                                    variant="light"
                                                    className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10"
                                                  />
                                                </button>
                                              </div>

                                              {/* ERROR IF OCCUR FROM API SIDE - MOBILE*/}
                                              {item?.errors && (
                                                <div
                                                  className={`${
                                                    item?.errors?.length > 0 &&
                                                    "border-t-rose-500 "
                                                  }con_transform w-full col-span-full px-4 pr-4 py-3 text-sm12 border-t border-rose-600 flex flex-wrap items-center`}
                                                >
                                                  <IKTSVG
                                                    className="flex items-center justify-center fill-rose-500 transition"
                                                    svgClassName="w-3.5 h-3.5"
                                                    path={warningSVG}
                                                  />
                                                  <h4 className="text-sm12 ml-3 font-medium">
                                                    {item?.errors}
                                                  </h4>
                                                </div>
                                              )}

                                              {/* DRAGABBLE NORMAL */}
                                              {!isFulfilledLoader && (
                                                <div
                                                  className={`${
                                                    item?.errors?.length > 0
                                                      ? "border-rose-500 !bg-rose-50 border-solid"
                                                      : item?.isFull
                                                      ? "border-green-500 !bg-green-50 border-solid border-t"
                                                      : "border-solid border-t"
                                                  } px-2 col-span-full   w-full ${
                                                    checckIfTicketUpload?.length >
                                                    0
                                                      ? "bg-violet-300/50"
                                                      : "bg-gray-100/50"
                                                  }`}
                                                >
                                                  {/* IS ACTIVE BUTTOn */}
                                                  {!item?.isActive ? (
                                                    <div className="flex items-center justify-between">
                                                      <div className="w-full flex bg-white border rounded gap-x-2 px-2 py-1 my-2  transition">
                                                        <span className="text-sm12 font-medium  block ">
                                                          Confirm you have
                                                          transferred the{" "}
                                                          {item?.ticket_type ===
                                                          "mobile"
                                                            ? "mobile"
                                                            : ""}
                                                          tickets outside of
                                                          Tixstock?
                                                        </span>
                                                      </div>

                                                      <button
                                                        type="button"
                                                        className={`  group flex mx-1 items-center justify-center h-6 w-6 border border-gray-300  text-white bg-violet-500 hover:bg-indigo-500 transition rounded mr-0`}
                                                        onClick={() => {
                                                          handleIsActive(
                                                            order,
                                                            item
                                                          );
                                                        }}
                                                      >
                                                        <IKTSVG
                                                          path={checkSVG}
                                                          className="fill-violet-500 fill-white transition"
                                                          svgClassName="w-[.75rem] h-[.625rem]"
                                                        />
                                                      </button>
                                                    </div>
                                                  ) : (
                                                    (!item?.ticketFiles ||
                                                      item?.ticketFiles
                                                        ?.length === 0) && (
                                                      <div className="flex flex-wrap items-center ">
                                                        <span className="text-sm12 mr-2">
                                                          Proof of transfer
                                                        </span>
                                                        <div className="flex-1 bg-white border border-dashed rounded gap-x-2 px-2 py-1 my-2 transition ">
                                                          <span className="text-sm12 font-medium text-gray-400 ">
                                                            Drag files here
                                                          </span>
                                                        </div>
                                                      </div>
                                                    )
                                                  )}
                                                  {/* {(!item?.ticketFiles ||
                                                  item?.ticketFiles?.length ===
                                                    0) && (
                                                  <div className="flex bg-white border border-dashed rounded gap-x-2 px-2 py-1 my-2 transition">
                                                    <span className="text-sm12 font-medium text-gray-400 block ">
                                                      Drag files here
                                                    </span>
                                                  </div>
                                                )} */}

                                                  {grouping[eventIndex]?.orders[
                                                    eventRecordIndex
                                                  ]?.ticketFiles &&
                                                    grouping[
                                                      eventIndex
                                                    ]?.orders[
                                                      eventRecordIndex
                                                    ]?.ticketFiles?.map(
                                                      (
                                                        ticketFile: any,
                                                        ticketFileIndex: any
                                                      ) => {
                                                        if (ticketFile) {
                                                        }
                                                        return (
                                                          <Draggable
                                                            key={`${eventIndex}-${eventRecordIndex}-${ticketFileIndex}`}
                                                            draggableId={`${eventIndex}-${eventRecordIndex}-${ticketFileIndex}`}
                                                            index={
                                                              ticketFileIndex
                                                            }
                                                          >
                                                            {(
                                                              provided: any,
                                                              snapshot: any
                                                            ) => (
                                                              <div
                                                                onMouseEnter={() => {
                                                                  setHoverFile(
                                                                    ticketFile?.id
                                                                  );
                                                                }}
                                                                onMouseLeave={() => {
                                                                  setHoverFile(
                                                                    ""
                                                                  );
                                                                }}
                                                                className={`${
                                                                  item?.errors
                                                                    ?.length > 0
                                                                    ? "!border-rose-500"
                                                                    : item?.isFull &&
                                                                      "!border-green-500"
                                                                } flex bg-white border rounded gap-x-2 px-2 py-1 my-2 transition hover:!bg-violet-50 hover:!border-indigo-500`}
                                                                key={
                                                                  ticketFileIndex
                                                                }
                                                                ref={
                                                                  provided.innerRef
                                                                }
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                              >
                                                                <span className="text-xs flex-auto">
                                                                  {
                                                                    ticketFile.path
                                                                  }
                                                                </span>
                                                                <span className="flex items-center gap-x-2">
                                                                  <Tooltip
                                                                    anchorId={`${eventIndex}-${eventRecordIndex}-${ticketFileIndex}`}
                                                                    content="Preview"
                                                                    place="top"
                                                                    variant="light"
                                                                    className="!bg-white !opacity-100 shadow !px-1.5 !py-0.5 !text-xxs font-medium text-xs z-10"
                                                                  />
                                                                  <button
                                                                    type="button"
                                                                    onClick={() =>
                                                                      pdfViewer(
                                                                        ticketFile
                                                                      )
                                                                    }
                                                                    id={`${eventIndex}-${eventRecordIndex}-${ticketFileIndex}`}
                                                                  >
                                                                    <IKTSVG
                                                                      className="fill-violet-500 hover:fill-indigo-500 transition"
                                                                      svgClassName="w-4 h-4"
                                                                      path={
                                                                        eyeSVG
                                                                      }
                                                                    />
                                                                  </button>
                                                                  <Tooltip
                                                                    anchorId={`delete${eventIndex}${eventRecordIndex}${ticketFileIndex}`}
                                                                    content="Delete"
                                                                    place="top"
                                                                    variant="light"
                                                                    className="!bg-white !opacity-100 shadow !px-1.5 !py-0.5 !text-xxs font-medium text-xs z-10"
                                                                  />
                                                                  <button
                                                                    onClick={() =>
                                                                      deletePdfRightSide(
                                                                        ticketFile,
                                                                        ticketFileIndex,
                                                                        eventRecordIndex,
                                                                        eventIndex
                                                                      )
                                                                    }
                                                                    id={`delete${eventIndex}${eventRecordIndex}${ticketFileIndex}`}
                                                                  >
                                                                    <IKTSVG
                                                                      className="fill-violet-500 hover:fill-indigo-500 h-2.5 w-2.5 transition"
                                                                      svgClassName="h-2.5 w-2.5"
                                                                      path={
                                                                        crossSVG
                                                                      }
                                                                    />
                                                                  </button>
                                                                  <Tooltip
                                                                    anchorId={`delete${eventIndex}${eventRecordIndex}`}
                                                                    content="Delete"
                                                                    place="top"
                                                                    variant="light"
                                                                    className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10"
                                                                  />
                                                                </span>
                                                              </div>
                                                            )}
                                                          </Draggable>
                                                        );
                                                      }
                                                    )}
                                                </div>
                                              )}
                                            </div>
                                          </>
                                        )}
                                      </Droppable>
                                      {/* addtional file for mobile */}
                                      {/* addtional file for mobile */}

                                      {!isFulfilledLoader && (
                                        <div
                                          className={`
                                      
                                      ${
                                        ticketType === "paper" ||
                                        ticketType === "Paper" ||
                                        ticketType === "season-cards"
                                          ? "md:grid-cols-[1fr_17%_1fr_2.5rem] grid-cols-[25%_1fr_2.5rem]"
                                          : "md:grid-cols-[1fr_17%_1fr_2.5rem_2.5rem] grid-cols-[25%_1fr_2.5rem_2.5rem]"
                                      } 
                                      px-2 flex ${
                                        activeTemplates?.length > 0
                                          ? "flex-col"
                                          : ""
                                      } ${
                                            (activeTemplates > 0 ||
                                              (item?.template &&
                                                (item?.templateKeys?.length >
                                                  0 ||
                                                  item?.template?.value ===
                                                    0))) &&
                                            "gap-2.5"
                                          } ${
                                            item?.template?.value !== 0 &&
                                            item?.templateKeys?.length > 0
                                              ? "pb-2"
                                              : ""
                                          }
                                      border border-solid   border-violet-500/10 rounded w-full text-xs mb-2.5 bg-gray-100/50 
                                      ${
                                        ticketType === "paper" ||
                                        ticketType === "season-cards"
                                          ? "border-solid"
                                          : "border-solid"
                                      } 
                                      ${
                                        item?.addtionalFile &&
                                        "!bg-green-800/5 !border-green-500 !border-solid"
                                      }
                                      
                                      `}
                                        >
                                          <div
                                            className={`flex items-center ${
                                              activeTemplates?.length > 0
                                                ? "w-full"
                                                : "w-[5.6rem]"
                                            } gap-2 pt-2 ${
                                              (!item?.template ||
                                                item?.templateKeys?.length ===
                                                  0) &&
                                              item?.template?.value !== 0 &&
                                              "pb-2"
                                            }`}
                                          >
                                            <p>Additional file</p>

                                            {activeTemplates?.length > 0 && (
                                              <DropDownField
                                                options={[
                                                  ...activeTemplates,
                                                  {
                                                    title: "upload your own",
                                                    value: 0,
                                                    id: "0",
                                                  },
                                                ]}
                                                placeholder="Template"
                                                name="template"
                                                value={item?.template}
                                                getOptionLabel="title"
                                                handleOnChange={(data: any) => {
                                                  if (
                                                    data?.target?.value
                                                      ?.value !== 0
                                                  ) {
                                                    setTemplateView(true);
                                                  } else {
                                                    setTemplateView(false);
                                                  }
                                                  setTemplate(
                                                    data.target.value
                                                  );
                                                  setTemplateInputes({});

                                                  handleChangeTemplate(
                                                    data?.target?.value,
                                                    eventRecordIndex,
                                                    eventIndex
                                                  );
                                                  deletePdfRightSide(
                                                    item?.addtionalFile,
                                                    "additionalFile",
                                                    eventRecordIndex,
                                                    eventIndex
                                                  );
                                                }}
                                                isClearble={false}
                                              />
                                            )}

                                            {item?.template &&
                                              item?.template?.value !== 0 && (
                                                <button
                                                  type="button"
                                                  id={`view-${eventIndex}-${eventRecordIndex}-additionalFile`}
                                                  onClick={() => {
                                                    setTemplate(item?.template);
                                                    setTemplateInputes(
                                                      item?.inputs
                                                    );
                                                    setTemplateView(true);
                                                  }}
                                                >
                                                  <IKTSVG
                                                    className="fill-violet-500 hover:fill-indigo-500 transition"
                                                    path={eyeSVG}
                                                    svgClassName="w-4 h-[11px]"
                                                  />
                                                  <Tooltip
                                                    anchorId={`view-${eventIndex}-${eventRecordIndex}-additionalFile`}
                                                    content="Preview"
                                                    place="top"
                                                    variant="light"
                                                    className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10"
                                                  />
                                                </button>
                                              )}
                                          </div>

                                          {
                                            <div
                                              className={`flex flex-col w-full gap-2.5 gray-bg-label`}
                                            >
                                              {item?.templateKeys?.length > 0 &&
                                                item?.templateKeys?.map(
                                                  (key: any) => (
                                                    <div
                                                      key={`${item?.order_id}-${key}`}
                                                    >
                                                      {/* <label htmlFor={key}>
                                                {key.charAt(0).toUpperCase() +
                                                  key.slice(1)}
                                                :
                                              </label> */}
                                                      {/* <input
                                                type="text"
                                                id={key}
                                                name={key}
                                                value={
                                                  templateInputes[key] || ""
                                                }
                                                onChange={handleInputChange}
                                              /> */}

                                                      <Textfield
                                                        type="text"
                                                        id={`${item?.order_id}-${key}`}
                                                        name={key}
                                                        value={
                                                          item?.inputs?.[key] ||
                                                          ""
                                                        }
                                                        // placeholder={key}
                                                        label={key}
                                                        handleOnChange={(
                                                          e: any
                                                        ) => {
                                                          setTemplate(
                                                            item?.template
                                                          );
                                                          handleInputChange(
                                                            e,
                                                            eventRecordIndex,
                                                            eventIndex,
                                                            key
                                                          );
                                                        }}
                                                      />
                                                    </div>
                                                  )
                                                )}

                                              {(activeTemplates?.length <= 0 ||
                                                item?.template?.value ===
                                                  0) && (
                                                <Droppable
                                                  droppableId={`${eventIndex}-${eventRecordIndex}-additionalFile`}
                                                  key={`${eventIndex}-${eventRecordIndex}-additionalFile`}
                                                  isDropDisabled={
                                                    !item?.can_be_fulfilled &&
                                                    true
                                                  }
                                                >
                                                  {(
                                                    provided: any,
                                                    snapshot: any
                                                  ) => {
                                                    let isPathExist =
                                                      item?.addtionalFile?.path;

                                                    let viewButtonVisible =
                                                      (isPathExist &&
                                                        getExtension(
                                                          isPathExist
                                                        ) !== "pkpass") ||
                                                      !item?.can_be_fulfilled;
                                                    return (
                                                      <div
                                                        ref={provided.innerRef}
                                                        className={`     addtional_file_drag p-2 ${
                                                          item?.template
                                                            ?.value === 0 &&
                                                          "!ps-0 !pt-0"
                                                        } w-full eventRecord `}
                                                      >
                                                        <div
                                                          className="drag_items"
                                                          key={eventRecordIndex}
                                                          ref={
                                                            provided.innerRef
                                                          }
                                                          {...provided.draggableProps}
                                                          {...provided.dragHandleProps}
                                                        >
                                                          <div className="single_drag flex flex-wrap items-center ">
                                                            {}
                                                            {/* <span className="mr-2 text-sm12">
                                                                {ticketFile?.row &&
                                                                ticketFile?.row !==
                                                                  "N/A"
                                                                  ? ticketFile?.row
                                                                  : ""}{" "}
                                                                {ticketFile?.seat &&
                                                                  ticketFile?.seat !==
                                                                    "N/A" &&
                                                                  `(${ticketFile?.seat})`}
                                                              </span> */}
                                                            <div
                                                              onMouseEnter={() => {
                                                                setHoverFile(
                                                                  item
                                                                    ?.addtionalFile
                                                                    ?.id
                                                                );
                                                              }}
                                                              onMouseLeave={() => {
                                                                setHoverFile(
                                                                  ""
                                                                );
                                                              }}
                                                              className={`${
                                                                isPathExist &&
                                                                "!border-solid !border-green-500 hover:!border-indigo-500 hover:bg-violet-50 transition"
                                                              }  border border-dashed rounded bg-white px-2 py-[3px] flex-1 transition`}
                                                            >
                                                              <span
                                                                className={`${
                                                                  isPathExist &&
                                                                  "!text-black !inline-flex items-center justify-between w-full"
                                                                } ${
                                                                  !item?.can_be_fulfilled &&
                                                                  "inline-flex justify-between w-full "
                                                                }text-sm12 font-medium text-gray-400 block `}
                                                              >
                                                                {isPathExist
                                                                  ? isPathExist
                                                                  : !item?.can_be_fulfilled
                                                                  ? "Already uploaded"
                                                                  : " Drag file here"}
                                                                <span
                                                                  className={`flex s-center gap-x-2 `}
                                                                >
                                                                  {viewButtonVisible && (
                                                                    <button
                                                                      type="button"
                                                                      id={`view-${eventIndex}-${eventRecordIndex}-additionalFile`}
                                                                      onClick={() =>
                                                                        pdfViewer(
                                                                          item?.addtionalFile
                                                                        )
                                                                      }
                                                                    >
                                                                      <IKTSVG
                                                                        className="fill-violet-500 hover:fill-indigo-500 transition"
                                                                        path={
                                                                          eyeSVG
                                                                        }
                                                                        svgClassName="w-4 h-4"
                                                                      />
                                                                      <Tooltip
                                                                        anchorId={`view-${eventIndex}-${eventRecordIndex}-additionalFile`}
                                                                        content="Preview"
                                                                        place="top"
                                                                        variant="light"
                                                                        className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10"
                                                                      />
                                                                    </button>
                                                                  )}

                                                                  {isPathExist &&
                                                                    item?.can_be_fulfilled && (
                                                                      <button
                                                                        type="button"
                                                                        onClick={() => {
                                                                          deletePdfRightSide(
                                                                            item?.addtionalFile,
                                                                            "additionalFile",
                                                                            eventRecordIndex,
                                                                            eventIndex
                                                                          );
                                                                        }}
                                                                        id={`delete-${eventIndex}-${eventRecordIndex}-additionalFile`}
                                                                      >
                                                                        <IKTSVG
                                                                          className="fill-violet-500 hover:fill-indigo-500 h-2.5 w-2.5 transition"
                                                                          svgClassName="h-2.5 w-2.5"
                                                                          path={
                                                                            Cross
                                                                          }
                                                                        />
                                                                        <Tooltip
                                                                          anchorId={`delete-${eventIndex}-${eventRecordIndex}-additionalFile`}
                                                                          content="Unassign"
                                                                          place="top"
                                                                          variant="light"
                                                                          className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10"
                                                                        />
                                                                      </button>
                                                                    )}
                                                                </span>
                                                              </span>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    );
                                                  }}
                                                </Droppable>
                                              )}
                                            </div>
                                          }
                                        </div>
                                      )}

                                      {/* addtional file ended */}
                                      {/* addtional file ended */}
                                    </>
                                  );
                                }
                              }
                            }
                          }
                        )}
                      </div>
                    </React.Fragment>
                  );
                })}
            </div>
          </div>

          {/* FOOTER BUTTONS */}
          <div className=" offcanvas-footer justify-end bottom-0 right-0 w-full flex  px-5 py-3 bg-white z-10 border-t">
            {!isFulfilledLoader &&
            isFulfilled &&
            errorLength === 0 &&
            fullOrdersLength === getLength?.length ? (
              <>
                <span className="md:ml-2.5 text-sm13 mr-2 ">
                  All orders fulfilled
                </span>
                <IKTSVG
                  className="flex items-center justify-center fill-green-600 transition"
                  svgClassName="w-4 h-4"
                  path={publishGraySVG}
                />
              </>
            ) : isFulfilledLoader ? (
              <>
                <span className="md:ml-2.5 text-sm13 mr-2">
                  Fulfilling orders
                </span>
                <IKTSVG
                  className="flex items-center justify-center fill-violet-500 transition animate-spin"
                  svgClassName="w-4 h-4"
                  path={processSVG}
                />
              </>
            ) : (
              <div className="l_btn gap-2.5 flex flex-wrap items-center">
                <span className="md:ml-2.5 text-sm13">
                  Fulfill{" "}
                  {`${isFulfilledLoader ? "0" : fullOrdersLength}/${
                    getLength?.length
                  } orders`}{" "}
                </span>
                <button
                  type="button"
                  className="border border-gray-300 hover:border-indigo-500 text-violet-800 hover:text-white bg-white hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5"
                  data-bs-dismiss="offcanvas"
                  aria-label="close"
                  onClick={handleClose}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className={`${
                    // fullOrdersLength === 0 &&
                    (potRequired?.value === false &&
                    data?.ticket_type === "mobile"
                      ? false
                      : fullOrdersLength !== getLength?.length) &&
                    "pointer-events-none !border-0 !text-gray-400 !bg-gray-100"
                  } relative z-10   hover:text-white active: bg-green-600 text-white hover:bg-indigo-500 active:bg-indigo-500 transition font-semibold rounded text-sm13 px-2.5 py-0.5`}
                  disabled={
                    potRequired?.value === false &&
                    data?.ticket_type === "mobile"
                      ? false
                      : fullOrdersLength !== getLength?.length
                  }
                  onClick={() => submitFulfill()}
                >
                  Confirm
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="offcanvas-backdrop fade show"></div>
      {paperLoader && <Loader />}
    </DragDropContext>
  );
};

export default ETicketUpload;
