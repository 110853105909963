import { useState } from "react";
import SVG from "react-inlinesvg";
import PurpleCheckSVG from "../../assets/media/icons/other_icons/purple-check.svg";
import CrossSVG from "../../assets/media/icons/standard_icons/cross.svg";
import { IKTSVG } from "../ui/IKTSVG";
import { KTSVG } from "../ui/KTSVG";

const TextInput = ({
  label,
  value,
  type = "text",
  id,
  name,
  handleOnChange,
  handleClearValue,
  disabled = false,
  errorClass,
  required = true,
  labelErrorClass,
  apiError,
  isIcon = false,
  width = "",
  autoComplete,
  focusProp,
  key,
  inputHeight = "h-10",
  placeholder = "",
  className,
  handleKeyDown = () => {},
  handleOnFocus = () => {},
  inputClassName = "placeholder:truncate block px-2.5 font-medium w-full text-xs transition bg-gray-100 border border-gray-300 appearance-none rounded focus:outline-none  focus:ring-0 focus:border-indigo-500 focus:bg-violet-300/50 peer peer-focus:bg-white autofill:bg-indigo-500 form",
  labelClassName = "absolute text-xxs rounded-full text-gray-400 duration-300 transform -translate-y-4 font-basier top-2 left-3 z-10 origin-[0] bg-gray-100 peer-focus:bg-white px-2 peer-focus:px-2 peer-focus:text-indigo-500 peer-placeholder-shown:-translate-y-1/2  peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-placeholder-shown:text-xs peer-focus:text-xxs peer-placeholder-shown:text-gray-500 peer-focus:-translate-y-4",
  checkHidden = false,
  yellowBorder = false,
}: any) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);
  return (
    <>
      <div
        key={key}
        className={`date_h relative w-full  ${isFocused && "dp-focused "} ${
          isIcon && "group"
        }`}
      >
        {isIcon && (
          <span
            className={`table-date-icon h-[2.5rem] ${width} cursor-pointer absolute left-0 top-0 flex items-center justify-center fill-violet-500 rounded-l border-r border-gray-300 w-8  pointer-events-none group-[.dp-focused]:bg-indigo-500 group-[.dp-focused]:z-10 group-[.dp-focused]:!fill-white group-[.dp-focused]:border-indigo-500`}
          >
            <KTSVG svgClassName="h-3 w-3" path="standard_icons/geo_icon.svg" />
          </span>
        )}
        <input
          type={type}
          id={id}
          name={name}
          autoComplete={autoComplete}
          className={`${
            disabled
              ? `!bg-gray-100 `
              : value && !isFocused
              ? "!bg-white border-indigo-500 input_focus"
              : ``
          } ${errorClass} ${
            isIcon && "pl-[3.125rem]"
          } ${inputClassName} ${inputHeight} ${className} ${
            yellowBorder && "border-yellow-100"
          } pr-8`}
          placeholder={placeholder ? placeholder : " "}
          value={value}
          disabled={disabled}
          onFocus={(data: any) => {
            let event = {
              target: { name: data.target.name, value: data.target.value },
            };
            handleOnFocus(event, true);
            setIsFocused(true);
          }}
          onBlur={(data: any) => {
            let event = {
              target: { name: data.target.name, value: data.target.value },
            };
            handleOnFocus(event, false);
            setIsFocused(false);
          }}
          onChange={(data) => {
            data.stopPropagation();
            let event = {
              target: { name: data.target.name, value: data.target.value },
            };
            handleOnChange(event);
          }}
          onKeyDown={handleKeyDown}
        />

        {/* LABEL */}
        <label
          htmlFor={id}
          className={`${
            value && "!bg-white text-indigo-500"
          } ${labelErrorClass} ${apiError} ${isIcon && "left-11"} ${
            disabled ? "!bg-gray-100" : ""
          } ${labelClassName}`}
        >
          {label}
          {required && <span className="text-red-500 ml-1 asterisk">*</span>}
        </label>

        {/* CLEAR & CHECK ICON */}
        {value && isFocused ? (
          <button
            tabIndex={-1}
            type="button"
            className={` w-5 h-5 absolute top-1/2 -translate-y-1/2 right-2.5 flex items-center justify-center fill-violet-500 group-hover:fill-white bg-white rounded-full   hover:fill-white cursor-pointer hover:bg-violet-500  transition-all focusIcon`}
            onMouseDown={(e: any) => {
              handleClearValue();
            }}
          >
            <SVG src={CrossSVG} className={"w-2 h-2"} />
          </button>
        ) : value && !errorClass ? (
          <IKTSVG
            className={`flex items-center justify-center transition absolute  w-5 h-5  top-1/2 -translate-y-1/2 right-2.5 focusIcon ${
              checkHidden && "hidden"
            }`}
            path={PurpleCheckSVG}
            svgClassName="w-[1.125rem]"
          />
        ) : null}
      </div>
    </>
  );
};

export default TextInput;
