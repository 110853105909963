import { LayoutContext } from "components/core/LayoutProvider";
import MultiDropDownField from "components/formComponent/MultiDropDownField";
import _ from "lodash";
import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import { useQuery } from "react-query";
import DropDownField from "../../../../components/formComponent/DropDownField";
import PriceField from "../../../../components/formComponent/PriceField";
import Textfield from "../../../../components/formComponent/Textfield";
import ProgressBardLoader from "../../../../components/ui/ProgressBardLoader";
import {
  convertToFormattedData,
  decimalNumber,
  handleSelectDeselectAll,
} from "../../../../helpers/Functions";
import { showAlert } from "../../../../helpers/ShowAlert";
import {
  ORDER_ASSIGN_TO_NEW_TEAM,
  ORDER_ON_OFFER,
  QUERIES,
  SYMBOLS,
} from "../../../../helpers/const";
import { getEventDetails } from "../../../add_inventory/core/_requsets";
import { SalesContext } from "../../core/SalesProvider";
import {
  getOrderReplacementTeamListingPrice,
  offerOrder,
} from "../../core/_requests";
import BackToOrder from "./components/BackToOrder";
import EventDetails from "./components/EventDetails";
import Header from "./components/Header";
import HighligtedFilter from "./components/HighligtedFilter";
import Label from "./components/Label";
import SellarPenalityDetails from "./components/SellarPenalityDetails";

const OrderOnOffer = () => {
  const layout = useContext(SalesContext);
  const coreLayout = useContext(LayoutContext);
  const orderOnOffer = layout?.orderOnOffer?.data;
  const teamsRef = useRef<any>(null);

  const [categoryOptions, setCategoryOptions] = useState<any>(null);
  const [customNewOrderLoader, setCustomNewOrderLoader] = useState(false);

  // Initial state
  const [state, setState] = useState<any>({
    seller_level: null,
    team_id: null,
    ticketDetails: {
      category: null,
      section: null,
      row: "",
      first_seat: "",
      ticketPrice: "",
    },
    penalty: {
      penaltyType: "absolute",
      penaltyValue: "",
      total: 0,
      notes: "",
    },
    orderAdmistration: {
      orderStatus: null,
      internalNote: "",
    },
    deduct_from_wallet: false,
  });

  // Filtered team options based on seller level and existing team ID
  const teamIDOptions = useMemo(() => {
    const sellerlevelIDs = state?.seller_level?.map((item: any) => item?.id);
    return layout?.teamsOptions?.filter(
      (option: any) =>
        option?.id !== orderOnOffer?.team_id &&
        sellerlevelIDs?.includes(option?.seller_level_id)
    );
  }, [state.seller_level, layout.teamsOptions, orderOnOffer?.team_id]);

  // Determine if button is disabled
  const isDisabled = useMemo(
    () => layout?.orderOnOffer?.confirmed,
    [layout?.orderOnOffer?.confirmed]
  );

  // Determine form field and review statuses
  const isActive = useMemo(
    () => ({
      fieldStatus: Boolean(state?.seller_level?.length),
      reviewStatus: Boolean(
        state?.seller_level &&
          _.size(state?.team_id) > 0 &&
          (categoryOptions?.categories?.length === 0 ||
            state?.ticketDetails?.category) &&
          state?.ticketDetails?.ticketPrice?.value &&
          state?.penalty?.penaltyValue &&
          state?.penalty?.total &&
          state?.penalty?.notes &&
          state?.orderAdmistration?.orderStatus &&
          state?.orderAdmistration?.internalNote
      ),
    }),
    [
      state?.seller_level,
      state?.team_id,
      categoryOptions?.categories,
      state?.ticketDetails?.category,
      state?.ticketDetails?.ticketPrice?.value,
      state?.penalty?.penaltyValue,
      state?.penalty?.total,
      state?.penalty?.notes,
      state?.orderAdmistration?.orderStatus,
      state?.orderAdmistration?.internalNote,
    ]
  );

  // Fetch category options
  const { data: categoryData } = useQuery(
    [QUERIES.GET_CATEGORY_LISTING],
    () => getEventDetails(orderOnOffer?.event_id, ""),
    {
      refetchOnWindowFocus: false,
      retry: false,
      onSettled: (data) => {
        if (data?.status === 200) {
          const options = data?.data?.data?.[0];
          setCategoryOptions(options);

          const selectedCategory = options?.categories?.find(
            (item: any) => item?.name === orderOnOffer?.category
          );

          setState((current: any) => ({
            ...current,
            ticketDetails: {
              category: selectedCategory,
              section: selectedCategory?.sections?.find(
                (item: any) => item?.name === orderOnOffer?.seating_location
              ),
              row: orderOnOffer?.row,
              first_seat: orderOnOffer?.first_seat,
              ticketPrice: {
                name: "ticketPrice",
                value: orderOnOffer?.refund_amount,
                symbol: orderOnOffer?.refund_currency,
              },
            },
          }));
        }
      },
    }
  );

  const transactionValue = useMemo(
    () =>
      (
        (orderOnOffer?.ticket_quantity || 0) *
        (state?.ticketDetails?.ticketPrice?.value || 0)
      ).toFixed(2),
    [orderOnOffer?.ticket_quantity, state?.ticketDetails?.ticketPrice?.value]
  );

  // Replacement data and payload
  const replacementData = useMemo(
    () => ({
      category: state?.ticketDetails?.category?.name,
      seating_location: state?.ticketDetails?.section?.name,
      first_seat: state?.ticketDetails?.first_seat,
      row: state?.ticketDetails?.row,
      ticket_currency: state?.ticketDetails?.ticketPrice?.symbol,
      ticket_price: state?.ticketDetails?.ticketPrice?.value,
      ticket_quantity: orderOnOffer?.ticket_quantity,
      ticket_type: orderOnOffer?.ticket_type,
      sub_ticket_type: orderOnOffer?.sub_ticket_type,
      transaction_value: transactionValue,
      broker_name: `${state?.team_id && state?.team_id?.length} selected`,
      seller_levels: state?.seller_level,
    }),
    [state?.ticketDetails, transactionValue, state?.team_id, orderOnOffer]
  );

  const payload = useMemo(
    () => ({
      fine_type:
        state?.penalty?.penaltyType === "absolute"
          ? "fixed_amount"
          : "percentage",
      fine_amount: state?.penalty?.total,
      order_id: orderOnOffer?.order_id,
      ticket_price: replacementData?.ticket_price,
      order_status: state?.orderAdmistration?.orderStatus?.id,
      note_internal: state?.orderAdmistration?.internalNote,
      note_penalty: state?.penalty?.notes,
      row: replacementData?.row,
      first_seat: replacementData?.first_seat,
      ticket_section: replacementData?.seating_location,
      team_ids: state?.team_id?.map((item: any) => item?.id),
      // assign_to: "team",
      wallet_deduction: state?.deduct_from_wallet,
    }),
    [state, replacementData, orderOnOffer]
  );

  // Replacement API call
  const { refetch: refetchOfferOrder } = useQuery(
    [QUERIES.REPLACEMENT_LIST_TEAM],
    () => offerOrder(payload),
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: false,
      onSettled: (data) => {
        setCustomNewOrderLoader(false);

        if (data?.data?.status) {
          showAlert(data?.message, false, 2000);

          setTimeout(() => {
            layout.setOrderOnOffer(ORDER_ON_OFFER);
            layout.setTabValue("cancelled");
            layout?.setQuery(orderOnOffer?.order_id);
          }, 2000);
        } else if (data) {
          const errorMessage =
            data?.data?.errors || data?.errors?.[0] || "Something went wrong.";
          showAlert(errorMessage, true);
          layout.setOrderOnOffer((current: any) => ({
            ...current,
            disabledButton: false,
          }));
        }
      },
    }
  );

  const handleTeamChange = useCallback((data: any) => {
    setState((current: any) => ({
      ...current,
      [data?.target?.name]: data?.target?.value,
    }));
  }, []);

  const handleSellerLevelChange = useCallback(
    (data: any) => {
      const sellerlevelIDs = data?.target?.value?.map((item: any) => item?.id);

      setState((current: any) => ({
        ...current,
        [data?.target?.name]: data?.target?.value,
        team_id: layout?.teamsOptions?.filter(
          (option: any) =>
            option?.id !== orderOnOffer?.team_id &&
            sellerlevelIDs?.includes(option?.seller_level_id)
        ),
      }));
    },
    [layout?.teamsOptions, orderOnOffer?.team_id]
  );

  // Listing API call for team listing price
  const { isLoading: teamListingPriceLoading } = useQuery(
    [QUERIES.ORDER_ON_OFFER, state?.team_id],
    () =>
      getOrderReplacementTeamListingPrice({
        order_id: orderOnOffer?.order_id,
        team_id: orderOnOffer?.team_id,
      }),
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: Boolean(state?.team_id?.length > 0),
      onSettled: (data) => {
        if (data?.data?.status) {
          setState((current: any) => ({
            ...current,
            available_amount: data?.data?.wallet_balance,
            // ticketDetails: {
            //   ...current?.ticketDetails,
            //   ticketPrice: {
            //     ...current?.ticketDetails?.ticketPrice,
            //     value: data?.data?.target_team?.ticket_price,
            //     symbol: data?.data?.target_team?.team_currency,
            //   },
            // },
          }));
        }
      },
    }
  );

  const handleOnSelection = (
    data: any,
    fieldName: string,
    options: any,
    visibleOptions: any = null
  ) => {
    if (teamsRef && teamsRef?.current) {
      teamsRef.current.blur();
    }

    // setOurderOutOfferCustomLoading(true);

    handleSelectDeselectAll(data, fieldName, options, visibleOptions, setState);
  };

  return (
    <React.Fragment>
      <div
        className={` ${
          coreLayout?.asideMenuOpen
            ? "md:max-w-[calc(100%_-_13.8125rem)]"
            : "md:max-w-[calc(100%_-_3.75rem)]"
        } show !scrollbar-thin  !scrollbar-thumb-violet-800 !scrollbar-thumb-rounded-full scrollbar-track-gray-650 !h-full overflow-y-auto overflow-x-hidden outline-none upload-ticket-popup offcanvas s_ticket offcanvas-end fixed bottom-0 top-[3.75rem] right-0 shadow-lg bg-clip-padding  transition duration-300 ease-in-out font-medium w-full z-[50]  max-h-[calc(100%-3.75rem)] min-h-[calc(100%-3.75rem)] bg-gray-650 `}
      >
        <div className="relative flex w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none dark:bg-neutral-600 h-full">
          <div className=" main-content-wrap  min-h-full bg-white flex flex-col">
            {/* BACK TO ORDER LISTING   */}

            <BackToOrder
              assign="offer"
              confirmed={isDisabled}
              title={`Order out to offer`}
            />
            <div className="inner-main-content p-5 overflow-auto h-full 123 scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full">
              <div className="top-filterbar rounded border mb-5">
                {/* EVENT DETAILS */}
                <EventDetails data={orderOnOffer} />
                {/* FILTER HIGHLIGHTED  */}
                <HighligtedFilter
                  data={orderOnOffer}
                  NoBorderBottom={true}
                  confirmedScreen={true}
                />
              </div>

              {/* ASSIGN NEW TEAM */}
              <div className="replacement-listing rounded border mb-5">
                <Header
                  title={`Order out to offer`}
                  className="ellips-text text-sm14 font-medium md:pl-5 md:p-[.9375rem] p-2.5 leading-5"
                />

                {/* DROPDOWN FIELDS  */}
                {!isDisabled ? (
                  <div className="panelty-status md:p-5 px-2.5 py-3.5 flex flex-wrap gap-y-5 -mx-2.5">
                    <div className="panelty-note lg:w-1/4 md:w-1/2 w-full  px-2.5">
                      <MultiDropDownField
                        name="seller_level"
                        classNamePrefix={"form_multiDropDown"}
                        placeholder="Seller Level"
                        value={state?.seller_level}
                        handleOnChange={(data: any) =>
                          handleSellerLevelChange(data)
                        }
                        options={layout?.sellerLevelOptions}
                        isMultiSelection={true}
                      />
                    </div>
                    {isActive?.fieldStatus && (
                      <div className="panelty-note lg:w-1/4 md:w-1/2 w-full  px-2.5">
                        <MultiDropDownField
                          name="team_id"
                          classNamePrefix={"form_multiDropDown"}
                          placeholder="Team"
                          value={state?.team_id}
                          handleOnChange={(data: any) => handleTeamChange(data)}
                          options={teamIDOptions}
                          isMultiSelection={true}
                          selectedRef={teamsRef}
                          // handleOnSelection={(
                          //   data: any,
                          //   visibleOptions: any
                          // ) => {

                          // }}

                          handleOnSelection={(
                            data: any,
                            visibleOptions: any
                          ) => {
                            handleOnSelection(
                              data,
                              "team_id",
                              teamIDOptions,
                              visibleOptions
                            );
                          }}
                        />
                      </div>
                    )}
                  </div>
                ) : (
                  <>
                    {/* FILTER HIGHLIGHTED  */}
                    <HighligtedFilter
                      data={replacementData}
                      bgColor="bg-white"
                      NoBorderBottom={true}
                      confirmedScreen={true}
                    />
                  </>
                )}
              </div>

              {isActive?.fieldStatus && (
                <>
                  {/* EDITABLE - BLOCK */}
                  {!isDisabled && (
                    <div className="order-administration rounded border mb-5 anim-fade z-[11] relative">
                      <Header
                        title={`Amend ticket details`}
                        className="ellips-text text-sm14 font-medium max-w-[18.75rem] md:pl-5 md:p-[.9375rem] p-2.5 leading-5"
                      />

                      {/* INPUT FIELDS  */}
                      <div className="panelty-status md:p-5 px-2.5 py-3.5 flex flex-wrap gap-y-5 -mx-2.5 ">
                        {/* CATEGORY */}
                        <div className="panelty-note lg:w-1/4 md:w-1/2 w-full  px-2.5">
                          <DropDownField
                            value={state?.ticketDetails?.category}
                            name="category"
                            placeholder="Category"
                            // isDisabled={isDisabled}
                            isClearable={true}
                            options={categoryOptions?.categories}
                            handleOnChange={(data: any) =>
                              setState((currentValue: any) => {
                                return {
                                  ...currentValue,
                                  ticketDetails: {
                                    ...currentValue?.ticketDetails,
                                    [data?.target?.name]: data?.target?.value,
                                    section: null,
                                  },
                                };
                              })
                            }
                          />
                        </div>

                        {/* SECTION */}
                        <div className="panelty-note lg:w-1/4 md:w-1/2 w-full  px-2.5">
                          <DropDownField
                            value={state?.ticketDetails?.section}
                            name="section"
                            placeholder="Section/block"
                            // isDisabled={isDisabled}
                            isClearable={true}
                            options={state?.ticketDetails?.category?.sections}
                            handleOnChange={(data: any) =>
                              setState((currentValue: any) => {
                                return {
                                  ...currentValue,
                                  ticketDetails: {
                                    ...currentValue?.ticketDetails,
                                    [data?.target?.name]: data?.target?.value,
                                  },
                                };
                              })
                            }
                          />
                        </div>

                        {/* ROW */}

                        <div className="panelty-note lg:w-1/4 md:w-1/2 w-full  flex flex-wrap gap-y-5">
                          <div className="panelty-input md:w-1/2 w-full px-2.5">
                            <Textfield
                              name="row"
                              id={"row-new-order"}
                              value={state?.ticketDetails?.row}
                              label={"Row"}
                              handleOnChange={(data: any) => {
                                setState((currentValue: any) => {
                                  return {
                                    ...currentValue,
                                    ticketDetails: {
                                      ...currentValue?.ticketDetails,
                                      [data?.target?.name]: data?.target?.value,
                                    },
                                  };
                                });
                              }}
                            />
                          </div>
                          <div className="panelty-input md:w-1/2 w-full px-2.5">
                            <Textfield
                              name="first_seat"
                              value={state?.ticketDetails?.first_seat}
                              id={"first-seat-new-order"}
                              label={"First seat"}
                              handleOnChange={(data: any) => {
                                setState((currentValue: any) => {
                                  return {
                                    ...currentValue,
                                    ticketDetails: {
                                      ...currentValue?.ticketDetails,
                                      [data?.target?.name]: data?.target?.value,
                                    },
                                  };
                                });
                              }}
                            />
                          </div>
                        </div>

                        {/* SEAT */}

                        {/* TICKET PRICE */}
                        <div
                          className={`panelty-input lg:w-1/4 md:w-1/2 px-2.5 w-full`}
                        >
                          <PriceField
                            value={state?.ticketDetails?.ticketPrice?.value}
                            currenncySymbol={
                              state?.ticketDetails?.ticketPrice?.symbol
                            }
                            name="ticketPrice"
                            label={"Ticket price"}
                            textFielClassName={`truncate`}
                            id={`ticketPriceorderoutoffer`}
                            handleOnChange={(data: any) => {
                              setState((currentValue: any) => {
                                return {
                                  ...currentValue,
                                  ticketDetails: {
                                    ...currentValue?.ticketDetails,
                                    [data?.target?.name]: data?.target?.value,
                                  },
                                };
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="order-panelty rounded border mb-5 anim-fade">
                    {/* HEADER */}
                    <Header
                      title="Order penalty"
                      className="ellips-text text-sm14 font-medium md:pl-5 md:p-[.9375rem] p-2.5 leading-5"
                    />
                    <SellarPenalityDetails
                      currenncy={SYMBOLS?.[orderOnOffer?.refund_currency]}
                      inputValue={state?.penalty?.total}
                      amount={state?.available_amount}
                      checkedValue={state?.deduct_from_wallet}
                      onChange={(e: any) =>
                        setState((currentValue: any) => {
                          return {
                            ...currentValue,
                            deduct_from_wallet: e.target.checked,
                          };
                        })
                      }
                      confirmation={layout?.orderOnOffer?.confirmed}
                      sellarName={orderOnOffer?.team_name}
                    />

                    {/* PENALITY INPUT FIELDS - BLOCK */}
                    <div
                      className={`${
                        !isDisabled ? "bg-indigo-500" : "bg-white"
                      }  panelty-block md:px-5 md:py-4 p-2.5 flex flex-wrap items-center bg-opacity-[7%] md:gap-x-5 gap-2.5 border-b`}
                    >
                      {!isDisabled && (
                        <>
                          <div className="panelty-radio flex items-center gap-[.9375rem] font-medium md:w-auto w-full">
                            <div className="inline-flex items-center font-medium rounded text-sm13 single_radio leading-5">
                              <input
                                type="radio"
                                name="penaltyType"
                                value="absolute"
                                checked={
                                  state?.penalty?.penaltyType === "absolute"
                                    ? true
                                    : false
                                }
                                id="absolutetype"
                                onChange={(e: any) => {
                                  setState((current: any) => {
                                    return {
                                      ...current,
                                      penalty: {
                                        ...current?.penalty,
                                        penaltyType: "absolute",
                                        penaltyValue: "",
                                        total: 0,
                                      },
                                    };
                                  });
                                }}
                              />
                              <label htmlFor="absolutetype" className="">
                                ({SYMBOLS[orderOnOffer?.ticket_currency]})
                                Amount
                              </label>
                            </div>
                            <div className="inline-flex items-center font-medium rounded text-sm13 single_radio leading-5">
                              <input
                                type="radio"
                                name="penaltyType"
                                value="relative"
                                checked={
                                  state?.penalty?.penaltyType === "relative"
                                    ? true
                                    : false
                                }
                                id="relativetype"
                                onChange={(e: any) => {
                                  setState((current: any) => {
                                    return {
                                      ...current,
                                      penalty: {
                                        ...current?.penalty,
                                        penaltyType: "relative",
                                        penaltyValue: "",
                                        total: 0,
                                      },
                                    };
                                  });
                                }}
                              />
                              <label htmlFor="relativetype" className="">
                                (%) Percentage
                              </label>
                            </div>
                          </div>
                          <div className="panelty-input max-w-[9.375rem]">
                            {/* {JSON.stringify(orderOnOffer?.ticket_currency)} */}
                            <PriceField
                              textfieldType="number"
                              value={state?.penalty?.penaltyValue}
                              currenncySymbol={
                                state?.penalty?.penaltyType === "absolute"
                                  ? orderOnOffer?.ticket_currency
                                  : "PER"
                              }
                              name="penaltyValue"
                              label="Penalty value"
                              textFielClassName={`truncate`}
                              id={`penaltyValue`}
                              handleOnChange={(data: any) => {
                                setState((currentValue: any) => {
                                  const penaltyType =
                                    currentValue?.penalty?.penaltyType;
                                  const orderValue =
                                    orderOnOffer?.transaction_value;
                                  const penaltyValue =
                                    data?.target?.value?.value;

                                  let total: any = 0;

                                  if (penaltyType === "relative") {
                                    total = (
                                      (penaltyValue / 100) *
                                      orderValue
                                    ).toFixed(2);
                                  } else if (penaltyType === "absolute") {
                                    total = penaltyValue;
                                  }

                                  return {
                                    ...currentValue,
                                    deduct_from_wallet:
                                      Number(total) >
                                      Number(state?.available_amount)
                                        ? false
                                        : currentValue?.deduct_from_wallet,
                                    penalty: {
                                      ...currentValue?.penalty,
                                      penaltyValue,
                                      total,
                                    },
                                  };
                                });
                              }}
                            />
                          </div>
                        </>
                      )}
                      <h5 className="text-sm14 font-semibold">
                        Penalty total:{" "}
                        {`${
                          SYMBOLS[orderOnOffer?.ticket_currency]
                        }${decimalNumber(state?.penalty?.total)}`}
                      </h5>
                    </div>

                    {/* PENALITY NOTES - BLOCK */}
                    <div className="panelty-note md:px-5 md:py-5 px-2.5 py-3.5 -mx-2.5">
                      <div className="md:w-1/2 w-full px-2.5">
                        <Label data="Penalty notes" required={true} />
                        {!isDisabled ? (
                          <input
                            className={`bg-opacity-50 border 
                        font-medium transition bg-gray-100 focus:border-indigo-500 focus:bg-white outline-none peer
                               border-gray-300 whitespace-normal text-sm12 rounded  px-2 block text-violet-800 resize-none py-1.5 leading-4 w-full`}
                            name="notes"
                            value={state?.penalty?.notes}
                            disabled={isDisabled}
                            onChange={(e: any) => {
                              setState((currentValue: any) => {
                                return {
                                  ...currentValue,
                                  penalty: {
                                    ...currentValue?.penalty,
                                    notes: e?.target?.value,
                                  },
                                };
                              });
                            }}
                          />
                        ) : (
                          <div className="bg-opacity-100  !border-gray-100 text-violet-800 font-medium transition bg-gray-100 focus:border-indigo-500 focus:bg-white outline-none peer whitespace-normal text-sm12 rounded  px-2 block  resize-none py-1.5 leading-4 w-full min-h-[1.875rem]">
                            {state?.penalty?.notes}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* ORDER ADMINISTRATION - BLOCK */}
                  <div className="order-administration rounded border ">
                    <Header
                      title="Order administration (internal)"
                      className="ellips-text text-sm14 font-medium max-w-[18.75rem] md:pl-5 md:p-[.9375rem] p-2.5 leading-5"
                    />

                    {/* INPUT FIELDS  */}
                    <div className="panelty-status md:p-5 px-2.5 py-3.5 flex flex-wrap gap-y-5 -mx-2.5">
                      <div className="panelty-note lg:w-1/4 md:w-1/2 w-full px-2.5">
                        <Label data="Internal order status" required={true} />
                        <DropDownField
                          value={state?.orderAdmistration?.orderStatus}
                          name="orderStatus"
                          placeholder={`${
                            state?.orderAdmistration?.orderStatus
                              ? ""
                              : "Internal order status"
                          }`}
                          isDisabled={isDisabled}
                          isClearable={true}
                          options={convertToFormattedData(
                            layout?.salesOptions?.internal_order_statuses
                          )}
                          handleOnChange={(data: any) =>
                            setState((currentValue: any) => {
                              return {
                                ...currentValue,
                                orderAdmistration: {
                                  ...currentValue?.orderAdmistration,
                                  [data?.target?.name]: data?.target?.value,
                                },
                              };
                            })
                          }
                        />
                      </div>
                      <div className="panelty-note md:w-1/2 w-full px-2.5">
                        <Label data="Internal notes" required={true} />

                        {!isDisabled ? (
                          <input
                            className={`bg-opacity-50 border font-medium transition bg-gray-100  focus:border-indigo-500 focus:bg-white outline-none peer
                               border-gray-300 whitespace-normal text-sm12 rounded w-full px-2 block text-violet-800 resize-none py-1.5 leading-4`}
                            name="internalNote"
                            value={state?.orderAdmistration?.internalNote}
                            disabled={isDisabled}
                            onChange={(e: any) => {
                              setState((currentValue: any) => {
                                return {
                                  ...currentValue,
                                  orderAdmistration: {
                                    ...currentValue?.orderAdmistration,
                                    internalNote: e?.target?.value,
                                  },
                                };
                              });
                            }}
                          />
                        ) : (
                          <div
                            className="bg-opacity-50 border text-violet-800 font-medium transition bg-gray-100  focus:border-indigo-500 focus:bg-white outline-none peer
                               border-gray-300 whitespace-normal text-sm12 rounded w-full px-2 block resize-none py-1.5 leading-4 min-h-[1.875rem]"
                          >
                            {state?.orderAdmistration?.internalNote}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
            {/* ACTION BUTTONS */}
            <div className=" offcanvas-footer bottom-0 right-0 w-full flex flex-wrap justify-between px-5 py-3 bg-white z-10 border-t">
              {!layout?.orderOnOffer?.confirmed &&
              !layout?.orderOnOffer?.disabledButton ? (
                <div className="l_btn gap-2.5 flex flex-wrap items-center justify-end w-full">
                  <button
                    type="button"
                    className="border border-gray-300 hover:border-indigo-500 text-violet-800 hover:text-white bg-white hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5"
                    onClick={() => {
                      layout.setOrderOnOffer(ORDER_ASSIGN_TO_NEW_TEAM);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className={`${
                      isActive?.reviewStatus
                        ? "hover:text-white active: bg-violet-500 text-white hover:bg-indigo-500 active:bg-indigo-500 anim-fade"
                        : "bg-gray-100 text-gray-500 pointer-events-none"
                    } relative z-10    transition  rounded text-sm13 px-2.5 py-0.5`}
                    onClick={() => {
                      layout.setOrderOnOffer((currentValue: any) => {
                        return {
                          ...currentValue,
                          confirmed: true,
                        };
                      });
                    }}
                  >
                    Review
                  </button>
                </div>
              ) : (
                layout?.orderOnOffer?.confirmed &&
                !layout?.orderOnOffer?.disabledButton && (
                  <div className="l_btn gap-2.5 flex flex-nowrap items-center justify-end w-full overflow-auto whitespace-nowrap max-md:justify-start">
                    <span className="text-sm13">
                      Do you confirm all details are correct?
                    </span>
                    <button
                      type="button"
                      className="border border-gray-300 hover:border-indigo-500 text-violet-800 hover:text-white bg-white hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5"
                      onClick={() => {
                        layout.setOrderOnOffer((currentValue: any) => {
                          return {
                            ...currentValue,
                            confirmed: false,
                          };
                        });
                      }}
                    >
                      Back
                    </button>
                    <button
                      type="button"
                      className={` relative z-10   hover:text-white active: bg-violet-500 text-white hover:bg-indigo-500 active:bg-indigo-500 transition  rounded text-sm13 px-2.5 py-0.5`}
                      onClick={() => {
                        layout.setOrderOnOffer((currentValue: any) => {
                          return {
                            ...currentValue,
                            confirmed: false,
                            disabledButton: true,
                          };
                        });
                        setCustomNewOrderLoader(true);
                        // refetch();
                        refetchOfferOrder();
                      }}
                    >
                      Confirm
                    </button>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>
      {customNewOrderLoader && (
        <ProgressBardLoader LoadingText="" secondCounter={1.5} />
      )}
    </React.Fragment>
  );
};

export default OrderOnOffer;
