import { LayoutContext } from "components/core/LayoutProvider";
import DatePickerField from "components/formComponent/DatePickerField";
import DropDownField from "components/formComponent/DropDownField";
import PriceField_copy from "components/formComponent/PriceField";
import TextInput from "components/formComponent/TextInput";
import { IKTSVG } from "components/ui/IKTSVG";
import { KTSVG } from "components/ui/KTSVG";
import {
  decimalNumber,
  ticketTypeRename,
  ticketTypeRenameForSalesReport,
} from "helpers/Functions";
import { showAlert } from "helpers/ShowAlert";
import moment from "moment";
import TooltipPortal from "pages/tx_trade/table/component/TooltipPortal";
import { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import notifyIcon from "../../../assets/media/icons/other_icons/notifyIcon.svg";
import CalenderSVG from "../../../assets/media/icons/standard_icons/calendar.svg";
import checkIcon from "../../../assets/media/icons/standard_icons/check_circle_new.svg";
import ClockIcon from "../../../assets/media/icons/standard_icons/clock-outline.svg";
import LocationIcon from "../../../assets/media/icons/standard_icons/geo_icon.svg";

import { splitTypeOptions } from "helpers/AssetHelpers";
import { SALES_REDIRECT_ROUTES } from "pages/Sales/core/const";
import { DashboardContext } from "../core/DashboardProvider";
import { getConvertedCurrency, orderAction } from "../core/requests";

const CONFIRMATION_INITIAL_STATE = {
  status: false,
  message: "",
  output: "",
};

const OrderOutOfferDetailsModal = ({
  userOptions,
  setOrderOutOfferListData,
  refetchOrderOutOffer,
  scrollToTop,
}: any) => {
  const navigate = useNavigate();
  const layout = useContext(DashboardContext);
  const coreLayout = useContext(LayoutContext);

  const [order, setOrder] = useState(layout.selectedOrderOutOffer?.order);
  const [selectedTeam, setSelectedTeam] = useState<any>(null);
  const [selectedUser, setSelectedUser] = useState<any>(null);
  const [acceptedOrder, setAcceptedOrder] = useState(false);
  const [confirmation, setConfirmation] = useState(CONFIRMATION_INITIAL_STATE);
  const [orderStatus, setOrderStatus] = useState("");
  const [changeCurrencyLoading, setChangeCurrencyLoading] = useState(false);

  const isGodAdmin = coreLayout?.allowedAccess?.account_info?.is_god_admin;
  const userSpecificData: any = coreLayout?.allowedAccess?.allowed_access?.find(
    (item: any) => item?.id === "user-specific-data"
  )?.status;
  const currentUserId = coreLayout?.authUser?.id;

  const handleOrderAction = ({ user_id, status }: any) => {
    orderAction({
      status,
      user_id,
      order_id: order?.order_id,
    })
      .then((res) => {
        if (res.data?.status) {
          setOrderOutOfferListData((prev: any) =>
            prev.filter((item: any) => item?.order_id !== order?.order_id)
          );
          if (status) {
            setAcceptedOrder(true);
            setOrderStatus(res.data?.order?.order_status);
          } else {
            document.getElementById("orderoutofferdetails-close")?.click();
          }
          setConfirmation(CONFIRMATION_INITIAL_STATE);
          refetchOrderOutOffer();
          scrollToTop();
        } else {
          showAlert(res.message, true);
        }
      })
      .catch((err) => {
        console.error("Order action error:", err);
      });
  };

  const getTeamsOptions = useMemo(() => {
    if (!isGodAdmin) return [];
    const allTeams = coreLayout?.allowedAccess?.teams;
    return allTeams.filter((team: any) => order?.offer_team?.includes(team.id));
  }, [order, isGodAdmin, coreLayout?.allowedAccess?.teams]);

  const getUsersOptions = useMemo(() => {
    return isGodAdmin ? selectedTeam?.users : userOptions;
  }, [isGodAdmin, selectedTeam, userOptions]);

  const isAcceptDisabled = useMemo(() => {
    if (isGodAdmin) return !selectedTeam || !selectedUser;
    return !userSpecificData && !selectedUser;
  }, [isGodAdmin, selectedTeam, selectedUser, userSpecificData]);

  useEffect(() => {
    setSelectedTeam(null);
    setSelectedUser(null);
    setAcceptedOrder(false);
    setOrder(layout.selectedOrderOutOffer?.order);
  }, [layout.selectedOrderOutOffer]);

  const navigateToSales = () => {
    navigate(SALES_REDIRECT_ROUTES[orderStatus], { replace: true });
    coreLayout.setGlobalSales(order?.order_id);
  };

  const ticketTypesToDisplay = useMemo(() => {
    let ticketTypes: Array<any> =
      order?.ticket_types?.map((item: any) =>
        ticketTypeRenameForSalesReport(item.name, item.subTypes)
      ) || [];

    ticketTypes = [ticketTypeRename(order?.ticket_type), ...ticketTypes];

    return ticketTypes;
  }, [order]);

  useEffect(() => {
    if (getTeamsOptions?.length === 1) {
      setSelectedTeam(getTeamsOptions[0]);
    }

    if (getUsersOptions?.length === 1) {
      setSelectedUser(getUsersOptions[0]);
    }
  }, [getTeamsOptions, getUsersOptions]);

  const handleTeamChange = (data: any) => {
    const teamValue = data?.target?.value;
    // if (!teamValue || !teamValue.base_currency) {
    //   console.error("Invalid team data:", data);
    //   return;
    // }

    setSelectedTeam(teamValue);
    setSelectedUser(null);
    if (teamValue && teamValue?.base_currency) {
      setChangeCurrencyLoading(true);
      const { ticket_price, ticket_currency, face_value, face_value_currency } =
        order || {};
      const payload = {
        conversion_array: [
          {
            ticket_price,
            from_currency: ticket_currency,
            to_currency: teamValue.base_currency,
          },
          {
            face_value,
            from_currency: face_value_currency,
            to_currency: teamValue.base_currency,
          },
        ],
      };

      getConvertedCurrency(payload)
        .then((res) => {
          if (res?.data?.status && res.data?.converted_array) {
            setOrder((prev: any) => ({
              ...prev,
              ticket_price: res.data.converted_array[0]?.ticket_price_converted,
              ticket_currency: teamValue.base_currency,
              face_value: res.data.converted_array[1]?.face_value_converted,
              face_value_currency: teamValue.base_currency,
            }));
          }
        })
        .catch((err) => {
          console.error("Error during currency conversion:", err);
        })
        .finally(() => setChangeCurrencyLoading(false));
    }
  };
  return (
    <div
      data-te-modal-init
      className={`offcanvas offcanvas-end add_teammember fill_canvas  fixed bottom-0 top-[3.75rem] right-0 shadow-lg bg-clip-padding outline-none transition duration-300 ease-in-out font-medium ${
        coreLayout?.asideMenuOpen
          ? "md:max-w-[calc(100%_-_13.8125rem)]"
          : "md:max-w-[calc(100%_-_3.75rem)]"
      } w-full upload-ticket-popup z-40  ${
        layout?.selectedOrderOutOffer?.status === true && "show"
      }`}
      // tabIndex={-1}
      id="orderoffer"
      aria-labelledby="orderofferLabel"
    >
      <div className="md:max-w-[45.3125rem] max-w-full bg-white shadow-3xl w-full h-full ml-auto relative flex flex-col">
        <div className=" flex justify-between border-t border-b">
          <h5
            className="offcanvas-title mb-0 leading-normal text-sm15 font-semibold py-2 px-5"
            id="offcanvasRightLabel"
          >
            Order out to offer
          </h5>
          <div className="buttons flex flex-wrap">
            <button
              type="button"
              className="flex items-center justify-center w-9 border-l box-content group"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              id="orderoutofferdetails-close"
              onClick={() => {
                setConfirmation(CONFIRMATION_INITIAL_STATE);
                setSelectedTeam("");
                setSelectedUser("");
                layout.setSelectedOrderOutOffer({});
              }}
            >
              <KTSVG
                className="fill-violet-500 group-hover:fill-indigo-500 h-2.5 w-2.5 transition"
                path="standard_icons/cross.svg"
                svgClassName="w-2.5 h-2.5"
              />
            </button>
          </div>
        </div>

        <div
          className="offcanvas-body h-full w-full flex-row overflow-y-auto scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full px-5 py-5 mb-1"
          id="orderoutofferbody"
        >
          {acceptedOrder && (
            <div className="p-3.5 px-[0.9375rem] pb-3.5 border border-green-600/20 bg-green-600/[7%] mb-5 rounded">
              <div className="mb-3.5 flex items-center">
                <IKTSVG
                  path={checkIcon}
                  svgClassName="w-[1.125rem] h-[1.125rem]"
                  className="w-[1.125rem] h-[1.125rem] fill-green-600 flex items-center justify-center rounded-full"
                />
                <h4 className="text-sm13 font-semibold ml-1.5 leading-[1.125rem]">
                  Order accepted
                </h4>
              </div>
              <p className="text-sm12 font-medium leading-[.9375rem]">
                Thank you for accepting this order offer. Order ID{" "}
                <span
                  className="underline font-semibold cursor-pointer"
                  onClick={navigateToSales}
                >
                  {order?.order_id}
                </span>{" "}
                is now in your Sales.
              </p>
            </div>
          )}
          <div className="firstRow p-3.5 border mb-5 rounded">
            <div className="title mb-3.5 relative pr-[1.125rem]">
              <h3 className="text-sm13 font-medium leading-4">
                {order?.event_description}
              </h3>
              {order?.event_expired_soon === true && (
                <div className="absolute top-0 right-0">
                  <IKTSVG
                    path={notifyIcon}
                    svgClassName="w-[.5625rem] h-2.5"
                    className="w-[1.125rem] h-[1.125rem] bg-rose-500 flex items-center justify-center rounded-full cursor-pointer hover:bg-indigo-500"
                    dataTooltipID={`order-offer-details-${order?.order_id}`}
                  />

                  <TooltipPortal
                    id={`order-offer-details-${order?.order_id}`}
                    content={`Offer ending in ${order?.event_expired_hours}hrs`}
                    className="!bg-white !py-0.5 !px-1.5 font-medium !text-xxs z-[1045] mt-1.5 text-center !opacity-100"
                  />
                </div>
              )}
            </div>
            <div className="eventInfo flex flex-wrap gap-x-2.5 gap-y-1">
              <div className="singleInfo">
                <p className="flex items-center text-xs text-gray-500 font-medium">
                  <IKTSVG
                    path={CalenderSVG}
                    className="mr-1.5"
                    svgClassName="w-3 h-3 fill-gray-500 "
                  />
                  {moment(order?.event_date || new Date()).format(
                    "ddd, DD MMM YYYY"
                  )}
                </p>
              </div>
              <div className="singleInfo">
                <p className="flex items-center text-xs text-gray-500 font-medium">
                  <IKTSVG
                    path={ClockIcon}
                    className="mr-1.5"
                    svgClassName="w-3 h-3 fill-gray-500 "
                  />
                  {moment(order?.event_date || new Date()).format("HH:mm")}
                </p>
              </div>
              <div className="singleInfo">
                <p className="flex items-center text-xs text-gray-500 font-medium">
                  <IKTSVG
                    path={LocationIcon}
                    className="mr-1.5"
                    svgClassName="w-3 h-3 fill-gray-500 "
                  />
                  {order?.address}
                </p>
              </div>
            </div>
          </div>
          <div className="firstRow p-3.5 border mb-5 rounded">
            <div className="text-sm13 font-semibold leading-[1.0625rem] mb-[.875rem]">
              Ticket detail
            </div>
            <div className="formBlock flex flex-wrap -mx-[.4375rem] gap-y-[.875rem]">
              <div className="singleFOrmBLock md:w-1/2 w-full px-[.4375rem]">
                <label className="text-sm12 font-medium mb-[.3125rem] text-gray-500 block leading-4">
                  Ticket Type
                </label>
                <TextInput
                  name={`customer_contact_number`}
                  type="text"
                  value={ticketTypeRenameForSalesReport(
                    order?.ticket_type,
                    order?.sub_ticket_type
                  )}
                  required={false}
                  placeholder="None"
                  className="!h-[1.875rem] border-0"
                  disabled={true}
                  checkHidden={true}
                />
              </div>
              <div className="singleFOrmBLock md:w-1/2 w-full px-[.4375rem]">
                <label className="text-sm12 font-medium mb-[.3125rem] text-gray-500 block leading-4">
                  Quantity
                </label>
                <TextInput
                  name={`customer_contact_number`}
                  type="text"
                  value={order?.ticket_quantity}
                  required={false}
                  placeholder="None"
                  className="!h-[1.875rem] border-0"
                  disabled={true}
                  checkHidden={true}
                />
              </div>
              <div className="tagLine w-full px-[.4375rem]">
                <p className="bg-rose-500 bg-opacity-[7%] px-2.5 py-[.4375rem] left-4 text-sm12 font-medium rounded">
                  The accepted ticket types for this order are{" "}
                  <span className="font-semibold">
                    {ticketTypesToDisplay &&
                      ticketTypesToDisplay
                        .slice(0, ticketTypesToDisplay?.length - 1)
                        .join(", ")}
                  </span>{" "}
                  {ticketTypesToDisplay?.length >= 2 && "and"}{" "}
                  <span className="font-semibold">
                    {ticketTypesToDisplay &&
                      ticketTypesToDisplay[ticketTypesToDisplay?.length - 1]}
                  </span>{" "}
                  only. Once you accept the order, you can change the ticket
                  type from your order details page.
                </p>
              </div>
              <div className="singleFOrmBLock md:w-1/2 w-full px-[.4375rem]">
                <label className="text-sm12 font-medium mb-[.3125rem] text-gray-500 block leading-4">
                  Split type
                </label>
                <TextInput
                  name={`customer_contact_number`}
                  type="text"
                  value={
                    order?.spite_type
                      ? splitTypeOptions([
                          { id: order?.spite_type, name: order?.spite_type },
                        ])[0].name
                      : ""
                  }
                  required={false}
                  placeholder="None"
                  className="!h-[1.875rem] border-0"
                  disabled={true}
                  checkHidden={true}
                />
              </div>
              <div className="singleFOrmBLock md:w-1/2 w-full px-[.4375rem]">
                <label className="text-sm12 font-medium mb-[.3125rem] text-gray-500 block leading-4">
                  Category
                </label>
                <TextInput
                  name={`customer_contact_number`}
                  type="text"
                  value={order?.seating_location}
                  required={false}
                  placeholder="None"
                  className="!h-[1.875rem] border-0"
                  disabled={true}
                  checkHidden={true}
                />
              </div>

              <div className="singleFOrmBLock md:w-1/2 w-full px-[.4375rem]">
                <label className="text-sm12 font-medium mb-[.3125rem] text-gray-500 block leading-4">
                  Section/block
                </label>
                <TextInput
                  name={`customer_contact_number`}
                  type="text"
                  value={order?.seating_location}
                  required={false}
                  placeholder="None"
                  className="!h-[1.875rem] border-0"
                  disabled={true}
                  checkHidden={true}
                />
              </div>
              <div className="flex flex-wrap singleFOrmBLock md:w-1/2 w-full max-md:px-[.4375rem] gap-y-3.5">
                <div className="singleFOrmBLock md:w-1/2 w-full md:px-[.4375rem]">
                  <label className="text-sm12 font-medium mb-[.3125rem] text-gray-500 block leading-4">
                    Row
                  </label>
                  <TextInput
                    name={`customer_contact_number`}
                    type="text"
                    value={order?.items?.[0]?.row}
                    required={false}
                    placeholder="None"
                    className="!h-[1.875rem] border-0"
                    disabled={true}
                    checkHidden={true}
                  />
                </div>
                <div className="singleFOrmBLock md:w-1/2 w-full md:px-[.4375rem]">
                  <label className="text-sm12 font-medium mb-[.3125rem] text-gray-500 block leading-4">
                    First seat
                  </label>
                  <TextInput
                    name={`customer_contact_number`}
                    type="text"
                    value={order?.items?.[0]?.seat}
                    required={false}
                    placeholder="None"
                    className="!h-[1.875rem] border-0"
                    disabled={true}
                    checkHidden={true}
                  />
                </div>
              </div>
              <div
                className={`singleFOrmBLock md:w-1/2 w-full px-[.4375rem] priceDisoffer ${
                  changeCurrencyLoading && "shimmer-effect"
                }`}
              >
                <label
                  className={`text-sm12 font-medium mb-[.3125rem] text-gray-500 block leading-4 `}
                >
                  Face value
                </label>

                <PriceField_copy
                  isDisabled={true}
                  currenncySymbol={order?.face_value_currency}
                  value={decimalNumber(order?.face_value)}
                />
              </div>
              <div
                className="singleFOrmBLock md:w-1/2 w-full px-[.4375rem]"
                title={order?.restrictions?.join(", ")}
              >
                <label className="text-sm12 font-medium mb-[.3125rem] text-gray-500 block leading-4">
                  Benefits/restrictions
                </label>
                <TextInput
                  name={`customer_contact_number`}
                  type="text"
                  value={order?.restrictions?.join(", ")}
                  required={false}
                  placeholder="None"
                  className="!h-[1.875rem] border-0 truncate"
                  disabled={true}
                  checkHidden={true}
                />
              </div>
              {/* <div className="singleFOrmBLock md:w-1/2 w-full px-[.4375rem]">
                <label className="text-sm12 font-medium mb-[.3125rem] text-gray-500 block leading-4">
                  Restrictions
                </label>
                <TextInput
                  name={`customer_contact_number`}
                  type="text"
                  value={"test"}
                  required={false}
                  placeholder="None"
                  className="!h-[1.875rem] border-0"
                  checkHidden={true}
                  disabled={true}
                />
              </div> */}
              <div className="singleFOrmBLock md:w-1/2 w-full px-[.4375rem] disable_field">
                <label className="text-sm12 font-medium mb-[.3125rem] text-gray-500 block leading-4">
                  Date to ship
                </label>
                <DatePickerField
                  name={`customer_contact_number`}
                  type="text"
                  value={moment(
                    order?.date_shipped || new Date(),
                    "DD/MM/YYYY"
                  ).toDate()}
                  format="dd/MM/yy"
                  required={false}
                  className="!h-[1.875rem] border-0 disabled "
                  isDisabled={true}
                />
              </div>
            </div>
          </div>

          <div className="firstRow p-3.5 border rounded">
            <div className="text-sm13 font-semibold leading-[1.0625rem] mb-[.875rem]">
              Price details
            </div>
            <div className="formBlock flex flex-wrap -mx-[.4375rem] gap-y-[.875rem]">
              <div
                className={`singleFOrmBLock md:w-1/2 w-full px-[.4375rem] priceDisoffer  ${
                  changeCurrencyLoading && "shimmer-effect"
                }`}
              >
                <label
                  className={`text-sm12 font-medium mb-[.3125rem] text-gray-500 block leading-4 `}
                >
                  Ticket price
                </label>

                <PriceField_copy
                  isDisabled={true}
                  value={decimalNumber(order?.ticket_price)}
                  currenncySymbol={order?.ticket_currency}
                />
              </div>
              <div
                className={`singleFOrmBLock md:w-1/2 w-full px-[.4375rem] priceDisoffer  ${
                  changeCurrencyLoading && "shimmer-effect"
                }`}
              >
                <label className="text-sm12 font-medium mb-[.3125rem] text-gray-500 block leading-4">
                  Order total
                </label>

                <PriceField_copy
                  isDisabled={true}
                  value={decimalNumber(
                    Number(order?.ticket_price) * order?.ticket_quantity
                  )}
                  currenncySymbol={order?.ticket_currency}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="  bottom-0 right-0 w-full flex flex-wrap justify-between px-5 py-1.5 bg-white z-10 border-t min-h-[3.125rem] offermodelfooter">
          <div className="r_btn gap-2.5 flex md:flex-wrap items-center md:justify-end w-full max-md:overflow-auto max-md:whitespace-nowrap">
            {!isGodAdmin && !acceptedOrder && !confirmation?.status && (
              <button
                className="border border-gray-300 hover:border-indigo-500 text-rose-500 hover:text-white bg-white hover:bg-indigo-500 transition font-medium rounded text-sm13 px-[.5625rem] py-[.1875rem] leading-[1.0625rem]"
                // data-bs-dismiss="offcanvas"
                // aria-label="close"
                onClick={() => {
                  // hadlneOrderAction({ status: false, user_id: current_user_id })
                  setConfirmation({
                    status: true,
                    message: "Confirm you want to reject this order?",
                    output: "reject",
                  });
                }}
              >
                Reject order
              </button>
            )}
            {confirmation?.status && (
              <div className="confirm_btn flex md:flex-wrap max-md:overflow-auto gap-2.5 items-center md:justify-end w-full ">
                <p className="text-sm13 font-medium">{confirmation?.message}</p>
                <button
                  className="relative text-sm13 font-medium leading-4 px-[.5625rem] pt-[.1875rem] pb-[.1875rem] border border-gray-300 rounded hover:border-indigo-500 hover:bg-indigo-500 hover:text-white w-[3.9375rem] h-6 flex items-center justify-center"
                  onClick={() => {
                    // setConfirmationActive(false);
                    setConfirmation(CONFIRMATION_INITIAL_STATE);
                  }}
                >
                  Cancel
                </button>
                <button
                  className={`relative active:text-white text-white hover:bg-indigo-500 hover:border-indigo-500 bg-green-600 transition font-medium rounded text-sm13 px-2.5 pt-[0.2188rem] pb-[0.2813rem] leading-4 disabled:pointer-events-none disabled:bg-gray-500`}
                  onClick={() => {
                    if (confirmation?.output === "accept") {
                      handleOrderAction({
                        status: true,
                        user_id: userSpecificData
                          ? currentUserId
                          : selectedUser?.id,
                      });
                    }

                    if (confirmation?.output === "reject") {
                      handleOrderAction({
                        status: false,
                        user_id: currentUserId,
                      });
                    }
                  }}
                >
                  Confirm
                </button>
              </div>
            )}

            {!confirmation?.status && (
              <>
                {isGodAdmin && !acceptedOrder && (
                  <DropDownField
                    name={"team_dropdown"}
                    options={getTeamsOptions}
                    value={selectedTeam}
                    handleOnChange={(data: any) => handleTeamChange(data)}
                    placeholder="Select team"
                    isClearable={true}
                    classname="block"
                    divClassName={`max-w-[9.375rem]`}
                    width="w-[4rem]"
                    menuPosition="fixed"
                  />
                )}
                {!userSpecificData && !acceptedOrder && (
                  <DropDownField
                    name={"user_dropdown"}
                    placeholder="Select user"
                    options={getUsersOptions}
                    handleOnChange={(data: any) => {
                      setSelectedUser(data?.target?.value);
                    }}
                    value={selectedUser}
                    divClassName={`max-w-[9.375rem]`}
                    isClearable={true}
                    menuPosition="fixed"
                    isDisabled={isGodAdmin ? !selectedTeam : false}
                  />
                )}

                {!acceptedOrder && (
                  <button
                    className={`relative z-10 active:text-white text-white hover:bg-indigo-500 hover:border-indigo-500 bg-violet-500 transition font-medium rounded text-sm13 px-2.5 py-1 leading-[1.0625rem] ${
                      isAcceptDisabled
                        ? "pointer-events-none !bg-gray-100 !text-gray-400"
                        : ""
                    }`}
                    onClick={() => {
                      // setConfirmationActive(true);
                      setConfirmation({
                        status: true,
                        message:
                          "Confirm you have reviewed all details and are able to fulfil this order?",
                        output: "accept",
                      });
                    }}
                    disabled={isAcceptDisabled}
                  >
                    {isGodAdmin || !userSpecificData ? "Assign" : "Accept"}{" "}
                    order
                  </button>
                )}

                {acceptedOrder && (
                  <button
                    className={`relative z-10 active:text-white text-white hover:bg-indigo-500 hover:border-indigo-500 bg-violet-500 transition font-medium rounded text-sm13 px-2.5 py-1 leading-[1.0625rem]`}
                    onClick={navigateToSales}
                  >
                    View order
                  </button>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderOutOfferDetailsModal;
