import { IKTSVG } from "components/ui/IKTSVG";
import {
  decimalNumber,
  getCurrencySymbol,
  ticketTypeRenameForSalesReport,
} from "helpers/Functions";
import moment from "moment";
import TooltipPortal from "pages/tx_trade/table/component/TooltipPortal";
import { useContext } from "react";
import notifyIcon from "../../../assets/media/icons/other_icons/notifyIcon.svg";
import CalenderSVG from "../../../assets/media/icons/standard_icons/calendar.svg";
import ClockIcon from "../../../assets/media/icons/standard_icons/clock-outline.svg";
import LocationIcon from "../../../assets/media/icons/standard_icons/geo_icon.svg";
import { DashboardContext } from "../core/DashboardProvider";

const OrderOutOfferCard = ({ order, scrollToTop }: any) => {
  const layout = useContext(DashboardContext);

  const handleCardClick = (order: any) => {
    layout.setSelectedOrderOutOffer({ order, status: true });
    scrollToTop();
  };

  return (
    <div
      className="singleOrder w-full md:w-1/2 lg2:w-1/4 px-2.5 mb-5"
      id="sadsdaswedsad"
    >
      <div
        className="border rounded p-[.9375rem] flex flex-col h-full group hover:border-indigo-500 cursor-pointer"
        data-bs-toggle="offcanvas"
        data-bs-target="#orderoffer"
        aria-controls="orderofferLabel"
        onClick={() => handleCardClick(order)}
      >
        <div className="title mb-3 relative pr-[1.125rem]">
          <h3 className="text-sm13 font-medium leading-[1.0625rem]">
            {order?.event_description}
          </h3>
          {order?.event_expired_soon === true && (
            <div className="absolute top-0 right-0">
              <IKTSVG
                path={notifyIcon}
                svgClassName="w-[.5625rem] h-2.5"
                className="w-[1.125rem] h-[1.125rem] bg-rose-500 flex items-center justify-center rounded-full cursor-pointer hover:bg-indigo-500"
                dataTooltipID={`order-offer-card-${order?.order_id}`}
              />
              <TooltipPortal
                id={`order-offer-card-${order?.order_id}`}
                content={`Offer ending in ${order?.event_expired_hours}hrs`}
                className="!bg-white !py-0.5 !px-1.5 font-medium !text-xxs z-[1045] mt-1.5 text-center !opacity-100"
              />
            </div>
          )}
        </div>
        <div className="eventInfo flex flex-wrap gap-x-5 gap-y-1 mb-[.9375rem]">
          <div className="singleInfo">
            <p className="flex items-center text-xs text-gray-500 font-medium">
              <IKTSVG
                path={CalenderSVG}
                className="mr-1.5"
                svgClassName="w-3 h-3 fill-gray-500 "
              />
              {moment(order?.event_date).format("ddd, DD MMM YYYY")}
            </p>
          </div>
          <div className="singleInfo">
            <p className="flex items-center text-xs text-gray-500 font-medium">
              <IKTSVG
                path={ClockIcon}
                className="mr-1.5"
                svgClassName="w-3 h-3 fill-gray-500 "
              />
              {moment(order?.event_date).format("HH:mm")}
            </p>
          </div>
          <div className="singleInfo">
            <p className="flex items-start text-xs text-gray-500 font-medium">
              <IKTSVG
                path={LocationIcon}
                className="mr-1.5 mt-0.5"
                svgClassName="w-3 h-3 fill-gray-500 "
              />
              {order?.address}
            </p>
          </div>
        </div>

        <div className="eventdetails mt-auto px-2.5 py-[.5625rem]  bg-gray-100/50 rounded group-hover:bg-indigo-500 group-hover:bg-opacity-[7%]">
          <div className="-mx-[.3125rem] flex flex-wrap gap-y-[.4375rem]">
            <div className="singleDetail md:w-1/2 w-full font-medium px-[.3125rem]">
              <label className="leading-[.875rem] text-xxs text-gray-500 mb-[.1875rem] flex">
                Category/Section
              </label>
              <h4 className="text-sm12 leading-4">{order?.seating_location}</h4>
            </div>

            <div className="singleDetail md:w-1/2 w-full font-medium px-[.3125rem]">
              <label className="leading-[.875rem] text-xxs text-gray-500 mb-[.1875rem] flex">
                Row/seat
              </label>
              <h4 className="text-sm12 leading-4 md:break-words">
                {order?.items[0]?.row}
                {order?.items[0]?.row && order?.items[0]?.seat && "/"}
                {order?.items[0]?.seat}

                {!order?.items[0]?.seat && !order?.items[0]?.row && "N/A"}
              </h4>
            </div>
            <div className="singleDetail md:w-1/2 w-full font-medium px-[.3125rem]">
              <label className="leading-[.875rem] text-xxs text-gray-500 mb-[.1875rem] flex">
                Ticket type
              </label>
              <h4 className="text-sm12 leading-4">
                {ticketTypeRenameForSalesReport(
                  order.ticket_type,
                  order?.sub_ticket_type
                )}
                {/* {TICKET_TYPE_MAP[order?.ticket_type]} */}
              </h4>
            </div>
            <div className="singleDetail md:w-1/2 w-full font-medium px-[.3125rem]">
              <label className="leading-[.875rem] text-xxs text-gray-500 mb-[.1875rem] flex">
                Quantity
              </label>
              <h4 className="text-sm12 leading-4">{order?.ticket_quantity}</h4>
            </div>
            <div className="singleDetail md:w-1/2 w-full font-medium px-[.3125rem]">
              <label className="leading-[.875rem] text-xxs text-gray-500 mb-[.1875rem] flex">
                Ticket price
              </label>
              <h4 className="text-sm12 leading-4">
                {getCurrencySymbol(order?.ticket_currency)}
                {decimalNumber(order?.ticket_price)}
              </h4>
            </div>
            {/* <div className="singleDetail md:w-1/2 w-full font-medium px-[.3125rem]">
              <label className="leading-[.875rem] text-xxs text-gray-500 mb-[.1875rem] flex">
                Date to ship
              </label>
              <h4 className="text-sm12 leading-4">{order?.date_shipped}</h4>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderOutOfferCard;
