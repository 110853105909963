import PageTitle from "components/core/PageTitle";
import { Outlet } from "react-router-dom";
import { SellerLevelsProvider } from "./core/SellerLevelsProvider";

const SellerLevelsWrapper = () => {
  return (
    <SellerLevelsProvider>
      <PageTitle title="Seller levels" />
      {/* <SellerLevels /> */}

      <div className="main-content-wrap relative pb-4">
        <Outlet />
      </div>
    </SellerLevelsProvider>
  );
};

export default SellerLevelsWrapper;
