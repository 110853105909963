/* eslint-disable react-hooks/exhaustive-deps */
import _, { size } from "lodash";
import moment from "moment";
import React, {
  memo,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useQuery } from "react-query";
import { Tooltip } from "react-tooltip";
import close from "../../../assets/media/icons/other_icons/Clear.svg";
import filter from "../../../assets/media/icons/other_icons/filter.svg";
import refreshSVG from "../../../assets/media/icons/other_icons/refresh.svg";
import crossSVG from "../../../assets/media/icons/standard_icons/cross.svg";
import { LayoutContext } from "../../../components/core/LayoutProvider";
import Checkbox from "../../../components/formComponent/Checkbox";
import DatePickerField from "../../../components/formComponent/DatePickerField";
import DropDownField from "../../../components/formComponent/DropDownField";
import MultiDropDownField from "../../../components/formComponent/MultiDropDownField";
import MultiSelect from "../../../components/formComponent/MultiSelect";
import { IKTSVG } from "../../../components/ui/IKTSVG";
import {
  addCommaInNumber,
  convertToFormattedData,
  handleSelectDeselectAll,
} from "../../../helpers/Functions";
import { QUERIES, REPORT_FILTERS_ADMIN } from "../../../helpers/const";
import useDebounce from "../../add_inventory/core/useDebounce";
import { ReportsContext } from "../core/ReportsProvider";
import { algoliaSearch } from "../core/requests";

const ReportsFilter = ({
  query,
  options,
  handleQuery,
  paginateData,
  debouncedSearch,
  handleAlgoliaValue,
  handleKeyDown,
  teamsOptions,
  isGodAdmin,
  reportsLoading,
  setShimmerLoader,
}: any) => {
  const layout = useContext(ReportsContext);
  const coreLayout = useContext(LayoutContext);
  const [algoliaOptions, setAlgoliaOptions] = useState<any>();
  const [paginateData1, setPaginateData] = useState(paginateData?.total);
  const [inputChange, setInputChange] = useState<any>();
  const [performerAlgoliaOptions, setPerformerAlgoliaOptions] = useState<any>(
    []
  );
  const [performerInput, setPerformerInput] = useState<string>("");
  const performerDebounce = useDebounce(performerInput, 600);
  const algoliaDebounce = useDebounce(inputChange, 1200);
  const salesFilter = layout.reportsFilterHandle;
  const access = coreLayout.allowedAccess?.allowed_access;
  const selectedRef: any = useRef<any>();

  const orderStatusOptions: any = options?.order_statuses?.flatMap(
    (group: any) => group.data
  );

  const paymentOptions = [
    { id: "paid", name: "Paid", value: "yes" },
    { id: "unpaid", name: "Unpaid", value: "no" },
  ];

  const userSpecific = access?.some(
    (item: any) => item.id === "user-specific-data" && item.status === true
  );

  // HANDLE INPUT CHANGE - ALGOLIA
  // const handleInputChange = useMemo(() => {
  //   return debounce((data, event) => {
  //     if (event.action === "menu-close" || event.action === "set-value") {
  //       if (!data) {
  //         setInputChange((current: any) => {
  //           return inputChange;
  //         });
  //       }
  //     } else {
  //       setInputChange((current: any) => {
  //         if (algoliaDebounce !== data) {
  //           // layout.setReportsFilterHandle((current: any) => {
  //           //   return {
  //           //     ...current,
  //           //     algoliaPage: "",
  //           //   };
  //           // });
  //           setAlgoliaOptions([]);
  //         }
  //         return data;
  //       });
  //     }
  //   });
  // }, [algoliaDebounce, inputChange]);
  const handleInputChange = (data: any, event: any) => {
    if (
      event.action === "menu-close" ||
      event.action === "set-value" ||
      event.action === "input-blur"
    ) {
      if (!data) {
        setInputChange((current: any) => {
          return current;
        });
      }
    } else {
      setInputChange((current: any) => {
        if (algoliaDebounce !== data) {
          setAlgoliaOptions([]);
        }
        return data;
      });
    }
  };
  const { isLoading, data } = useQuery(
    [`${QUERIES.ALGOLIA_SALES}`, algoliaDebounce, salesFilter],
    () => {
      if (inputChange && inputChange.trim() !== "") {
        return algoliaSearch({
          inputChange,
          salesFilter: { module: "report" },
          coreLayout,
        });
      }
      return Promise.resolve();
    },
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: true,
      enabled: algoliaDebounce ? true : false,
      onError: (err) => {},
    }
  );

  let algoliaData = data;

  // LOAD ALGOLIA OPTIONS IN STATE
  useEffect(() => {
    if (data?.hits) {
      setAlgoliaOptions((current: any) => {
        return current?.length > 0
          ? _.uniqBy([...current, ...data?.hits], "id")
          : data?.hits;
      });
    }
  }, [data]);

  // CALL QUERY VALUE VIA API
  useEffect(() => {
    handleAlgoliaValue(algoliaDebounce);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [algoliaDebounce]);

  // LOAD PERFORMER ALGOLIA
  const { isFetching: isPerformerLoading } = useQuery(
    [`${QUERIES.ALGOLIA_PERFORMERS}`, performerDebounce],
    () => {
      if (performerInput && performerInput.trim() !== "") {
        return algoliaSearch({
          inputChange: performerInput,
          searchIndex: "performers",
        });
      }
      return Promise.resolve(); // Return an empty resolved promise when algoliaDebounce is falsy or an empty string
    },
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: true,
      enabled: performerDebounce && performerDebounce.length > 2 ? true : false,
      onError: (err) => {},
      onSettled: (data: any) => {
        if (data && data?.hits?.length > 0) {
          setPerformerAlgoliaOptions(data.hits);
        }
      },
    }
  );

  // RESET ALGOLIA OPTIONS
  useEffect(() => {
    if (_.size(layout.reportsFilterHandle?.event_id) === 0) {
      // setAlgoliaOptions([]);
    }
  }, [layout.reportsFilterHandle?.event_id]);

  useEffect(() => {
    return () => {
      coreLayout.setGlobalReports("");
    };
  }, []);

  // ON CLEAR BUTTON - ALGOLIA
  const onSearch = (data: any) => {
    if (data?.target?.id !== "search") {
      setInputChange("");
      setAlgoliaOptions([]);
    }
  };

  // HANDLE REFRESH
  const handleRefresh = () => {
    handleQuery("");
    setInputChange("");
    setAlgoliaOptions([]);
    layout.setReportsFilterHandle((currentVal: any) => {
      return {
        ...currentVal,
        team_member: [],
        event_id: [],
        venue: "",
        orderDateRange: "",
        order_exchanges: [],
        eventDateRange: "",
        order_statuses: [],
        payment_status: [],
        team_id: [],
        internal_order_statuses: [],
        store_id: false,
        page: 1,
        category_ids: null,
        performer_ids: null,
        buyer_team_id: null,
      };
    });
  };

  // Function to get index of dragged Filter item
  const getFilterIndex = (id: any) => {
    let index = layout?.draggedItems?.findIndex((item: any) => item.id === id);
    return index + 1;
  };

  // const getFilterIndexForCheckbox = (id: any) => {
  //   let index = layout?.filterItems?.findIndex((item: any) => item.id === id);
  //   return index;
  // };
  const getFilterValue = isGodAdmin
    ? layout.filterItems
    : layout.filterItems?.filter(
        (obj: any) => !REPORT_FILTERS_ADMIN?.includes(obj?.name)
      );
  const getFilterIndexForCheckbox = (id: any) => {
    let index = getFilterValue?.findIndex((item: any) => item?.id === id);
    return index;
  };

  useEffect(() => {
    if (coreLayout.globalReports) {
      handleQuery(coreLayout.globalReports);
      layout.setReportsFilterHandle((currentVal: any) => {
        return {
          team_member: [],
          page: 1,
        };
      });
    }
  }, [coreLayout.globalReports]);
  useEffect(() => {
    if (paginateData) {
      setPaginateData(paginateData?.total);
    }
  }, [paginateData]);

  // HANDLE SELECT-DESELECT FOT TEAMS
  // const handleOnSelection = (data: any) => {
  //   if (selectedRef && selectedRef?.current) {
  //     selectedRef.current.blur();
  //   }
  //   if (data?.target?.id === "select-all") {
  //     layout.setReportsFilterHandle((currentValue: any) => {
  //       return {
  //         ...currentValue,
  //         team_id: teamsOptions?.filter((option: any) => option.name),
  //       };
  //     });
  //   } else if (data?.target?.id === "deselect-all") {
  //     layout.setReportsFilterHandle((currentValue: any) => {
  //       return {
  //         ...currentValue,
  //         team_id: "",
  //       };
  //     });
  //   }
  // };

  const teamSelectedRef: any = useRef<any>();
  const teamMemberSelectedRef: any = useRef<any>();
  const marketplaceSelectedRef: any = useRef<any>();
  const orderStatusSelectedRef: any = useRef<any>();
  const categorySelectedRef: any = useRef<any>();
  const performerSelectedRef: any = useRef<any>();
  const paymentStatusSelectedRef: any = useRef<any>();
  const orderInternalStatusSelectedRef: any = useRef<any>();
  const storesSelectedRef: any = useRef<any>();

  const handleOnSelection = (
    data: any,
    fieldName: string,
    options: any,
    visibleOptions: any = null
  ) => {
    if (teamSelectedRef && teamSelectedRef?.current) {
      teamSelectedRef.current.blur();
    }
    if (teamMemberSelectedRef && teamMemberSelectedRef?.current) {
      teamMemberSelectedRef.current.blur();
    }
    if (marketplaceSelectedRef && marketplaceSelectedRef?.current) {
      marketplaceSelectedRef.current.blur();
    }
    if (orderStatusSelectedRef && orderStatusSelectedRef?.current) {
      orderStatusSelectedRef.current.blur();
    }
    if (paymentStatusSelectedRef && paymentStatusSelectedRef?.current) {
      paymentStatusSelectedRef.current.blur();
    }
    if (
      orderInternalStatusSelectedRef &&
      orderInternalStatusSelectedRef?.current
    ) {
      orderInternalStatusSelectedRef.current.blur();
    }
    if (storesSelectedRef && storesSelectedRef?.current) {
      storesSelectedRef.current.blur();
    }
    if (categorySelectedRef && categorySelectedRef?.current) {
      categorySelectedRef.current.blur();
    }
    if (performerSelectedRef && performerSelectedRef?.current) {
      performerSelectedRef.current.blur();
    }

    handleSelectDeselectAll(
      data,
      fieldName,
      options,
      visibleOptions,
      layout.setReportsFilterHandle
    );
  };

  function ToggleElemClose() {
    const submenuWrapper: any = document.querySelector("#filtersMob");
    submenuWrapper.classList.remove("activeFIlter");
    document.body.classList.remove("overflow-hidden");
  }
  function ToggleElemOpen() {
    const submenuWrapper: any = document.querySelector("#filtersMob");
    submenuWrapper.classList.add("activeFIlter");
    document.body.classList.add("overflow-hidden");
  }
  const isTicketTypeExist =
    layout.reportsFilterHandle?.ticket_type &&
    size(layout.reportsFilterHandle?.ticket_type) > 0 &&
    Object.values(layout.reportsFilterHandle?.ticket_type)?.some(
      (item: any) => item
    );

  const clearDisable =
    (layout.reportsFilterHandle?.order_statuses &&
      layout.reportsFilterHandle?.order_statuses.length > 0) ||
    layout.reportsFilterHandle?.category_ids?.length > 0 ||
    layout.reportsFilterHandle?.performer_ids?.length > 0 ||
    layout.reportsFilterHandle?.buyer_teams?.length > 0 ||
    (layout.reportsFilterHandle?.team_member &&
      layout.reportsFilterHandle?.team_member.length > 0) ||
    layout.reportsFilterHandle?.venue ||
    (layout.reportsFilterHandle?.order_exchanges &&
      layout.reportsFilterHandle?.order_exchanges.length > 0) ||
    (layout.reportsFilterHandle?.orderDateRange &&
      layout.reportsFilterHandle?.orderDateRange?.startDate !== undefined &&
      layout.reportsFilterHandle?.orderDateRange?.endDate !== undefined) ||
    (layout.reportsFilterHandle?.eventDateRange &&
      layout.reportsFilterHandle?.eventDateRange?.startDate !== undefined &&
      layout.reportsFilterHandle?.eventDateRange?.endDate !== undefined) ||
    (query !== undefined && debouncedSearch) ||
    (layout.reportsFilterHandle?.event_id &&
      layout.reportsFilterHandle?.event_id?.length > 0) ||
    layout.reportsFilterHandle?.payment_status?.length > 0 ||
    layout?.reportsFilterHandle?.team_id?.length > 0 ||
    layout.reportsFilterHandle?.internal_order_statuses?.length > 0 ||
    layout.reportsFilterHandle?.store_id;

  const handleEventSearch = (): void => {
    const isMobileView = window.matchMedia("(max-width: 768px)").matches;
    if (inputChange && isMobileView) {
      handleQuery(inputChange);
    }
  };

  useEffect(() => {
    if (!performerInput) {
      setPerformerAlgoliaOptions(
        layout?.reportsFilterHandle?.performer_ids || []
      );
    }
  }, [layout?.reportsFilterHandle?.performer_ids, performerInput]);

  const internalOrderStatusOptions = useMemo(() => {
    const convertedOptions =
      convertToFormattedData(options?.internal_order_statuses) || [];

    return [
      {
        label: "No IOS",
        options: [
          {
            id: "",
            name: "No IOS",
            value: "",
          },
        ],
      },
      ...convertedOptions,
    ];
  }, [options?.internal_order_statuses]);
  return (
    <>
      <div className="filtersMob max-md:flex max-md:flex-col" id={"filtersMob"}>
        <div
          className="flex flex-wrap md:border-b border-t gap-y-2.5 py-4 px-[.9375rem] bg-white max-md:pt-14 max-md:flex-1 max-md:overflow-auto max-md:content-start"
          id={`filters_block`}
        >
          <div
            className="closePopup hidden max-md:flex"
            id="closePopup"
            onClick={ToggleElemClose}
          >
            <IKTSVG
              path={close}
              className="w-10 absolute top-[1px] right-0 h-10 flex items-center justify-center cursor-pointer z-[1] bg-white"
              svgClassName="w-4 h-4"
            />
          </div>
          <div className={`w-full lg2:w-1/3 lg:w-1/2 xl:w-[25%] px-[.3125rem]`}>
            {/* SEARCH BAR */}
            <div
              className={`relative flex-1 w-full md:mb-0 mb-2 rounded ${
                reportsLoading && "shimmer-effect"
              } `}
            >
              <div
                className="w-full  "
                title={
                  isGodAdmin
                    ? "Search event or order ID or marketplace ID"
                    : "Search event or order ID"
                }
              >
                <MultiSelect
                  name="event_id"
                  options={algoliaOptions}
                  isLoading={isLoading}
                  handleOnChange={(data: any, e: any) => {
                    layout.setReportsFilterHandle((currentValue: any) => {
                      // return {
                      //   ...currentValue,
                      //   [data?.target?.name]: data?.target?.value,
                      // };
                      return {
                        ...currentValue,
                        ...(e?.action !== "pop-value"
                          ? { [data?.target?.name]: data?.target?.value }
                          : {}),
                        page: 1,
                      };
                    });
                  }}
                  onMenuScrollToBottom={(data: any) => {
                    layout.setReportsFilterHandle((currentValue: any) => {
                      let currentPage = currentValue?.algoliaPage + 1;
                      if (algoliaData?.nbPages - 1 >= currentPage) {
                        return {
                          ...currentValue,
                          ...(currentValue?.algoliaPage
                            ? { algoliaPage: currentValue?.algoliaPage + 1 }
                            : { algoliaPage: 1 }),
                        };
                      } else {
                        return { ...currentValue };
                      }
                    });
                  }}
                  handleKeyDown={(event: any) => handleKeyDown(event)}
                  value={layout.reportsFilterHandle?.event_id}
                  inputValue={inputChange}
                  handleInputChange={handleInputChange}
                  onSearch={(data: any) => onSearch(data)}
                  classNamePrefix="inventory_select"
                  className="searchbar-wrap z-[3]"
                  placeholder={
                    isGodAdmin
                      ? "Search event or order ID or marketplace ID"
                      : "Search event or order ID"
                  }
                />
              </div>
            </div>
          </div>

          {/* VENUE */}
          {getFilterValue?.[`${getFilterIndexForCheckbox(1)}`]?.checked && (
            <div
              className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.3125rem] order-${getFilterIndex(
                1
              )}   `}
            >
              <div className={`${reportsLoading && "shimmer-effect"} rounded`}>
                <DropDownField
                  name="venue"
                  // classNamePrefix={"form_multiDropDown"}
                  placeholder="Venue"
                  value={layout.reportsFilterHandle?.venue}
                  handleOnChange={(data: any) =>
                    layout.setReportsFilterHandle((currentValue: any) => {
                      return {
                        ...currentValue,
                        [data?.target?.name]: data?.target?.value,
                        page: 1,
                      };
                    })
                  }
                  options={options?.venues?.map((item: any) => {
                    return { id: item.venue_id, name: item.venue };
                  })}
                  isClearable={true}
                />
              </div>
            </div>
          )}

          {/* MARKETPLACES */}
          {getFilterValue?.[`${getFilterIndexForCheckbox(2)}`]?.checked &&
            isGodAdmin && (
              <div
                className={`w-full rounded sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.3125rem] order-${getFilterIndex(
                  2
                )} ${reportsLoading && "shimmer-effect"} `}
              >
                <MultiDropDownField
                  name="order_exchanges"
                  classNamePrefix={"form_multiDropDown"}
                  placeholder="Marketplaces"
                  value={layout.reportsFilterHandle?.order_exchanges}
                  selectedRef={marketplaceSelectedRef}
                  isMultiSelection={true}
                  handleOnSelection={(data: any, visibleOptions: any) =>
                    handleOnSelection(
                      data,
                      "order_exchanges",
                      options?.order_exchanges,
                      visibleOptions
                    )
                  }
                  handleOnChange={(data: any) =>
                    layout.setReportsFilterHandle((currentValue: any) => {
                      return {
                        ...currentValue,
                        [data?.target?.name]: data?.target?.value,
                        page: 1,
                      };
                    })
                  }
                  options={options?.order_exchanges}
                  isClearable={true}
                />
              </div>
            )}

          {/* ORDER DATE */}
          {getFilterValue?.[`${getFilterIndexForCheckbox(3)}`]?.checked && (
            <div
              className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.3125rem] order-${getFilterIndex(
                3
              )} `}
            >
              <div className={`${reportsLoading && "shimmer-effect"} rounded`}>
                <DatePickerField
                  name="orderDateRange"
                  placeholder="Transaction date"
                  placeholderClass={"truncate max-w-[calc(100%_-_2.3125rem)]"}
                  disabledKeyboardNavigation={false}
                  value={
                    layout?.reportsFilterHandle?.orderDateRange
                      ? layout?.reportsFilterHandle?.orderDateRange
                      : undefined
                  }
                  isDateRange={true}
                  handleOnChange={(data: any) =>
                    layout.setReportsFilterHandle((currentValue: any) => {
                      return {
                        ...currentValue,
                        [data?.target?.name]: data?.target?.value,
                        page: 1,
                      };
                    })
                  }
                  format="dd/MM/yy"
                />
              </div>
            </div>
          )}

          {/* EVENT DATE */}
          {getFilterValue?.[`${getFilterIndexForCheckbox(4)}`]?.checked && (
            <div
              className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.3125rem] order-${getFilterIndex(
                4
              )}  `}
            >
              <div className={`${reportsLoading && "shimmer-effect"} rounded`}>
                <DatePickerField
                  name="eventDateRange"
                  placeholder="Event date"
                  disabledKeyboardNavigation={false}
                  value={
                    layout?.reportsFilterHandle?.eventDateRange
                      ? layout?.reportsFilterHandle?.eventDateRange
                      : undefined
                  }
                  isDateRange={true}
                  handleOnChange={(data: any) =>
                    layout.setReportsFilterHandle((currentValue: any) => {
                      return {
                        ...currentValue,
                        [data?.target?.name]: data?.target?.value,
                        page: 1,
                      };
                    })
                  }
                  format="dd/MM/yy"
                />
              </div>
            </div>
          )}

          {/* Team */}
          {isGodAdmin &&
            getFilterValue?.[`${getFilterIndexForCheckbox(5)}`]?.checked && (
              <div
                className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.3125rem] order-${getFilterIndex(
                  5
                )} `}
              >
                <div
                  className={`${reportsLoading && "shimmer-effect"} rounded`}
                >
                  <MultiDropDownField
                    isCapital={true}
                    isValueCapital={true}
                    classNamePrefix={"form_multiDropDown"}
                    options={teamsOptions}
                    placeholder={
                      layout.reportsFilterHandle?.team_id?.length > 0
                        ? "Teams"
                        : "All Teams"
                    }
                    isClearable={true}
                    name="team_id"
                    handleOnChange={(data: any) => {
                      layout.setReportsFilterHandle((currentValue: any) => {
                        return {
                          ...currentValue,
                          [data?.target?.name]: data?.target?.value,
                          page: 1,
                        };
                      });
                      if (
                        _.size(data?.target?.value) === 0 &&
                        _.size(layout.reportsFilterHandle?.team_member) > 0
                      ) {
                        layout.setReportsFilterHandle((currentValue: any) => {
                          return {
                            ...currentValue,
                            team_member: "",
                            page: 1,
                          };
                        });
                      }
                    }}
                    selectedRef={teamSelectedRef}
                    isMultiSelection={true}
                    handleOnSelection={(data: any, visibleOptions: any) =>
                      handleOnSelection(
                        data,
                        "team_id",
                        teamsOptions,
                        visibleOptions
                      )
                    }
                    value={layout.reportsFilterHandle?.team_id}
                    label="Teams"
                  />
                </div>
              </div>
            )}

          {/* TEAM MEMBERS */}
          <span className="order-1 order-2 order-3 order-4 order-5 hidden"></span>
          {!userSpecific &&
            getFilterValue?.[`${getFilterIndexForCheckbox(6)}`]?.checked && (
              <div
                className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.3125rem] order-${getFilterIndex(
                  6
                )} `}
              >
                <div
                  className={`${reportsLoading && "shimmer-effect"} rounded`}
                >
                  <MultiDropDownField
                    name="team_member"
                    classNamePrefix={"form_multiDropDown"}
                    placeholder="Team members"
                    value={layout.reportsFilterHandle?.team_member}
                    handleOnChange={(data: any) =>
                      layout.setReportsFilterHandle((currentValue: any) => {
                        return {
                          ...currentValue,
                          [data?.target?.name]: data?.target?.value,
                          page: 1,
                        };
                      })
                    }
                    options={options?.team_members}
                    isMenuPortalTarget={reportsLoading ? false : true}
                    selectedRef={teamMemberSelectedRef}
                    isMultiSelection={true}
                    handleOnSelection={(data: any, visibleOptions: any) =>
                      handleOnSelection(
                        data,
                        "team_member",
                        options?.team_members,
                        visibleOptions
                      )
                    }
                  />
                </div>
              </div>
            )}

          {/** Order status */}
          {getFilterValue?.[`${getFilterIndexForCheckbox(7)}`]?.checked && (
            <div
              className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.3125rem] order-${getFilterIndex(
                7
              )} `}
            >
              <div className={`${reportsLoading && "shimmer-effect"} rounded`}>
                <MultiDropDownField
                  options={orderStatusOptions}
                  classNamePrefix={"form_multiDropDown"}
                  placeholder="Order status"
                  name="order_statuses"
                  getOptionLabel="description"
                  isClearable={true}
                  getOptionValue="id"
                  value={layout?.reportsFilterHandle?.order_statuses}
                  handleOnChange={(data: any) =>
                    layout?.setReportsFilterHandle((currentValue: any) => {
                      return {
                        ...currentValue,
                        [data?.target?.name]: data?.target?.value,
                      };
                    })
                  }
                  selectedRef={orderStatusSelectedRef}
                  isMultiSelection={true}
                  handleOnSelection={(data: any, visibleOptions: any) =>
                    handleOnSelection(
                      data,
                      "order_statuses",
                      orderStatusOptions,
                      visibleOptions
                    )
                  }
                />
              </div>
            </div>
          )}

          {getFilterValue?.[`${getFilterIndexForCheckbox(8)}`]?.checked && (
            <div
              className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.3125rem] order-${getFilterIndex(
                8
              )} `}
            >
              <div className={`${reportsLoading && "shimmer-effect"} rounded`}>
                <MultiDropDownField
                  options={paymentOptions}
                  classNamePrefix={"form_multiDropDown"}
                  placeholder="Payment status"
                  name="payment_status"
                  isClearable={true}
                  value={layout?.reportsFilterHandle?.payment_status}
                  handleOnChange={(data: any) =>
                    layout?.setReportsFilterHandle((currentValue: any) => {
                      return {
                        ...currentValue,
                        [data?.target?.name]: data?.target?.value,
                      };
                    })
                  }
                  selectedRef={paymentStatusSelectedRef}
                  isMultiSelection={true}
                  handleOnSelection={(data: any, visibleOptions: any) =>
                    handleOnSelection(
                      data,
                      "payment_status",
                      paymentOptions,
                      visibleOptions
                    )
                  }
                />
              </div>
            </div>
          )}

          {getFilterValue?.[`${getFilterIndexForCheckbox(9)}`]?.checked &&
            isGodAdmin && (
              <div
                className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] xl:min-w-[13.125rem] px-[.3125rem] order-${getFilterIndex(
                  9
                )} `}
              >
                <div
                  className={`${reportsLoading && "shimmer-effect"} rounded`}
                >
                  <MultiDropDownField
                    name="internal_order_statuses"
                    classNamePrefix={"form_multiDropDown"}
                    placeholder="Internal order status"
                    value={layout.reportsFilterHandle?.internal_order_statuses}
                    handleOnChange={(data: any) =>
                      layout.setReportsFilterHandle((currentValue: any) => {
                        return {
                          ...currentValue,
                          [data?.target?.name]: data?.target?.value,
                          page: 1,
                        };
                      })
                    }
                    options={internalOrderStatusOptions}
                    isClearable={true}
                    isMenuPortalTarget={true}
                    selectedRef={orderInternalStatusSelectedRef}
                    isMultiSelection={true}
                    handleOnSelection={(data: any, visibleOptions: any) =>
                      handleOnSelection(
                        data,
                        "internal_order_statuses",
                        options?.internal_order_statuses,
                        visibleOptions
                      )
                    }
                  />
                </div>
              </div>
            )}

          {getFilterValue?.[`${getFilterIndexForCheckbox(10)}`]?.checked &&
            isGodAdmin && (
              <div
                className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] xl:min-w-[9.375rem] px-[.3125rem] order-${getFilterIndex(
                  10
                )} `}
              >
                <div
                  className={`${reportsLoading && "shimmer-effect"} rounded`}
                >
                  <Checkbox
                    label="Include webstores"
                    id="store_id"
                    name="store_id"
                    labelclass="text-gray-500"
                    checked={layout.reportsFilterHandle?.store_id}
                    tooltip={false}
                    handleOnChange={(data: any, e: any) => {
                      layout.setReportsFilterHandle((currentValue: any) => {
                        return {
                          ...currentValue,
                          [data?.target?.name]: data?.target?.checked,
                          page: 1,
                        };
                      });
                    }}
                  />
                </div>
              </div>
            )}

          {/* CATEGORY  */}
          {getFilterValue?.[`${getFilterIndexForCheckbox(11)}`]?.checked && (
            <div
              className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.3125rem] order-${getFilterIndex(
                11
              )} `}
            >
              <div className={`${reportsLoading && "shimmer-effect"} rounded`}>
                <MultiDropDownField
                  options={options?.categories}
                  classNamePrefix={"form_multiDropDown"}
                  placeholder="Category"
                  name="category_ids"
                  isClearable={true}
                  value={layout?.reportsFilterHandle?.category_ids}
                  handleOnChange={(data: any) =>
                    layout?.setReportsFilterHandle((currentValue: any) => {
                      return {
                        ...currentValue,
                        [data?.target?.name]: data?.target?.value,
                      };
                    })
                  }
                  selectedRef={categorySelectedRef}
                  isMultiSelection={true}
                  handleOnSelection={(data: any, visibleOptions: any) =>
                    handleOnSelection(
                      data,
                      "category_ids",
                      options?.categories,
                      visibleOptions
                    )
                  }
                />
              </div>
            </div>
          )}

          {/* PERFORMER - DROPDOWN */}
          {getFilterValue?.[`${getFilterIndexForCheckbox(12)}`]?.checked &&
            isGodAdmin && (
              <div
                className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.3125rem] order-${getFilterIndex(
                  12
                )} `}
              >
                <div
                  className={`${reportsLoading && "shimmer-effect"} rounded`}
                >
                  <MultiDropDownField
                    name="performer_ids"
                    classNamePrefix={"form_multiDropDown"}
                    placeholder="Performer"
                    value={layout?.reportsFilterHandle?.performer_ids}
                    inputValue={performerInput}
                    isLoading={isPerformerLoading}
                    handleInputChangeCallback={(input: string) => {
                      setPerformerInput(input);
                    }}
                    noOptionsMessageText={
                      !performerInput
                        ? "Search performer"
                        : performerInput.length <= 2
                        ? "Minimum 3 Characters"
                        : "No Options"
                    }
                    handleOnChange={(data: any) =>
                      layout?.setReportsFilterHandle((currentValue: any) => {
                        return {
                          ...currentValue,
                          [data?.target?.name]: data?.target?.value,
                        };
                      })
                    }
                    options={performerAlgoliaOptions}
                    isClearable={true}
                    //isMenuPortalTarget={shimmerLoader ? false : true}
                    selectedRef={performerSelectedRef}
                    isMultiSelection={true}
                    handleOnSelection={(data: any, visibleOptions: any) => {
                      handleOnSelection(
                        data,
                        "performer_ids",
                        performerAlgoliaOptions,
                        visibleOptions
                      );
                    }}
                  />
                </div>
              </div>
            )}

          {/* BUYER */}
          {getFilterValue?.[`${getFilterIndexForCheckbox(13)}`]?.checked && (
            <div
              className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.3125rem] order-${getFilterIndex(
                13
              )} `}
            >
              <div className={`${reportsLoading && "shimmer-effect"} rounded`}>
                <MultiDropDownField
                  options={options?.buyer_teams}
                  classNamePrefix={"form_multiDropDown"}
                  placeholder="Buyer"
                  name="buyer_team_id"
                  isClearable={true}
                  value={layout?.reportsFilterHandle?.buyer_team_id}
                  handleOnChange={(data: any) =>
                    layout?.setReportsFilterHandle((currentValue: any) => {
                      return {
                        ...currentValue,
                        [data?.target?.name]: data?.target?.value,
                      };
                    })
                  }
                  selectedRef={categorySelectedRef}
                  isMultiSelection={true}
                  handleOnSelection={(data: any, visibleOptions: any) =>
                    handleOnSelection(
                      data,
                      "buyer_team_id",
                      options?.buyer_teams,
                      visibleOptions
                    )
                  }
                />
              </div>
            </div>
          )}
        </div>
        <div className="bottomBtns hidden max-md:flex sticky bottom-0 left-0 w-full px-5 py-2.5 shadow-3xl">
          <button
            className={`border  hover:border-indigo-500  hover:text-white  hover:bg-indigo-500 transition rounded text-sm13 py-0.5 px-2.5 mr-2.5 my-0.5 ${
              clearDisable
                ? "bg-white text-violet-800 border-gray-300"
                : "bg-gray-100 text-gray-400 pointer-events-none border-gray-100"
            }`}
            onClick={handleRefresh}
          >
            Clear
          </button>

          <button
            className="border border-violet-500 hover:border-indigo-500 text-white bg-violet-500 hover:bg-indigo-500 transition rounded text-sm13 py-0.5 px-2.5 mr-2.5 my-0.5"
            onClick={() => {
              ToggleElemClose();
              handleEventSearch();
            }}
          >
            Apply
          </button>
        </div>
      </div>
      {/*third layer*/}
      <div
        className="flex flex-wrap customStyle relative bg-white border-t max-sm:justify-between"
        id="report_filter"
      >
        <div className="py-4 px-5 font-semibold text-sm13 sm:border-r text-center w-full sm:w-auto flex items-center max-sm:w-[calc(100%-9rem)]">
          {reportsLoading ? (
            <div className="shimmer-effect inline-block ">
              <span className="opacity-0">
                {paginateData?.total > 0
                  ? `${paginateData?.total} results`
                  : "0 results"}
              </span>
            </div>
          ) : (
            <>
              {paginateData1 > 0
                ? `${addCommaInNumber(paginateData1)} results`
                : "0 result"}
            </>
          )}

          {/* {paginateData?.total > 0
            ? `${paginateData?.total} results`
            : "0 result"} */}
        </div>
        <div className="flex items-center justify-center sm:justify-start px-5 py-3 gap-x-2.5 flex-1 filterChipArea overflow-auto flex-nowrap whitespace-nowrap scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full md:min-h-[3.375rem] max-sm:flex-1 max-sm:order-3 max-sm:border-t max-sm:justify-start">
          {reportsLoading ? (
            <div className="flex gap-5">
              <div
                className={`flex !rounded overflow-hidden ${
                  reportsLoading && "shimmer-effect"
                }`}
                style={{ height: "1.625rem", width: "9.375rem" }}
              >
                &nbsp;
              </div>
            </div>
          ) : (
            <>
              {((layout.reportsFilterHandle?.order_statuses &&
                layout.reportsFilterHandle?.order_statuses.length > 0) ||
                layout.reportsFilterHandle?.category_ids?.length > 0 ||
                layout.reportsFilterHandle?.performer_ids?.length > 0 ||
                layout.reportsFilterHandle?.buyer_team_id?.length > 0 ||
                (layout.reportsFilterHandle?.team_member &&
                  layout.reportsFilterHandle?.team_member.length > 0) ||
                layout.reportsFilterHandle?.venue ||
                (layout.reportsFilterHandle?.order_exchanges &&
                  layout.reportsFilterHandle?.order_exchanges.length > 0) ||
                (layout.reportsFilterHandle?.orderDateRange &&
                  layout.reportsFilterHandle?.orderDateRange?.startDate !==
                    undefined &&
                  layout.reportsFilterHandle?.orderDateRange?.endDate !==
                    undefined) ||
                (layout.reportsFilterHandle?.eventDateRange &&
                  layout.reportsFilterHandle?.eventDateRange?.startDate !==
                    undefined &&
                  layout.reportsFilterHandle?.eventDateRange?.endDate !==
                    undefined) ||
                (query !== undefined && debouncedSearch) ||
                (layout.reportsFilterHandle?.event_id &&
                  layout.reportsFilterHandle?.event_id?.length > 0) ||
                layout.reportsFilterHandle?.payment_status?.length > 0 ||
                layout?.reportsFilterHandle?.team_id?.length > 0 ||
                layout.reportsFilterHandle?.internal_order_statuses?.length >
                  0 ||
                layout.reportsFilterHandle?.store_id) && (
                <button
                  type="button"
                  className={`group border text-center bg-white rounded min-w-[1.5rem] w-6 h-6 hover:bg-indigo-500  hover:text-white hover:border-indigo-500 transition   ${
                    reportsLoading && "shimmer-effect"
                  } 
              `}
                  onClick={handleRefresh}
                  id="reset"
                >
                  <Tooltip
                    anchorId={`reset`}
                    content={`Reset`}
                    place="top"
                    variant="light"
                    className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10"
                  />
                  <IKTSVG
                    path={refreshSVG}
                    className="fill-violet-500 group-hover:fill-white transition"
                    svgClassName="ml-[.313rem] w-3 h-3"
                  />
                </button>
              )}

              {/* PAYMENT STATUS */}
              {layout.reportsFilterHandle?.payment_status &&
                layout.reportsFilterHandle?.payment_status?.length > 0 &&
                layout.reportsFilterHandle?.payment_status.map(
                  (user: any, index: number) => {
                    return (
                      <React.Fragment key={index}>
                        <div
                          className={`flex py-1 px-2.5 text-xs border rounded-full my-0.5   `}
                        >
                          {user?.name}

                          <button
                            type="button"
                            className="ml-2 hover:fill-indigo-500"
                            onClick={(e: any) => {
                              layout.setReportsFilterHandle(
                                (currentVal: any) => {
                                  let payment_status =
                                    currentVal?.payment_status?.filter(
                                      (user: any, indexNumber: number) =>
                                        index !== indexNumber
                                    );
                                  return {
                                    ...currentVal,
                                    payment_status: payment_status,
                                    page: 1,
                                  };
                                }
                              );
                            }}
                          >
                            <IKTSVG
                              path={crossSVG}
                              svgClassName="h-2 w-2 hover:fill-indigo-500"
                            />
                          </button>
                        </div>
                      </React.Fragment>
                    );
                  }
                )}

              {/* TEAM */}
              {layout.reportsFilterHandle?.team_id &&
                layout.reportsFilterHandle?.team_id?.length > 0 &&
                layout.reportsFilterHandle?.team_id.map(
                  (user: any, index: number) => {
                    return (
                      <React.Fragment key={index}>
                        <div className="flex py-1 px-2.5 text-xs border rounded-full my-0.5">
                          {user?.name}
                          <button
                            type="button"
                            className="ml-2 hover:fill-indigo-500"
                            onClick={(e: any) => {
                              layout.setReportsFilterHandle(
                                (currentVal: any) => {
                                  let team_id = currentVal?.team_id?.filter(
                                    (user: any, indexNumber: number) =>
                                      index !== indexNumber
                                  );
                                  return {
                                    ...currentVal,
                                    team_id: team_id,
                                    page: 1,
                                  };
                                }
                              );
                            }}
                          >
                            <IKTSVG
                              path={crossSVG}
                              svgClassName="h-2 w-2 hover:fill-indigo-500"
                            />
                          </button>
                        </div>
                      </React.Fragment>
                    );
                  }
                )}

              {/* TEAM MEMBER */}
              {layout.reportsFilterHandle?.team_member &&
                layout.reportsFilterHandle?.team_member?.length > 0 &&
                layout.reportsFilterHandle?.team_member.map(
                  (user: any, index: number) => {
                    return (
                      <React.Fragment key={index}>
                        <div
                          className={`flex py-1 px-2.5 text-xs border rounded-full my-0.5  ${
                            reportsLoading && "shimmer-effect"
                          } `}
                        >
                          <span className={`${reportsLoading && "opacity-0"}`}>
                            {user?.name}
                          </span>
                          <button
                            type="button"
                            className="ml-2 hover:fill-indigo-500"
                            onClick={(e: any) => {
                              layout.setReportsFilterHandle(
                                (currentVal: any) => {
                                  let team_member =
                                    currentVal?.team_member?.filter(
                                      (user: any, indexNumber: number) =>
                                        index !== indexNumber
                                    );
                                  return {
                                    ...currentVal,
                                    team_member: team_member,
                                    page: 1,
                                  };
                                }
                              );
                            }}
                          >
                            <IKTSVG
                              path={crossSVG}
                              svgClassName="h-2 w-2 hover:fill-indigo-500"
                            />
                          </button>
                        </div>
                      </React.Fragment>
                    );
                  }
                )}

              {/* DEBOUNCE ALGOLIA - QUERY */}
              {debouncedSearch && (
                <div
                  className={`${
                    query
                      ? "flex py-1 px-2.5 text-xs border rounded-full my-0.5"
                      : "hidden"
                  } `}
                >
                  {query}
                  <button
                    type="button"
                    className="ml-2 hover:fill-indigo-500"
                    onClick={() => {
                      handleQuery("");
                      setInputChange("");
                    }}
                  >
                    <IKTSVG
                      path={crossSVG}
                      svgClassName="h-2 w-2 hover:fill-indigo-500"
                    />
                  </button>
                </div>
              )}

              {/* ALGOLIA SELECTED - VALUES */}
              {layout.reportsFilterHandle?.event_id &&
                layout.reportsFilterHandle?.event_id?.length > 0 &&
                layout.reportsFilterHandle?.event_id.map(
                  (user: any, index: number) => {
                    return (
                      <React.Fragment key={index}>
                        <div className="flex py-1 px-2.5 text-xs border rounded-full my-0.5">
                          {user?.name}
                          <button
                            type="button"
                            className="ml-2 hover:fill-indigo-500"
                            onClick={(e: any) => {
                              layout.setReportsFilterHandle(
                                (currentVal: any) => {
                                  let event_id = currentVal?.event_id?.filter(
                                    (user: any, indexNumber: number) =>
                                      index !== indexNumber
                                  );
                                  return {
                                    ...currentVal,
                                    event_id: event_id,
                                    page: 1,
                                  };
                                }
                              );
                            }}
                          >
                            <IKTSVG
                              path={crossSVG}
                              svgClassName="h-2 w-2 hover:fill-indigo-500"
                            />
                          </button>
                        </div>
                      </React.Fragment>
                    );
                  }
                )}

              {/* VENUE */}
              {layout.reportsFilterHandle?.venue && (
                <div className="flex py-1 px-2.5 text-xs border rounded-full my-0.5">
                  {layout.reportsFilterHandle?.venue?.name}
                  <button
                    type="button"
                    className="ml-2 hover:fill-indigo-500"
                    onClick={() => {
                      layout.setReportsFilterHandle((currentVal: any) => {
                        return {
                          ...currentVal,
                          venue: "",
                          page: 1,
                        };
                      });
                    }}
                    title="Refresh"
                  >
                    <IKTSVG
                      path={crossSVG}
                      svgClassName="h-2 w-2 hover:fill-indigo-500"
                    />
                  </button>
                </div>
              )}

              {/* ORDER EXCHANGE */}
              {layout.reportsFilterHandle?.order_exchanges &&
                layout.reportsFilterHandle?.order_exchanges?.length > 0 &&
                layout.reportsFilterHandle?.order_exchanges.map(
                  (user: any, index: number) => {
                    return (
                      <React.Fragment key={index}>
                        {user?.name !== "" && (
                          <div className="flex py-1 px-2.5 text-xs border rounded-full my-0.5">
                            {user?.name}
                            <button
                              type="button"
                              className="ml-2 hover:fill-indigo-500"
                              onClick={(e: any) => {
                                layout.setReportsFilterHandle(
                                  (currentVal: any) => {
                                    let order_exchanges =
                                      currentVal?.order_exchanges?.filter(
                                        (user: any, indexNumber: number) =>
                                          index !== indexNumber
                                      );
                                    return {
                                      ...currentVal,
                                      order_exchanges: order_exchanges,
                                      page: 1,
                                    };
                                  }
                                );
                              }}
                            >
                              <IKTSVG
                                path={crossSVG}
                                svgClassName="h-2 w-2 hover:fill-indigo-500"
                              />
                            </button>
                          </div>
                        )}
                      </React.Fragment>
                    );
                  }
                )}

              {/* ORDER DATE RANGE  */}
              {layout.reportsFilterHandle?.orderDateRange &&
                layout.reportsFilterHandle?.orderDateRange?.startDate !==
                  undefined &&
                layout.reportsFilterHandle?.orderDateRange?.endDate !==
                  undefined && (
                  <div className="flex py-1 px-2.5 text-xs border rounded-full my-0.5">
                    {moment(
                      layout.reportsFilterHandle?.orderDateRange?.startDate
                    ).format("DD/MM/YYYY")}
                    <span className="px-2">-</span>
                    {moment(
                      layout.reportsFilterHandle?.orderDateRange?.endDate
                    ).format("DD/MM/YYYY")}
                    <button
                      type="button"
                      className="ml-2 hover:fill-indigo-500"
                      onClick={() =>
                        layout?.setReportsFilterHandle((current: any) => {
                          return {
                            ...current,
                            orderDateRange: "",
                          };
                        })
                      }
                    >
                      <IKTSVG
                        path={crossSVG}
                        svgClassName="h-2 w-2 hover:fill-indigo-500"
                      />
                    </button>
                  </div>
                )}

              {/* EVENT DATE RANGE */}
              {layout.reportsFilterHandle?.eventDateRange &&
                layout.reportsFilterHandle?.eventDateRange?.startDate !==
                  undefined &&
                layout.reportsFilterHandle?.eventDateRange?.endDate !==
                  undefined && (
                  <div className="flex py-1 px-2.5 text-xs border rounded-full my-0.5">
                    {moment(
                      layout.reportsFilterHandle?.eventDateRange?.startDate
                    ).format("DD/MM/YYYY")}
                    <span className="px-2">-</span>
                    {moment(
                      layout.reportsFilterHandle?.eventDateRange?.endDate
                    ).format("DD/MM/YYYY")}
                    <button
                      type="button"
                      className="ml-2 hover:fill-indigo-500"
                      onClick={() =>
                        layout?.setReportsFilterHandle((current: any) => {
                          return {
                            ...current,
                            eventDateRange: "",
                          };
                        })
                      }
                    >
                      <IKTSVG
                        path={crossSVG}
                        svgClassName="h-2 w-2 hover:fill-indigo-500"
                      />
                    </button>
                  </div>
                )}

              {/* order_statuses */}
              {layout.reportsFilterHandle?.order_statuses &&
                layout.reportsFilterHandle?.order_statuses?.length > 0 &&
                layout.reportsFilterHandle?.order_statuses.map(
                  (user: any, index: number) => {
                    return (
                      <React.Fragment key={index}>
                        <div className="flex py-1 px-2.5 text-xs border rounded-full my-0.5">
                          {user?.description}
                          <button
                            type="button"
                            className="ml-2 hover:fill-indigo-500"
                            onClick={(e: any) => {
                              layout.setReportsFilterHandle(
                                (currentVal: any) => {
                                  let order_statuses =
                                    currentVal?.order_statuses?.filter(
                                      (user: any, indexNumber: number) =>
                                        index !== indexNumber
                                    );
                                  return {
                                    ...currentVal,
                                    order_statuses: order_statuses,
                                    page: 1,
                                  };
                                }
                              );
                            }}
                          >
                            <IKTSVG
                              path={crossSVG}
                              svgClassName="h-2 w-2 hover:fill-indigo-500"
                            />
                          </button>
                        </div>
                      </React.Fragment>
                    );
                  }
                )}

              {layout.reportsFilterHandle?.internal_order_statuses &&
                layout.reportsFilterHandle?.internal_order_statuses.map(
                  (obj: any, index: number) => {
                    return (
                      <React.Fragment key={index}>
                        <div className="flex py-1 px-2.5 text-xs border rounded-full">
                          {obj?.name}
                          <button
                            type="button"
                            className="ml-2 hover:fill-indigo-500"
                            onClick={(e: any) => {
                              layout.setReportsFilterHandle(
                                (currentVal: any) => {
                                  let internal_order_statuses =
                                    currentVal?.internal_order_statuses?.filter(
                                      (user: any, indexNumber: number) =>
                                        index !== indexNumber
                                    );
                                  return {
                                    ...currentVal,
                                    internal_order_statuses:
                                      internal_order_statuses,
                                    page: 1,
                                  };
                                }
                              );
                            }}
                          >
                            <IKTSVG path={crossSVG} svgClassName="h-2 w-2" />
                          </button>
                        </div>
                      </React.Fragment>
                    );
                  }
                )}

              {layout.reportsFilterHandle?.store_id && (
                <div className="flex py-1 px-2.5 text-xs border rounded-full">
                  Include webstores
                  <button
                    type="button"
                    className="ml-2 hover:fill-indigo-500"
                    onClick={(e: any) => {
                      layout.setReportsFilterHandle((currentVal: any) => {
                        return {
                          ...currentVal,
                          store_id: false,
                          page: 1,
                        };
                      });
                    }}
                  >
                    <IKTSVG path={crossSVG} svgClassName="h-2 w-2" />
                  </button>
                </div>
              )}

              {layout.reportsFilterHandle?.category_ids &&
                layout.reportsFilterHandle?.category_ids?.length > 0 &&
                layout.reportsFilterHandle?.category_ids.map(
                  (user: any, index: number) => {
                    return (
                      <React.Fragment key={index}>
                        {user?.name !== "" && (
                          <div className="flex py-1 px-2.5 text-xs border rounded-full my-0.5">
                            {user?.name}
                            <button
                              type="button"
                              className="ml-2 hover:fill-indigo-500"
                              onClick={(e: any) => {
                                layout.setReportsFilterHandle(
                                  (currentVal: any) => {
                                    let category_ids =
                                      currentVal?.category_ids?.filter(
                                        (user: any, indexNumber: number) =>
                                          index !== indexNumber
                                      );
                                    return {
                                      ...currentVal,
                                      category_ids: category_ids,
                                      page: 1,
                                    };
                                  }
                                );
                              }}
                            >
                              <IKTSVG
                                path={crossSVG}
                                svgClassName="h-2 w-2 hover:fill-indigo-500"
                              />
                            </button>
                          </div>
                        )}
                      </React.Fragment>
                    );
                  }
                )}

              {layout.reportsFilterHandle?.performer_ids &&
                layout.reportsFilterHandle?.performer_ids?.length > 0 &&
                layout.reportsFilterHandle?.performer_ids.map(
                  (user: any, index: number) => {
                    return (
                      <React.Fragment key={index}>
                        {user?.name !== "" && (
                          <div className="flex py-1 px-2.5 text-xs border rounded-full my-0.5">
                            {user?.name}
                            <button
                              type="button"
                              className="ml-2 hover:fill-indigo-500"
                              onClick={(e: any) => {
                                layout.setReportsFilterHandle(
                                  (currentVal: any) => {
                                    let performer_ids =
                                      currentVal?.performer_ids?.filter(
                                        (user: any, indexNumber: number) =>
                                          index !== indexNumber
                                      );
                                    return {
                                      ...currentVal,
                                      performer_ids: performer_ids,
                                      page: 1,
                                    };
                                  }
                                );
                              }}
                            >
                              <IKTSVG
                                path={crossSVG}
                                svgClassName="h-2 w-2 hover:fill-indigo-500"
                              />
                            </button>
                          </div>
                        )}
                      </React.Fragment>
                    );
                  }
                )}

              {/* BUYER - CHIP */}
              {layout.reportsFilterHandle?.buyer_team_id &&
                layout.reportsFilterHandle?.buyer_team_id?.length > 0 &&
                layout.reportsFilterHandle?.buyer_team_id.map(
                  (user: any, index: number) => {
                    console.log("asd", user);
                    return (
                      <React.Fragment key={index}>
                        {user?.name !== "" && (
                          <div className="flex py-1 px-2.5 text-xs border rounded-full my-0.5">
                            {user?.name}
                            <button
                              type="button"
                              className="ml-2 hover:fill-indigo-500"
                              onClick={(e: any) => {
                                layout.setReportsFilterHandle(
                                  (currentVal: any) => {
                                    let buyer_team_id =
                                      currentVal?.buyer_team_id?.filter(
                                        (user: any, indexNumber: number) =>
                                          index !== indexNumber
                                      );
                                    return {
                                      ...currentVal,
                                      buyer_team_id: buyer_team_id,
                                      page: 1,
                                    };
                                  }
                                );
                              }}
                            >
                              <IKTSVG
                                path={crossSVG}
                                svgClassName="h-2 w-2 hover:fill-indigo-500"
                              />
                            </button>
                          </div>
                        )}
                      </React.Fragment>
                    );
                  }
                )}

              {/* {((layout.reportsFilterHandle?.order_statuses &&
                layout.reportsFilterHandle?.order_statuses.length > 0) ||
                (layout.reportsFilterHandle?.team_member &&
                  layout.reportsFilterHandle?.team_member.length > 0) ||
                layout.reportsFilterHandle?.venue ||
                (layout.reportsFilterHandle?.order_exchanges &&
                  layout.reportsFilterHandle?.order_exchanges.length > 0) ||
                (layout.reportsFilterHandle?.orderDateRange &&
                  layout.reportsFilterHandle?.orderDateRange?.startDate !==
                    undefined &&
                  layout.reportsFilterHandle?.orderDateRange?.endDate !==
                    undefined) ||
                (layout.reportsFilterHandle?.eventDateRange &&
                  layout.reportsFilterHandle?.eventDateRange?.startDate !==
                    undefined &&
                  layout.reportsFilterHandle?.eventDateRange?.endDate !==
                    undefined) ||
                (query !== undefined && debouncedSearch) ||
                (layout.reportsFilterHandle?.event_id &&
                  layout.reportsFilterHandle?.event_id?.length > 0) ||
                layout.reportsFilterHandle?.payment_status?.length > 0 ||
                layout?.reportsFilterHandle?.team_id?.length > 0 ||
                layout.reportsFilterHandle?.internal_order_statuses?.length >
                  0 ||
                layout.reportsFilterHandle?.store_id?.length > 0) && (
                <button
                  type="button"
                  className={`group border text-center bg-white rounded w-6 h-6 hover:bg-indigo-500  hover:text-white hover:border-indigo-500 transition   ${
                    reportsLoading && "shimmer-effect"
                  } 
              `}
                  onClick={handleRefresh}
                  id="reset"
                >
                  <Tooltip
                    anchorId={`reset`}
                    content={`Reset`}
                    place="top"
                    variant="light"
                    className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10"
                  />
                  <IKTSVG
                    path={refreshSVG}
                    className="fill-violet-500 group-hover:fill-white transition"
                    svgClassName="ml-[.313rem] w-3 h-3"
                  />
                </button>
              )} */}
            </>
          )}
        </div>

        <div
          className="filterBtn max-md:flex max-md:items-center hidden p-2.5 text-sm12 border-l max-sm:order-2 max-sm:max-w-[9rem]"
          id="filterBtn"
          onClick={ToggleElemOpen}
        >
          <button className="group inline-flex group items-center text-white hover:text-white bg-violet-500  hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5">
            <IKTSVG
              path={filter}
              className="fill-white mr-1.5"
              svgClassName="w-4 h-4"
            />
            Filter results
          </button>
        </div>
        {/* <Pagination paginateData={paginateData} /> */}
      </div>
    </>
  );
};

export default memo(ReportsFilter);
