const DataCell = ({
  value,
  subValue,
  classname = "",
  width = "",
  padding,
  noCapitalize,
  noEllips = true,
  evtName = false,
  renderDirect = false,
}: any) => {
  return (
    <>
      <td
        className={`font-medium min-h-[2.8125rem] h-[2.8125rem] ${width} ${padding} ${evtName} matchedView`}
      >
        {renderDirect ? (
          value
        ) : (
          <span className={`${noEllips ? " " : "ellips-text"} !h-full`}>
            <p
              className={` ${classname} ${
                noCapitalize === "yes" ? "" : "capitalize"
              }`}
            >
              {/* {children ? children : value || "-"} */}
              {value}
            </p>
            {subValue && (
              <small className="text-xxs text-gray-500">{subValue}</small>
            )}
          </span>
        )}
      </td>
    </>
  );
};

export default DataCell;
